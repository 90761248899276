import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    color: "rgba(0,0,0,0.8)",
    fontFamily:"Source Sans Pro, sans-serif",
    // paddingBottom: "24px",
  },
  formControl: {
    width: "100%"
  },
  tileNumeral: {
    height: "14px",
    fontFamily:"Source Sans Pro, sans-serif",
    width: "120px",
    fontSize: "11px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.27,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#585858",
    textTransform:"uppercase"
  },
    selectedItem: {
      "&:first-child": {
        color:"black"
      },
      color: "#005eff",
      fontSize: "14px",
      minHeight: "36px",
      "&:hover": {
        fontWeight: "bold",
        color: "#005eff",
        marginLeft: "5px",
        marginRight: "5px"
      },
    "&:last-child": {
      marginBottom:"8px"
    },
  },
  inputField: {
    "&:before": {
      borderBottom: "1px solid #005eff"
    },
    "&:after": {
      borderBottom: "1px solid #005eff"
    },
  },
  selectStatus: {
    fontSize: "11px",
    height: "30px",
    display: "flex",
    alignItems: "center",
    padding:"8px 16px 0px 16px",
  },
}));

export default useStyles;
