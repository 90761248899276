import React from "react"
import { Box } from "@material-ui/core"
import Skeleton from '@material-ui/lab/Skeleton';

function SummaryTable({ classes, summaryDetails, loading }: any) {
    // if (loading) return <div>loading...</div>
    if (!summaryDetails) return null
    return (
        <div >
            <Box display="flex" justifyContent="center">
                <Box className={classes.summaryTableDiv}>
                    <Box display="flex" justifyContent="space-between"
                        flexWrap="wrap">
                        {summaryDetails.map((item: any) =>
                            <div className={classes.summaryTableBox} key={item.key}>
                                {
                                    loading ? <Skeleton variant="text" width={150} height={10} /> :
                                        <div className={classes.tableHeading + " " + classes.summaryTableHeadGrid}>
                                            <span >{item.key}</span>
                                            <span>{" "}{item.value}</span>
                                        </div>
                                }
                                <div>
                                    {item.data.map((newItmes) =>
                                        loading ? <Skeleton variant="text" width={150} height={10} /> :
                                            <Box display="flex" key={newItmes.key} className={classes.tableRow + " " + classes.summaryTableHeadGrid}>
                                                <span style={{ width: "81px" }}>{newItmes.key}</span>
                                                <span>{newItmes.value}</span>
                                            </Box>
                                    )}
                                </div>
                            </div>
                        )}
                    </Box>
                </Box>
            </Box>
        </div>
    )
}

export default React.memo(SummaryTable)