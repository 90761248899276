import axios from 'axios';
import config from "../configs/clientConfig";

// stupid brigade hardcoding
export async function uploadImage(file: File) {
    try {
        let form = new FormData();
        form.append('uploadType', 'image');
        form.append('fileName', `brigade/${file.name.replace(/[\s@]+/g, "_")}`);
        form.append('file', file);
        const res = await axios.post(config.TransactionServerUrl + 'files/upload',
            form,
            { headers: { "Content-Type": "multipart/form-data" } }
        );
        await new Promise((res, _) => setTimeout(() => { res(); }, 1000));
        return { ...res.data, fileName: res.data.fileName.replace("brigade/", "") };
        // return { ...res.data, fileName: res.data.fileName };
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

export async function updateForm(payload: { [K: string]: any; }) {
    try {
        const res = await axios.post(config.TransactionServerUrl + 'portal/updateFormData', { ...payload });
        console.log(res.data);
        return res.data
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}
