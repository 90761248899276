import React, { RefObject, forwardRef, useEffect, useMemo, useRef, PropsWithoutRef } from "react";
import { Grid } from "@material-ui/core";
import { getEnv, getSnapshot } from 'mobx-state-tree';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { reaction } from 'mobx';
import { VariableSizeGrid, VariableSizeList } from 'react-window';
import { useHistory } from "react-router";
import { chan, putAsync, go, sliding, IChan } from "csp-with-ts";
import { compose, map } from "transducist";
import { Dashboard } from '../../components/Graph/GraphLayout';
import TowerHeadingScroll from './TowerHeadingScroll';
import XHeadingScroll from './XHeadingScroll';
import useStyles from './ScrollingLayoutStyles';
import { useStore } from "../../models/ProvideModel";
import { GOT } from "../../models/enums";
import LoadingSkeleton from "../../components/loadingSkelaton/LoadingSkelaton";
import NoofFlooreOrUnits from './FloorUnitCount'
/* import CustomScrollbarX from "../../components/customScrollbar/CustomScrollbarOverrideX";
 * import CustomScrollbarY from "../../components/customScrollbar/CustomScrollbarOverrideY"; */

function innerElementType(x, y) {
  return forwardRef(({ style, ...rest }: PropsWithoutRef<Record<string, any>>, ref: RefObject<any>) => (
    <div
      ref={ref}
      style={{
        ...style,
        paddingLeft: x,
        paddingTop: y,
        marginBottom: 50
      }}
      {...rest}
    />
  ))
}


/* function outerElementTypeFloor(classes, h, m, screen, phase) {
 * 
 *   return forwardRef(({ style, ...rest }: PropsWithoutRef<Record<string, any>>, ref: RefObject<any>) => {
 *     return (
 * 
 *       <div
 *         ref={ref}
 *         style={{
 *           ...style
 *         }}
 *         {...rest}
 *       >
 *         {(screen === "landing" && phase === "finishing") ? null :
 *           <div className={screen === "landing" ? classes.axisLabelLand : classes.axisLabelFullscreen} style={{ top: Math.min((h - m) / 2, (rest.children.props.style.height || h) / 2) }} >
 *             FLOORS</div>
 *         }
 *         {rest.children}
 *       </div>
 *     )
 *   })
 * } */


// function that gives back the width of the cell with index index
const columnWidthExt = (columns: number, start: number[], data, screen, phase) => index => {
  const GUTTER = getEnv(data)?.scale[phase][screen]?.x;
  if (start.includes(index))
    return getEnv(data)?.axis[phase][screen]?.left;
  if (start.slice(1).concat(columns).map((n: number) => n - 1).includes(index))
    return getEnv(data).axis[phase][screen].right;
  return getEnv(data).brick[phase][screen].width + GUTTER;
};
// Function that gives back the height of the cell with index index
const rowHeightExt = (rows, data, screen, phase) => index => {
  if (index === 0)
    return 0;
  return getEnv(data).brick[phase][screen].height + getEnv(data).scale[phase][screen].y;
};


function useWindowedObservables(internalRef: RefObject<VariableSizeGrid>, internalTRef: RefObject<VariableSizeList>, internalXRef: RefObject<VariableSizeList>, internalVRef: RefObject<VariableSizeList>) {
  const store = useStore();
  const { push } = useHistory();
  const handleClick = (inp: string): EventHandlerNonNull | undefined => {
    if (store.params.phase === 'finishing' && store.params.screen === 'landing') {
      if (store.params.spaceType === 'tower') {
        return () => {
          push(store.params.toPath({ ...getSnapshot(store.params), screen: 'fullscreen', tower: inp }));
        };
      }
      if ((store.params.spaceType === 'tca' || store.params.spaceType === 'eca' || store.params.spaceType === "basement") && store.currentDashboard?.tab?.graphType === GOT.BARATHEON) {
        return () => {
          push(store.params.toPath({ ...getSnapshot(store.params), section: 'unit-act-info' }) + `?unit_type_id=${inp}`);
        }
      }
    }
  };
  const computedMeasure = useLocalObservable(() => ({
    get bsSuffix() {
      return store.params.phase === "structures" && store.params.spaceType !== "tower" ? "_alt" : "";
    },
    get minusHeight() {
      return store.params.screen === 'fullscreen' ? (store.params.spaceType === 'tca' || store.params.spaceType === 'eca' || store.params.spaceType === "basement") ? 65 : 55 : 85;
    },
    get GUTTER(): { x: number; y: number; } {
      return { x: getEnv(store)?.scale[store.params.phase + computedMeasure.bsSuffix][store.params.screen]?.x, y: getEnv(store)?.scale[store.params.phase + computedMeasure.bsSuffix][store.params.screen]?.y };
    },
    get columnWidth() {
      return columnWidthExt(store.totalColumns, store.pos, store, store.params.screen, store.params.phase + computedMeasure.bsSuffix);
    },
    get rowHeight() {
      return rowHeightExt(store.totalRows.length + 1, store, store.params.screen, store.params.phase + computedMeasure.bsSuffix);
    },
    get columnDim() {
      let res: number = 0, i: number = 0;
      const len: number = store.totalColumns;
      for (; i < len; i++) { res += computedMeasure.columnWidth(i); }
      return res;
    },
    get rowDim() {
      let res: number = 0, i: number = 1;
      const len: number = store.totalRows.length;
      for (; i <= len; i++) { res += computedMeasure.rowHeight(i); }
      return res;
    }
  }));

  useEffect(() => reaction(() => [store.currentTower, store.params.phase, store.params.screen, store.params.spaceType], () => {
    //TODO: Maybe reverse the scroll-to's if asked for?
    if (internalRef && internalRef.current) {
      internalRef.current.resetAfterColumnIndex(0, false);
      internalRef.current.resetAfterRowIndex(0, false);
      internalRef.current.scrollTo({ scrollTop: 0, scrollLeft: 0 });
    }
    if (internalTRef && internalTRef.current) { internalTRef.current.resetAfterIndex(0, false); internalTRef.current.scrollTo(0); }
    if (internalXRef && internalXRef.current) { internalXRef.current.resetAfterIndex(0, false); internalXRef.current.scrollTo(0); }
    if (internalVRef && internalVRef.current) { internalVRef.current.resetAfterIndex(0, false); internalVRef.current.scrollTo(0); }
  }), []);

  useEffect(() => reaction(() => store.currentTower, () => {
    if (internalRef && internalRef.current) { internalRef.current.scrollTo({ scrollLeft: 0, scrollTop: 0 }); }
  }), []);

  useEffect(() => reaction(() => [store.totalRows.length, store.currentDashboard?.towers?.map(({ id }) => id), store.totalColumns, store.filters.activity], (_) => {
    if (internalRef && internalRef.current) { internalRef.current.resetAfterColumnIndex(0, false); internalRef.current.resetAfterRowIndex(0, false); }
    if (internalTRef && internalTRef.current) { internalTRef.current.resetAfterIndex(0, false); }
    if (internalXRef && internalXRef.current) { internalXRef.current.resetAfterIndex(0, false); }
  }
  ), []);
  useEffect(() => reaction(() => store.filters.floorSort, () => {
    if (store.totalRows.length > 0) {
      if (internalRef && internalRef.current) { internalRef.current.resetAfterRowIndex(0, false); }
      if (internalVRef && internalVRef.current) { internalVRef.current.resetAfterIndex(0, false); }
    }
  }), []);
  return [store, computedMeasure, handleClick];
}

export interface IScrollingLayout {
  width: number;
  height: number;
  IsMacOs: boolean;
  screen: string;
  phase: string;
  loading: boolean;
  label: string | null
};

const ScrollingLayout = observer(({ height, width, IsMacOs, screen, phase, loading, label }: IScrollingLayout) => {
  const myRef = useRef<HTMLDivElement>(null);
  const scrollChan = useMemo<IChan<{ x?: number; y?: number; }>>(() => chan<{ x?: number; y?: number; }>(sliding(10)), []);
  const remoteChan = useMemo(() => ({ x: chan<number, { x: number; }>(sliding(1), compose(map((v: number) => ({ x: v })))), y: chan<number, { y: number; }>(sliding(1), compose(map((v: number) => ({ y: v })))) }), []);
  /* const dragChan = useMemo(() => chan<{ x: number; y: number; }>(), []); */
  useEffect(() => { return () => { scrollChan.close(); remoteChan.x.close(); remoteChan.y.close(); /* dragChan.close(); */ } }, []);
  /* useEffect(() => go<number>`eval ${loop<any, { x?: number; y?: number; }>`?: ${[remoteChan.x as IChan<number, { x: number; }>, remoteChan.y as IChan<number, { y: number; }>]} ${function (val: { x?: number; y?: number; }) { putAsync(scrollChan, val); internalRef.current?.scrollTo({ scrollLeft: val.x !== undefined ? val.x : myRef.current?.scrollLeft || 0, scrollTop: val.y !== undefined ? val.y : myRef.current?.scrollTop || 0 }); }}`}`, []); */

  useEffect(() => go<any, { x?: number; y?: number; }>`<! ${remoteChan.x as IChan<number, { x: number; }>} ${function* () { while (true) { const { x } = yield; if (x !== undefined) { putAsync(scrollChan, { x }); internalRef.current?.scrollTo({ scrollLeft: x, scrollTop: myRef.current!.scrollTop }); internalTRef.current?.scrollTo(x); internalXRef.current?.scrollTo(x); } } }}`, []);
  /* useEffect(() => go`<! ${dragChan} ${function* () { while (true) { const { x, y } = yield; putAsync(scrollChan, { x: x * 3 + (myRef.current?.scrollLeft || 0), y: y * 3 + (myRef.current?.scrollTop || 0) }); internalRef.current?.scrollTo({ scrollLeft: x * 3 + (myRef.current?.scrollLeft || 0), scrollTop: y * 3 + (myRef.current?.scrollTop || 0) }); } }}`, []); */
  const classes = useStyles({ screen, phase });
  const internalRef: RefObject<VariableSizeGrid> = useRef<VariableSizeGrid>(null);
  const internalTRef: RefObject<VariableSizeList> = useRef<VariableSizeList>(null);
  const internalXRef: RefObject<VariableSizeList> = useRef<VariableSizeList>(null);
  const internalVRef: RefObject<VariableSizeList> = useRef<VariableSizeList>(null);
  useEffect(() => go<any, { x?: number; y?: number; }>`<! ${scrollChan} ${function* () { while (true) { const { x, y } = yield; if (x !== undefined) { internalTRef.current?.scrollTo(x); internalXRef.current?.scrollTo(x); } if (y !== undefined) { internalVRef.current?.scrollTo(y); } } }}`, []);

  // const PourRender = useObserver(() => MakeScrollablePourRender(data, store.pos, size.columns, GUTTER.x));
  const [store, computedMeasure, handleClick] = useWindowedObservables(internalRef, internalTRef, internalXRef, internalVRef);
  const { isLg, isSm } = store.responsiveUtils.currentViewport;

  const scrollHandler = (val: { scrollLeft: number; scrollTop: number }) => {
    const { scrollLeft, scrollTop } = val;
    putAsync(scrollChan as IChan<{ x: number; y: number; }>, { x: scrollLeft, y: scrollTop }, false);
  };

  const getYGridBasedOnScSize = (currentViewport) => {
    if (currentViewport.isLg) {
      return 1;
    } else if (currentViewport.isSm) {
      return 1;
    } else if (currentViewport.isXs) {
      return 2;
    }
  }

  const getXGridBasedOnScSize = (currentViewport) => {
    if (currentViewport.isLg) {
      return 11;
    } else if (currentViewport.isSm) {
      return 11;
    } else if (currentViewport.isXs) {
      return 10;
    }
  }

  const getDynamicStyles = (currentViewport) => {
    if (store.params.screen === "landing" && store.params.phase === "finishing") {
      if (currentViewport.isXs) {
        return {
          paddingLeft: '8em'
        }
      }

      if (currentViewport.isSm) {
        return {
          paddingLeft: '6em'
        }
      }
    } else if (screen === "landing" && phase === "structures") {
      return { right: '16px' }
    }
  }
  // TODO: Style for mobile view as well
  return loading ?
    (<div className={store.responsiveUtils.currentViewport.isLg ? classes.loadingSkeletondiv : classes.SmloadingSkeletondiv}><div><LoadingSkeleton /></div></div>)
    : (
      <Grid container className={store.responsiveUtils.currentViewport.isLg || phase === 'structures' || screen === 'fullscreen' ? classes.root : classes.rootXsSm} style={{ height: height }} direction="column">
        <Grid item style={{ margin: (!store.responsiveUtils.currentViewport.isLg && store.params.phase === 'finishing' && store.params.screen === 'landing') ? "10px 0px 3px 0px" : "10px 0px auto 0px" }}>
          <Grid container direction="row">
            <Grid item xs={getYGridBasedOnScSize(store.responsiveUtils.currentViewport)}
            ><NoofFlooreOrUnits label={label} /></Grid>
            <Grid item xs={1}>
              {((store.params.spaceType === 'tca' || store.params.spaceType === 'eca' || store.params.spaceType === "basement") && store.params.screen === 'landing') || (store.params.phase === 'structures' && store.params.screen === 'landing') ?
                (<TowerHeadingScroll
                  ref={internalTRef}
                  loading={store.loading || store.currentDashboard?.loading}
                  phase={store.params.phase}
                  screen={store.params.screen}
                  width={isLg ? 1.01 * width : isSm ? 0.96 * width : 0.93 * width}
                  data={store.towersList}
                  gutter={computedMeasure.GUTTER.x}
                  itemSize={index => ((store.currentDashboard?.graphs?.get(store.towersList[index]?.id)?.columns?.length || 0) * (getEnv(store).brick[store.params.phase + computedMeasure.bsSuffix][store.params.screen].width + computedMeasure.GUTTER.x) + getEnv(store).axis[store.params.phase + computedMeasure.bsSuffix][store.params.screen].left + getEnv(store).axis[store.params.phase + computedMeasure.bsSuffix][store.params.screen].right)}
                >
                  {innerElementType(computedMeasure.GUTTER.x, computedMeasure.GUTTER.y)}
                </TowerHeadingScroll>) :
                null
              }
              <XHeadingScroll
                ref={internalXRef}
                width={isLg ? 0.98 * width : isSm ? 0.93 * width : 0.9 * width}
                data={store.currentDashboard?.columns || []}
                columns={store.totalColumns}
                totalColumnSortedFiltered={store.totalColumnSortedFiltered}
                itemSize={computedMeasure.columnWidth}
                gutter={computedMeasure.GUTTER.x}
                phase={store.params.phase}
                screen={store.params.screen}
                loading={store.loading || store.currentDashboard?.loading}
              >
                {innerElementType(computedMeasure.GUTTER.x, computedMeasure.GUTTER.y)}
              </XHeadingScroll>
            </Grid >
          </Grid>
        </Grid>
        <Grid item>
          <Grid container style={{ width: "100%", height: height - computedMeasure.minusHeight }} direction="row">
            <Grid item xs={getYGridBasedOnScSize(store.responsiveUtils.currentViewport)}>
              {/* <div className={classes.axisLabel} style={{top: height > 350 ? (height)/3.1 : height > 650 ? (height)/2 : (height)/2}}>Floors</div> */}
              {/* outerElementType={outerElementTypeFloor(classes, height, computedMeasure.minusHeight, store.params.screen, store.params.phase)} */}
              <VariableSizeList
                ref={internalVRef}
                overscanCount={1}
                innerElementType={innerElementType(computedMeasure.GUTTER.x, computedMeasure.GUTTER.y)}
                style={{ overflow: "hidden", ...getDynamicStyles(store.responsiveUtils.currentViewport) }}
                height={height - computedMeasure.minusHeight}
                width={isLg ? 0.098 * width : 100}
                itemCount={store.totalRows.length + 1}
                itemData={store.totalRows}
                itemSize={computedMeasure.rowHeight}
              >
                {({ index, data: floor, style }) => (
                  <div
                    style={{
                      ...style,
                      top: style.top + computedMeasure.GUTTER.y,
                    }}
                    className={index > 0 ? store.params.screen === 'fullscreen' ? classes.axisStyleFull : classes.axisStyleLand : ''}
                  >
                    <div
                      className={(index > 0 && store.params.screen === "landing" && store.params.phase === "finishing") ? classes.fLP : classes.other}
                      onClick={index > 0 ? handleClick(store.filters.sort ? floor[index]!.id : floor[store.totalRows.length - index].id) : undefined}>
                      {index > 0 ? store.filters.sort ? floor[index].name : floor[store.totalRows.length - index].name : ''}
                    </div>
                  </div>
                )}
              </VariableSizeList>
            </Grid>
            <Grid item xs={getXGridBasedOnScSize(store.responsiveUtils.currentViewport)}>
              <VariableSizeGrid
                useIsScrolling={false}
                overscanColumnCount={1}
                overscanRowCount={1}
                ref={internalRef}
                outerRef={myRef}
                innerElementType={innerElementType(computedMeasure.GUTTER.x, computedMeasure.GUTTER.y)}
                style={{ cursor: "grab" }}
                height={height - computedMeasure.minusHeight}
                width={isLg ? 0.925 * width : isSm ? 0.88 * width : 0.85 * width}
                rowCount={store.totalRows.length + 1}
                columnCount={store.totalColumns}
                rowHeight={computedMeasure.rowHeight}
                columnWidth={computedMeasure.columnWidth}
                onScroll={scrollHandler}
                className={IsMacOs ? classes.hideScroll : ''}
              >
                {Dashboard}
              </VariableSizeGrid>
            </Grid>

          </Grid>
        </Grid>
      </Grid >
    );
});

export default ScrollingLayout;
