import axios from 'axios';
import config from "../configs/clientConfig";
import { IChecklistItem } from '../models/MultiSelect';

export async function downloadReports(customerID: string, items: IChecklistItem[]) {
    try {
        const res = await axios.post(config.RestApiUrl + 'reports/exports/v2/checklist-reports', {
            customerID,
            data: items.map(({ id, fileName }) => ({ Key: id, file_name: fileName || null }))
        });
        return res.data;
    }
    catch (error) {
        console.error(`Error response: ${error}`);
        throw new Error(error);
    }
}

// reports/exports/v2/checklist-reports-status
export async function checkReportStatus(fileName: string) {
    try {
        const res = await axios.post(config.RestApiUrl + 'reports/exports/v2/checklist-reports-status', {
            fileName
        });
        return res.data;
    }
    catch (error) {
        console.error(`Error response: ${error}`);
        throw error;
    }
}

export async function shareEmail(fileURL: string, emails: string[], data: any) {
    try {
        const res = await axios.post(config.RestApiUrl + 'share/sendPDFToEmails', {
            emails,
            fileURL,
            data
        });
        return res.data;
    }
    catch (error) {
        console.error(`Error response: ${error}`);
        throw error;
    }
}

export async function shareWhatsapp(fileURL: string, toNumber: number, data: any) {
    try {
        const res = await axios.post(config.RestApiUrl + 'share/sendPDFToWhatsapp', {
            fileURL,
            toNumber: '+91' + toNumber,
            data
        });
        return res.data;
    }
    catch (error) {
        console.error(`Error response: ${error}`);
        throw error;
    }
}
