import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // backgroundColor: "#ffffff",
    flexDirection: 'column',
    width: '95%',
    display: "flex",
    flexGrow: 1,
    flexBasis: "auto",
    flexShrink: 1,
  },
  loadingSkeletondiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%"
  },
  listOfUnitsandBlocks: {
    width: "133px",
    height: "50px",
    display: "flex",
    alignSelf: "center",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "1rem",
    color: "#2f3e6c",
    lineHeight: 1.29,
    letterSpacing: "normal",
    fontFamily: 'Source Sans Pro, sans-serif',
  },
  verticalDivider: {
    width: "2px",
    height: "12px",
    backgroundColor: "#cecece",
    alignSelf: "center",
    marginTop: "1px",
  },
  lPanel: {
    flex: "0 0 auto",
    paddingBottom: "30px"
  },
  tabPanel: {
    // backgroundColor: "#ffffff",
  },
  // sticky: {
  //   display: "flex",
  //   flexGrow: 1,
  //   width: "100%",
  //   backgroundColor: "#ffffff",
  //   // position: 'sticky',
  //   // top: 0,
  //   // zIndex: 100
  // },
  landingPane: {
    // height: '100%',
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 'auto',
  },
  Structureslanding:{
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexBasis: 'auto',
    flexGrow: 1,
    flexShrink: 1,
    marginTop:"18px"
  },
  landing: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexBasis: 'auto',
    flexGrow: 1,
    flexShrink: 1
    // marginTop:"40px"
  },
  fix: {
    display: 'flex',
    height: 2,
    position: 'sticky',
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: "2px",
    bottom: 10
  }
}));

export default useStyles;
