import React from "react";
import { Box, Grid } from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';

// used for both conventional and aluform pour
function PeopleInvolved({ classes, peopleInvolved, loading }: any) {
    if (!peopleInvolved) return null
    return (
        <div style={{marginBottom:"5rem"}}>
            {/* People Involved Heading */}
            <div id="people-involved" className={classes.Headers}>
                {"People Involved"}
            </div>
            {/* People Involved table */}
            <Box display="flex" justifyContent="center">
                <Box className={classes.peopleInvolvedTable}>
                    <Grid container className={classes.tableHeadGrid}>
                        <Grid item xs={4}>
                            <span className={classes.tableHeading}>{"Person Name"}</span>
                        </Grid>
                        <Grid item xs={4} className={classes.tableHead}>
                            <span className={classes.tableHeading}>{"Designation"}</span>
                        </Grid>
                        <Grid item xs={4} className={classes.tableHead} >
                            <span className={classes.tableHeading}>{"Role"}</span>
                        </Grid>
                    </Grid>
                    {
                        peopleInvolved && peopleInvolved.map((key: any, index) =>
                            <Grid container key={key.id} >
                                <Grid item xs={4} className={classes.tableRowGrid}>
                                    {
                                        loading ? <Skeleton variant="text" width={100} height={10} /> :
                                            <span className={classes.tableRow}>{key.name}</span>
                                    }
                                </Grid>
                                <Grid item xs={4} className={classes.tableRowsAlign + " " + classes.tableRowGrid}>
                                    {
                                        loading ? <Skeleton variant="text" width={100} height={10} /> :
                                            <span className={classes.tableRow}>-</span>
                                    }
                                </Grid>
                                <Grid item xs={4} className={classes.tableRowsAlign + " " + classes.tableRowGrid}>
                                    {
                                        loading ? <Skeleton variant="text" width={100} height={10} /> :
                                            <span className={classes.tableRow}>-</span>
                                    }
                                </Grid>
                            </Grid>
                        )
                    }
                </Box>
            </Box>
        </div>
    )
}
export default React.memo(PeopleInvolved)