import * as React from "react";
import useStyles from './PageNotFoundStyles';
import { useStore } from "../../models/ProvideModel";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router";
import projectPlaceholder from "../../assets/images/projectSelection/Project_Placeholder_Image.svg";
import { useState } from "react";
import menuIcon from '../../assets/images/Menu.svg';
import fbtLogoIcon from "../../assets/images/FBTLogo.svg";
import { useObserver } from "mobx-react-lite";

interface IPageNotFoundProps {
  errorText?: string;
  pageNotFound?: any;
}

const PageNotFound: React.FC<IPageNotFoundProps> = ({ errorText }) => {
  const classes = useStyles();
  const store = useStore();
  const [open, setOpen] = useState(true);
  const { push } = useHistory();
  const dots: any = [".", ".", ".", ".", ".", "."]
  const hadlemenu = () => {
    if (store.params.phase === "none" && store.params.spaceType === "none") {
      push(`/menu?open=${open}`);
    } else {
      push(`/menu/${store.params.phase}/${store.params.spaceType}?open=${open}`);
    }
  }
  return useObserver(() => (store.auth.isAuthenticated ? store.projectInfo.currentProject ? <div className={classes.root}>
    <div onClick={hadlemenu} className={classes.leftSidePanel}>
      <img src={menuIcon} alt="menu" style={{ marginTop: "10px", width: "19px", height: "19px" }} />
      {store.responsiveUtils.currentViewport.isLg && <div className={classes.propertyName}>{store.projectInfo.currentProject.description}</div>}
    </div>
    <div className={classes.mainDiv}>
      <div
        className={classes.headerRight}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className={classes.menuClick}>
            <img
              className={
                store.projectInfo.projects[0].builder_image
                  ? classes.homeButton
                  : classes.homeButtonWOimg
              }
              src={
                store.projectInfo.projects[0].builder_image
                  ? store.projectInfo.projects[0].builder_image
                  : projectPlaceholder
              }
              height="45px"
              alt="Builder"
            />
            <div className={classes.headerText}>PAGE NOT FOUND - 404</div>
          </div>
        </div>
        <img
          src={fbtLogoIcon}
          width={106}
          height={48}
          style={{ paddingTop: "10px" }}
          alt="FBTLogo"
        />
      </div>
      <div style={{ display: "flex", justifyContent: store.responsiveUtils.currentViewport.isLg ? "center" : "normal", height: "100%", alignItems: store.responsiveUtils.currentViewport.isLg ? "center" : "normal", background: "#ffffff" }}>
        <div className={store.responsiveUtils.currentViewport.isLg ? classes.secondMainDiv : classes.secondMainDivSm}>
          <span className={store.responsiveUtils.currentViewport.isLg ? classes.fontSize : classes.fontSizeSm}>Error 404</span>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span className={store.responsiveUtils.currentViewport.isLg ? classes.BoldtextSize : classes.BoldtextSizeSm}>We couldn't find the drawings for this page.</span>
            <span className={store.responsiveUtils.currentViewport.isLg ? classes.BoldtextSize : classes.BoldtextSizeSm}>High chance we never got it.</span>
          </div>
          <div style={{ display: "flex", flexDirection: "column", margin: "3% 0px" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span className={classes.textSize}>Before you press back</span>
              <span className={classes.NormaltextSize}>Why did the construction worker dip his finger in blue ink?</span>
            </div>
            {dots.map(key => {
              return <span style={{ fontSize: "10px", color: "#ffffff" }}>{key}</span>
            })}
            <span className={classes.NormaltextSize}>To get a blue print. Lol.</span></div></div>
      </div>
    </div>
  </div> : <Redirect to="/projects" /> : <Redirect to="/login" />
  ))
};

PageNotFound.defaultProps = {
  errorText: "Page Not Found"
};

export default PageNotFound;
