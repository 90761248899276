import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: (props: { params: Array<String>}) => ({
    fontFamily: "Source Sans Pro, sans-serif !important",
    background: "#faf9f9",
    padding: "1rem 1rem 0rem 1rem"
  }),
  RootGrid: {
    justifyContent: "center",
    display: "flex",
  },
  mainToggle:(props: { params: Array<String>}) => ({
    background: "#faf9f9",
  }),
  buttonToggleGroup: {
    height: "32px",
    width:"96px",
    fontSize: "14px",
    fontFamily: "Source Sans Pro, sans-serif !important",
    border: "2px solid #f4f4f4 !important",
    padding: "0px",
    borderRadius: "0px !important",
    "& span": {
      height: "100% !important",
    },
    "&:hover": {
      background: "#e2e2e2 !important"
    }
  },
  verticalDivider:{
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center left",
    backgroundSize: "1px 12px, auto",
    backgroundImage: "linear-gradient(#cecece,#cecece)",
    height: "32px",
    width: "2px",
    padding:"0px !important",
    margin:"0px !important",
    background:"#f4f4f4",
    border:"0px !important",
  },
  buttonToggleRight: {
    width:"96px",
    height: "32px",
    fontSize: "14px",
    fontFamily: "Source Sans Pro, sans-serif !important",
    fontWeight: "normal",
    background: "white",
    border: "2px solid #f4f4f4 !important",
    padding: "0px",
    "& span": {
      height: "100%",
      alignItems: "center",
      display: "flex",
    },
    "&:hover": {
      background: "white !important"
    }
  },
  sort: {
    width: "90px",
    fontFamily: "Source Sans Pro, sans-serif !important",
    fontSize: "11px !important",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.27,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#585858",
    textTransform: "uppercase",
  },
  floor: {
    fontFamily: "Source Sans Pro, sans-serif !important",
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.27,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#585858",
    alignSelf: "center"
  },
  floorDiv: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "10px"
  },
  underlineHover: {
    height: "100%",
    display: "inline-block",
    textDecoration: " none",
    cursor: "pointer",
    width: "100% !important",
    background: "#f4f4f4 !important",
    color: "#585858",
    fontWeight: "normal",
    "&:hover": {
      backgroundSize: "0px, !important",
      textDecoration: "none",
      background: "#e2e2e2 !important",
    },
  },
  displayNone: {
    display: "none"
  },
  underlineHoverSelected: {
    width: "100% !important",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    color: "#2f3e6c !important",
    fontWeight: 700,
    "&:hover": {
      backgroundSize: "0px !important",
      textDecoration: "none",
      background: "white !important"
    },
  },
  towerTitle: {
    fontSize: "14px",
    fontFamily: "Source Sans Pro, sans-serif !important",
    textTransform: "capitalize",
    verticalAlign: "-webkit-baseline-middle",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly"
  },
  towerTitleSelected: {
    fontSize: "14px",
    fontFamily: "Source Sans Pro, sans-serif !important",
    textAlign: "center",
    cursor: "pointer",
    textTransform: "capitalize",
  },
  filterBy: {
    height: "14px",
    width: "90px",
    fontFamily: "Source Sans Pro, sans-serif !important",
    fontSize: "11px !important",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.27,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#585858",
    textTransform: "uppercase"
  },
  tabTitle: {
    marginRight: "24px",
    textTransform: "capitalize",
    fontSize: "14px",
    fontWeight: "normal",
    letterSpacing: "normal",
    color: "#585858"
  },
  tabTitleSelected: {
    marginRight: "24px",
    textTransform: "capitalize",
    fontSize: "14px",
    fontWeight: "bold",
    letterSpacing: "normal",
    color: "#2f3e6c",
    borderBottom: "2px solid #2f3e6c"
  },
  blockTypeSelected: {
    fontSize: "8px",
    color: "#2f3e6c",
    alignSelf: "flex-end"
  },
  blockType: {
    fontSize: "8px",
    color: "#585858",
    alignSelf: "flex-end"
  }
}));

export default useStyles;
