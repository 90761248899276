import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  rootDiv: {
    width: "100%",
    background: "#faf9f9",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  GridBox: {
    width: "100%",
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "14px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.25,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#585858",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: "16px 15px 0px 15px"
  },
  PropertyValue: {
    fontWeight: "normal",
    marginLeft: "4px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
}));
export default useStyles;
