import React, { useState } from "react";
import { useHistory } from 'react-router';
import { useStore } from "../models/ProvideModel";
import { observer, useObserver } from "mobx-react-lite";
const StepNameDetails = (props) => {
  const { push } = useHistory();
  const stepName: string = props.cell.value;
  const store = useStore();
  const [hover, setHover] = useState<boolean>(false);
  const [linkId, setId] = useState<string>("");

  const handleClick = () => {
    document?.getElementById(`${props.cell.row.values.unit_id}_${props.cell.row.original.unit_activity_id}`)?.removeAttribute("href");
    push(`/activityInfo/${props.cell.row.original.unit_activity_id}`);
  }

  const hoverState = (id) => {
    setId(id)
    setHover(true)
  }

  return useObserver(() => (!stepName || stepName === '—') ? (
    <div style={{ display: "flex", justifyContent: "center", }}>
      {stepName}
    </div>
  ) : store.params.screen === "misc" ?
    (
      <div>
        {stepName}
      </div>
    ) :
    (
      <div>
        <div style={{ cursor: "pointer" }}>
          <a id={`${props.cell.row.values.unit_id}_${props.cell.row.original.unit_activity_id}`}
            onMouseEnter={() => hoverState(`${props.cell.row.values.unit_id}_${props.cell.row.original.unit_activity_id}`)}
            onMouseLeave={() => setHover(false)}
            href={hover && linkId === `${props.cell.row.values.unit_id}_${props.cell.row.original.unit_activity_id}` ? `${window.location.href.split('/', 3).join('/')}/activityInfo/${props.cell.row.original.unit_activity_id}` : ``}
            target="popup" style={{ textDecoration: hover ? "underline" : "none", color: "#005eff" }}
            onClick={(e) => { handleClick(); return false }}>
            {stepName}</a>
        </div>
      </div>
    ));
};

export default observer(StepNameDetails);
