import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: "Source Sans Pro, sans-serif !important",
      display: "flex",
      // justifyContent: "center",
      color: "#2f3e6c",
      fontSize: 18,
      textAlign: "center",
      height: "60vh",
      alignItems: "center",
      // position: "absolute",
      marginLeft: "24%"
    },
    rootsm:(props: { fullScreenMode }) => ({
      fontFamily: "Source Sans Pro, sans-serif !important",
      display: "flex",
      // justifyContent: "center",
      color: "#2f3e6c",
      fontSize: 18,
      textAlign: "center",
      height: "40vh",
      alignItems: "center",
      // position: "absolute",
      paddingLeft: props.fullScreenMode ? "22%" : "18%",
      paddingTop: props.fullScreenMode ? "27px" : ""
    }),
    Brickroot:{
      fontFamily: "Source Sans Pro, sans-serif !important",
      display: "flex",
      justifyContent: "center",
      color: "#2f3e6c",
      fontSize: 18,
      textAlign: "center",
      height: "60vh",
      alignItems: "center",
      position: "absolute",
      left: "43%"
    },
    screenRoot: {
      fontFamily: "Source Sans Pro, sans-serif !important",
      fontSize: 16,
      display: "flex",
      justifyContent: "center",
      color: "#fff",
      flexDirection: "column",
      textAlign: "left",
      alignItems: "center",
      height: "99vh"
    },
    portrait: {
      width: "117px",
      height: "176px",
      borderRadius: "12px",
      boxShadow: "0 3px 8px 0 rgba(0, 0, 0, 0.14)",
      border: "solid 2px #ffffff",
    },
    portraitInside: {
      width: "99px",
      height: "158px",
      borderRadius: "2px",
      boxShadow: "0 3px 8px 0 rgba(0, 0, 0, 0.14)",
      background: "#596589",
      margin: 9
    },
    landscape: {
      width: "176px",
      height: "117px",
      borderRadius: "12px",
      boxShadow: "0 3px 8px 0 rgba(0, 0, 0, 0.14)",
      border: "solid 2px #ffffff",
    },
    mainDiv: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: "350px",
      alignItems: "center"
      // height: "78vh"
    },
    imgStyle: {
      height: "278px",
      width: "214px"
    },
    fontSize: {
      fontFamily: "Source Sans Pro, sans-serif",
      fontSize: "18px",
      lineHeight: 1.29,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#2f3e6c",
      fontWeight:"bold"
    },
    snagsfontSize: {
      fontFamily: "Source Sans Pro, sans-serif",
      fontSize: "15px",
      lineHeight: 1.29,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#2f3e6c",
    },
    fontSizeSm:{
      fontFamily: "Source Sans Pro, sans-serif",
      fontSize: "15px",
      lineHeight: 1.29,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#2f3e6c",
      fontWeight:"bold"
    },
    snagsfontSizeSm: {
      fontFamily: "Source Sans Pro, sans-serif",
      fontSize: "15px",
      lineHeight: 1.29,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#2f3e6c",
    },
    textSize: {
      fontFamily: "Source Sans Pro, sans-serif",
      fontSize: "14px",
      lineHeight: 1.28,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#2f3e6c",
    },
    textSizeSm:{
      fontFamily: "Source Sans Pro, sans-serif",
      fontSize: "11px",
      lineHeight: 1.28,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#2f3e6c",
    }
  }),
);

export default useStyles