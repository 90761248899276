/* eslint-disable react-hooks/rules-of-hooks */
import React, { useMemo } from 'react'
import { useLocation } from "react-router";
import { useObserver } from 'mobx-react-lite';
import {
    useTable, useSortBy, useFilters, useFlexLayout
} from 'react-table'
import useStyles from "./FinishingActivityOverviewStyles";
import { DateRender, StatusRender, snagCountToString, DaysFilter, DateRenderDelay, inBetweenPastDays } from '../../components/table/Commonfunctions';
import { Grid, Breadcrumbs, Divider, TableRow, TableCell, TableHead, TableBody } from '@material-ui/core';
import { useStore } from "../../models/ProvideModel";
import MaUTable from '@material-ui/core/Table'
import useWindowDimensions from '../../components/windowDimentions/windowDimentionsHook';
import NoDataFound from '../../components/noDataFound/NoDataFound';
import SelectColumnFilter from '../../components/multiSelectionBox/SelectColumnFilter';
import { useSingleValueURLParam } from '../../utils/hooks';


function Table({ columns, data, classes, tDetails, snaps, store, print }) {
    // function FilterTableForm(props) {
    //     return <>
    //         <div id="FilterTable" style={{position:"absolute", left:"-30.5%"}}>
    //             {headerGroups.map(headerGroup => (
    //                 <div {...headerGroup.getHeaderGroupProps()}>
    //                     {headerGroup.headers.map(column => (
    //                         <div {...column.getHeaderProps()}>
    //                             {/* Render the columns filter UI */}
    //                             {column.canFilter ? column.render('Filter') : null}
    //                         </div>
    //                     ))}
    //                 </div>
    //             ))}
    //         </div>
    //     </>;
    // }

    const defaultColumn = React.useMemo(
        () => ({ Filter: " ", }), []
    )

    const {
        getTableProps, getTableBodyProps, headerGroups, rows, prepareRow
    } = useTable(
        { columns, data, defaultColumn },
        useFlexLayout,
        useFilters,
        useSortBy
    );


    const RenderRow = React.useCallback(
        ({ index, style }) => {
            const row = rows[index]
            prepareRow(row)
            return (
                <TableRow className={classes.tableRowUnits} {...row.getRowProps({ style })}>
                    {row.cells.map(cell => {
                        return <TableCell key={cell.value} className={classes.tableCell} {...cell.getCellProps()}><div style={{ height: "100%", display: "flex", alignItems: "center", fontSize: "12px" }}>{cell.render('Cell')}</div></TableCell>
                    })}
                </TableRow>
            )
        },
        [classes.tableCell, classes.tableRow, prepareRow, rows]
    )

    const { screen } = store.params;
    const { lg } = store.responsiveUtils.currentViewport;
    const deviderStyles = { height: 18, alignSelf: "center", backgroundColor: "#585858" }
    return useObserver(() => (
        <>
            <Grid container>
                <Grid item xs={11}>
                    <Breadcrumbs aria-label="breadcrumb" className={classes.boldDarkBlue24px}>
                        <span>{tDetails.towerName}</span> <span>{tDetails.floor} </span> <span>{tDetails.activity} </span>
                    </Breadcrumbs>
                </Grid>
                {lg && <Grid container>
                    {/* <FilterTableForm /> */}
                    <Grid item xs={12} style={{ alignSelf: "center" }}>
                        <div className={classes.snapshots}>
                            <span className={classes.boldGrey16px}>{(screen === "fullscreen") ? 'Floor Snapshot' : 'Block Snapshot'} </span>
                            <div className={classes.statusCount}>
                                <span>{"Completed: " + snaps.started} </span> <Divider orientation="vertical" style={{ ...deviderStyles }} />
                                <span>{"Ongoing: " + snaps.onGoing} </span> <Divider orientation="vertical" style={{ ...deviderStyles }} />
                                <span> {"Not Started: " + snaps.notStarted}</span></div>
                        </div>
                    </Grid>
                </Grid>}
            </Grid>
            <div style={{ overflow: "scroll", flex: 1, marginLeft: "1.5rem", marginRight: "1.5rem", height: lg ? "86%" : "unset" }}>
                <MaUTable className={classes.table} {...getTableProps()} stickyHeader={store.responsiveUtils.fatDropdownFlag === false} style={{ marginTop: 12, padding: 0 }}>
                    <TableHead className={classes.tableHeader}>
                        {headerGroups.map((headerGroup, i) => (
                            <TableRow key={i}>
                                {headerGroup.headers.map((column, i) => (
                                    <TableCell {...column.getHeaderProps()} key={i}>
                                        <div className={[(print === "print") ? classes.printHeader : classes.stickyHeader].join(" ")}>
                                            {/* <span>{column.Filter ? column.render('Filter') : column.render('Header') }</span> */}
                                            {column.render('Filter')}
                                        </div>
                                    </TableCell>
                                ))}
                            </TableRow>
                            //     <TableCell {...header.getHeaderProps()} key={header.name} style={{ zIndex: 0 }}>
                            //     <div className={[(print === "print") ? classes.printHeader :
                            //       typeof (header.Header) === "string" ? classes.nonDropdownText : "", classes.stickyHeader].join(" ")}>
                            //       {(typeof (header.Header) === "string" || (print === "print")) ? <span style={{ marginTop: "16px", }}>{header.render('Header')}</span> : header.render('Header')}
                            //     </div>
                            //   </TableCell>
                        ))}
                    </TableHead>
                    {
                        rows.length !== 0 ? <TableBody {...getTableBodyProps()}>
                            {rows.map((row) => {
                                prepareRow(row)
                                return (
                                    <TableRow {...row.getRowProps()} key={row.id} style={{ height: row.cells[0].column.minHeight }}>
                                        {row.cells.map(cell => {
                                            return (
                                                <TableCell {...cell.getCellProps()} key={`${cell.column.id}_${cell.value}`}>
                                                    <div className={classes.printViewCell}>{cell.render('Cell')}</div>
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                            :
                            <NoDataFound />
                    }
                </MaUTable>
            </div>
        </>
    ));


}


function FinishingActivityOverview({ summary }: any) {
    const store = useStore();
    const { lg } = store.responsiveUtils.currentViewport;
    const classes = useStyles({ lg });
    const tDetails = summary.towerDetails;
    const snaps = summary.statusCount;
    const { search } = useLocation();

    const searchParams = useMemo(() => new URLSearchParams(search), [search]);

    const [print] = useSingleValueURLParam("view", "normal", searchParams);


    const columns = React.useMemo(() => [
        {
            Header: 'Unit Name',
            accessor: 'unit_name',
            Filter: SelectColumnFilter,
            filter: "includes",
            maxWidth: lg ? 30 : 100
        },
        {
            Header: 'Activity Name',
            accessor: 'activity_name',
            Filter: SelectColumnFilter,
            filter: "includes",
            maxWidth: lg ? 30 : 100
        },
        {
            Header: 'Status',
            accessor: 'derivedStatus',
            Cell: StatusRender,
            Filter: SelectColumnFilter,
            filter: "includes",
            maxWidth: lg ? 30 : 100
        },
        {
            Header: 'Planned Start Date',
            accessor: 'planStart',
            Filter: DaysFilter,
            filter: inBetweenPastDays,
            Cell: DateRender,
            maxWidth: lg ? 30 : 100
        },
        {
            Header: 'Planned End Date',
            accessor: 'planEnd',
            Filter: DaysFilter,
            filter: inBetweenPastDays,
            Cell: DateRenderDelay,
            maxWidth: lg ? 30 : 100
        },
        {
            Header: 'Actual Start Date',
            accessor: 'actualStart',
            Cell: DateRender,
            Filter: DaysFilter,
            filter: inBetweenPastDays,
            maxWidth: lg ? 30 : 100
        },
        {
            Header: 'Actual End Date',
            accessor: 'actualEnd',
            Filter: DaysFilter,
            filter: inBetweenPastDays,
            Cell: DateRender,
            maxWidth: lg ? 30 : 100
        },
        // {
        //     Header: '(Plan) Start/End Date',
        //     Cell: CombinedPlanDateRenderDelay
        // },
        // {
        //     Header: '(Actual) Start/End Date',
        //     accessor: 'actualEnd',
        //     Filter: DaysFilter,
        //     filter: inBetweenPastDays,
        //     Cell: CombinedActualDateRenderDelay
        // },
        {
            Header: 'Last Updated',
            accessor: 'lastUpdated',
            Filter: DaysFilter,
            filter: inBetweenPastDays,
            Cell: DateRender,
            maxWidth: lg ? 30 : 100
        },

        {
            Header: 'Person Responsible',
            accessor: 'current_user_name',
            Filter: SelectColumnFilter,
            filter: "includes",
            maxWidth: lg ? 30 : 100
        },
        {
            Header: 'Current Step',
            accessor: 'current_step',
            Filter: SelectColumnFilter,
            filter: "includes",
            maxWidth: lg ? 30 : 100
        },
        {
            Header: 'Open/Total Snags',
            accessor: snagCountToString,
            Filter: SelectColumnFilter,
            filter: "includes",
            disableFilters: true,
            maxWidth: lg ? 30 : 80
        },


    ], [])
    return useObserver(() => (
        <div className={classes.root}>
            {/* Activity overview table */}
            {!!summary.unitActivityDetails &&
                <Table columns={columns} data={summary.unitActivityDetails} classes={classes} tDetails={tDetails} snaps={snaps} store={store} print={print} />
            }
        </div>
    ))
}
export default React.memo(FinishingActivityOverview);