import { types, Instance } from 'mobx-state-tree';

// helper mapping between query, and phase.


export const Tower = types.model('Tower', {
    id: types.identifier,
    name: types.string
});

export interface ITower extends Instance<typeof Tower> { };
