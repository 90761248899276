import React, { memo, useCallback, useMemo } from "react";
import { MenuItem, ListItemText } from "@material-ui/core";
import commonStyles from "./FilterStyles";

const SelectedItem = memo(
    ({
        value,
        id,
        handleClick,
        name,
    }: {
        value: string[];
        id: string;
        handleClick: (id: string | number) => void;
        name: string;
    }) => {
        const checked = useMemo(() => {
            return (value as Array<
                typeof value extends Array<infer T> ? T : never
            >).includes(id);
        }, [id, value]);
        const commonClasses = commonStyles({ checked });
        const handleClickCallback = useCallback(() => {
            handleClick(id);
        }, []);

        return (
            <MenuItem
                key={id}
                value={id}
                className={commonClasses.menuItems}
                onClick={handleClickCallback}
            >
                <ListItemText
                    style={{
                        paddingLeft: "4px",
                        whiteSpace: "pre-wrap",
                        lineHeight: 1.25,
                    }}
                    primary={name}
                />
            </MenuItem>
        );
    }
);

export default SelectedItem;
