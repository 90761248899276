import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { ApolloLink, split } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { HttpLink } from 'apollo-link-http'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import { OperationDefinitionNode } from 'graphql'
import jwt_decode from "jwt-decode";

// !remove hardcoding the url - done
// *if env is dev it will use env set url
const getHttpUrl = () => {
    let token = localStorage.getItem('Authorization') || null
    if (!!token) {
        let decoded = jwt_decode(token);
        // return `https://fbt-gql-${process.env.REACT_APP_ENV}-${decoded.customer_id}.herokuapp.com/v1/graphql`
        // return `https://fbt-gql-${process.env.REACT_APP_ENV}-embassy.herokuapp.com/v1/graphql`
        return decoded.gql_endpoint
    }
 
    return ''
}

const getAuthHeader = (): string | null => {
    return localStorage.getItem('Authorization') || null
}


function createClient() {
    const httpUri = getHttpUrl();
    // const wsUri = httpUri.replace(/^https?/, process.env.REACT_APP_ENV === 'dev' ? 'ws' : 'wss');
    const wsUri = httpUri.replace(/^https?/, 'wss');
    const httpLink = new HttpLink({
        uri: httpUri,
    });
    const wsLink = new WebSocketLink({
        uri: wsUri,
        options: {
            lazy: true,
            reconnect: true,
            connectionParams: () => {
                return { headers: { 'Authorization': getAuthHeader() } };
            },
        },
    });
    const authLink = setContext((_, { headers }) => {
        const auth = getAuthHeader()

        return {
            headers: {
                ...headers,
                Authorization: `Bearer ${auth}`
            },
        }
    });
    const terminatingLink = split(
        ({ query }) => {
            const { kind, operation } = getMainDefinition(query) as OperationDefinitionNode
            return kind === 'OperationDefinition' && operation === 'subscription'
        },
        wsLink,
        authLink.concat(httpLink),
    )
    const link = ApolloLink.from([terminatingLink])
    const cache = new InMemoryCache()
    return new ApolloClient({
        link,
        cache,
        queryDeduplication: false
    });
}
export interface IClientConstructor {
    (): ApolloClient<NormalizedCacheObject>;
};

export interface IClient {
    (): void;
    client?: ApolloClient<NormalizedCacheObject> | null;
    reset(): void;
};

let __client: ApolloClient<NormalizedCacheObject> | IClientConstructor = createClient;

const client = () => { if (__client instanceof ApolloClient) { } else { __client = __client(); } }
Object.defineProperty(client, 'client', {
    get() { if (__client instanceof ApolloClient) { return __client; } else return null; }
});
Object.defineProperty(client, 'reset', {
    value() { if (__client instanceof ApolloClient) { __client = createClient; } }
});

export default client as IClient;
