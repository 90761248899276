import React, { memo, useMemo, useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import useAccordion from "../filter/UseAccordionHook";
import { DefaultList } from "../filter/DefaultAccordianRender";
import moment, { Moment } from "moment";
import useStyles from './FatTableStyle'
import { Divider } from "@material-ui/core";
import RadioButton from "../filter/RadioButton";
const TempTitle = memo(({ title, dateRange }: { title: string; dateRange: string[]; urlId?: string; }) => (<div>{title}{dateRange}</div>));

interface IFatColumnData {
  column: { id: string; name: string; data?: { id: string; name: string; }[] };
}

interface TitleProps {
  title: string,
  urlId: string,
  dateRange: any
}

const defaultRanges: { id: string; name: string; }[] = [
  { id: "today-7", name: "Last 7 days" },
  { id: "today-week", name: "This week" },
  { id: "week-week", name: "Last week" },
  { id: "today-30", name: "Last 30 days" },
  { id: "today-month", name: "This month" },
  { id: "month-month", name: "Last month" },
  { id: "today-quarter", name: "This quarter" },
  { id: "quarter-quarter", name: "Last quarter" }
];

function uniqueRanges(range: { id: string; name: string; }[]): { id: string; name: string; }[] {
  let i: number = 0, ret: { id: string; name: string; }[] = [], check: string[] = [];
  const len: number = range.length;
  for (; i < len; i++) {
    if (range[i].id.indexOf('today-') === -1) { ret.push(range[i]); }
    else {
      const value = parseId(range[i].id).join('-');
      if (!check.includes(value)) {
        check.push(value);
        ret.push(range[i]);
      }
    }
  }
  return ret;
}

function reverseParseValue(arg: string[]): string[] {
  if (arg.length !== 2 && arg.length !== 0) { throw new TypeError("Illegal Arguments. Wrong number of Arguments"); }
  if (!arg.length) { return []; }
  try {
    let ret: string = "";
    const [first, second]: Moment[] = arg.map(d => moment(d).startOf('day'));
    const future = moment.max(first, second);
    const past = moment.min(first, second);
    const today = moment().startOf('day');
    if (future.isSame(today, 'day')) {
      ret = ret + 'today-';
      if (past.isSame(today.clone().startOf('week'), 'day')) { ret = ret + 'week'; }
      else if (past.isSame(today.clone().startOf('month'), 'day')) { ret = ret + 'month'; }
      else if (past.isSame(today.clone().startOf('quarter'), 'day')) { ret = ret + 'quarter'; }
      else {
        const num: number = future.diff(past, 'days');
        if (Number.isNaN(num)) { throw new TypeError("bad specification of number"); }
        if (num === 7 || num === 30) { ret = ret + num.toString(); }
        else {
          ret = "";
        }
      }
    }
    else {
      if (future.isSame(today.clone().startOf('week'), 'day') && past.isSame(future.clone().subtract(1, 'week'), 'day')) { ret = ret + 'week-week'; }
      else if (future.isSame(today.clone().startOf('month'), 'day') && past.isSame(future.clone().subtract(1, 'month'), 'day')) { ret = ret + 'month-month'; }
      else if (future.isSame(today.clone().startOf('quarter'), 'day') && past.isSame(future.clone().subtract(1, 'quarter'), 'day')) { ret = ret + 'quarter-quarter'; }
    }
    return ret.length ? [ret] : [];
  }
  catch (err) { throw new TypeError("Illegal Arguments. Arguments should be convertible to Moment objects. " + err.message); }
}

function parseId(arg: string): Moment[] {
  const [first, second] = arg.split('-');
  const future = parseFirstArg(first);
  const past = parseSecondArg(second, first, future);
  return [future, past];
}

function parseFirstArg(dat: string): Moment {
  switch (dat) {
    case 'today': return moment().startOf('day');
    case 'week': return moment().startOf('week');
    case 'month': return moment().startOf('month');
    case 'quarter': return moment().startOf('quarter');
    default: throw new Error("unimplemented");
  }
}

function parseSecondArg(dat: string, first: string, firstDat: Moment): Moment {
  let ret: Moment = firstDat.clone();
  if (first === 'today') {
    switch (dat) {
      case 'week':
      case 'month':
      case 'quarter': ret.startOf(dat); break;
      default:
        let num = Number.parseInt(dat);
        if (Number.isNaN(num)) { throw new Error("unimplemented or bad specification of number"); }
        ret.subtract(num, 'days');
    }
  }
  else {
    switch (dat) {
      case 'week':
      case 'month':
      case 'quarter': ret.subtract(1, dat); break;
      default: throw new Error("unimplemented");
    }
  }
  return ret;
}

function Filter({ column: { id, name, data } }: IFatColumnData) {
  const [status, setStatus] = useState<string[]>([]);
  const location = useLocation();
  const { search } = useMemo(() => location, [
    location.search,
    location.pathname,
  ]);
  const { push } = useHistory();
  const searchParam = useMemo(() => new URLSearchParams(search), [search]);
  const classes = useStyles({});
  /* const current: string[] = useMemo(
   *   () => searchParam.get(id) === null ? [] : ["true" === searchParam.get(id)],
   *   [search, id]
   * );
   * const initialState = useCallback(() => current, []); */
  const initialState = useCallback(() => reverseParseValue(searchParam.getAll(id)), []);
  const [state, Filter] = useAccordion<{ id: string; name: string; }, 'id'>(DefaultList, { multiple: false }, initialState);
  const opts = useMemo(() => !!data ? data : uniqueRanges(defaultRanges), [data]);
  const disabled = useMemo(() => state[0] === reverseParseValue(searchParam.getAll(id))[0], [id, state, searchParam]);
  const applyHandler = useCallback(() => {
    searchParam.delete(id);
    searchParam.delete('page');
    if (state.length) {
      const [future, past] = parseId(state[0]);
      searchParam.append(id, future.toISOString());
      searchParam.append(id, past.toISOString());
    }
    push({ ...location, search: searchParam.toString() });
  }, [state, id]);
  const applyReset = () => {
    const ascList = searchParam.getAll('sort');
    const descList = searchParam.getAll('sortDesc');
    searchParam.delete('sort');
    searchParam.delete('sortDesc');
    ascList.forEach(val => {
      /* if (id === 'activity_type_id' && val === 'precedence') { // Special case Handled
       *   return null;
       * } */
      if (val === id) {
        return null;
      }
      searchParam.append('sort', val);
    });
    descList.forEach(val => {
      /* if (id === 'activity_type_id' && val === 'precedence') { // Special case Handled
       *   return null;
       * } */
      if (val === id) {
        return null;
      }
      searchParam.append('sortDesc', val);
    });
    searchParam.delete(id);
    push({ ...location, search: searchParam.toString() });
  }
  useEffect(() => {
    const dates = searchParam.getAll(id);
    if (!dates.length) { setStatus([]); }
    if (dates.length === 2) {
      const datesM = dates.map(d => moment(d));
      setStatus([moment.min(...datesM).format('DD MMM YY'), moment.max(...datesM).format('DD MMM YY')]);
    }
  }, [searchParam]);
  /* useEffect(() => { if (!state.length) { setStatus([]); } }, [state]); */
  const titleProps: TitleProps = {
    title: name,
    urlId: id,
    dateRange: status
  };

  return (<Filter
    options={opts}
    Render={RadioButton}
    callback={applyHandler}
    reset={applyReset}
    title={<TempTitle title={name} dateRange={status} urlId={id} />}
    titleProps={titleProps}
    disabled={disabled}
  >
    {status.length ? (
      <><div style={{ display: "flex", flexDirection: "column", padding: "0 1.7em", fontFamily: "Source Sans Pro, sans-serif" }}> <span>Selected Date Range</span><span className={classes.dateRange}>{status.join(' — ')}</span></div> <Divider style={{ marginBottom: "0.5em" }} /></>
    ) : null}
  </Filter>);
}

export default memo(Filter);
