import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: (props: { isLg: boolean, isTab: boolean }) => ({
    background: "#2f3e6b",
    display: "flex",
    justifyContent: props.isLg && !props.isTab ? "unset" : "center",
    // paddingTop: "24vh",
    alignItems: "center",
    height: "100%",
    minHeight: "100vh"
  }),
  base: {
    justifyContent: "center",
    padding: "101px 150px",
    width: "600px!important",
    height: "500px",
    borderRadius: "2px",
    marginLeft: "23px",
    backgroundColor: "#ffffff",
    fontFamily: "Source Sans Pro, sans-serif !important",
  },
  baseSm: {
    justifyContent: "center",
    padding: "27px 64px",
    width: "358px!important",
    height: "314px",
    borderRadius: "2px",
    backgroundColor: "#ffffff",
    fontFamily: "Source Sans Pro, sans-serif !important",
  },
  formGroup: {
    display: "contents"
  },
  inputField: {
    "&:before": {
      borderBottom: "1px solid #005eff !important"
    },
    "&:after": {
      borderBottom: "1px solid #005eff !important"
    },
  },
  underline: {
    display: "inline-flex"
  },
  button: {
    fontSize: "12px",
    fontWeight: "normal",
    fontStyle: "normal",
    backgroundColor: "#005eff",
    color: "#ffffff",
    width: "300px",
    height: "40px",
    "&:hover": {
      backgroundColor: "#005eff"
    }
  },
  buttonSm: {
    fontSize: "12px",
    fontWeight: "normal",
    fontStyle: "normal",
    backgroundColor: "#005eff",
    color: "#ffffff",
    width: "230px",
    height: "40px",
    "&:hover": {
      backgroundColor: "#005eff"
    }
  },
  applyDisabled: {
    fontSize: "12px",
    fontWeight: "normal",
    fontStyle: "normal",
    backgroundColor: "#eaeaea",
    color: "#585858",
    width: "300px",
    height: "40px",
  },
  applyDisabledSm: {
    fontSize: "12px",
    fontWeight: "normal",
    fontStyle: "normal",
    backgroundColor: "#eaeaea",
    color: "#585858",
    width: "230px",
    height: "40px",
  },
  alert: {
    color: "#f44336",
    fontSize: "0.7rem"
  },
  invalidForm: {
    width: "100%",
    // height: "35px",
    textAlign: "center",
    backgroundColor: "#ff7777",
    border: "1px solid #ff3535",
    fontSize: "11px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: " normal",
    lineHeight: 1.27,
    letterSpacing: "normal",
    color: "#ffffff",
    padding: "4px",
    marginTop: "20px"
  },
  inputLabel: {
    fontWeight: "bolder",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.27,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#585858",
    textTransform: "uppercase"

  },
  // inputLabel: {
  //   textOverflow: 'ellipsis',
  //   whiteSpace: 'nowrap',
  //   overflow: 'hidden',
  //   width: '100%',
  //   color: 'red'
  // },

  input: {
    '&::placeholder': {
      fontSize: 11
    }
  },
  Iconbutton: {
    "& .MuiSvgIcon-root": {
      fill: "#2F3E6C",
      "&:hover": {
        fill: "#005eff"
      }
    },
  },
  forgetPassword: {
    fontSize: "14px",
    fontFamily: "Source Sans Pro",
    textTransform: "capitalize",
    color: "rgb(0, 94, 255)",
    marginTop: "10px",
    cursor: "pointer"
  },
  imageHead: {
    // backgroundImage:url(http://placehold.it/1920x1080);
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    height: "200px",
    width: "200px",
    margin: 0,
    padding: 0,
    color: "black",
    textAlign: "center"
  },
  rootDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  imageDiv: {
    height: "117px",
    background: "white",
    width: "117px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  animationsdiv: {
    height: "450px",
    display: "flex",
    alignItems: "center",
    "& .carousel.carousel-slider": {
      width: "70%",
      height: "70%"
    },
    "& .carousel .slide img": {
      width: "100px",
      height: "83px"
    },
    "& .carousel .slider-wrapper": {
      height: "90%"
    }
  },
  legend: {
    fontWeight: "bold",
    bottom: "40px",
    color: "white",
    opacity: "unset",
    fontSize: "23px",
    marginBottom: "9px"
  },
  paragraph: {
    // marginLeft: "27%",
    color: "white",
    width: "25%",
    display: "flex"
  }
}));

export default useStyles;
