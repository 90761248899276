import React from 'react'
import { useHistory } from 'react-router';
import { useObserver } from 'mobx-react-lite';
import arrowIcon from '../../../assets/images/menuNavigation/Arrow-default.svg';
import Cookie from '../../../configs/cookie';
import config from '../../../configs/clientConfig';
import { useStore } from '../../../models/ProvideModel';

const ActivityManagerLink = ({ activityManagerDetails, classes }) => {
  const store = useStore();
  const setCookie = (url: string) => {
    const token = localStorage.getItem('Authorization');
    const currentProject = localStorage.getItem('currentProject');
    const projects = localStorage.getItem('projectInfo')
    if (projects && token) {
      const projList = JSON.parse(projects).projects?.map(({id, name}) => {return {id, name}});
      const selectedProj = projList.filter(({id}) => currentProject === id);
      const propertyList = JSON.parse(projects).projects?.map(({id, name, properties}) => {return {id, name, properties}})
      const selectedProp  =  JSON.parse(projects).projects?.map(({id, name, properties}) => {return {id, name, properties}}).filter(({id}) => currentProject === id)
      // console.log(selectedProj)
      const projectsInfo = {projList, selectedProj: selectedProj[0], propertyList: propertyList[0].properties, propertySeleted: selectedProp[0].properties[0]}
      Cookie.set("projectsInfo", JSON.stringify(projectsInfo), 7);
      Cookie.set("jwtAuth", token, 7);
      window.open(config.PortalV1+url, '_blank' );
    }
  }

  return useObserver(() => (
    <>
      {
        activityManagerDetails.map((report, index) => 
            <div key={index}
              onClick={() => setCookie(report.embedConfig.redirectUrl)}
              className={`${store.responsiveUtils.currentViewport.isLg ? classes.tools : classes.toolsSm} ${classes.underlineAnim}`}>
              <div>{report.dashboardName}</div><img src={arrowIcon} className={'arrowIcon'} alt={'powerBi'} style={{ alignSelf: "end", transform: "rotate(270deg)" }} />
            </div>
          )
      }
    </>
  ));
}

export default ActivityManagerLink

