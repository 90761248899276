import { makeStyles } from "@material-ui/core/styles";
import { CommonAreaFinDetailsBrickProps } from "./CommonAreaFinishingDetail";
import { CommonAreaFinLandingBrickProps } from './StructuresLanding';
import { StructuresFullscreenBrickProps } from "./StructuresFullscreen";

const getBorderColorBasedOnStatus = (status) => {
    switch (status) {
        case 'completed':
            return '#2fb597';
        case 'notStarted':
            return '#c3c3c3';
        case 'started':
            return '#ffc400';
        case 'default':
            return "#eaeaea";
    }
}

const getBgBasedOnStatus = (status) => {
    switch (status) {
        case 'completed':
            return '#f5fff9';
        case 'notStarted':
            return '#f4f4f4';
        case 'started':
            return '#ffffff';
        case 'default':
            return "#ffffff";
    }
}

const getLandingBgBasedOnStatus = (status) => {
    switch (status) {
        case 'completed':
            return '#2fb597';
        case 'notStarted':
            return '#faf9f9';
        case 'started':
            return '#ffffff';
        case 'default':
            return "#ffffff";
    }
}

export const getStructuresFullscreenBgBasedOnStatus = (status) => {
    switch (status) {
        case 'completed':
            return '#f0fff7';
        case 'notStarted':
            return '#faf9f9';
        case 'started':
            return '#fff6cc';
        case 'default':
            return "#ffffff";
    }
}

const getStatusTxtColorByStatus = (status) => {
    switch (status) {
        case 'completed':
            return '#585858';
        case 'notStarted':
            return '#575757';
        case 'started':
            return '#575757';
        case 'default':
            return "#575757";
    }
}

const getLandingTextColorByStatus = (status) => {
    switch (status) {
        case 'completed':
            return '#faf9f9';
        case 'notStarted':
            return '#585858';
        case 'started':
            return '#585858';
        case 'default':
            return "#575757";
    }
}

export const getRectangleBorderColorBasedOnStatus = (status) => {
    switch (status) {
        case 'completed':
            return '#2fb597';
        case 'notStarted':
            return '#585858';
        case 'started':
            return '#ffc400';
        case 'default':
            return "#585858";
    }
}

export const getRectangleBackgroundColorBasedOnStatus = (status) => {
    switch (status) {
        case 'completed':
            return '#ffffff';
        case 'notStarted':
            return '#f4f4f4';
        case 'started':
            return '#ffffff';
        case 'default':
            return "#f4f4f4";
    }
}

export const getPercntageWidth = (width, completionRate) => {
    let w = (width * completionRate) / 100;
    return w.toFixed(2);
}

export const useCADetailsStyles = makeStyles((props: CommonAreaFinDetailsBrickProps) => {
    return {
        brickContainer: (props: CommonAreaFinDetailsBrickProps) => {
            return {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                fontFamily: "Source Sans Pro, sans-serif !important",
                width: `${props.width}px`,
                height: `${props.height}px`,
                background: getBgBasedOnStatus(props.status),
                boxSizing: "content-box",
                border: `1px solid ${getBorderColorBasedOnStatus(props.status)}`,
                opacity: props.filter ? "unset" : 0.1
            }
        },
        brickContent: (props: CommonAreaFinDetailsBrickProps) => {
            return {
                display: "flex",
                zIndex: 100,
                padding: '7px',
                flexDirection: "column",
                justifyContent: "space-around",
                height: "100%"
            }
        },
        brickProgressBg: (props: CommonAreaFinDetailsBrickProps) => {
            return {
                position: 'absolute',
                minWidth: `${getPercntageWidth(props.width, props.completionRate)}px`,
                height: `${props.height - 2}px`,
                backgroundColor: "#fff6cc"
            }
        },
        statusLabel: (props: CommonAreaFinDetailsBrickProps) => {
            return {
                display: "flex",
                flex: 1,
                justifyContent: "flex-start",
                fontSize: "8px",
                lineHeight: 1.25,
                color: getStatusTxtColorByStatus(props.status)
            }
        },
        brickDescCont: (props: CommonAreaFinDetailsBrickProps) => {
            return {
                display: "flex",
                width: "100%",
                justifyContent: "flex-start",
                fontSize: "12px",
                fontWeight: "bold",
                lineHeight: 1.25,
                color: getStatusTxtColorByStatus(props.status)
            }
        },
        snagsCont: (props: CommonAreaFinDetailsBrickProps) => {
            return {
                display: "flex",
                width: "100%",
                justifyContent: "flex-start",
                fontSize: "10px",
                fontWeight: "normal",
                lineHeight: 1.3,
                color: getStatusTxtColorByStatus(props.status)
            }
        },
        remainingDays: (props: CommonAreaFinDetailsBrickProps) => {
            return {
                color: "#ea3434",
                fontSize: "8px",
                flex: 1,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: "center"
            }
        },
        units: (props: CommonAreaFinDetailsBrickProps) => {
            return {
                color: "#585858",
                fontSize: "10px",
                fontWeight: "normal",
                display: 'flex',
                width: "100%"
            }
        },
        delayedDays: (props: CommonAreaFinDetailsBrickProps) => {
            return {
                display: 'flex',
                fontSize: '8px',
                color: '#ea3434',
                alignItems: 'center'
            }
        },
        circle: (props: CommonAreaFinDetailsBrickProps) => {
            return {
                width: "8px",
                height: "8px",
                borderRadius: "50%",
                border: "solid 1px #ea3434",
                backgroundColor: "#ff7777",
                marginLeft: '2px'
            }
        },
        rectangle: (props: CommonAreaFinDetailsBrickProps) => {
            return {
                width: "6px",
                height: "10px",
                border: "solid 1px #ea3434",
                backgroundColor: "#ff7777",
                marginLeft: '2px'
            }
        }

    }
});

export const useCALandingStyles = makeStyles((props: CommonAreaFinLandingBrickProps) => {
    return {
        brickContainer: (props: CommonAreaFinLandingBrickProps) => {
            return {
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                fontFamily: "Source Sans Pro, sans-serif !important",
                width: `${props.width}px`,
                height: `${props.height}px`,
                background: getLandingBgBasedOnStatus(props.status),
                boxSizing: "content-box",
                border: `1px solid ${getBorderColorBasedOnStatus(props.status)}`,
                opacity: props.filter ? "unset" : 0.1
            }
        },
        brickContent: (props: CommonAreaFinDetailsBrickProps) => {
            return {
                display: "flex",
                zIndex: 100,
                // padding: '7px',
                flexDirection: "column",
                justifyContent: "space-around",
                height: "100%",
                width: "100%",
                flexGrow: 1
            }
        },
        brickProgressBg: (props: CommonAreaFinLandingBrickProps) => {
            return {
                position: 'absolute',
                width: props.width,
                height: props.height,
                display: 'flex',
                alignItems: 'flex-end'
            }
        },
        brickProgressBgInner: (props: CommonAreaFinLandingBrickProps) => {
            return {
                position: 'relative',
                width: `${props.width}px`,
                minHeight: `${getPercentageForLanding(props.completionRate)}%`,
                backgroundColor: "#fff6cc"
            }
        },
        brickDescCont: (props: CommonAreaFinDetailsBrickProps) => {
            return {
                display: "flex",
                width: "100%",
                justifyContent: "center",
                fontSize: "11px",
                fontWeight: 600,
                lineHeight: 1.3,
                color: getLandingTextColorByStatus(props.status),
                flexDirection: "column",
                alignItems: "center"
            }
        },
        circle: {
            width: "11px",
            height: "11px",
            borderRadius: "50%",
            border: "solid 1px #ea3434",
            backgroundColor: "#ff7777",
            // marginLeft: '2px'
            marginLeft: '.18rem'
        },
        rectangle: {
            width: "8px",
            height: "11px",
            border: "solid 1px #ea3434",
            backgroundColor: "#ff7777",
            // marginLeft: '2px'
            marginRight: '.2rem'
        }
    }
});

export const useStructuresFullscreenStyles = makeStyles((props: StructuresFullscreenBrickProps) => {
    return {
        brickContainer: (props: StructuresFullscreenBrickProps) => {
            return {
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                fontFamily: "Source Sans Pro, sans-serif !important",
                background: getBgBasedOnStatus(props.status),
                width: `${props.width}px`,
                height: `${props.height}px`,
                boxSizing: "content-box",
                border: `1px solid ${getBorderColorBasedOnStatus(props.status)}`,
                opacity: props.filter ? "unset" : 0.1
            }
        },
        innerbrickContainer: {
            width: "90%",
            height: "90%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignSelf: "center",
            justifyItems: "flex-start"
        },
        brickContent: (props: StructuresFullscreenBrickProps) => {
            return {
                display: "flex",
                zIndex: 100,
                padding: '7px',
                flexDirection: "column",
                justifyContent: "space-around",
                height: "100%"
            }
        },
        brickProgressBg: (props: StructuresFullscreenBrickProps) => {
            return {
                position: 'absolute',
                width: props.width,
                minHeight: `${props.progressAsPercent}%`,
                background: getStructuresFullscreenBgBasedOnStatus(props.status),
                // display: 'flex',
                // alignItems: 'flex-end'
            }
        },
        brickProgressBgInner: (props: StructuresFullscreenBrickProps) => {
            return {
                position: 'relative',
                width: `${props.width}px`,
                minHeight: `${props.progressAsPercent}%`,
                background: getStructuresFullscreenBgBasedOnStatus(props.status),
                backgroundColor: "#fff6cc"
            }
        },
        brickName: {
            fontSize: "14px",
            fontWeight: "bold",
            fill: "#585858",
            fontFamily: "Source Sans Pro, sans-serif !important",
            textTransform: "uppercase"
        },
        display: {
            fontSize: "12px",
            fontFamily: "Source Sans Pro, sans-serif !important",
            fill: "#585858",
            fontWeight: "bold",
            lineHeight: 1.5,
        },
        delayed: {
            fill: "#ff3535",
            fontSize: "12px",
            fontWeight: "normal",
            fontFamily: "Source Sans Pro, sans-serif !important",
        },
        date: {
            fontSize: "12px",
            fontFamily: "Source Sans Pro, sans-serif !important",
            color: "#585858",
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: 1.25,
            letterSpacing: "normal",
            textAlign: "left",
        },
        circle: (props: StructuresFullscreenBrickProps) => {
            return {
                width: "8px",
                height: "8px",
                borderRadius: "50%",
                border: "solid 1px #ea3434",
                backgroundColor: "#ff7777",
                marginLeft: '1px',
                marginRight: "5px"
            }
        },
        startDelayedCircle: (props: StructuresFullscreenBrickProps) => {
            return {
                width: "8px",
                height: "8px",
                borderRadius: "50%",
                border: `1px solid ${getRectangleBorderColorBasedOnStatus(props.status)}`,
                backgroundColor: getRectangleBackgroundColorBasedOnStatus(props.status),
                marginLeft: '2px',
                marginRight: "5px"
            }
        },
        endDelayedRectangle: (props: StructuresFullscreenBrickProps) => {
            return {
                width: "6px",
                height: "10px",
                border: `1px solid ${getRectangleBorderColorBasedOnStatus(props.status)}`,
                backgroundColor: getRectangleBackgroundColorBasedOnStatus(props.status),
                marginLeft: '2px',
                marginRight: "7px"
            }
        },
        rectangle: (props: StructuresFullscreenBrickProps) => {
            return {
                width: "6px",
                height: "10px",
                border: "solid 1px #ea3434",
                backgroundColor: "#ff7777",
                marginLeft: '2px',
                marginRight: "7px"
            }
        },
        brickDescCont: (props: StructuresFullscreenBrickProps) => {
            return {
                display: "flex",
                width: "100%",
                justifyContent: "center",
                fontSize: "10px",
                fontWeight: 600,
                lineHeight: 1.3,
                color: getLandingTextColorByStatus(props.status)
            }
        },
    }
});

const getPercentageForLanding = (completionRate) => {
    return Number(completionRate).toFixed(2);
}
