import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: "#faf9f9",
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        padding: "5px 1px"
    },
    vertical: {
        flexDirection: "column"
    },
    horizontal: {
        flexDirection: "row"
    },
    item: {
        height: "60px",
        padding: "1em",
        width: "90%",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    active: {
        backgroundColor: "white"
    }
}));

export default useStyles;
