import { red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";
import './react-custom-scrollbar.css'
import "typeface-source-sans-pro";
import './loading.css';
import arrowIcon from "../assets/images/Arrow.svg";
// A custom theme for this app
export interface CustomThemeProps {
    viewPort: any
}

export const useCustomTheme = (props: CustomThemeProps) => {
    const { viewPort } = props;
    return createMuiTheme({
        palette: {
            type: "light",
            primary: {
                main: "#2F3E6C",
            },
            secondary: {
                main: "#19857B"
            },
            error: {
                main: red.A400
            },
            background: {
                default: "#fff"
            },
            action: {
                disabledBackground: 'transparent'
            }
        },
        overrides: {
            MuiInput: {
                underline: {
                    display: "inline-block",
                    textDecoration: " none",
                    /* Set up the hover */
                    /* If you aren't using autoprefix, remember to prefix the gradient for other browsers */
                    backgroundImage: "linear-gradient(#005EFF, #005EFF)",
                    backgroundSize: "0 1px, auto",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center bottom",
                    transition: "all .2s ease-out",
                    "&:hover": {
                        borderBottom: "0px solid #005EFF",
                        backgroundSize: "100% 1px, auto",
                    },
                    '&:hover:not($disabled):before': {
                        backgroundSize: "100% 1px, auto",
                        borderBottom: "1px solid #005EFF",
                    },
                    "&:after": {
                        backgroundSize: "100% 1px, auto",
                        borderBottom: "1px solid #005EFF"
                    },
                    "&:before": {
                        borderBottom: "1px solid #005EFF"
                    },
                    "&$disabled:before": {
                        borderBottomStyle: "none"
                    }
                }
            },
            MuiSelect: {
                select: {
                    paddingRight: "0px !important",
                    // "&:focus": {
                    //     backgroundColor: "rgb(237, 235, 235)"
                    // },
                },
                // iconOpen: {
                //     transform:"rotate(45deg)"
                // },
                selectMenu: {
                    fontSize: "14px"
                },
                icon: {
                    top: " calc(50% - 9px)",
                    // color: "#E9E9E9",
                    right: "0px",
                    // position: "absolute",
                    // pointerEvents: "none",
                    // border: "solid #005EFF",
                    // width: "7px",
                    // height: "7px",
                    // borderWidth: "0 1px 1px 0 !important",
                    // display: "inline-block",
                    // padding: "0px",
                    // transform: "rotate(45deg)"
                    backgroundImage: `url(${arrowIcon})`,
                }
            },
            MuiCheckbox: {
                colorSecondary: {
                    color: "#005EFF",
                    '&$checked': {
                        color: '#005EFF',
                    },
                }
            },
            MuiTypography: {
                body1: {
                    fontWeight: 0,
                    fontSize: "",
                    fontFamily: "Source Sans Pro, sans-serif",
                },
                colorSecondary: {
                    color: "#005EFF"
                }
            },
            MuiList: {
                padding: {
                    paddingBottom: "0px",
                    paddingTop: "0px"
                }
            },
            MuiListItem: {
                button: {
                    "&:hover": {
                        backgroundColor: "#EDEBEB",
                    },
                    "&$selected": {
                        fontFamily: "Source Sans Pro, sans-serif",
                        fontSize: "14px",
                        fontWeight: "bold",
                        fontStyle: "normal",
                        fontStretch: "normal",
                        lineHeight: 2.5,
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#585858",
                        backgroundColor: "rgb(255, 255, 255)",
                        "&:hover": {
                            backgroundColor: "#EDEBEB",
                            color: "#005eff",
                        }
                    },
                }
            },
            MuiRadio: {
                colorPrimary: {
                    color: "#005EFF",
                    "&$checked": {
                        color: "#005EFF",
                    }
                }
            },
            MuiFormLabel: {
                root: {
                    color: "#585858",
                    "&$focused": {
                        // fontSize: "11px",
                        fontWeight: "bold",
                        fontStyle: "normal",
                        fontStretch: "normal",
                        lineHeight: 1.27,
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#585858",
                    },
                    "&$disabled": {
                        color: "#2f3e6c",
                    }
                }
            },
            MuiInputLabel: {
                formControl: {
                    fontFamily: "Source Sans Pro, sans-serif !important",
                    fontSize: "12px ",
                    color: "#585858 ",
                },
                shrink: {
                    fontSize: "11px",
                    fontWeight: "bold",
                    fontStyle: "normal",
                    fontStretch: "normal",
                    lineHeight: 1.27,
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#2f3e6c",
                    textTransform: "uppercase"
                }
            },
            MuiPopover: {
                paper: {
                    minWidth: "200px",
                    // padding:"1rem",
                }
            },
            MuiDialog: {
                paperFullScreen: {
                    width: viewPort.lg ? "1377px" : '100%',
                    height: viewPort.lg ? "96%" : '100%',
                    borderRadius: "4px",
                    overflow: viewPort.lg ? "hidden" : 'scroll'
                }
            },
            MuiToolbar: {
                regular: {
                    '@media (min-width: 0px)': {
                        minHeight: "48px"
                    }
                },
                gutters: {
                    '@media (min-width: 0px)': {
                        padding: "0px"
                    }
                },
            },
            MuiTab: {
                root: {
                    margin: "0px 34px",
                    padding: "6px 0px",
                    '@media (min-width: 600px)': {
                        minWidth: "50px"
                    }
                },
                textColorPrimary: {
                    color: "#005EFF",
                    fontSize: "20px",
                    fontFamily: "Source Sans Pro, sans-serif !important",
                    fontWeight: "normal",
                    "&$selected": {
                        color: "#585858",
                        fontSize: "20px",
                        fontFamily: "Source Sans Pro, sans-serif !important",
                        fontWeight: "bold",
                        "&:hover": {
                            transition: "none",
                            backgroundSize: "0% 0px, auto",
                        },
                    },
                    display: "inline-block",
                    textDecoration: " none",
                    /* Set up the hover */
                    /* If you aren't using autoprefix, remember to prefix the gradient for other browsers */
                    backgroundImage: "linear-gradient(#005EFF, #005EFF)",
                    backgroundSize: "0 3px, auto",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center bottom",
                    transition: "all .2s ease-out",
                    "&:hover": {
                        backgroundSize: "100% 2px, auto",
                    },
                },
                wrapper: {
                    fontSize: "20px",
                    fontFamily: "Source Sans Pro, sans-serif !important",
                    textTransform: "capitalize"
                },
            },
            MuiTabs: {
                indicator: {
                    backgroundColor: "#585858"
                }
            },
            MuiAppBar: {
                colorPrimary: {
                    backgroundColor: "#fff"
                },
                root: {
                    boxShadow: "none"
                }
            },
            // .MuiInputBase-input
            // .MuiListItemText-root
            MuiInputBase: {
                input: {
                    paddingTop: "8px"
                }
            },
            MuiLink: {
                underlineHover: {
                    display: "inline-flex",
                    textDecoration: " none",
                    /* Set up the hover */
                    /* If you aren't using autoprefix, remember to prefix the gradient for other browsers */
                    backgroundImage: "linear-gradient(#005EFF, #005EFF)",
                    backgroundSize: "0 3px, auto",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center bottom",
                    transition: "all .2s ease-out",
                    cursor: "pointer",
                    fontFamily: "Source Sans Pro, sans-serif !important",
                    "&:hover": {
                        backgroundSize: "100% 2px, auto",
                        textDecoration: "none",
                        fontFamily: "Source Sans Pro, sans-serif !important",
                    },
                }
            },
            MuiMenuItem: {
                root: {
                    lineHeight: 2.5,
                    fontFamily: "Source Sans Pro, sans-serif !important",
                    // "&:first-child": {
                    //     lineHeight:1
                    //   }
                    minHeight: "35px"
                }
            },
            MuiTooltip: {
                tooltip: {
                    padding: 0,
                    backgroundColor: "#005EFF",
                    top: "-1px"
                }
            },
            MuiSvgIcon: {
                root: {
                    fill: "transparent"
                }
            },
            MuiListSubheader: {
                root: {
                    fontFamily: "Source Sans Pro, sans-serif !important",
                    lineHeight: "12px",
                    fontWeight: 300
                },
                sticky: {
                    backgroundColor: "#fff",
                    fontSize: "11px",
                    color: "#808080"
                }
            },
            MuiBackdrop: {
                root: {
                    backgroundColor: "#2F3E6C"
                }
            },
            MuiTableHead: {
                root: {
                },
            },
            MuiTableCell: {
                root: {
                    borderBottom: "none"
                },
                head: {
                    fontFamily: "Source Sans Pro, sans-serif",
                    fontSize: "14px",
                    textTransform: "capitalize",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: 1.25,
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#585858",
                    padding: "12px 12px 12px 1px"
                },
                body: {
                    fontFamily: "Source Sans Pro, sans-serif",
                    lineHeight: 1.29,
                    border: "1px solid #d1d1d1",
                    fontSize: "14px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#585858",
                    padding: "12px 24px 12px 12px"
                },
                stickyHeader: {
                    backgroundColor: "#faf9f9"
                }
            },
            MuiTableRow: {
                root: {
                    backgroundColor: "#f4f4f4",
                    "&:nth-child(even)": {
                        backgroundColor: "#fff",
                        fontSize: "11px",
                        color: "#808080"
                    }
                }
            }
        }
    })
}