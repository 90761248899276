import { Instance, types } from 'mobx-state-tree';
import { Pour } from './Pour';
import { Finishing } from './Finishing';
import { BrickTypes } from '../enums';

export function brickDispatch({ type }) {
    switch (type as BrickTypes) {
        case BrickTypes.S: return Pour;
        case BrickTypes.F: return Finishing;
        default: throw new Error("please give a valid type of brick");
    }
}


export const Brick = types.union({ dispatcher: brickDispatch, eager: false }, Pour, Finishing);

export type IBrick = Instance<typeof Brick>;
