import React, {
  memo,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import { useHistory, useLocation } from "react-router";
import { putAsync } from "csp-with-ts";
import useAccordion from "./UseAccordionHook";
import { SelectedState } from './SelectedState'
import RadioButton from "./RadioButton";
import { DefaultList } from "./DefaultAccordianRender";

const ViewSpaceType = ({ spaceTypes, sel, current }) =>
  (<div><span>{sel ?
    spaceTypes.find(({ id }) => id === sel)?.name :
    spaceTypes.find(({ id }) => id === current)?.name}</span> </div>);

const byParam: string = "Space";
export interface IBySpaceType {
  data: { id: string; name: string }[];
  phase: string;
  spaceType: string;
  section: string;
}

function SpaceType({ data, phase, spaceType, section }: IBySpaceType) {
  const { search } = useLocation();
  const towers = useMemo(() => (new URLSearchParams(search)).getAll('block_id').map(val => `block_id=${val}`).join('&'), [search]);
  const { push } = useHistory();

  const initialState = useCallback(() => spaceType ? [spaceType] : [], [spaceType]);

  const [state, BySpaceTypeFilter, relay] = useAccordion<{ id: string; name: string; }, 'id'>(DefaultList, { multiple: false, accordian: true, reset: false }, initialState);
  useEffect(() => { putAsync(relay, { type: 'replace', payload: [spaceType!] }); }, [spaceType]);
  const disabled = useMemo(() => {
    if (state[0] && state[0] === spaceType) { return true; }
    return false;
  }, [state, spaceType]);

  const applyHandler = () => {
    // maybe generalize this later sometime.
    if (state[0]) {
      push(`/${section}/${phase}/${state[0]}?${towers}`);
    }
  }

  return (
    <BySpaceTypeFilter
      options={data}
      Render={RadioButton}
      callback={applyHandler}
      disabled={disabled}
      title={<SelectedState title={byParam} state={state} filterType={"spaceType"} />}
      externalDisplay={<ViewSpaceType spaceTypes={data} sel={state[0]} current={spaceType} />}
    />
  );
};

export default memo(SpaceType);
