import * as React from "react";
import { Typography } from "@material-ui/core";
import useStyles from "./TitleStyles";

interface ITitleProps {
  noOfUnits: number;
  title: string;
  screenProps?: any
}

const Title = ({ noOfUnits, title, screenProps }: ITitleProps) => {
  const classes = useStyles({ isLg: screenProps.isLg });
  return <Typography className={classes.root}><span>{title}  ({noOfUnits})</span></Typography>;
};

export default Title;
