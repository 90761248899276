import { makeStyles, Theme } from "@material-ui/core/styles";
import towerBackIcon from "../../assets/images/towerBack.svg";
import towerUpIcon from "../../assets/images/hoverup.svg";

const useStyles = makeStyles((theme: Theme) => ({
  root: () => ({
    width: '100%',
    fontFamily: "Source Sans Pro, sans-serif !important",
    // padding: '0 1rem',
    // paddingBottom: "1rem",
    backgroundColor: "#faf9f9"
  }),
  formControl: {
    paddingBottom: "0px",
    // minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  headerText: {
    fontSize: 12,
    lineHeight: 1.27,
    fontWeight: 'bold',
    color: '#585858',
    textTransform: 'uppercase',
    textAlign: 'left',
    fontFamily: "Source Sans Pro, sans-serif !important",
  },
  tableCell: {
    borderCollapse: 'collapse',
    border: '1px solid #d1d1d1',
    fontSize: 12,
    lineHeight: 1.27,
    padding: "12px 12px"
  },
  table: {
    borderCollapse: 'collapse',
    width: '100%',
    // padding: '1rem',
    fontFamily: "Source Sans Pro, sans-serif !important",
  },
  tableHeader: {
    '& tr': {
      background: '#ffffff',
    },
    '& th': {
      padding: '0 8px 16px 0 ',
      textAlign: 'left',
      verticalAlign: 'baseline',
    }
  },
  tableHeaderUnits: {
    '& tr': {
      background: '#ffffff',
    },
    '& th': {
      padding: '0 8px 16px 0 ',
      textAlign: 'left',
      verticalAlign: 'baseline',

      '&:nth-child(n+1)': {
        width: "30px !important"
      }
    },
  },
  tableBody: {
    '& tr:nth-child(odd)': {
      background: '#f4f4f4'
    },
  },
  tableRow: {
    '& td': {
      alignItems: "center",
      display: "flex",
      fontFamily: "Source Sans Pro, sans-serif !important",
    }
  },
  tableRowUnits: {
    '& td': {
      alignItems: "center",
      display: "flex",
      fontFamily: "Source Sans Pro, sans-serif !important",

      '&:nth-child(n+1)': {
        width: "30px !important"
      }
    },
    '& tr': {
      height: "100px !important"
    }
  },
  statusCell: {
    display: 'flex',
    height: "100%",
    alignItems: "center",
    flexDirection: 'row',
    fontFamily: "Source Sans Pro, sans-serif !important",
  },
  arrowImg: {
    position: "absolute",
    // left: "60px",
    right: "-6px",
    top: "2px",
    width: "28px"
  },
  selectInput: {
    fontFamily: "Source Sans Pro, sans-serif !important",
    fontSize: "12px",
    cursor: "pointer",
    width: "100%",
    textTransform: "capitalize",
    whiteSpace: "nowrap",
    "& input": {
      color: "#585858",
      fontFamily: "Source Sans Pro, sans-serif !important",
      fontSize: "12px",
      cursor: "pointer",
      width: "100%",
      textTransform: "capitalize",
      whiteSpace: "nowrap",
      borderBottom: "1px solid #005eff",
    },
    "& label": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "80%",
    }
  },
  searchSort: {
    top: 0,
    position: "sticky",
    background: "#fff",
    zIndex: 99999,
  },

  //Dropdown styles
  progressOver: {
    fontFamily: "Source Sans Pro, sans-serif !important",
    fontSize: "11px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.27,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#585858",
    display: "inline-block",
    width: "90%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  dateField: {
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "14px",
    fontWeight: "bold",
    "&:before": {
      borderBottom: "1px solid #005eff",
    },
    "&:after": {
      borderBottom: "1px solid #005eff",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "#faf9f9"
    },
    "& .MuiSelect-selectMenu": {
      width: "80%"
    }
  },
  inputField: {
    // color:"#005eff",
    // fontWeight:"bold",
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "14px",
    "&:before": {
      borderBottom: "1px solid #005eff"
    },
    "&:after": {
      borderBottom: "1px solid #005eff",
      backgroundColor: "#fff"
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "#faf9f9"
    }
  },
  selectStatus: {
    fontSize: "11px !important",
    height: "30px",
    display: "flex",
    alignItems: "center",
    padding: "8px 16px 0px 16px",
  },
  selectedItem: {
    // "&:first-child": {
    //   color:"black"
    // },
    color: "#005eff",
    fontSize: "14px",
    minHeight: "36px",
    "&:hover": {
      fontWeight: "bold",
      color: "#005eff",
      marginLeft: "5px",
      marginRight: "5px"
    },
    fontFamily: "Source Sans Pro, sans-serif !important",
    "&:last-child": {
      marginBottom: 8
    },
  },
  noDataFound: {
    fontSize: "24px",
    textAlign: "center",
    fontWeight: 700,
    fontFamily: "Source Sans Pro, sans-serif !important"
  },
  boldDarkBlue24px: {
    fontSize: "24px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.29,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#2f3e6c",
  },
  backBtn: {
    fontSize: 14,
    width: 24,
    height: 24,
    fontFamily: 'Source Sans Pro',
    color: '#005eff',
    fontWeight: "normal",
    alignSelf: "center",
    // marginLeft:24,
  },
  backDiv: {
    border: "none",
    textAlign: "end",
    padding: "0px",
    width: "60px",
    transition: "all 0.5s",
    cursor: "pointer",
    "& span": {
      cursor: "pointer",
      display: "inline-block",
      position: "relative",
      transition: "0.5s",
      "&:after": {
        position: "absolute",
        opacity: 0,
        top: 0,
        right: "-15px",
        transition: "0.5s",
      },
    },
    "&:hover": {
      "& span": {
        paddingRight: "10px",
        "&:hover": {
          opacity: 1,
          right: 0
        }
      }
    }
  },
  changeImage: {
    width: 24,
    height: 25,
    marginTop: 3,
    background:
      `url(${towerBackIcon})  no-repeat`,
    display: "inline-block",
    "&:hover": {
      background:
        `url(${towerUpIcon})  no-repeat`,
    }
  },
  statusFilterQL: {
    // padding: "0 12px 0 0"
    // minWidth : "min-content",
    // width: "75%",
    // fontSize: "12px !important"
  },
  statusFilter: {
    padding: "0 12px 0 0",
    // position: "absolute",
    // left: "30%"
    // width:"inherit",
    fontSize: "12px !important",
  },
  typeButton: {
    minWidth: "80px", width: "96%", border: "none",
    "&:after": { border: "none" },
  },
  resetFilter: {
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 16px",
    alignItems: "center"
  },
  normText11px: {
    display: "flex",
    height: "30px",
    fontFamily: "Source Sans Pro, sans-serif !important",
    fontSize: "11px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.27,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#808080",
    alignItems: "center",
  },
  progressBarContainer: {

  },
  progressValueCont: () => {
    return {
      color: "#585858",
      marginTop: "-3px",
      fontSize: "14px",
      lineHeight: 1.29
    }
  },
  paperProps: (props: { isSm?: boolean; }) => {
    return {
      maxHeight: props.isSm ? '80vh' : "600px",
      minWidth: "280px",
      maxWidth: "280px",
      top: props.isSm ? '10px !important' : ''
    }
  },
  filled: {
    fill: "#ff7777",
    stroke: "#ff3535",
    strokeWidth: 1
  },
  formDataImgClass: {
    maxHeight: "150px",
    maxWidth: "150px"
  },
  linkStyle: {
    textDecoration: "none",
    color: "#005eff",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  imageStyle : {
    display: 'flex', 
    padding: '0em 0.5em', 
    cursor: 'pointer',
    '&:hover' : {
      opacity: 0.7
    }
  }
})
);

export default useStyles;
