const fullscreen = [
    {
        "key": "Completed",
        "BGColor": "#34d1ae",
        "border": "#34d1ae",
        "borderRadius": "1px"
    },
    {
        "key": "Ongoing",
        "BGColor": "#ffd300",
        "border": "#ffd300",
        "borderRadius": "1px"
    },
    {
        "key": "Not Started",
        "BGColor": "#eaeaea",
        "border": "#c3c3c3",
        "borderRadius": "1px"
    },
    {
        "key": "Start Delay",
        "BGColor": "#ff7777",
        "border": "#ea3434",
        "borderRadius": "none"
    }
];
const landing = [
    {
        "key": "Completed",
        "BGColor": "#e0f7f2",
        "border": "#34d1ae",
        "borderRadius": "1px"
    },
    {
        "key": "Ongoing",
        "BGColor": "#ffed99",
        "border": "#ffd300",
        "borderRadius": "1px"
    },
    {
        "key": "Not Started",
        "BGColor": "#eaeaea",
        "border": "#585858",
        "borderRadius": "1px"
    },
    {
        "key": "Start Delay",
        "BGColor": "#ff7777",
        "border": "#ea3434",
        "borderRadius": "none"
    }
    
];

const barChart = [
    {
        "key": "Completed",
        "BGColor": "#34d1ae",
        "border": "#34d1ae",
        "borderRadius": "1px"
    },
    {
        "key": "Ongoing",
        "BGColor": "#ffd300",
        "border": "#ffd300",
        "borderRadius": "1px"
    },
    {
        "key": "Not Started",
        "BGColor": "#eaeaea",
        "border": "#c3c3c3",
        "borderRadius": "1px"
    }
    
]

module.exports = { fullscreen, landing ,barChart}

