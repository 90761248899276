import { types, Instance, flow, getRoot } from 'mobx-state-tree';
import { downloadReports, checkReportStatus } from '../api/downloadReports'
import { LateStoreModel } from './DataStore';
import { getPdf } from '../utils/utils';
import { DownloadStatuses } from './enums';
import moment from 'moment';

const __Q_LIMIT = 20;

//currently there are a loooot of repeated s3 keys. Once that ish is gone, put
//in id as types.identifier again.
export enum ChecklistSelectActions {
    TOGGLE = "TOGGLE",
    REPLACE = "REPLACE",
    CLEAR = "CLEAR",
    ADD = "ADD"
};

export enum ChecklistSelectStates {
    EMPTY = "EMPTY",
    PAGE_SELECTED = "PAGE_SELECTED",
    PARTIAL = "PARTIAL",
    ALL = "ALL",
    DEFAULT = "DEFAULT"
};


const ChecklistItem = types.model({
    id: types.string,
    fileName: types.maybeNull(types.string)
});

export const ChecklistReportsDownload = types.model({
    type: 'checklist',
    fileName: types.identifier,
    url: types.string,
    status: types.frozen<DownloadStatuses>(DownloadStatuses.PENDING),
    timeStamp: types.string,
}).volatile(_ => ({ loading: false }))
    .views(_ => ({ get extension() { return ""; } }))
    .actions(self => ({
        setStatus(status: DownloadStatuses) {
            self.status = status;
        },
        getPdf() {
            getPdf(self.url, self.fileName);
            self.status = DownloadStatuses.DONE;
        }
    })).actions(self => ({
        poll: flow(function* poll() {
            if (self.status === DownloadStatuses.PENDING) {
                try {
                    self.loading = true;
                    const { status } = yield checkReportStatus(self.fileName);
                    if (status === "DONE") {
                        self.setStatus(DownloadStatuses.SUCCESS);
                    }
                }
                catch (error) { self.setStatus(DownloadStatuses.FAILURE); }
                finally { self.loading = false; }
            }
        })
    }));

// There is one unhandled edge case where manually selecting all the checkboxes
// will not register as a Page_Selected checklistState.
// TODO: How should clicking on header checkbox behave after selecting Select All?
const filtering = (items, checklistItems, add, remove) => {
    for (var i = 0; i < items.length; i++) {
        const val = checklistItems.find(({ id }) => id === items[i]?.id);
        if (val) { remove && checklistItems.remove(val) }
        else { add && checklistItems.push(items[i]); }
    }
}
export const ChecklistSelect = types.model({
    checklistItems: types.array(ChecklistItem),
    checklistState: types.frozen<ChecklistSelectStates>(ChecklistSelectStates.EMPTY),
    downloadQ: types.array(ChecklistReportsDownload),
    selectAll: false,
    pageisempty: types.frozen<ChecklistSelectStates>(ChecklistSelectStates.EMPTY),
    loading: false,
    zipPopupOpen: types.optional(types.boolean, false)
}).views(self => ({
    get isBusy() {
        return self.downloadQ.length > 0 ? self.downloadQ.some(({ status }) => status === DownloadStatuses.PENDING) : false;
    }
})).views(self => ({
    get disabled() { return self.isBusy || !!!self.checklistItems.length; },
})).actions(self => ({
    addItems({ action, payload: { items, state, totalNoItems } }: IChecklistSelectAction) {
        var particularPageSelected = false;
        if (!particularPageSelected) {
            for (var i = 0; i < items.length; i++) {
                const val = self.checklistItems?.find(({ id }) => id === items[i]?.id);
                if (val) { particularPageSelected = false }
                else { particularPageSelected = true }
            }
        }
        switch (action) {
            case ChecklistSelectActions.CLEAR:
                items.length ? filtering(items, self.checklistItems, false, true)
                    : self.checklistItems.clear()
                self.checklistState = ChecklistSelectStates.EMPTY;
                break;
            case ChecklistSelectActions.REPLACE:
                state?.toLowerCase() === "all" &&
                    self.checklistItems.clear();
                state?.toLowerCase() === "all" ?
                    self.checklistItems.push(...items) :
                    filtering(items, self.checklistItems, true, false)
                self.checklistState = state ? state : particularPageSelected ? ChecklistSelectStates.DEFAULT : ChecklistSelectStates.EMPTY;
                break;
            case ChecklistSelectActions.ADD:
                self.checklistState = ChecklistSelectStates.EMPTY;
                break;
            case ChecklistSelectActions.TOGGLE:
                filtering(items, self.checklistItems, true, true)
                if (totalNoItems && self.pageisempty === ChecklistSelectStates.EMPTY) {
                    for (var i = 0; i < totalNoItems?.length; i++) {
                        const val = self.checklistItems?.find(({ id }) => id === totalNoItems[i]?.id);
                        if (val) { self.pageisempty = ChecklistSelectStates.PARTIAL }
                    }
                }
                if (particularPageSelected) { self.checklistState = ChecklistSelectStates.PAGE_SELECTED }
                else if (self.checklistItems.length && self.pageisempty === ChecklistSelectStates.PARTIAL) { self.checklistState = ChecklistSelectStates.DEFAULT }
                else { self.checklistState = ChecklistSelectStates.EMPTY; }
        }
    }
})).actions(self => ({
    addToDownloadQ(url: string, fileName: string) {
        if (self.downloadQ.length >= __Q_LIMIT) { self.downloadQ.shift(); }
        self.downloadQ.push({ fileName, url, type: "checklist", timeStamp: moment().toISOString() });
    },
    setZipPopupOpen(val: boolean) {
        self.zipPopupOpen = val
    },
})).actions(self => ({
    changeLoading(arg) {
        self.loading = false
    }
})).actions(self => ({
    changeChecklistState(action) {
        self.checklistState = ChecklistSelectStates.EMPTY;
        switch (action) {
            case ChecklistSelectActions.CLEAR:
                self.checklistState = ChecklistSelectStates.EMPTY;
                break;
            case ChecklistSelectActions.REPLACE:
                self.checklistState = ChecklistSelectStates.PAGE_SELECTED;
        }
    }
})).actions(self => ({
    changeSelectedState(arg: boolean) {
        self.selectAll = arg
    }
})).actions(self => ({
    download: flow(function* dnld() {
        if (!self.disabled) {
            try {
                self.loading = true
                self.zipPopupOpen = true
                const { reXportPath, fileName } = yield downloadReports((getRoot(self) as Instance<typeof LateStoreModel>).auth.customerId, self.checklistItems);
                self.addToDownloadQ(reXportPath, fileName);
                // self.loading = false
                self.loading = false;
            } catch (err) { console.log(err); self.loading = false; throw err; }
        }
    }),
    pollStatuses() {
        if (self.downloadQ.length === 0) { return; }
        for (let dwnld of self.downloadQ) {
            if (dwnld.status === DownloadStatuses.PENDING) { dwnld.poll(); }
        }
    },
    cleanQ() {
        if (self.downloadQ.length === 0) { return; }
        // const newQ = Array.from(self.downloadQ.filter(({ status }) => status !== DownloadStatuses.DONE));
        const newQ = Array.from(self.downloadQ.filter(({ timeStamp, status }) => moment().diff(moment(timeStamp), 'd') < 3 && status !== DownloadStatuses.FAILURE));

        self.downloadQ.replace(newQ);
    }
})).actions(self => ({
    afterAttach() {
        const storg = localStorage.getItem('reportsQ');
        if (!storg) { return; }
        const arr = JSON.parse(storg);
        if (Array.isArray(arr) && arr.length) {
            self.downloadQ.push(...arr);
        }
    }
}));

export interface IChecklistSelectAction {
    action: ChecklistSelectActions;
    payload: {
        items: IChecklistItem[];
        state?: ChecklistSelectStates;
        totalNoItems?: IChecklistItem[];
    };
};

export interface IChecklistItem extends Instance<typeof ChecklistItem> { };
export interface IChecklistSelect extends Instance<typeof ChecklistSelect> { };
export interface IChecklistReportsDownload extends Instance<typeof ChecklistReportsDownload> { };
