import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
// import  from 'react-responsive-carousel/lib/styles/carousel.min.css';
import image1 from '../../assets/css/image1.svg'
import image2 from '../../assets/css/image2.svg'
import image3 from '../../assets/css/image3.svg'
import image4 from '../../assets/css/image4.svg'
import image5 from '../../assets/css/image5.svg'
import image6 from '../../assets/css/image6.svg'
import { classicNameResolver } from 'typescript';
import useStyles from './login.styles';


export default function Animations() {
    const classes = useStyles();

    return (
        <Carousel autoPlay={true}
            infiniteLoop={true}
            transitionTime={0}
            showStatus={false}
            showThumbs={false}
            showArrows={false}
            stopOnHover={false}
            className={classes.animationsdiv}
        >
            <div className={classes.rootDiv}>
                <div className={classes.imageDiv}><img src={image1} /></div>
                <h4 className={classes.legend}>Project Management</h4>
                <span className={classes.paragraph}>
                    Easy-to-use, mobile-based project management software that connects site and office for real-time visibility
                </span>
            </div>
            <div className={classes.rootDiv}>
                <div className={classes.imageDiv}><img src={image2} /></div>
                <h4 className={classes.legend}>Quality And Safety Monitoring</h4>
                <span className={classes.paragraph}>
                    Conduct auto-inspections, monitor and improve your construction quality performance
                </span>
            </div>
            <div className={classes.rootDiv}>
                <div className={classes.imageDiv}><img src={image3} /></div>
                <h4 className={classes.legend}>Progress Monitoring</h4>
                <span className={classes.paragraph}>
                    Stay on top of progress, through powerful real-time progress dashboards
                </span>
            </div>
            <div className={classes.rootDiv}>
                <div className={classes.imageDiv}><img src={image4} /></div>
                <h4 className={classes.legend}>Planning Coordination</h4>
                <span className={classes.paragraph}>
                    Bridge the gap between planning and execution, with full control on project delays
                </span>
            </div>
            <div className={classes.rootDiv}>
                <div className={classes.imageDiv}><img src={image5} /></div>
                <h4 className={classes.legend}>Single Platform</h4>
                <span className={classes.paragraph}>
                    All teams, site and Head office, coordinate on one system, from the first slab till project handover
                </span>
            </div>
            <div className={classes.rootDiv}>
                <div className={classes.imageDiv}><img src={image6} /></div>
                <h4 className={classes.legend}>Zero-Internet Mode</h4>
                <span className={classes.paragraph}>
                    Use the product seamlessly in remotely located sites, with near-zero internet connectivity
                </span>
            </div>
        </Carousel>
    );
}