import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: "#F7F7F7",
    // borderTop: "1px solid rgb(206,205,205)",
    fontSize: "12px !important",
    fontFamily: "Source Sans Pro, sans-serif !important",
    padding: "8px 16px !important",
    // opacity:0.8,
    flexShrink: 0,
    '@media print': {
      display: "none"
    },
  },
  avtarWidth: {
    width: "12px",
    height: "12px",
    alignSelf: "center",
    marginLeft: "18px",
    marginRight: "6px",
    // borderRadius: "1px"
  },
  endDelayAvtar: {
    height: "12px",
    width: "8px",
    backgroundColor: "#ff7777",
    border: "1px solid #ea3434",
    alignSelf: "center",
    borderRadius: "0px",
    marginLeft: "18px",
    marginRight: "6px"
  },
  flexBox: {
    display: "flex",
    alignSelf: "center",

  },
  legend: {
    alignSelf: "center",
    paddingRight: "16px",
    fontWeight: "bold",
    textTransform: "uppercase"
  },
  divider: {
    width: "2px",
    height: "16px",
    backgroundColor: "#edebeb",
    alignSelf: "center"
  },
  legendContainer: (props: { isLg: boolean }) => ({
    // width: "465px",
    display: "flex",
    overflow: props?.isLg ? "unset" : "scroll"
  }),
  gridContainer: {
    justifyContent: "space-between"
  }
}));

export default useStyles;
