import { makeStyles, Theme } from "@material-ui/core/styles";
import { ITab } from "./detailBasementBlock";

const getBackgroundColorBasedOnStatus = (status) => {
  switch (status) {
    case 'completed':
      return '#2fb597';
    case 'notStarted':
      return '#c3c3c3';
    case 'started':
      return '#ffc400';
    case 'startedwithdelay':
      return 'none'
    case 'completedwithdelay':
      return 'none'
    case 'default':
      return "#eaeaea";
  }
}

const getBorderColorBasedOnStatus = (status) => {
  switch (status) {
    case 'completed':
      return '#2fb597';
    case 'completedwithdelay':
      return '#2fb597';
    case 'notStarted':
      return '#c3c3c3';
    case 'started':
      return '#ffc400';
    case 'startedwithdelay':
      return "#585858";
    case 'default':
      return "#585858";
  }
}

const getHeightBasedonscreen = (blockType) => {
  switch (blockType) {
    case 'landing':
      return 84;
    case 'fullscreen':
      return 67;
  }
}
const useStyles = makeStyles((props: ITab) => ({
  FilterBase: (props: ITab) => {
    return {
      width: "100%",
      height: getHeightBasedonscreen(props.screen),
      margin: "8px 0px",
      padding: "4px 8px 6px 12px",
      borderRadius: "2px",
      border: props.selected ? "solid 1px #dddddd" : "none",
      backgroundColor: props.selected ? "#f4f4f4" : "#ffffff",
      // borderBottom: props.selected ? "2px solid darkblue" : "solid 1px #dddddd"
      backgroundImage: props.selected ? "linear-gradient(#2f3e6c, #2f3e6c)" : "",
      backgroundSize: "157px 2px, auto",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center bottom",
    }
  },
  BlockName: (props: ITab) => {
    return {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
      minHeight: "20px",
      paddingTop: "4px",
      // margin: props.blockType === "basement" ? "0 0px 3px 0" : "0 0px 8px 0",
      // fontFamily: "SourceSansPro",
      fontSize: "16px",
      fontWeight: props.selected ? "bold" : "normal",
      lineHeight: "1.25",
      letterSpacing: "normal",
      color: props.selected ? "var(--dark-grey-blue)" : "#005eff"
    }
  },
  Details: {

  },
  Rectangle: (props: ITab) => {
    return {
      display: "flex",
      /* flexDirection: (props.status === 'completedwithdelay') ? "row" : "column",
       * justifyContent: (props.status === 'completedwithdelay') ? "flex-end" : "center", */
      /* flexDirection: "column", */
      justifyContent: "flex-start",
      width: "10px",
      height: "10px",
      // margin: "3px 6px 5px 0",
      margin: "0px 6px 0px 0px",
      borderRadius: "1px",
      /* border: `solid 1px ${getBorderColorBasedOnStatus(props.status)}`,
       * backgroundColor: getBackgroundColorBasedOnStatus(props.status) */
      border: `solid 1px #585858`,
      backgroundColor: "#eaeaea"
    }
  },
  delayedRectangle: (props: ITab) => {
    return {
      /* width: (props.status === 'completedwithdelay') ? "4px" : "6px", */
      width: "6px",
      height: "6px",
      margin: "1px",
      /* borderRadius: (props.status === 'completedwithdelay') ? "1px" : "4px", */
      borderRadius: "4px",
      border: `solid 1px #ea3434`,
      backgroundColor: "#ff7777"
    }
  },
  tabDiv: {
    width: "100%"
  },
  TabName: {
    display: "flex",
    justifyContent: "space-between",
    textTransform: "capitalize"
  },
  graphTypeDetails: {
    fontSize: "9px",
    color: "#2f3e6c",
    marginTop: "4px"
  },
  Floors: {
    display: "flex",
    flexGrow: 1,
    flexShrink: 1,
    height: "15px",
    margin: "0px 8px 0px 6px",
    // fontFamily: "SourceSansPro",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.25,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#585858"
  },
  Ellipse: {
    width: "12px",
    height: "12px",
    backgroundColor: "#e6e6e6",
    borderRadius: "16px",
    // marginTop: "2px",
    "& .MuiCircularProgress-colorSecondary": {
      color: "#2fb597"
    },
    "& .MuiCircularProgress-root": {
      display: "revert"
    }
  },
  DashboardBase: {
    width: "1126px",
    height: "768px",
    borderRadius: "2px",
    backgroundColor: "#faf9f9"
  },
  Percentage: {
    width: "24px",
    height: "15px",
    margin: "0 0 0 6px",
    // fontFamily: "SourceSansPro",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.25,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#585858"
  },
  input: {
    "& .MuiInputLabel-animated": {
      marginLeft: "23px"
    },
    "& .MuiInput-formControl": {
      display: "flex",
      flexDirection: "row-reverse"
    },
    "& .MuiInput-underline": {
      backgroundImage: "none",
      "&:hover:not($disabled):before": {
        borderBottom: "none",
      },
      "&:hover:not($disabled):after": {
        borderBottom: "none",
      },
      "&:hover": {
        borderBottom: "none",
      },
      "&:after": {
        borderBottom: "none",
      },
      "&:before": {
        borderBottom: "none",
      }
    }
  }
}));
export default useStyles;
