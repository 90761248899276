import React, { useState, useCallback } from "react";
import { useObserver } from "mobx-react-lite";
import useStyles from "./OverviewReportsStyles";
// import Fade from 'react-reveal'
import { useHistory } from 'react-router';
import { Link, Typography } from "@material-ui/core";
import backIcon from '../../../../assets/images/menuNavigation/Back.svg';
import structuresIcon from '../../../../assets/images/menuNavigation/finishing-overview.svg';
import finishingIcon from '../../../../assets/images/menuNavigation/structures-overview.svg';
import commonStyles from '../ReportsStyles'
import { useStore } from "../../../../models/ProvideModel";

function OverviewReports({ name, spaceType, phase, title }: { name: string; spaceType: string; phase: string; title: string; }) {
  const classes = useStyles();
  const commonClasses = commonStyles();
  const { push } = useHistory()
  const [hover, sethover] = useState(false);
  const store = useStore();
  const Urlpath: any = store.params.toPath({ section: "dashboard", screen: "landing", spaceType, phase })
  const overviewHandler = useCallback(() => {
    document?.getElementById(phase)?.removeAttribute("href");
    push(Urlpath);
  }, [spaceType, phase, name]);

  return useObserver(() => (
    <>
      <Typography variant={"h5"} className={commonClasses.heading12px}>{name}</Typography>
      <Typography className={commonClasses.normalText12px}>
        Graph with the status & pour details of <b>all towers</b> in the
        property
      </Typography>
      <Link className={`${classes[store.responsiveUtils.getClassNameBasedOnViewport('overviewBox', true)]} ${classes.underlineAnim}`}
        style={{ backgroundColor: "" }}
        key={phase}
        id={phase} target="popup"
        href={`${window.location.href.split('/', 3).join('/')}${Urlpath}`}
        onMouseEnter={() => sethover(true)}
        onMouseLeave={() => sethover(false)}
        onClick={overviewHandler}
      >
        <div style={{ display: "flex" }} className={!store.responsiveUtils.currentViewport.isLg ? classes.overviewLabelSm : ''}>
          <img style={{ padding: "19px 0 16px 16px", alignSelf: "flex-start" }} src={((phase).toString().toLowerCase() === "finishing") ? finishingIcon : structuresIcon} alt="Overview" />
          <span className={classes.overviewName}>
            {/* {spaceType} {phase} {"Overview"} */}
            {title}
          </span>
        </div>
        <div className={classes.bottomDiv}><span className={classes.allBlocks}>All blocks</span>
          <img src={backIcon} alt={"Back"} />
        </div>
      </Link>
    </>
  ));
}
export default React.memo(OverviewReports);
