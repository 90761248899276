import React, { useState, useEffect, FunctionComponent, useMemo } from 'react';
import { chan, go } from 'csp-with-ts';
import SearchBar from './SearchBar';

//filter function. Replace with something more efficient if it ever becomes a bottleneck.
function filterString<T extends { [K: string]: string | number; }>(this: string, el: T): boolean {
  /* const elVal: string = Object.keys(el).reduce((acc: string, k: string) => acc + (typeof el[k] === 'string' ? el[k] : el[k]?.toString() || ""), "");
   * return elVal.toLowerCase().indexOf(this) !== -1; */
  return el.name?.toString().toLowerCase().indexOf(this) !== -1;
}
export interface IOpts {
  title: string
}

interface ISearchHookReturns<T extends { [K: string]: string | number; }> extends Array<T[] | FunctionComponent> {
  [0]: T[];
  [1]: FunctionComponent;
};
// currently hardcoded to check all key values concatted into a string. Change later.
export default function useSearch<T extends { [K: string]: any; }>(options: T[], opt?: IOpts): ISearchHookReturns<T> {
  const input = useMemo(() => chan<string>(), []);
  // const [search, setSearch] = useState<string>();
  // const debounced = useMemo(() => chan<string>(), []);
  const [newOptions, setOpts] = useState<T[]>(() => [...options]);
  useEffect(() => {
    if (options.length > 0 /* && newOptions.length === 0 */) {
      setOpts(() => options);
    }
  }, [options]);
  const Component = useMemo(() => () => <SearchBar ch={input} title={opt?.title} />, [input]);
  useEffect(() => { return () => { input.close(); }; }, []);
  useEffect(() => go<string>`<! ${input} ${function* () { while (true) { const val = yield; if (!val.length) { setOpts(opts => options.length ? options : opts); } else { setOpts(() => options.filter(filterString.bind(val.toLowerCase()))) } } }} `, [options]);
  return [newOptions, Component];
}
