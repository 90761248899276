import { makeStyles, Theme } from "@material-ui/core/styles";
import "typeface-source-sans-pro";
import arrowIconHover from '../../../assets/images/menuNavigation/Arrow-hover.svg';

// const dark_grey_blue = "#2f3e6c";
const default_grey = "#faf9f9";
const dark_blue = "#005eff";

const useStyles = makeStyles((them: Theme) => ({
  root: {
    color: "#585858",
    padding: "32px",
    fontSize: "16px",
    backgroundColor: "#f4f4f4",
    width: "82.5%",
    height: "100%",
    fontFamily: "Source Sans Pro, sans-serif",
    overflow: "auto",
    display: "flex",
    // justifyContent: "space-between",
  },
  types: {
    display: "flex",
    flexDirection: "row",
    fontFamily: "Source Sans Pro, sans-serif",
  },
  phase: {
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "18px",
    fontWeight: 900,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.28",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#585858",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  phaseSm: {
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "15px",
    fontWeight: 900,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.28",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#585858",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  backButton: {
    display: "flex",
    color: "#2f3e6c",
    flexDirection: "row-reverse",
    width: "64px",
    height: "20px",
    fontSize: "16px",
    fontWeight: "normal",
    justifyContent: "space-between"
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "184px 184px 184px 184px 184px",
    gridGap: "10px"
  },
  name: {
    paddingLeft: "7px",
    maxWidth: "280px",
    height: "48px",
    borderRadius: "2px",
    color: dark_blue,
    fontSize: "14px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: "Source Sans Pro, sans-serif",
    cursor: "pointer",
    textTransform: "capitalize",
    paddingRight: "7px",
    "&:hover": {
      backgroundColor: default_grey,
      fontWeight: "bold",
      "& #arrowIcon": {
        background: `url(${arrowIconHover})`
      }
    }
  },
  arrowIcon: {
    transform: "rotate(-90deg)",
  },
  modalContainer: {
    position: "absolute",
    bottom: "45px",
    right: "28px",
    zIndex: 11
  },
  modalContainerNoFilter: {
    position: "absolute",
    bottom: "45px",
    right: "31px",
    zIndex: 11
  },
  backBtn: {
    fontSize: 14,
    fontFamily: 'Source Sans Pro',
    fontWeight: "normal",
    alignSelf: "center",
    marginLeft: 0,
    top: -2
  },
  backDiv: {
    color: '#005eff',
    border: "none",
    textAlign: "center",
    marginRight: "8px",
    transform: "scaleX(-1)",
    padding: "0px",
    width: "80px",
    transition: "margin 0.5s",
    cursor: "pointer",
    "& span": {
      cursor: "pointer",
      display: "inline-block",
      position: "relative",
      transition: "0.5s",
      color: "#2f3e6c",
      "&:after": {
        position: "absolute",
        opacity: 0,
        top: 0,
        right: "-15px",
        transition: "0.5s",
      },
    },
    "&:hover": {
      color: "#2f3e6c",
      "& span": {
        paddingRight: "8px",
        "&:hover": {
          opacity: 1,
          right: 0,
          color: "#2f3e6c"
        }
      }
    }
  },
  changeImage: {
    position: "absolute",
    color: '#005eff',
    right: 0,
    transform: "scaleX(-1)",
    display: "flex",
    flexFlow: "row-reverse",
    width: "80px",
    zIndex:101,
    alignItems: 'center'
  },
}));

export default useStyles;
