import useStyles from "../../components/table/CommonTableStyles";
import React from 'react'
import { Typography, Popover, InputAdornment, TextField, Link } from "@material-ui/core";
import TableSortFilter from "./TableSortFilter";
import SearchFilter from "./SearchFilter";
import CheckboxComponent from "./CheckboxComponent";
import ApplyButton from "./ApplyButton";
import { useObserver } from "mobx-react-lite";
import downArrowIcon from '../../assets/images/Arrow.svg';
import { useStore } from '../../models/ProvideModel';

function SelectColumnFilter({
    column: { filterValue: fv, setFilter, preFilteredRows, id, toggleSortBy, isSorted, isSortedDesc, sortBy, render, canFilter, filter}
  }) {
    const filterValue: any[] = fv || [];
    const [searchText, setSearchText] = React.useState<String>("")
    const [value, setValue] = React.useState<String | undefined>(undefined);
    // const [disabled, setDisabled] = React.useState<Boolean>(true);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [checkedValues, setCheckedValues] = React.useState<String[]>([...filterValue])
    const store = useStore();
    const { isSm, isXs } = store.responsiveUtils.currentViewport;
    const classes = useStyles({isSm: isSm || isXs});
    const disabled = React.useMemo(() => { 
      if (filterValue.length === checkedValues.length) {
         return filterValue.length === 0 ? true : filterValue.every((el: string | number) => 
         (checkedValues as Array<typeof el>).includes(el));
        } else { 
          return false; 
        } 
      }, [filterValue, checkedValues]);
    // Calculate the options for filtering
    // using the preFilteredRows 
    const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach(row => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);
    
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      if(filterValue){
        setCheckedValues(filterValue)
      }
      setAnchorEl(event.currentTarget);
      setSearchText("");
    };
    
    //Sorting Handler function and states
    const handleChangeSort = (event: React.ChangeEvent<HTMLInputElement>) => {
      if ((event.target as HTMLInputElement).value === "dsc") {
        setValue((event.target as HTMLInputElement).value);
        toggleSortBy(true, true);
      }
      else if(isSortedDesc || value === undefined || (event.target as HTMLInputElement).value === "dsc") {
        setValue((event.target as HTMLInputElement).value);
        toggleSortBy(false, true);
      }
    };
  
    const open = Boolean(anchorEl);
    const popId = open ? 'simple-popover' : undefined;
    //Search handler function and state
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>)  => {
      setSearchText(e.target.value);
    }
  
    //Checkbox handler and states
    let valueArray: String[] = [];
    const handleChangeMultiple = (
      event: React.ChangeEvent<HTMLInputElement>
      ) => {
        if (!event.target.checked) {
          valueArray = [...checkedValues]
          valueArray.splice(valueArray.indexOf(event.target.value), 1);
          setCheckedValues(valueArray);
        } else {
          valueArray.push(...checkedValues, event.target.value)
          setCheckedValues(valueArray);
        }
    };
  
  // handle Apply button
  const handleApplyClick =(e) => {
    setFilter(checkedValues);
    setCheckedValues([]);
    setAnchorEl(null);
  }
  const resetAll = () => {
    setFilter();
    setCheckedValues([]);
    setSearchText("");
    // setAnchorEl(null);
  }
    // Render a multi-select box

    return useObserver(() => (
      <div>
        <Typography variant="button" display={"block"} gutterBottom onClick={handleClick} className={classes.typeButton}> 
        <TextField
        className={classes.selectInput}
              id="standard-adornment-columnFilter"
              label= {isSorted &&  isSorted ? isSortedDesc
                  ? `${render('Header')} ↓`
                  : `${render('Header')} ↑`
                : `${render('Header')}`}
              value={fv ? `${fv.length} selected` : ''}
              InputProps={{
              endAdornment: <InputAdornment position="end">
                <img className={classes.arrowImg} src={downArrowIcon} alt='arrow'></img></InputAdornment>
            }}
            disabled
            />
        </Typography>
        <Popover
          id={popId}
          open={open}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          PaperProps= {{ className: (classes.paperProps) }}
        >   
        <div className={classes.searchSort} style={{paddingBottom: (canFilter &&  options.length > 0 )  ? "0px" : "16px"}}>
        <div className={classes.resetFilter}><div className={classes.normText11px}>{render('Header')}</div> {(canFilter &&  options.length > 0 ) && <Link style={{color: (filterValue.length > 0 || checkedValues.length > 0) ? "#005eff" : "#585858",
        cursor: (filterValue.length > 0 || checkedValues.length > 0) ? "pointer" : "context-menu",  backgroundImage:"none"}} onClick={(filterValue.length > 0 || checkedValues.length > 0) ? resetAll : () => {}}>Reset</Link>} </div>
        <TableSortFilter handleChangeSort = {handleChangeSort} value={value} columnId={id}/>
        {(canFilter &&  options.length > 0 )  && <SearchFilter handleSearchChange ={handleSearchChange}/>}
        </div>
        {(canFilter &&  options.length > 0 )  && <CheckboxComponent options = {options} searchText= {searchText} handleChangeMultiple={handleChangeMultiple} filterValue={checkedValues} />}
        {(canFilter &&  options.length > 0 )  && <ApplyButton handleApplyClick= {handleApplyClick} disabled={disabled}/>}
        </Popover>
      </div>
    ));
  }

  export default React.memo(SelectColumnFilter);