import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import useSyles from "./ModalStyles";
import { ModalContextProvider, useModalContext } from "./context";
import { useModal, UseModalProps } from './use-modal';
import { useHistory, useLocation } from 'react-router';
import { useStore } from '../../models/ProvideModel';


export interface ModalProps extends UseModalProps {
    /**
     * Any jsx or html component that will be rendered inside modal.
     */
    children?: ReactNode
}


export const Modal = (props: ModalProps) => {
    const { children } = props;
    const modal = useModal(props);
    const classes = useSyles();

    if (!modal.isOpen) return null;

    return (
        <ModalContextProvider value={modal}>
            <div className={classes.modal}>
                {children}
            </div>
        </ModalContextProvider>
    )
}

export const ModalOverlay = (props) => {
    const classes = useSyles();
    const { children } = props;
    const store = useStore();
    const location = useLocation();
    const { search, pathname } = useMemo(() => location, [
        location.search,
        location.pathname,
    ]);
    const searchParam = useMemo(() => new URLSearchParams(search), [search]);
    const { isCentered, getOverlayProps } = useModalContext();
    const [popup, setPopup] = useState<boolean>(false);

    const overlay = getOverlayProps();

    const classesArray = [
        classes.modalOverlay
    ];

    if (isCentered) classesArray.push(classes.contentCenter);

    useEffect(() => {
        if (searchParam.has('popup_menu') === true) {
            setPopup(true)
        }
    })
    return (
        <div {...overlay} style={{ position: "absolute", alignItems: popup ? "inherit" : "center", padding: popup ? "20px 20px" : "0px" }} className={classesArray.join(" ")} >
            {children}
        </div>
    )
}

export const ModalContent = (props) => {
    const classes = useSyles();
    const { children } = props;
    const styleObj = {};
    const { position, getContentPosition } = useModalContext();

    return (
        <div
            className={classes.modalContent}
            {...props}
            onClick={(event) => { event.stopPropagation() }}
            style={{ ...getContentPosition(position), ...props.style, display: "flex" }}
        >
            {children}
        </div>
    )
}

export const ModalBody = (props) => {
    const { children } = props;
    const classes = useSyles();
    return (
        <div className={classes.modalBody} {...props} >
            {children}
        </div>
    )
}



