import { makeStyles, Theme } from "@material-ui/core/styles";
const useStyles = makeStyles((theme: Theme) => ({
  formDataDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    /* width: "41%", */
    padding: "10px 10px 0px 26px",
    alignItems: "center"
  },
  formDiv: {
    display: "flex",
    flexShrink: 1,
    alignItems: "center",
    height: "65px",
    /* width: "140px" */
  },
  formSpan: {
    fontSize: "24px",
    fontWeight: 700,
    color: "#2F3E6C"
  },
  arrowDiv: {
    height: "65px",
    display: "flex",
    alignItems: "center",
    padding: "1.5em"
  },
  initialMessageDiv: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignSelf: "center",
    color: "#B3B3B3",
    fontSize: 32,
    fontWeight: 800
  }
}));

export default useStyles;
