import React, { memo } from 'react';
import { useTable, Column, useGroupBy, useExpanded } from 'react-table';
import { TableRow, TableCell, TableBody, TableHead } from '@material-ui/core';
import MaUTable from '@material-ui/core/Table';
import useStyles from './ThinTableStyle';

export interface IThinTableProps<T extends object = {}> {
  columns: Array<Column<T>>;
  data: T[];
  print: string;
  blockCount?: number
};

const ThinTable = memo(({ columns, data, print }: IThinTableProps) => {
  const classes = useStyles();
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    rows,
    headers,
  } = useTable({ columns, data, },
    useGroupBy,
    useExpanded
  );
  /* {...header.getGroupByToggleProps()} */
  return (
    <div className={classes.table}>
      <MaUTable stickyHeader {...getTableProps()}>
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell {...header.getHeaderProps()} key={header.name} style={{ zIndex: 0 }}>
                <div className={[(print === "print") ? classes.printHeader :
                  typeof (header.Header) === "string" ? classes.nonDropdownText : "", classes.stickyHeader].join(" ")}>
                  {(typeof (header.Header) === "string" || (print === "print")) ? <span style={{ marginTop: "16px", }}>{header.render('Header')}</span> : header.render('Header')}
                </div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {(data.length !== 0) ? <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            return (
              <TableRow {...row.getRowProps()} key={row.id} style={{ height: row.cells[0].column.minHeight }}>
                {row.cells.map(cell => {
                  return (
                    <TableCell {...cell.getCellProps()} key={`${cell.column.id}_${cell.value}`}>
                      <div style={print === "print" ? { minWidth: "50px", pageBreakAfter: "always", pageBreakInside: "avoid" } : { minWidth: cell.column.minWidth, textTransform: "capitalize" }} className={(print === "print") ? classes.printViewCell : ""}>{cell.render('Cell')}</div>
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
          :
          <TableRow>
            <TableCell align="center" colSpan={headers.length} style={{ background: "#faf9f9" }}>
            </TableCell>
          </TableRow>
        }
      </MaUTable>
    </div>
  )
});

export default ThinTable;
