import React, { memo } from 'react'
import { CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';

// Inspired by the former Facebook spinners.
const useStyles = makeStyles((theme) => ({
    root: {
      position: 'relative',
    },
    bottom: {
      color: "#eeeeee",
    },
    top: {
      color: '#005eff',
      animationDuration: '550ms',
      position: 'absolute',
      left: 0,
    },
    circle: {
      strokeLinecap: 'round',
    },
  }));

  function CustomCircularProgress(props) {
    const classes = useStyles();
  
    return (
      <div className={classes.root}>
        <CircularProgress
          variant="determinate"
          className={classes.bottom}
          size={40}
          thickness={4}
          {...props}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          className={classes.top}
          classes={{
            circle: classes.circle,
          }}
          size={40}
          thickness={4}
          {...props}
        />
      </div>
    );
  }

function LoadingSpinner(props) {
    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: props?.width || "100%", height: "100%", margin: "auto", backgroundColor: props.bgColor || "#FAFAFA" }}>
            <CustomCircularProgress />
        </div>
    )
}

export default memo(LoadingSpinner)