import React, { useState } from 'react';
import { IDownloadPdf, MessageDataType } from '../../models/Download';
import { observer } from 'mobx-react-lite';
import { Button, Dialog, TextField, TextareaAutosize } from '@material-ui/core';
import shareIcon from '../../assets/images/share-48.svg'
import { useStyles } from './ShareStyles'
import config from '../../configs/clientConfig';
import CloseDefault from '../../assets/images/menuNavigation/Close Default.svg'
import CloseDisable from '../../assets/images/menuNavigation/Arrow-Circle-Disabled.svg'

// Later, refactor the two shares of email and whatsapp into two files with this
// file being the index.
export interface ISharePopup {
  report: IDownloadPdf | null;
  isOpen: boolean;
  onClose(): void;
};

type ShareParamsType = {
  email: string[],
  whatsapp: number,
  data: MessageDataType
}

function validateEmail(mail: string): Boolean {
  let isValid = false
  let emailRegEx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  if (emailRegEx.test(mail)) { isValid = true }
  return isValid
}
const inputTypeCheck = (e) => { e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10) }
const disableCheck = (input1: any, input2: any, loadingState: boolean) => (!input1 && (!input2 || input2.length < 10)) || loadingState;
const messageColorToggle = (error: any) => error ? 'rgb(255, 0, 0)' : 'rgb(0, 150, 0)'

const SharePopup = ({ report, isOpen, onClose }: ISharePopup) => {
  const classes = useStyles();
  const [email, setEmail] = useState<string>("");
  const [whatsapp, setWhatsapp] = useState<string>("")
  const [messageBody, setMessageBody] = useState<string>("")
  const [message, setMessage] = useState<string | undefined>("")
  const [loading, setLoading] = useState<boolean>(false)
  const [errorStatus, setErrorStatus] = useState<boolean>(false)
  /* const { isOpen: openState } = useModal({ isOpen, closeOnOverlayClick: true }); */
  const customMessageLength = 500

  const callShareAPI = async (action: string, message: string) => {
    let params: ShareParamsType = {
      email: [],
      whatsapp: 0,
      data: {
        subject: `PDF for Project ${report?.project}`,
        body: message == "" ? `Please find attached the report for Project ${report?.project}` : message
      }
    }

    switch (action) {
      case "BOTH": params = { ...params, email: [email], whatsapp: parseInt(whatsapp) }
        break;
      case "EMAIL": params = { ...params, email: [email] }
        break;
      case "WHATSAPP": params = { ...params, whatsapp: parseInt(whatsapp) }
        break;
      default: return params
    }

    // console.log('params',params);


    setLoading(true);
    if (config.isWhatsappEnabled) {
      report && await report.shareBoth(params.email, params.whatsapp, params.data).then(result => {
        setMessage(result?.message);
        setLoading(false);
      }).catch(error => {
        setErrorStatus(true);
        setMessage(error.message.response.body.errors[0].message);
        setLoading(false);
      });
    }
    else {
      report && await report.share(params.email, params.data).then(result => {
        setMessage(result?.message);
        setLoading(false);
      }).catch(error => {
        setErrorStatus(true);
        setMessage(error.message.response.body.errors[0].message);
        setLoading(false);
      });
    }
  }

  const clickHandler = async () => {
    if (report) {

      setErrorStatus(false); setMessage("");
      let action = (email && whatsapp) ? "BOTH" : email ? "EMAIL" : whatsapp ? "WHATSAPP" : ""

      switch (action) {
        case "BOTH": {
          if (!validateEmail(email)) { setErrorStatus(true); setMessage("Invalid email address"); return }
          callShareAPI(action, messageBody)
        }
          break;
        case "EMAIL": {
          if (!validateEmail(email)) { setErrorStatus(true); setMessage("Invalid email address"); return }
          callShareAPI(action, messageBody)
        }
          break;
        case "WHATSAPP": callShareAPI(action, messageBody)
          break;
        default: return
      }
    }
  }

  const closeHandler = () => {
    onClose(); setErrorStatus(false); setMessage(""); setEmail(""); setWhatsapp(""); setMessageBody("");
  }
  const [hover, setHover] = useState<boolean>(false);

  return (<Dialog
    open={isOpen}
    onClose={closeHandler}
    BackdropProps={{ classes: { root: classes.backDrop } }}
  >
    <div className={classes.dialogPaddings}>
      <div className={classes.dialogTitleFlex}>
        <div className={classes.title}>Share &nbsp; </div>
        <img src={shareIcon} height={17}></img>
        <div className={classes.closeDiv}>
          <div className={classes.close}
            onMouseEnter={() => { setHover(true); }}
            onMouseLeave={() => { setHover(false); }}
            onClick={() => onClose()}
          >
            <img alt="close"
              src={hover ? CloseDisable : CloseDefault} />
          </div>
        </div>
      </div>
      <div className={classes.titleDescription}>{config.isWhatsappEnabled ? "Please enter atleast one input" : "Please enter email address to send the report to"}</div>
      <TextField
        autoFocus
        margin="dense"
        id="name"
        label="Email Address"
        type="email"
        fullWidth
        variant="standard"
        onChange={(e) => { setEmail(e.target.value); }}
      />
      {config.isWhatsappEnabled &&
        <TextField
          className={classes.numberInput}
          margin="dense"
          id="number"
          label="WhatsApp Number"
          type="number"
          fullWidth
          variant="standard"
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', onInput: (e) => inputTypeCheck(e) }}
          onChange={(e) => { setWhatsapp(e.target.value); }}
        />
      }
      <TextareaAutosize
        aria-label="empty textarea"
        placeholder={`Please find attached the report for Project ${report?.project}`}
        rows={3}
        className={classes.textArea}
        maxLength={customMessageLength}
        onChange={(e) => { setMessageBody(e.target.value); }}
      />
      <span className={classes.charLeft}>{customMessageLength - messageBody.length} characters left</span>
      <div className={classes.actionStyles}>
        <div className={classes.messageStyle} style={{ color: messageColorToggle(errorStatus) || '#000' }}>{message}</div>
        <div>
          <Button
            className={classes.buttonStyles}
            variant="contained"
            color="primary"
            size="small"
            disabled={disableCheck(email, whatsapp, loading)}
            onClick={(message && !errorStatus) ? closeHandler : clickHandler}
          >
            {loading ? 'Sharing..' : (message && !errorStatus) ? 'Done' : 'Share'}
          </Button>
        </div>
      </div>
    </div>
  </Dialog>);
};

export default observer(SharePopup);
