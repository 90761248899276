import React, { memo, useState } from 'react';
import FsLightbox from "fslightbox-react";
import { useObserver } from 'mobx-react-lite';
import useStyles from "./URlcomponentStyles";
import moment from 'moment';
import curroptImgPlaceholder from "../../assets/images/table/Image Corrupted.svg";
import config from "../../configs/clientConfig";

export interface Irow {
  activity_id: string;
  created_at: string;
  created_by: string;
  data: any;
  sum?:number
};

const UrlComponent = (props: any) => {
  const baseURL = config.baseURL;
  const classes = useStyles();
  const [toggle, setToggle] = useState<boolean>(false);
  const [image, setImage] = useState<any>("");
  const [event, setEvent] = useState<any>("");

  const loadCorreptImages = (event) => {
    event.currentTarget.onError = null;
    event.currentTarget.src = curroptImgPlaceholder;
  };
  const handleonClick = (event, image, key) => {
    if (event.currentTarget.src === image) {
      setToggle(!toggle)
      props.openFlexbox(key)
    }
  }

  const OnMouseopenFlexbox = (event, image, key) => {
    setImage(key)
    setEvent(event.currentTarget.src)
    if (event.currentTarget.src === image) {
      props.OnMouseopenFlexbox(key)
    }
  }

  const OnMouseleaveFlexbox = (event, image, key) => {
    if (event.currentTarget.src === image) {
      props.OnMouseleaveFlexbox(key)
    }
  }
  return useObserver(() => (
    <div style={{ marginBottom: "20px" }}>
      <div className={classes.activityProgressRectangle}>
        <span className={classes.activityProgressname}>ACTIVITY PROGRESS</span>
        {props.data.input_value !== undefined && <div className={classes.percentageRectangle}>
          <div className={classes.inputValue}>
            <span style={{marginRight: "2px"}}>{props.sum}% </span>
            <span style={{fontWeight: "normal"}}>({props.data.input_value > 0 ? '+' : null}{props.data.input_value}%)</span>
            </div>
        </div>}
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {props.data?.photo_url?.map((key) => {
          // Remove this below line and modify accordingly 
          // when backend Image paths will be modefied
          const imgKey = key.substr(key.lastIndexOf('/') + 1).trim();
          return (
            <div key={imgKey}>
              <img
                src={`${baseURL}small/${imgKey}`}
                alt={key}
                onError={loadCorreptImages}
                onMouseEnter={(event) => imgKey ? OnMouseopenFlexbox(event, `${baseURL}small/${imgKey}`, key) : null}
                onMouseLeave={(event) => imgKey ? OnMouseleaveFlexbox(event, `${baseURL}small/${imgKey}`, key) : null}
                onClick={(event) => imgKey ? handleonClick(event, `${baseURL}small/${imgKey}`, key) : null}
                className={event === `${baseURL}small/${imgKey}` ? classes.image : classes.brokenImage}
              />
            </div>
          )
        })}
      </div>
      <div className={classes.remarks}>{props.data.comment}</div>
      <div className={classes.dateAndtime}>
        <div>{props.data.user_name}</div>
        <div className={classes.miniDivider}></div>
        <div>{moment(props.created).format("DD MMM YYYY")}</div>
        <div className={classes.miniDivider}></div>
        <div>{moment(props.created).format("HH:mm")}</div>
      </div>
      <div className={classes.divider}></div>
    </div>
  ));
};

export default memo(UrlComponent);

