import React, { useCallback, useState } from 'react'
import useStyles from './PropertySelStyles'
import '../../flyMenu/FlyMenu.scss';
import arrowIconHover from '../../../../assets/images/menuNavigation/Arrow-hover.svg';
import arrowIcon from '../../../../assets/images/menuNavigation/Arrow-default.svg';
import { useStore } from '../../../../models/ProvideModel';
import { useHistory } from "react-router";

// import Fade from 'react-reveal/Fade'
function ProjectProperty({ children, name }) {
    const [hover, sethover] = useState(false)
  const classes = useStyles({ hover });
    const store = useStore();
    const { push } = useHistory();

    const handleProject = useCallback(
        () => {
            store.projectInfo.unsetProject();
            push('/')
        }, [])
    return (
        <div className={classes.root}
            onClick={handleProject}>
            <div className={classes.name}>
                {name}
            </div>
            <div className={classes.project}><span className={classes.ellipsis}>{children}</span>
                {name === "Project Name" && <img src={hover ? arrowIconHover : arrowIcon} alt="Arrow-right" style={{ transform: "rotate(270deg)" }} />}
            </div>
        </div>
    )
}
export default ProjectProperty;