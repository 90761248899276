import React from "react";
import InfoPanelWidgets from "../InfoPanelWidgets/InfoPanelWidgets";

function InfoPanel() {
  return (
    <div style={{ height: "100%" }}>
      <InfoPanelWidgets />
    </div>
  );
}

export default InfoPanel;
