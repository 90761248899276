import { flow, getRoot, Instance, types } from 'mobx-state-tree';
import { LateStoreModel } from './DataStore';
import { IColumnOptions } from '../pages/exportSnagsPage/ExportSnagsPage'
import { downloadExcel } from '../api/Auth';
import { processExcelData } from '../utils/utils';

const Excel = types.model({
    loading: false,
    headerDataLoading: false,
    columns: types.array(types.frozen<IColumnOptions>()),
    zeroRows: types.optional(types.boolean, false),
    columnConfigloading: types.optional(types.boolean, false),
    params: types.optional(types.string, ''),
    pathParams: types.optional(types.string, ''),
    columnConfig: types.maybeNull(types.array(types.frozen<IColumnOptions>())),
    headersData: types.optional(types.string, ''),
    headersDataCount: types.optional(types.number, 0),
    thinData: types.array(types.frozen<{ [K: string]: any; }>()),
    pdfPopupOpen: types.optional(types.boolean, false)
})
    .actions(self => ({
        setColumns(val: any) {
            self.columns = val
        },
        setZeroRows(val: any) {
            self.zeroRows = val
        },
        setParams(val: any) {
            self.params = val
        },
        setPathParams(val: any) {
            self.pathParams = val
        },
        setHeadersDataCount(val: any) {
            self.headersDataCount = val
        },
        setPdfPopupOpen(val: any) {
            self.pdfPopupOpen = val
        },
        setColumnConfig(val: any) {
            self.columnConfigloading = true
            self.columnConfig = val
            self.columnConfigloading = false
        }
    }))
    .actions(self => ({
        clearTableFiltersData() { self.setHeadersDataCount(0); self.setPathParams("{}"); self.setParams(""); self.setColumns([]); self.setColumnConfig([]); self.thinData.clear(); },
        setThinData(data: any[]) { self.thinData.clear(); self.thinData.push(...data); },
        thinExcelExport: flow(function* thinExport() {
            self.loading = true;
            try {
                const res = yield downloadExcel(processExcelData(self.columns, self.thinData), (getRoot(self) as Instance<typeof LateStoreModel>).auth.customerId);
                window.location = res.data.reXportPath;
            } catch (error) {
                self.loading = false;
                console.error(`Error response: ${error}`);
                throw new Error(error);
            }
            self.loading = false
        }),
        getexceldataPage: flow(function* populate(header) {
            self.loading = true;
            try {
                const property_id = (getRoot(self) as Instance<typeof LateStoreModel>).projectInfo.currentProject.properties.map(({ id }) => id);
                const params = JSON.parse(self.params || '{}')
                const pathparams = JSON.parse(self.pathParams || '{}')
                const data: any = yield (getRoot(self) as Instance<typeof LateStoreModel>).fetch(header, {
                    variables: { ...params, where: { ...params.where, ...pathparams } },
                    errorPolicy: "all"
                });
                const title: [] = data.snags ? data.snags : data.unitInfo ? data.unitInfo : data.units
                try {
                    downloadExcel(processExcelData(self.columns, title), (getRoot(self) as Instance<typeof LateStoreModel>).auth.customerId).then((res) => {
                        window.location = res.data.reXportPath;
                    })
                    self.loading = false;
                } catch (error) {
                    self.loading = false;
                    console.error(`Error response: ${error}`);
                    throw new Error(error);
                }
                self.loading = false
            } catch (err) {
                console.error(err.message);
            }
        }),
        getunitexceldataPage: flow(function* populate(header) {
            self.loading = true;
            try {
                const property_id = (getRoot(self) as Instance<typeof LateStoreModel>).projectInfo.currentProject.properties.map(({ id }) => id);
                const params = JSON.parse(self.params || '{}')
                const pathparams = JSON.parse(self.pathParams || '{}')
                const data: any = yield (getRoot(self) as Instance<typeof LateStoreModel>).fetch(header, {
                    variables: { ...params, where: { ...params.where, ...pathparams } },
                    errorPolicy: "all"
                });
                try {
                    downloadExcel(processExcelData(self.columns, data.units), (getRoot(self) as Instance<typeof LateStoreModel>).auth.customerId).then((res) => {
                        window.location = res.data.reXportPath;
                    })
                } catch (error) {
                    self.loading = false;
                    console.error(`Error response: ${error}`);
                    throw new Error(error);
                }
                self.loading = false
            } catch (err) {
                console.error(err.message);
            }
        })
    }))
    .actions(self => ({
        getHeadersData: flow(function* populate(header) {
            self.headerDataLoading = true;
            try {
                const params = JSON.parse(self.params || '{}')
                const data: any = yield (getRoot(self) as Instance<typeof LateStoreModel>).fetch(header, {
                    variables: { ...params },
                    errorPolicy: "all"
                });
                const title: [] = data.snags ? data.snags : data.unitInfo ? data.unitInfo : data.units
            } catch (err) {
                console.error(err.message);
            }
            finally {
                self.headerDataLoading = false;
            }
        })
    })).actions(self => ({
        changeHeaderDataLoading(arg) {
            self.headerDataLoading = !!arg || false;
        }
    }))
    .views(self => (
        {
            get title(): string {
                return (getRoot(self) as Instance<typeof LateStoreModel>).responsiveUtils.currentViewport.isLg ? "EXPORT [EXCEL]" : "[EXCEL]"
            },
            get ZeroRows(): boolean {
                return self.zeroRows ? self.zeroRows : false
            },
            get Columns(): IColumnOptions[] {
                return self.columns ? self.columns : []
            },
            get PageParams(): any | string {
                return self.params ? self.params : ""
            },
            get ColumnsConfigData(): any {
                return self.columnConfig ? self.columnConfig : []
            },
            get HeadersDataCount(): any {
                return self.headersDataCount ? self.headersDataCount : 0
            }
        }
    ))

export default Excel;
