import React, { useEffect, useState } from "react";
import useStyles from "./summaryStyles";
import { Grid } from "@material-ui/core";
import { useObserver } from "mobx-react-lite";
import { ITabsList } from '../tabTiles/tabTiles'

export interface IUnitSummary {
  data: ITabsList[];
  selected: string;
  floors: number;
  screenProps?: any
};
const UnitTypeSummary = ({ data, selected, floors, screenProps }: IUnitSummary) => {
  const classes = useStyles({ isLg: screenProps.isLg });
  const [open, setOpen] = useState(false)
  const handleOnClick = () => {
    setOpen(!open)
  }

  return useObserver(() => (
    <Grid style={{ paddingLeft: "60px", width: "94%" }}>
      {data?.length > 0 && <> <Grid className={classes.unitTypeDiv}>
        <Grid className={classes.TypeName}>{selected}</Grid>
        <Grid className={classes.lessDetails} onClick={handleOnClick}>{open ? "More Details" : "Less Details"}</Grid>
      </Grid>
        {!open && data.map(({ id, name, stats }: { id: string; name: string; stats: any }) => (
          name?.toLowerCase() === selected?.toLowerCase() && <React.Fragment key={id}><Grid className={classes.activities} style={{ width: screenProps?.isLg ? "19%" : screenProps?.isSm ? "35%" : "42%" }}>
            <Grid className={classes.Noofactivities}>{stats.length} ACTIVITIES</Grid>
            <Grid className={classes.verticalDivider}></Grid>
            <Grid className={classes.Noofactivities}>{floors} FLOORS</Grid>
          </Grid>
          </React.Fragment>
        ))}
        <Grid className={classes.horizantalDivider}></Grid></>}
    </Grid>
  ));
}
export default React.memo(UnitTypeSummary);
