import { Instance, types, SnapshotOrInstance, getEnv, getRoot } from 'mobx-state-tree';
import moment, { Moment, MomentBuiltinFormat } from 'moment';
import { CellAddress } from './CellAddress';
import { DateDiff, eStatus, eProgressTypes, eScreen, IdDateDiff, DateType } from '../Common';
import { LateStoreModel } from '../DataStore';
import { BrickTypes, CellAddressType } from '../enums';
import { opacities } from '../../utils/constants';

// add support for same models being used for different phases.
export const Finishing = types.model({
    id: types.identifier,
    name: types.maybeNull(types.string),
    type: types.frozen<BrickTypes.F>(),
    tower: types.string,
    phase: types.optional(types.string, "finishing"),
    spaceType: types.optional(types.string, "tower"),
    yVal: CellAddress,
    xVal: CellAddress,
    plan: types.maybeNull(DateDiff),
    actual: types.maybeNull(DateDiff),
    lastUpdated: types.maybeNull(DateType),
    completed: types.number,
    total: types.number,
    inProgress: types.number
})
    .views(self => ({
        get status(): SnapshotOrInstance<typeof eStatus> {
            if (self.total < self.completed + self.inProgress) { return "plainWrong"; }
            else if (self.total === self.completed) { return "completed"; }
            else if (self.inProgress || self.completed) { return "started"; }
            else if (self.inProgress === 0 && self.completed === 0) { return "notStarted"; }
            return "plainWrong"
        }
        // get progress(): number { return self.completed / self.total; }
    }))
    .views(self => ({
        get digits() {
            // return noOfDigits(self.total);
            return 2;
        },
        get progressDisplay() {
            // const digits = Math.min(noOfDigits(self.total), 2);
            const digits = 2;
            return `${self.completed.toLocaleString('en', { minimumIntegerDigits: digits }).replace(',', '')} / ${self.total.toLocaleString('en', { minimumIntegerDigits: digits }).replace(',', '')}`;
        }
    }))
    .views(self => {
        return {
            get width(): number { return getEnv(self).brick['finishing'][(getRoot(self) as Instance<typeof LateStoreModel>).params.screen].width; },
            get height(): number { return getEnv(self).brick['finishing'][(getRoot(self) as Instance<typeof LateStoreModel>).params.screen].height; },
            get padding(): { [K in 'x' | 'y']: number; } { return getEnv(self).scale['finishing'][(getRoot(self) as Instance<typeof LateStoreModel>).params.screen]; }
        };
    }).views(self => ({
        get detail(): SnapshotOrInstance<typeof eProgressTypes> { return (getRoot(self) as Instance<typeof LateStoreModel>).filters.progressType; },
        get screen(): SnapshotOrInstance<typeof eScreen> { return (getRoot(self) as Instance<typeof LateStoreModel>).params.screen; },
        // get phase() { return (getRoot(self) as Instance<typeof LateStoreModel>).params.phase; },
    }))
    .views(self => ({
        get color(): string {
            return getEnv(self).brickPalette['finishing'][self.screen][self.status];
        },
        get startDelay(): number | false {
            if (self.actual && self.actual.from)
                return !!self.plan && !!self.plan.from && !!self.actual && !!self.actual.from && (self.actual.from.diff(self.plan.from, 'days') > 0) ? self.actual.from.diff(self.plan.from, 'days') : false;
            else
                return !!self.plan && !!self.plan.from && self.plan.diffFrom! > 0 ? self.plan.diffFrom! : false;
        },
        get endDelay(): number | false {
            if (self.actual && self.actual.to)
                return !!self.plan && !!self.plan.to && !!self.actual && !!self.actual.to && self.actual.to.diff(self.plan.to, 'days')! > 0 ? self.actual.to.diff(self.plan.to, 'days')! : false;
            else
                return !!self.plan && !!self.plan.to && self.plan.diffTo! > 0 ? self.plan.diffTo! : false;
        },
        get delayed(): boolean {
            switch (self.status) {
                case 'started': return !!self.plan && !!self.plan.to && self.plan!.diffTo! > 0;
                case 'notStarted': return !!self.plan && !!self.plan.from && self.plan.diffFrom! > 0;
                case 'completed': return !!self.plan && !!self.plan.to && !!self.actual && !!self.actual.to && self.actual.to.diff(self.plan.to, 'days') > 0;
                default: return false;
            }
        }
    }))
    .views(self => (
        {
            get delta(): Instance<typeof IdDateDiff> {
                return (getRoot(self) as Instance<typeof LateStoreModel>).filters.delta;
            },
            get statusFiltered(): boolean {
                return !(getRoot(self) as Instance<typeof LateStoreModel>).filters.activeFilters.includes('status')
                    ? true
                    : (getRoot(self) as Instance<typeof LateStoreModel>).filters.hasStatus(self.status);
            },
            get delayedFiltered(): boolean {
                return !(getRoot(self) as Instance<typeof LateStoreModel>).filters.delayed ? true : self.delayed;
            }
        }
    ))
    .views(self => ({
        get filtered(): boolean {
            if ((getRoot(self) as Instance<typeof LateStoreModel>).filters.activeFilters.length === 0) { return true; }
            const filter: boolean = self.statusFiltered && self.delayedFiltered;
            if (!filter) { return false; }
            const delta = self.delta.delta;
            if (filter && !delta) { return true; }
            if (!(delta && delta.from && delta.to && delta.diff)) { return false; }
            const [greater, lesser] = delta.diff > 0 ? [delta.to, delta.from] : [delta.from, delta.to];
            return !!self.lastUpdated && moment(self.lastUpdated!).isBetween(moment(lesser), moment(greater));
        }
    }))
    .views(self => ({
        get activityLastUpdated(): boolean {
            const yesterday = moment().subtract(1, 'day');
            return !!self.lastUpdated && (self.lastUpdated as Moment).isSame(yesterday, 'day');
        }
    }))
    .views(self => ({
        get progressDetails() {
            return null;
        },
        get progressView(): string | null {
            switch (self.status) {
                case 'started': return self['progressDisplay'] + ' U';
                case 'notStarted': return self.plan && self.plan.from ?
                    self.plan.from.format(`[W${Math.ceil(moment(self.plan.from).date() / 7)}-]MMM[-]YYYY`)
                    : null;
                case 'completed': return self.actual && self.actual.to ? self.actual.to.format(`${'DD[ ]MMM[ ]'} ${"'"}YY`) : '—';
                default: return "tsk tsk";
            }
        },
        get strength(): number {
            if (self.status === 'started') { return opacities[Math.ceil(self.completed * 100 / self.total)]; }
            return 1;
        },
        get progressMultiplier(): number { return 1.0; }
    }));

export interface IFinishing extends Instance<typeof Finishing> { };

export function snToFinishingFSBrick(sn: { [K: string]: any; }) {
    return {
        ...sn,
        id: sn.id.toString(),
        type: BrickTypes.F,
        xVal: { type: CellAddressType.ACT_STRING, id: sn.xVal, name: sn.activity || 'boo', precedence: sn.precedence, stage: sn.stage || 'ee' },
        yVal: { type: CellAddressType.FLOOR_NUM, id: sn.yVal, name: sn.floor || (sn.yVal as number).toLocaleString('en', { minimumIntegerDigits: 2 }) },
        lastUpdated: sn.lastUpdated ? sn.lastUpdated as string & MomentBuiltinFormat : null,
        plan: !(!sn.planEnd && !sn.planStart) ? { from: sn.planStart ? sn.planStart as string & MomentBuiltinFormat : null, to: sn.planEnd ? sn.planEnd as string & MomentBuiltinFormat : null } : null,
        actual: !(!sn.actualEnd && !sn.actualStart) ? { from: sn.actualStart ? sn.actualStart as string & MomentBuiltinFormat : null, to: sn.actualEnd ? sn.actualEnd as string & MomentBuiltinFormat : null } : null
    }
}

export function snToFinishingLPBrick(sn: { [K: string]: any; }) {
    return {
        ...sn,
        type: BrickTypes.F,
        id: `${sn.yVal}_${sn.xVal}`,
        xVal: { type: CellAddressType.ACT_STRING, id: sn.xVal, name: sn.activity || 'boo', precedence: sn.precedence, stage: sn.stage || "ee" },
        yVal: { type: CellAddressType.TOWER_STRING, id: sn.yVal, name: sn.block?.name || '???' },
        lastUpdated: sn.lastUpdated ? sn.lastUpdated as string & MomentBuiltinFormat : null,
        plan: !(!sn.planEnd && !sn.planStart) ? { from: sn.planStart ? sn.planStart as string & MomentBuiltinFormat : null, to: sn.planEnd ? sn.planEnd as string & MomentBuiltinFormat : null } : null,
        actual: !(!sn.actualEnd && !sn.actualStart) ? { from: sn.actualStart ? sn.actualStart as string & MomentBuiltinFormat : null, to: sn.actualEnd ? sn.actualEnd as string & MomentBuiltinFormat : null } : null
    }
}

export function snToFinishingTcaLPBrick(sn: { [K: string]: any; }) {
    return {
        ...sn,
        type: BrickTypes.F,
        id: `${sn.yVal}_${sn.xVal}_${sn.tower}_${sn.phase}_${sn.spaceType}`,
        xVal: { type: CellAddressType.UNIT_TYPE_STRING, id: sn.xVal, name: sn.unit_type || 'boo' },
        yVal: { type: CellAddressType.FLOOR_NUM, id: sn.yVal, name: sn.floor || (sn.yVal as number).toLocaleString('en', { minimumIntegerDigits: 2 }) },
        lastUpdated: sn.lastUpdated ? sn.lastUpdated as string & MomentBuiltinFormat : null,
        plan: !(!sn.planEnd && !sn.planStart) ? { from: sn.planStart ? sn.planStart as string & MomentBuiltinFormat : null, to: sn.planEnd ? sn.planEnd as string & MomentBuiltinFormat : null } : null,
        actual: !(!sn.actualEnd && !sn.actualStart) ? { from: sn.actualStart ? sn.actualStart as string & MomentBuiltinFormat : null, to: sn.actualEnd ? sn.actualEnd as string & MomentBuiltinFormat : null } : null
    }
}

export function snToFinishingTcaLPBaratheonBrick(sn: { [K: string]: any; }) {
    return {
        ...sn,
        type: BrickTypes.F,
        id: `${sn.yVal}_${sn.xVal}_${sn.tower}_${sn.phase}_${sn.spaceType}`,
        xVal: { type: CellAddressType.NULL, id: 0, name: "" },
        yVal: { type: CellAddressType.UNIT_TYPE_STRING, id: sn.xVal, name: sn.unit_type || 'boo' },
        lastUpdated: sn.lastUpdated ? sn.lastUpdated as string & MomentBuiltinFormat : null,
        plan: !(!sn.planEnd && !sn.planStart) ? { from: sn.planStart ? sn.planStart as string & MomentBuiltinFormat : null, to: sn.planEnd ? sn.planEnd as string & MomentBuiltinFormat : null } : null,
        actual: !(!sn.actualEnd && !sn.actualStart) ? { from: sn.actualStart ? sn.actualStart as string & MomentBuiltinFormat : null, to: sn.actualEnd ? sn.actualEnd as string & MomentBuiltinFormat : null } : null
    };
}

// TODO: Handle Baratheon
export function snToFinishingTcaFSBrick(sn: { [K: string]: any; }) {
    return {
        ...sn,
        type: BrickTypes.F,
        id: `${sn.yVal}_${sn.xVal}_${sn.tower}_${sn.group}_${sn.phase}_${sn.spaceType}`,
        xVal: { type: CellAddressType.ACT_STRING, id: sn.xVal, name: sn.activity || 'boo', precedence: sn.precedence || 0, stage: sn.stage || 'ee' },
        yVal: { type: CellAddressType.FLOOR_NUM, id: sn.yVal, name: sn.floor || (sn.yVal as number).toLocaleString('en', { minimumIntegerDigits: 2 }) },
        lastUpdated: sn.lastUpdated ? sn.lastUpdated as string & MomentBuiltinFormat : null,
        plan: !(!sn.planEnd && !sn.planStart) ? { from: sn.planStart ? sn.planStart as string & MomentBuiltinFormat : null, to: sn.planEnd ? sn.planEnd as string & MomentBuiltinFormat : null } : null,
        actual: !(!sn.actualEnd && !sn.actualStart) ? { from: sn.actualStart ? sn.actualStart as string & MomentBuiltinFormat : null, to: sn.actualEnd ? sn.actualEnd as string & MomentBuiltinFormat : null } : null
    }
}

export function snToFinishingTcaFSBaratheonBrick(sn: { [K: string]: any; }) {
    return {
        ...sn,
        type: BrickTypes.F,
        id: `${sn.yVal}_${sn.xVal}_${sn.tower}_${sn.group}_${sn.phase}_${sn.spaceType}`,
        xVal: { type: CellAddressType.ACT_STRING, id: sn.xVal, name: sn.activity || 'boo', precedence: sn.precedence || 0, stage: sn.stage || 'ee' },
        yVal: { type: CellAddressType.NULL, id: 0, name: "" },
        lastUpdated: sn.lastUpdated ? sn.lastUpdated as string & MomentBuiltinFormat : null,
        plan: !(!sn.planEnd && !sn.planStart) ? { from: sn.planStart ? sn.planStart as string & MomentBuiltinFormat : null, to: sn.planEnd ? sn.planEnd as string & MomentBuiltinFormat : null } : null,
        actual: !(!sn.actualEnd && !sn.actualStart) ? { from: sn.actualStart ? sn.actualStart as string & MomentBuiltinFormat : null, to: sn.actualEnd ? sn.actualEnd as string & MomentBuiltinFormat : null } : null
    }
}
