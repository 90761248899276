import React, { memo, ReactElement, PropsWithChildren, Children } from "react";
// import { getProps } from "../../schema/Schema";
import FlyMenu from "../flyMenu/FlyMenu";
import Reports from "../reports/Reports";
import OverviewReports from "../reports/overviewReports/OverviewReports";

const PhaseType = ({
  name,
  actualName,
  phase,
  openHandler,
  row,
  col,
  selected,
  overview,
  children,
  showDashboards
}: PropsWithChildren<{
  name: any;
  actualName: string;
  phase: any;
  selected: boolean;
  openHandler: (arg1: ReactElement | null, arg2?: number) => void;
  row: number;
  col: number;
  overview: string | null;
  children: any
  showDashboards: boolean
}>) => {
  return (
    <FlyMenu
      name={actualName}
      openHandler={openHandler}
      row={row}
      selected={selected}
      col={col}
      phase={phase}
      spaceType={name}
      columns={(overview ? 1 : 0) + Children.toArray(children).length}
    >
      <Reports>
        {(overview && showDashboards) && (
          <OverviewReports
            name={"Overview Report"}
            spaceType={name}
            phase={phase}
            title={overview}
          />
        )}
        {Children.map(children, (child) => {
          return (child?.props?.children.length > 0 ?
            child : null
          )
        })}
      </Reports>
    </FlyMenu>
  );
};
export default memo(PhaseType);
