import React, {
    memo,
    useCallback,
    useMemo,
    useEffect
} from "react";
import { useHistory } from "react-router";
import { putAsync } from "csp-with-ts";
import useAccordion from "./UseAccordionHook";
import { SelectedState } from "./SelectedState";
import { DefaultList } from "./DefaultAccordianRender";
import SelectedItem from "./SelectedItem";
import { useStore } from "../../models/ProvideModel";
import { getSnapshot } from "mobx-state-tree";

const byParam: string = "Common Area Group";
export interface IByPhase {
    data: { id: string; name: string }[];
    tab: string;
}

const ViewUnits = ({ units, sel, current, desktop }) =>
    <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}><span style={{ fontSize: desktop ? "26px" : "19px" }}>{sel ?
        units?.find(({ id }) => id === sel)?.name :
        units?.find(({ name }) => name === current)?.name}</span> </div>


function ByUnitType({ data, tab }: IByPhase) {
    const { push } = useHistory();
    const store = useStore();

    const initialState = useCallback(() => store.params.tab ? [store.params.tab] : [], [store.params.tab]);
    const [state, ByUnitTypeFilter, relay] = useAccordion<{ id: string; name: string; }, 'id'>(DefaultList, { multiple: false, apply: false, accordian: false, reset: false }, initialState);
    useEffect(() => { putAsync(relay, { type: 'replace', payload: store.params.tab ? [store.params.tab] : [] }); }, [store.params.tab]);

    const disabled = useMemo(() => {
        if (state[0] && state[0] === store.params.tab) { return true; }
        return false;
    }, [state, tab]);

    useEffect(() => {
        putAsync(relay, { type: 'toggle', payload: false });
    }, [store.params.tab])

    const applyHandler = useCallback(() => {
        if (state[0]) {
            push(store.params.toPath({ ...getSnapshot(store.params), tab: state[0], unitType: null }))
        }
    }, [state]);

    useEffect(() => {
        if (state[0] !== store.params.tab && store.params.tab !== "none" && typeof state[0] === 'string' && state[0] !== "none") {
            push(store.params.toPath({ ...getSnapshot(store.params), tab: state[0], unitType: null }))
        }
    }, [state])

    return (
        <ByUnitTypeFilter
            options={data}
            Render={SelectedItem}
            disabled={disabled}
            callback={applyHandler}
            // title={byParam}
            title={<SelectedState title={byParam} state={state[0]} filterType={"phaseType"} />}
            externalDisplay={<ViewUnits units={data} sel={state[0]} current={tab} desktop={store.responsiveUtils.currentViewport.isLg} />}
        />
    );
}
export default memo(ByUnitType);