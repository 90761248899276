import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((them: Theme) => ({
  root: ({ hover }: { hover?: boolean; }) => ({
    fontFamily: "Source Sans Pro, sans-serif",
    width: "100%",
    height: "50%",
    borderRadius: "2px",
    // backgroundColor: hover ? "#f4f4f4" : "#faf9f9",
    // marginBottom: "12px"
  }),
  project: {
    fontWeight: "normal",
    display: "flex",
    justifyContent: "space-between",
    padding: "0 8px 0px 8px",
    alignItems: "center",
    fontSize: "14px"
  },
  name: {
    padding: "0px 0px 3px 10px",
    fontWeight: "bold",
    textTransform: "uppercase",
    color: "#2f3e6c",
    fontSize: "11px"
  },
  ellipsis: { 
    textOverflow: "ellipsis", 
    overflow: "hidden", 
    whiteSpace: "nowrap" ,
    color: "#585858"
  }
}));

export default useStyles;
