import { makeStyles, Theme } from "@material-ui/core/styles";
import downArrowIcon from "../../assets/images/pagination/downArrow.svg";
const useStyles = makeStyles((theme: Theme) => ({
  mainDiv: (props: { pageCount: any, section: any }) => ({
    display: "flex",
    flexShrink: 1,
    flexGrow: 0,
    justifyContent: "space-evenly",
    alignContent: "center",
    margin: props.pageCount === 0 ? "-1px 30%" : props.section === "checklist-reports" ? "-1px 5%" : "-1px 21%",
    width: props.section === "checklist-reports" ? "69%" : "unset"
  }),
  mainDivSm: (pageCount: any) => ({
    display: "flex",
    flexShrink: 1,
    flexGrow: 0,
    justifyContent: "space-evenly",
    alignContent: "center",
    margin: pageCount === 0 ? "-1px 17%" : "-1px 9%",
  }),
  mainDivXs: {
    display: "flex",
    flexShrink: 1,
    flexGrow: 0,
    justifyContent: "space-evenly",
    alignContent: "center",
    margin: "-1px 3%",
  },
  secondDiv: (pageCount: any) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
    width: pageCount === 0 ? "49%" : "35%",
    alignSelf: "center",
  }),
  rectanglediv: {
    cursor: "pointer",
    width: "32px",
    height: "24px",
    borderRadius: "1px",
    border: "solid 1px #d1d1d1",
    backgroundColor: "#ffffff",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    backgroundImage: "none !important",
    padding: "0px",
    textAlignLast: "center",
    "&:before": {
      display: "none !important"
    }
  },
  resize: {
    fontSize: "11px",
    textAlignLast: "center"
  },
  changingpage: {
    width: "48px",
    height: "24px",
    borderRadius: "1px",
    border: "solid 1px #d1d1d1",
    backgroundColor: "#ffffff",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  pageNumber: {
    width: "12px",
    height: "15px",
    /* fontFamily: "SourceSansPro", */
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.25,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#585858",
    /* display: "flex", */
    /* flexDirection: "column", */
    /* justifyContent: "center", */
    alignSelf: "center",
  },
  upAndDownArrow: {
    height: "70%",
    display: "flex",
    flexDirection: "column",
    marginTop: "6%",
  },
  upArrow: {
    height: "50%",
    display: "flex",
    marginTop: "2px",
    cursor: "pointer",
  },
  downArrow: {
    cursor: "pointer",
    height: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  selectStatus: {
    fontSize: "11px !important",
    height: "30px",
    display: "flex",
    alignItems: "center",
    padding: "8px 16px 0px 16px",
  },
  invisibleButton: {
    fill: "lightgray",
    height: 20,
    display: "flex",
    alignSelf: "center",
  },
  visibleButton: {
    fill: "#005eff",
    height: 20,
    display: "flex",
    alignSelf: "center",
  },
  pageField: {
    fontFamily: "Source Sans Pro, sans-serif",
    backgroundImage: `url(${downArrowIcon}) !important`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
    width: "54px",
    height: "24px",
    borderRadius: "1px",
    // border: "solid 1px #d1d1d1",
    backgroundColor: "#ffffff",
    paddingLeft: "3px",
    fontSize: "11px",
    lineHeight: 1.25,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#585858",
    display: "inline-flex",
    "&:before": {
      fontSize: "44px",
      borderBottom: "none",
    },
    "& .MuiSelect-icon": {
      margin: "-2px -4px",
      backgroundImage: `url(${downArrowIcon}) !important`,
    },
    "& .MuiInputBase-input": {
      height: "-webkit-fill-available",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: "0px 0px 0px !important",
      paddingTop: "0px !important",
      backgroundColor: "#ffffff",
    },
    "&.MuiInput-underline": {
      "&:hover:not($disabled):before": {
        borderBottom: "none",
      },
      "&:hover": {
        borderBottom: "none",
      },
      "&:after": {
        borderBottom: "none",
      },
      "&:before": {
        borderBottom: "none",
      },
    },
    "&:after": {
      borderBottom: "none",
    },
  },
  inputField: {
    fontFamily: "Source Sans Pro, sans-serif",
    backgroundImage: `url(${downArrowIcon}) !important`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
    width: "84px",
    height: "24px",
    borderRadius: "1px",
    // border: "solid 1px #d1d1d1",
    backgroundColor: "#ffffff",
    paddingLeft: "3px",
    fontSize: "11px",
    lineHeight: 1.25,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#585858",
    display: "inline-flex",
    "&:before": {
      fontSize: "44px",
      borderBottom: "none",
    },
    "& .MuiSelect-icon": {
      margin: "-2px -4px",
      backgroundImage: `url(${downArrowIcon}) !important`,
    },
    "& .MuiInputBase-input": {
      height: "-webkit-fill-available",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: "0px 0px 0px !important",
      paddingTop: "0px !important",
      backgroundColor: "#ffffff",
    },
    "&.MuiInput-underline": {
      "&:hover:not($disabled):before": {
        borderBottom: "none",
      },
      "&:hover": {
        borderBottom: "none",
      },
      "&:after": {
        borderBottom: "none",
      },
      "&:before": {
        borderBottom: "none",
      },
    },
    "&:after": {
      borderBottom: "none",
    },
  },
  selectedItem: {
    // width: "196px",
    height: "36px",
    //   opacity: 0,
    borderRadius: "2px",
    //   background-color: var(--white-three);
    color: "#005eff",
    fontSize: "14px",
    minHeight: "36px",
    "&:hover": {
      fontWeight: "bold",
      color: "#005eff",
      marginLeft: "5px",
      marginRight: "5px",
    },
    fontFamily: "Source Sans Pro, sans-serif !important",
    "&:last-child": {
      marginBottom: 8,
    },
  },
  ItemsPerPage: {
    paddingLeft: "10px",
    width: "85px",
    display: "flex",
    height: "15px",
    // fontFamily: "SourceSansPro",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.25",
    letterSpacing: "normal",
    textAlign: "left",
    flexDirection: "column",
    alignSelf: "center",
    color: "#585858",
  },
  NumberOfItems: {
    display: "flex",
    alignSelf: "center",
    width: "158px",
  },
  totalNumberOfItems: {
    width: "100%",
    height: "15px",
    // fontFamily: "SourceSansPro",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.25,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#585858",
  },
  divider: {
    width: "1px",
    height: "16px",
    borderRadius: "10px",
    backgroundColor: " #d1d1d1",
    display: "flex",
    alignSelf: "center",
  },
  printViewCell: {
    fontSize: "12px",
    lineHeight: 1.25,
  },
  printHeader: {
    borderBottom: "1px solid #005eff",
    fontWeight: "bold",
    fontSize: "10px",
    lineHeight: 1.3,
    height: "100%",
    display: "flex",
    alignItems: "flex-end",
    paddingBottom: "2px",
  },
  nonDropdownText: (props: { print }) => ({
    borderBottom: "1px solid grey",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: props.print === "print" ? "auto" : "47px",
    minWidth: "83px"
    // paddingTop: "16px",
    // fontSize: "14px",
  }),
  statusRoot: {
    display: "flex",
    alignItems: "center",
  },
  status: {
    height: "18px",
    textTransform: "capitalize",
  },
  openImg: {
    width: "10px",
    height: "10px",
    borderRadius: "7px",
    backgroundColor: "#f52ff5",
    marginRight: "8px",
  },
  forReviewImg: {
    width: "18px",
    height: "10px",
    borderRadius: "7px",
    backgroundColor: "#2FEDF5",
    marginRight: "8px",
  },
  closeImg: {
    borderRadius: "1px",
    backgroundColor: "#2c20b7",
    width: "10px",
    height: "10px",
    transform: "rotate(45deg)",
    marginRight: "8px",
  },
  dateRange: {
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "left",
    backgroundColor: "#fff",
  },
  normText11px: {
    display: "flex",
    height: "30px",
    fontFamily: "Source Sans Pro, sans-serif !important",
    fontSize: "11px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.27,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#808080",
    alignItems: "center",
    paddingBottom: "12px"
  },
  infoPanelRoot: {
    flex: 1,
    // width: "200px",
    // overflow: "auto",
    backgroundColor: "#fff",/* "#faf9f9" */
    margin: "12px 0 12px 22px",
    // height: "91vh"
  },
  emptyFilter: {
    display: "flex",
    width: "240px"
  },
  infoPanelRootSm: {
    flex: 1,
    width: "20%",
    overflow: "auto",
    backgroundColor: "#fff",/* "#faf9f9" */
    padding: "1rem",
    height: "87vh"
  },
  table: {
    overflow: "auto",
    flex: 1,
    marginLeft: "1.5rem",
    marginRight: "1.5rem",
  },
  tablePanel: (props: { print }) => ({
    width: props.print === "print" ? "100%" : "100%",
    height: props.print === "print" ? "auto" : "100%",
    display: "flex",
    /* Direction of the items, can be row or column */
    flexDirection: "column",
  }),
  tablePanelSm: (props: { print: string }) => ({
    width: "100%",
    height: props.print === "print" ? "auto" : "100%",
    display: "flex",
    /* Direction of the items, can be row or column */
    flexDirection: "column",
  }),
  tablePanelFs: (props: { print: string }) => ({
    width: "100%",
    height: props.print === "print" ? "auto" : "100vh",
    display: "flex",
    /* Direction of the items, can be row or column */
    flexDirection: "column",
  }),
  sortfilterRectangle: {
    height: "32px",
    margin: "4px 0px 4px 0px",
    borderRadius: "2px",
    backgroundColor: "#f0efef",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  closeRectangle: {
    width: "32px",
    height: "32px",
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    background: "#f0efef",
    cursor: "pointer"
  },
  filterRectangle: {
    width: "80%",
    height: "32px",
    margin: "4px 0px 4px 0px",
    borderRadius: "2px",
    backgroundColor: "#f0efef",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  filterSecondRectangle: {
    // maxHeight: "40px",
    minHeight: "26px",
    // height:"26px",
    // marginLeft:"15px",
    borderRadius: "2px",
    margin: "0px 4px 4px 0px",
    backgroundColor: "#faf9f9",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    // justifyContent: "space-between"
    width: "fit-content",
  },
  sortBlock: {
    width: "70%",
    display: "flex",
    height: "inherit"
  },
  blockNumber: {
    width: "24px",
    background: "#edebeb",
    height: "inherit",
    fontSize: "9px",
    color: "#585858",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    padding: "0 7px"
  },
  blocknumber: {
    width: "20px",
    background: "#edebeb",
    height: "20px",
    fontSize: "9px",
    color: "#2f3e6c",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    borderRadius: "34px",
    marginLeft: "3px",
    marginBottom: "1px"
  },
  showAndHide: {
    paddingRight: "9px",
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "10px",
    lineHeight: 1.3,
    letterSpacing: "normal",
    color: "#005eff",
    cursor: "pointer"
  },
  columnText1: {
    fontSize: "12px",
    margin: "5.5px 1px 6.5px 8px",
    // marginBottom: "1px",
    fontFamily: "Source Sans Pro, sans-serif",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.25,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#005eff",
    // marginLeft: "8px",
    // marginRight: "10px",
    display: "flex",
    alignItems: "center",
  },
  columnText: {
    fontSize: "12px",
    fontFamily: "Source Sans Pro, sans-serif",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.25,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#2f3e6c",
    marginLeft: "8px",
    alignItems: "center",
    alignSelf: "center",
    whiteSpace: "nowrap",
    // overflow: "hidden",
    textOverflow: "ellipsis"
  },
  boldDarkBlue24px: {
    fontSize: "24px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.29,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#2f3e6c",
  },
  // ActiveInfoTable: {
  //   height: "65px"
  // },
  filterIcondiv: {
    display: "flex",
    textAlignLast: "end",
    flexDirection: "row",
    margin: "0.5rem 0rem",
    alignItems: "center"
  },
  filterBy: {
    height: "14px",
    fontFamily: "Source Sans Pro, sans-serif !important",
    fontSize: "11px !important",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.27,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#2f3e6c",
    textTransform: "uppercase",
    marginLeft: 10,
    width: "47px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  noFilter: {
    fontFamily: "Source Sans Pro, sans-serif !important",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.29,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#585858",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden"
  },
  phaseSpace: {
    fontFamily: "Source Sans Pro, sans-serif",
    // width: "216px",
    height: "58px",
    padding: "0.5rem 0.5rem 0rem 1.2rem",
    borderRadius: "2px",
    display: "flex",
    flexDirection: "column",
    marginBottom: "10px",
    background: "#faf9f9"
  },
  phaseLabel: {
    marginTop: "8px",
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.29,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#585858",
    textTransform: "capitalize"
  },
  phaseSpaceLabel: {
    fontFamily: "Source Sans Pro, sans-serif !important",
    fontSize: "11px !important",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.27,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#2f3e6c",
    textTransform: "uppercase",
  },
  resetAll: {
    marginBottom: "0.5rem",
    fontSize: "12px",
    cursor: "pointer",
    color: "#005eff"
  },
  titleLable: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  closeIcon: {
    marginRight: '5px'
  },
  button: {
    margin:"10px 0px",
    fontFamily: "Source Sans Pro, sans-serif !important",
    fontSize: "12px",
    fontWeight: 600,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.30,
    letterSpacing: "normal",
    backgroundColor: "#f4f4f4",
    color: "#005EFF",
    borderRadius: "2px",
    boxShadow: "none !important",
    textTransform: "capitalize",
    height: "28px",
    width: "100%",
    "&:hover": {
      backgroundColor: "#f4f4f4",
      textDecoration: "none",
      fontWeight: "bold"
    },
    '@media print': {
      display: "none"
    }
  },
  buttonSm: {
    margin: "7px 0px 0px 0px",
    fontFamily: "Source Sans Pro, sans-serif !important",
    fontSize: "12px",
    fontWeight: 600,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.30,
    letterSpacing: "normal",
    backgroundColor: "#f4f4f4",
    color: "#005EFF",
    borderRadius: "2px",
    boxShadow: "none !important",
    textTransform: "capitalize",
    height: "28px",
    width: "100%",
    "&:hover": {
      backgroundColor: "#f4f4f4",
      textDecoration: "none",
      fontWeight: "bold"
    },
    '@media print': {
      display: "none"
    }
  }
}));
export default useStyles;
