import { Theme, makeStyles } from '@material-ui/core/styles';
import { extendStyles } from '../../../utils/utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: "#585858",
    padding: "24px",
    fontFamily: "Source Sans Pro, sans-serif",
    // fontSize: "12px",
    width: "17.5%",
    height: "100%",
    overflow: "auto",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column"
  },
  rootSm: {
    color: "#585858",
    padding: "24px",
    fontFamily: "Source Sans Pro, sans-serif",
    // fontSize: "12px",
    width: '33%',
    height: "100%",
    overflow: "auto",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column"
  },
  personName: {
    height: "23px",
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "18px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.28,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#585858",
    textTransform: "capitalize"
  },
  customerName: {
    height: "18px",
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.29,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#808080",
  },
  logout: {
    height: "20px",
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.25,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#2f3e6c",
    marginLeft: 4,
    // marginBottom: "7px"
  },
  projectProperty: (hover: boolean) => ({
    fontFamily: "Source Sans Pro, sans-serif",
    width: "192px",
    borderRadius: "2px",
    height: "105px",
    backgroundColor: hover ? "#f4f4f4" : "#faf9f9",
    cursor: "pointer",
    padding: "11px 0px",
    margin: "16px 0px 30px 0px",
    // marginBottom: "12px",
    "&:hover": {
      backgroundImage: "linear-gradient(#b1b1b1, #b1b1b1)",
      backgroundSize: "90% 2px, auto",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center bottom",
    },
  }),
  project: (hover: boolean) => ({
    fontWeight: "normal",
    display: "flex",
    justifyContent: "space-between",
    padding: "0 8px 10px 8px",
    alignItems: "center",
    fontSize: "14px",
    // "&:hover": {
    backgroundImage: hover ? "linear-gradient(#b1b1b1, #b1b1b1)" : "",
    backgroundSize: hover ? "90% 2px, auto" : "",
    // },
  }),
  logoutContainer: {
    marginTop: "24px",
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    cursor: "pointer"
  },
  horizantalDivider: {
    width: "90%",
    height: "1px",
    backgroundColor: "#d1d1d1",
    marginLeft: "5%",
    marginBottom: "7px"
  },
  imgSm: {
    height: "98px",
    objectFit: "contain"
  },
  imgLg: {
    width: 64,
    height: 64,
    objectFit: "contain"
  }
}))

export default useStyles;