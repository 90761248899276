import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => createStyles({
    container: {
        width: "140px"
    },
    listItems: {
        listStyle: "none",
        lineHeight: 1.29,
        fontSize: "14px",
        color: "#005eff",
        margin: "12px 0",
        fontWeight: "normal",
        textTransform: "uppercase"
    },
    active: {
        fontWeight: "bold",
        color: "#585858",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },
    activeIndicator: {
        width: "0px",
        height: "0px",
        borderTop: "5px solid transparent",
        borderBottom: "5px solid transparent",
        borderRight: "5px solid #005eff"
    }
}));

export default useStyles;