import React, { useEffect, useMemo } from "react";
import { useHistory, useLocation } from 'react-router';
import { getSnapshot } from "mobx-state-tree";
import useStyles from "./InfoPanelStylesSm";
import { useStore } from "../../models/ProvideModel";
import { useObserver } from "mobx-react-lite";
import ButtonGroup from "../buttonGroup/ButtonGroup";
import filterIcon from "../../assets/images/FilterSmall.svg";
import BarGraphIcon from "../../assets/images/mobileIcons/BarGraph.png";
import UnitList from "../../assets/images/mobileIcons/PerUnit.png";
import allFloorIcon from "../../assets/images/mobileIcons/AllFloors.png";
import Legend from "../../assets/images/mobileIcons/legend.png";
import { ModalProps, useModal, Modal, ModalBody, ModalContent, ModalOverlay } from "../modal";
import InfoPanelWidgets from "../InfoPanelWidgets/InfoPanelWidgets";
import CloseDefault from '../../assets/images/menuNavigation/Close Default.svg'
import doubleRight from "../../assets/images/infoPanel/doubleRight.png"

const TABS = {
  BRICK_GRAPH: 'brickGraph',
  TABLE: 'table',
  BAR_CHART: 'barChart'
}

function InfoPanelSm() {
  const [filterModalState, setFilterModalState] = React.useState(false);
  const [legendOpen, setlegendOpen] = React.useState(false);
  const classes = useStyles();
  const store = useStore();
  const history = useHistory();
  const params = store.params;
  const location = useLocation();
  const { search, pathname } = useMemo(() => location, [
    location.search,
    location.pathname,
  ]);
  const searchParam = useMemo(() => new URLSearchParams(search), [search]);

  const getButtonElements = (graphType) => {
    return [
      {
        item: allFloorIcon,
        isActive: graphType === TABS.BRICK_GRAPH,
        onBtnClicked: () => { onBrickgraphClicked() }
      },
      {
        item: UnitList,
        isActive: graphType === TABS.TABLE,
        onBtnClicked: () => { onTableClicked() }
      },
      {
        item: BarGraphIcon,
        isActive: graphType === TABS.BAR_CHART,
        onBtnClicked: () => { onBarChartClicked() }
      }
    ]
  }
  const onBrickgraphClicked = () => {
    if (params.graphType !== 'brickGraph') {
      history.replace(params.toPath({ ...getSnapshot(params), screen: 'fullscreen', section: "dashboard", spaceType: "tower", graphType: 'brickGraph' }));
    }
  }

  const onTableClicked = () => {
    if (params.graphType !== 'table') {
      history.replace(params.toPath({ ...getSnapshot(params), section: 'legacy', screen: 'fullscreen', graphType: 'table' }));
    }
  }

  const onBarChartClicked = () => {
    if (params.graphType !== 'barChart') {
      history.replace(params.toPath({ ...getSnapshot(params), screen: 'fullscreen', section: "dashboard", spaceType: "tower", graphType: 'barChart' }));
    }
  }

  useEffect(() => {
    if (store.infoPanel.panels.length === 0) {
      store.infoPanel.setInfoPanel();
    }
  }, []);

  const arg: ModalProps = {
    isOpen: filterModalState,
    closeOnOverlayClick: true
  }

  const { isOpen } = useModal(arg);

  const onLegendOpen = () => {
    setlegendOpen(!legendOpen)
  }

  const onFilterModalClose = () => {
    setFilterModalState(!filterModalState);
  }

  const onFilterIconClicked = () => {
    setFilterModalState(true);
  }

  const filterApplied = (location) => {
    searchParam.delete("infopanelOpen");
    const paramsLength = Array.from(searchParam).length
    return paramsLength > 0;
  }
  const closeModel = () => {
    setFilterModalState(false);
  }

  useEffect(() => {
    setFilterModalState(false);
  }, [location]);

  const infopanelclick = () => {
    searchParam.delete("infopanelOpen");
    searchParam.append("infopanelOpen", "false");
    history.push({ ...location, search: searchParam.toString() });
  }

  return useObserver(() => (
    <div className={classes.root}>
      <div className={classes.top} onClick={() => onFilterIconClicked()}>
        <div style={{ display: 'flex' }}>
          <img className={classes.filterIcon} src={filterIcon} alt="filter_icon" />
          {filterApplied(location) && (<span className={classes.filterIndicator}></span>)}
        </div>
        {store.responsiveUtils.currentViewport.isLg && store.filters.infopanelOpen && <div className={classes.doubleRight} onClick={() => infopanelclick()} >
          <img src={doubleRight} style={{ height: "20px" }}></img>
        </div>}
        <Modal
          isOpen={isOpen}
          isCentered={false}
          onClose={() => onFilterModalClose()}
          closeOnOverlayClick={true}
          position={{ top: 20, left: 20 }}
        >
          <ModalOverlay>
            <div className={classes.mainDiv}>
              <ModalContent style={{ boxShadow: "unset" }}>
                <ModalBody style={{ padding: "15px 5px", marginTop: "12px" }}>
                  <InfoPanelWidgets />
                </ModalBody>
                <div onClick={closeModel}>
                  <img src={CloseDefault} style={{ cursor: "pointer" }}></img>
                </div>
              </ModalContent></div>
          </ModalOverlay>
        </Modal>
      </div>
      <div className={classes.center}>
        {store.infoPanel.panelsListDivided[6] &&
          store.infoPanel.panelsListDivided[6].map(
            ({ component: Comp, field, props }) => (
              <Comp key={field} vertical={true} elements={getButtonElements(store.params.graphType)} {...props} />
            )
          )}
      </div>
      {!store.responsiveUtils.fullScreenMode && !store.filters.open && store.infoPanel.panelsListDivided[3] && !store.responsiveUtils.currentViewport.isLg &&
        <div className={classes.bottom}>
          <div style={{ display: 'flex' }} onClick={() => onLegendOpen()}>
            <img className={classes.legendIcon} src={Legend} alt="legend_icon" />
          </div>
          {legendOpen &&
            <div className={classes.legend}>
              {store.infoPanel.panelsListDivided[3] &&
                store.infoPanel.panelsListDivided[3].map(
                  ({ component: Comp, field, props }) => (
                    // <div key={field} className={classes.filtersPadding}>
                    <Comp key={field} {...props} />
                    // </div>
                  )
                )}
              <div style={{ display: "flex", background: "#F7F7F7", alignItems: "center" }}>
                <div className={classes.divider}></div>
                <div className={classes.hide} onClick={() => onLegendOpen()}>Hide</div>
              </div>
            </div>}
        </div>}
    </div>
  ));
}

export default InfoPanelSm;
