import { makeStyles, createStyles } from "@material-ui/core/styles";
import { extendStyles } from "../../utils/utils";

export const SIZE_SM = 'small';
export const SIZE_MD = 'medium';
export const SIZE_LG = 'large';

export const TYPE_DEFAULT = 'default';
export const TYPE_PRIMARY = 'primary';

const stylesObj = {
    btn: {
        border: '1px solid #efefef',
        borderRadius: '50px',
        fontWeight: 700,
        outline: 'none',
        cursor: 'pointer'
    },
    [`btnType_${TYPE_PRIMARY}`]: {},
    [`btnSize_${SIZE_LG}`]: {},
    [`btnSize_${SIZE_MD}`]: {},
    [`btnSize_${SIZE_SM}`]: {},
    [`btnState_floating`]: {}
};

stylesObj[`btnType_${TYPE_DEFAULT}`] = extendStyles(stylesObj[`btn`], {
    backgroundColor: 'white',
    color: 'black',
});

stylesObj[`btnType_${TYPE_PRIMARY}`] = extendStyles(stylesObj[`btn`], {
    backgroundColor: '#005eff',
    color: 'white'
});

stylesObj[`btnSize_${SIZE_LG}`] = extendStyles(stylesObj[`btn`], {
    padding: '20px 40px',
    fontSize: '19px'
});

stylesObj[`btnSize_${SIZE_MD}`] = extendStyles(stylesObj[`btn`], {
    padding: '18px 28px'
});

stylesObj[`btnSize_${SIZE_SM}`] = extendStyles(stylesObj[`btn`], {
    padding: '15px 25px'
});

stylesObj[`btnState_floating`] = extendStyles(stylesObj[`btn`], {
    zIndex: 100,
    boxShadow: '6px 3px 33px -12px rgba(0,0,0,0.75)'
});

const useStyles = makeStyles(() => createStyles(stylesObj));

export default useStyles;