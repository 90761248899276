import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import PlusIcononHover from "../../assets/images/plusicon-on-hover.svg"
import PlusIcon from '../../assets/images/plusIcon.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fbtLogo: {
      height: "50px",
      display: "flex",
      paddingLeft: "16px",
      paddingRight: "16px",
      justifyContent: "space-between",
      backgroundColor: "#2f3e6c",
      overflow: "hidden",
      alignItems: "center",
      position: "fixed",
    },
    root: {
      fontFamily: "Source Sans Pro, sans-serif !important",
      backgroundColor: "#2f3e6c",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flex: 1,
      // paddingTop: "10vh"
    },
    projectSelectionContainer: {
      display: "flex",
      flexDirection: "column",
      height: "100vh"
    },
    mainContainer: {
      width: '100%',
      marginTop: '0px'
    },
    mainContainerSm: {
      width: '350px',
      marginTop: '0px',
      height: "75%"
    },
    custTitle: {
      fontFamily: "Source Sans Pro, sans-serif !important",
    },
    statusTitle: {
      fontFamily: "Source Sans Pro, sans-serif !important",
    },
    projFont: {
      fontFamily: "Source Sans Pro, sans-serif !important",
      fontSize: "14px",
      fontWeight: "bolder"
    },
    projImg: {
      fontFamily: "Source Sans Pro, sans-serif !important",
      fontWeight: "bolder",
      backgroundColor: "#edebeb",
      fontSize: "26px",
      width: "64px",
      height: "64px",
      margin: "0px 12px",
      display: "flex",
      justifyContent: "center"
    },
    propFont: {
      fontFamily: "Source Sans Pro, sans-serif !important",
      fontSize: "14px !important",
      fontWeight: 400,
      color: "#005eff"
    },
    projectContainer: {
      maxHeight: "65vh",
      marginTop: "20px",
      overflow: "auto",
      overflowX: "hidden",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      '&::-webkit-scrollbar': {
        width: "0px"
      },
    },
    projectContainerSm: {
      maxHeight: "65vh",
      marginTop: "20px",
      overflow: "auto",
      overflowX: "hidden",
      '&::-webkit-scrollbar': {
        width: "0px"
      },
    },
    exPanel: {
      marginBottom: "24px",
      backgroundColor: "transparent !important",
      cursor: "auto",
      borderRadius: "2px",
      boxShadow: "none !important",
      width: "506px"
    },
    exPanelSm: {
      marginBottom: "24px",
      backgroundColor: "transparent !important",
      cursor: "auto",
      borderRadius: "2px",
      boxShadow: "none !important"
    },
    projectItem: ({ hover }: { hover?: boolean; }) => {
      return ({
        display: "flex",
        height: "96px",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        backgroundColor: '#fff',
        borderRadius: "2px",
        color: "#585858",
        position: "sticky",
        top: 0,
        // zIndex: 999,
        boxShadow: "0px 10px 0px 0px rgba(47, 62, 108, 1), 0px 10px 1px 0px rgba(47, 62, 108, 1), 0px 10px 20px -1px rgba(47, 62, 108, 1)",
        '&:hover': {
          cursor: "pointer",
          backgroundColor: " #eaeaea",
          color: hover ? "#005eff" : "#2f3e6c",
          "& span": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            borderRadius: "2px",
            color: "#585858",
            top: 0,
            backgroundImage: `url(${PlusIcononHover})`,
            backgroundRepeat: "no-repeat",
          }
        }
      })
    },
    propertyItem: {
      height: "64px",
      backgroundColor: '#fff',
      justifyContent: "center",
      alignItems: "center",
      marginTop: '10px',
      padding: "0px !important",
      borderRadius: "2px !important",
    },
    myItemA: ({ hover }: { hover?: boolean; }) => {
      return ({
        padding: "16px",
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
      })
    },
    myItemC: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      width: "32px",
      height: "32px",
      marginRight: "19px",
      backgroundImage: `url(${PlusIcon})`,
      backgroundRepeat: "no-repeat",
    },
    loadParent: {
      height: "96px !important",
      backgroundColor: "white !important",
      borderRadius: "2px !important",
      marginBottom: "20px !important",
      paddingTop: "15px !important",
    },
    loadImg: {
      width: "64px !important",
      height: "64px !important",
      backgroundColor: "#E8E8E8 !important",
      borderRadius: "2px !important",
      marginLeft: "15px !important",
      display: "inline-block !important"
    },
    loadText: {
      width: "30% !important",
      marginLeft: "15px !important",
      backgroundColor: "#E8E8E8 !important",
      height: "20px !important",
      marginBottom: "20px !important",
      display: "inline-block !important"
    },
    loadTitle: {
      width: "100px !important",
      marginLeft: "15px !important",
      backgroundColor: "#E8E8E8 !important",
      height: "20px !important",
      marginBottom: "20px !important",
      display: "inline-block !important"
    },
    logoutBnt: {
      backgroundColor: "#2f3e6c",
      boxShadow: "none",
      textTransform: "capitalize",
      "&:hover": {
        backgroundColor: "#2f3e6c",
        boxShadow: "none",
      }
    }
  })
);

export default useStyles;
