import React from "react";
import { useHistory } from 'react-router';
import useStyles from "./ViewStyles";
import { useStore } from "../../models/ProvideModel";
import { useObserver } from "mobx-react-lite";
import { Link, Grid } from "@material-ui/core";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { getSnapshot } from "mobx-state-tree";

function Views() {
  const history = useHistory();
  const store = useStore();
  const params = useStore().params;
  const classes = useStyles({ params });
  const [value, setValue] = React.useState(false)

  const ByFloorsUrlPath: any = store.params.toPath({ ...getSnapshot(params), screen: 'fullscreen', section: "dashboard", spaceType: "tower", graphType: 'brickGraph' })

  const ByUnitsUrlPath: any = params.toPath({ ...getSnapshot(params), section: 'legacy', screen: 'fullscreen', graphType: 'table' })

  const BarChartUrlPath: any = params.toPath({ ...getSnapshot(params), screen: 'fullscreen', section: "dashboard", spaceType: "tower", graphType: 'barChart' })

  const RemoveHref = (id) => {
    document?.getElementById(id)?.removeAttribute("href");
  }

  return useObserver(() => (
    <div className={classes.root}>
      <Grid container direction="row" alignItems="center" className={classes.RootGrid}>
        <ToggleButtonGroup size="small" exclusive className={classes.mainToggle} onChange={() => { setValue(!value) }}>
          <ToggleButton key={"brickGraph"} className={!(params.graphType === 'brickGraph') ? classes.buttonToggleGroup : classes.buttonToggleRight} value={value} style={{ padding: "0px" }}>
            <Link color="secondary" id={"ByFloors"}
              href={`${window.location.href.split('/', 3).join('/')}${ByFloorsUrlPath}`}
              target="popup"
              className={!(params.graphType === 'brickGraph') ? classes.underlineHover : classes.underlineHoverSelected}
              onClick={!(params.graphType === 'brickGraph') ? () => {
                RemoveHref("ByFloors"); history.push(ByFloorsUrlPath); return false
              } : () => { RemoveHref("ByFloors") }}>
              <span className={!(params.graphType === 'brickGraph') ? classes.towerTitle : classes.towerTitleSelected}>By Floors</span>
            </Link>
          </ToggleButton>
          {!(params.graphType === 'brickGraph') && !(params.graphType === 'perUnit') && !(params.graphType === 'table') ? <div className={classes.verticalDivider}></div> : null}
          <ToggleButton key={"table"} className={!(params.graphType === 'table') ? classes.buttonToggleGroup : classes.buttonToggleRight} value={value} style={{ padding: "0px" }}>
            <Link color="secondary" id={"ByUnits"}
              href={`${window.location.href.split('/', 3).join('/')}${ByUnitsUrlPath}`}
              target="popup"
              className={(params.graphType !== 'table') ? classes.underlineHover : classes.underlineHoverSelected}
              onClick={(params.graphType !== 'table') ? () => {
                RemoveHref("ByUnits");
                history.push(ByUnitsUrlPath); return false
              } : () => { RemoveHref("ByUnits") }}>
              <span className={(params.graphType !== 'table') ? classes.towerTitle : classes.towerTitleSelected}>By Units</span>
            </Link>
          </ToggleButton>
          {(params.graphType === 'brickGraph') && !(params.graphType === 'perUnit') ? <div className={classes.verticalDivider}></div> : null}
          <ToggleButton key={"barChart"} className={(params.graphType !== 'barChart') ? classes.buttonToggleGroup : classes.buttonToggleRight} value={value}>
            <Link color="secondary" id={"BarChart"}
              href={`${window.location.href.split('/', 3).join('/')}${BarChartUrlPath}`}
              target="popup"
              className={(params.graphType !== 'barChart') ? classes.underlineHover : classes.underlineHoverSelected}
              onClick={(params.graphType !== 'barChart') ? () => {
                RemoveHref("BarChart");
                history.push(BarChartUrlPath); return false
              } : () => { RemoveHref("BarChart") }}>
              <span className={(params.graphType !== 'barChart') ? classes.towerTitle : classes.towerTitleSelected}>Bar Chart</span>
            </Link>
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
    </div >
  ));
}
export default React.memo(Views)
