import React, { memo } from 'react'

const ViewPdf = ({ cell }: { cell: { value: string; } }) => {
 
  return (
    <div>
      <a id={process.env.REACT_APP_REPORTS_BASE_URL + cell.value} key={process.env.REACT_APP_REPORTS_BASE_URL + cell.value}
        href={process.env.REACT_APP_REPORTS_BASE_URL + cell.value} rel="noopener noreferrer"
        target="popup" style={{ textDecoration: "none" }}>
        View/Download</a>
    </div>
  )
};

export default memo(ViewPdf);
