import React, { useMemo, useState } from "react";
import useStyles from "./InfoPanelWidgetStyles";
import { useStore } from "../../models/ProvideModel";
import { observer, useObserver } from "mobx-react-lite";
import filterIcon from "../../assets/images/FilterSmall.svg"
import { useLocation, useHistory } from "react-router";
import LeftArrow from "../../assets/images/infoPanel/doubleLeft.png"
import LeftArrowGig from "../../assets/images/infoPanel/doubleLeftGif.gif"


function InfoPanelWidgets() {
  const classes = useStyles();
  const store = useStore();
  const { push } = useHistory();
  const location = useLocation();
  const [hover, setHover] = useState<boolean>(false);
  const { search, pathname } = useMemo(() => location, [
    location.search,
    location.pathname,
  ]);
  const searchParam = useMemo(() => new URLSearchParams(search), [search]);

  const resetAll = () => {
    if (searchParam.get('infopanelOpen') === "true") {
      push({ ...location, search: "?infopanelOpen=true" });
    }
    else {
      push({ ...location, search: "" });
    }

  }

  const infopanelclick = (value) => {
    searchParam.delete("infopanelOpen");
    if (value === false) {
      store.filters.setInfopanelOpen(true)
    }
    else {
      searchParam.append("infopanelOpen", value.toString());
    }
    push({ ...location, search: searchParam.toString() });
  }

  return useObserver(() => (
    <div className={store.params.graphType === "table" ? classes.tableRoot : classes.root}>
      <>
        <div style={{ height: store.infoPanel.panelsListDivided[5] || store.infoPanel.panelsListDivided[4] ? "87%" : "unset", overflow: "auto" }}>
          <div style={{ marginRight: store.params.graphType === "table" && !store.filters.infopanelOpen ? "13px" : "unset" }}>
            {!store.filters.infopanelOpen && store.responsiveUtils.currentViewport.isLg && <div className={classes.closeButton} onClick={() => infopanelclick(!store.filters.infopanelOpen)}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)} >
              <img src={hover ? LeftArrowGig : LeftArrow} style={{ height: "18px", width: "18px" }}></img>
            </div>}
            {store.params.graphType !== "table" && <div className={classes.resetFilter}>
              <div className={classes.filterIcondiv}>
                <img width="17" src={filterIcon} />
                <label className={classes.filterBy}>&nbsp; filter By</label></div>
              <div className={classes.resetAll} onClick={resetAll}>Reset all</div></div>}
            {store.infoPanel.panelsListDivided[0] &&
              store.infoPanel.panelsListDivided[0].map(
                ({ component: Comp, field, props }) => <Comp key={field} {...props} />
              )}
            {/* <div className={classes.filtersPadding}> */}
            {store.infoPanel.panelsListDivided[2] &&
              store.infoPanel.panelsListDivided[2].map(
                ({ component: Comp, field, props }) => (
                  <Comp key={field} {...props} />
                )
              )}
            {/* </div> */}
            {store.infoPanel.panelsListDivided[1] &&
              store.infoPanel.panelsListDivided[1].map(
                ({ component: Comp, field, props }) => (
                  // <div key={field} className={classes.filtersPadding}>
                  <Comp key={field} {...props} />
                  // </div>
                )
              )}
          </div>
        </div>
        <div style={{ position: "sticky", marginRight: "21px" }}>
          {(store.infoPanel.panelsListDivided[5] || store.infoPanel.panelsListDivided[4]) &&
            <div className={classes.divider} style={{ marginTop: store.responsiveUtils.currentViewport.isLg ? "" : "20px" }}></div>}
          {store.infoPanel.panelsListDivided[4] &&
            store.infoPanel.panelsListDivided[4].map(
              ({ component: Comp, field, props }) => (
                <Comp key={field} {...props} />
              )
            )}
          {store.infoPanel.panelsListDivided[5] &&
            store.infoPanel.panelsListDivided[5].map(
              ({ component: Comp, field, props }) => (
                <Comp key={field} {...props} />
              )
            )}
        </div>
      </>
    </div>
  ));
}
export default observer(InfoPanelWidgets);
