import React, { memo, useRef, useEffect, useState, RefObject, Dispatch, SetStateAction } from "react";
import { useObserver } from "mobx-react-lite";
import { reaction } from 'mobx';
import { useHistory } from 'react-router';
import useStyles from "./LandingPageStyles";
import { useStore } from "../../models/ProvideModel";
import Container from '../../containers/scrollingLayout/ScrollingLayout';
import TabTiles from "../../containers/tabTiles/tabTiles"
import { getSnapshot } from "mobx-state-tree";
import { GOT, getThrones } from "../../models/enums";
import NoDataFound from "../../components/noDataFound/NoDataFound";

function update(divRef: RefObject<HTMLDivElement>, setWidth: Dispatch<SetStateAction<number>>, setHeight: Dispatch<SetStateAction<number>>): void {
  if (divRef.current) {
    const box = divRef.current!.getBoundingClientRect() as DOMRect;
    setWidth(box.width - 40);
    setHeight(box.height);
  }
}

const LandingPage: React.FC = () => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const classes = useStyles();
  const store = useStore();
  const { push } = useHistory();
  const Summary = store.summary.Summary;
  const divRef = useRef<HTMLDivElement>(null);

  const screenProps: any = {
    isLg: store.responsiveUtils.currentViewport.isLg,
    isSm: store.responsiveUtils.currentViewport.isSm,
    isXs: store.responsiveUtils.currentViewport.isXs
  };

  // reaction does not run first time
  useEffect(() => reaction(() => [store.currentTower, store.params.tower, store.params.path, store.responsiveUtils.fullScreenMode, store.totalRows, store.totalColumns, store.currentDashboard?.loading], () => {
    setImmediate(() => update(divRef, setWidth, setHeight));
  }), []);

  useEffect(() => {
    update(divRef, setWidth, setHeight);
  }, []);

  let IsMacOs = false;
  if (navigator.appVersion.indexOf("Mac") !== -1) IsMacOs = true;

  return useObserver(() => (
    <div className={classes.root} style={{ flexDirection: 'row' }}>
      {/* {loading === 0 ?
        <div className={classes.loadingSkeletondiv} style={{ width: "100%" }}><LoadingSkeleton /></div> : <> */}
      <TabTiles
        data={store.currentDashboard?.tabsList || []}
        title="COMMON AREA GROUPS"
        screen="landing"
        searchString="blocks"
        selected={store.params.tab}
        handleClick={(id: string) => { push(store.params.toPath({ ...getSnapshot(store.params), tab: id })); }}
        href={(id) => id !== undefined ? `${window.location.href.split('/', 3).join('/')}${store.params.toPath({ ...getSnapshot(store.params), tab: id })}` : ``}
        screenProps={screenProps}
      />
      <div ref={divRef} className={classes.landing}>
        {!store.currentDashboard?.tab?.loading && store.towersList.length === 0 ?
          <NoDataFound /> :
          <Container
            IsMacOs={IsMacOs}
            width={width}
            height={height}
            screen={store.params.screen}
            phase={store.params.phase}
            loading={store.loading || store.currentDashboard?.loading || store.currentDashboard?.tab?.loading}
            label={store.currentDashboard?.tab?.graphType === GOT.BARATHEON ? "Unit Types" : "Floors"}
          />
        }
      </div>
      <div className={classes.fix}></div>
      <Summary {...store.summary.props} />
    </div >
  ));
}

export default memo(LandingPage);
