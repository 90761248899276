import React, { useRef, useEffect, RefObject, useState, Dispatch, SetStateAction } from "react";
import { reaction } from "mobx";
import { observer } from "mobx-react-lite";
import { useStore } from "../../models/ProvideModel";
import Container from "../../containers/scrollingLayout/ScrollingLayout";
import useStyles from './FullScreenPageStyles';
import BarGraph from "../../containers/finishingBarChart/barChart";
import { Desktop } from '../../utils/responsive';
import Views from '../../components/viewPanel/View';

function update(divRef: RefObject<HTMLDivElement>, setWidth: Dispatch<SetStateAction<number>>, setHeight: Dispatch<SetStateAction<number>>, isLg: boolean, isSm: boolean): void {
  if (divRef.current) {
    const box = divRef.current!.getBoundingClientRect() as DOMRect;
    setWidth(box.width - (isLg ? 40 : isSm ? 10 : 25));
    setHeight(box.height);
  }
}

const FullScreenTowersPage = () => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const classes = useStyles();
  const store = useStore();
  const Summary = store.summary.Summary;
  const divRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  useEffect(() => reaction(() => [store.currentTower, store.params.path, store.responsiveUtils.fullScreenMode, store.currentDashboard?.loading], () => {
    // setImmediate because the damn ref is not mounting onto the div when
    // update is run. Correct at some point. This is a potential memory leak.
    setImmediate(() => update(divRef, setWidth, setHeight, store.responsiveUtils.currentViewport.isLg, store.responsiveUtils.currentViewport.isSm));
  }), []);
  /**
   * 
   * Reload once Fullscrenn mode changed.
   * 
   */
  useEffect(() => {
    update(divRef, setWidth, setHeight, store.responsiveUtils.currentViewport.isLg, store.responsiveUtils.currentViewport.isSm);
  }, []);

  /**
   * 
   * Reload once Fullscrenn mode changed.
   * 
   */
  /* useEffect(() => {
   *   update(divRef, setWidth, setHeight);
   * }, [store.responsiveUtils.fullScreenMode])
   */

  let IsMacOs = false;
  if (navigator.appVersion.indexOf("Mac") !== -1) IsMacOs = true;
  /* if (!store.currentTower || store.currentDashboard?.loading) {
   *   return (
   *     <div className={classes.root} style={{ justifyContent: "center", alignItems: "center" }}>
   *       <LoadingSkeleton />
   *     </div>
   *   );
   * } */
  if (['brickGraph', 'barChart', 'baratheon'].includes(store.params.graphType) && store.currentTower) {
    return (
      <div className={classes.root}>
        {store.params.phase === "finishing" ?
          <Desktop>
            <div>
              <Views />
            </div>
          </Desktop> : null}
        {store.params.phase === "finishing" && store.params.screen === "fullscreen" ?
          <div className={classes.listOfFloorsandActivities}>
            <span>{store.totalRows.length} Floors</span>
            <div className={classes.verticalDivider}></div>
            <span>{store.totalColumnSortedFiltered.length} Activities</span>
          </div> : null}
        {store.params.graphType === 'barChart' ?
          (<div style={{ display: 'flex', flexBasis: 'auto', flexGrow: 2, flexShrink: 1 }}><BarGraph /></div>)
          :
          (<div ref={divRef} className={store.params.screen === "fullscreen" && store.params.phase === "structures" ? classes.Structureslanding : classes.landing}>
            <Container
              width={width}
              height={height}
              IsMacOs={IsMacOs}
              screen={store.params.screen}
              phase={store.params.phase}
              loading={store.currentDashboard?.loading}
              label={"Floors"}
            />
          </div>)
        }
        <div className={classes.fix}></div>
        <Summary {...store.summary.props} />
      </div>
    )
  }
  return null;
};

export default observer(FullScreenTowersPage);
