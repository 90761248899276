import React, { memo, useCallback, useMemo } from "react";
import { MenuItem, Checkbox, ListItemText } from "@material-ui/core";
import { getStatusName } from '../../utils/utils';
import clsx from "clsx";
import useStyles from "./FilterStyles";

const CheckboxComponent = memo(
  ({
    value,
    id,
    handleClick,
    name,
  }: {
    value: string[];
    id: string;
    handleClick: (id: string | number) => void;
    name: string;
  }) => {

    const checked = useMemo(() => {
      return (value as Array<
        typeof value extends Array<infer T> ? T : never
      >).includes(id);
    }, [id, value]);

    const classes = useStyles({ checked });

    const handleClickCallback = useCallback(() => {
      handleClick(id);
    }, []);

    return (
      <MenuItem
        key={id}
        value={id}
        className={classes.menuItems}
        onClick={handleClickCallback}
      // style={{fontWeight : checked ? "bold" : "inherit"}}
      >
        <Checkbox
          checked={checked}
          checkedIcon={
            <span className={clsx(classes.icon, classes.checkedIcon)} />
          }
          icon={<span className={classes.icon} />}
          value={id}
        />
        <ListItemText
          style={{
            paddingLeft: "4px",
            whiteSpace: "pre-wrap",
            lineHeight: 1.25,
            textTransform: "capitalize"
          }}
          primary={name === "1_planned" || name === "3_active" || name === "8_done" ? getStatusName(name.toString()) : name}
        />
      </MenuItem>
    );
  }
);

export default CheckboxComponent;
