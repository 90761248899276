import React from 'react'
import useStyles from './TableCellStyles'
const ActivityStatusCell = (props) => {
    const {completed, in_progress, total} = props?.row?.original;
    const classes = useStyles();
    return (
        <div className={classes.root}>
            Completed: {completed} | OnGoing: {in_progress} | Not Started: {total - (completed + in_progress)}
        </div>
    )
}

export default ActivityStatusCell
