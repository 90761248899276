import React from "react";
import { useObserver } from 'mobx-react-lite';

// !do it propertly
const Logout = () => {
  localStorage.clear();
  return useObserver(() => (

    <div>you have logged out</div>

  ));
};

export default Logout;
