import React, {
  useState,
  useCallback,
  useEffect,
  memo,
  ReactNode,
  ReactElement,
} from "react";
import "./Accordion.scss";
import { Divider } from "@material-ui/core";
import { putAsync, IChan } from "csp-with-ts";
import { IUseMux, useGetState } from "../filter/UseAccordionHook";
import arrowIcon from "../../assets/images/Arrow.svg";
export interface IAccordianProps<T extends Record<string, any>, K extends keyof T> {
  children?: ReactNode;
  relay: IChan<IUseMux<T, K>>;
  toggle: IChan<boolean>;
  title: ReactElement;
  broadcast: IChan<T[K][], T[K][]>;
  sortSelected: string | null | undefined;
  numericDisplay?: boolean;
  externalDisplay?: JSX.Element;
}

/*
   ,----[ Ticking Time Bomb ]
   | Unlike the dropdown one, this immediately unmounts its children. Search bar
   | was asynchronously sending empty string to its hook channel, creating a memory
   | leak. Even after getting rid of that code, it sometimes does leak memory (check
   | console). We should link children to a transition group like the Dropdown
   | counterpart so that the bar doesn't unmount until the transition is over.
   `----
 */

function Accordion<T extends Record<string, any>, K extends keyof T>({ children, title: Title, relay, toggle: toggleCh, broadcast, numericDisplay = true, externalDisplay }: IAccordianProps<T, K>) {
  const toggle = useGetState<boolean>(toggleCh);
  const [active, setActive] = useState("");
  const [height, setHeight] = useState("0px");
  const [rotate, setRotate] = useState("accordion__icon");
  const [selected, setselected] = useState<string>("")
  const { title, viewDate } = Title.props;
  const selectState = useGetState(broadcast);
  useEffect(() => {
    setActive(active === "" ? "active" : "");
    setHeight(active === "active" ? "0px" : "400px");
    setRotate(
      active === "active" ? "accordion__icon" : "accordion__icon rotate"
    );
  }, [toggle]);

  const toggleAccordion = useCallback(
    () => {
      putAsync(relay, { type: 'toggle' });
    },
    []
  );

  useEffect(() => {
    if (numericDisplay) {
      if (selectState) {
        if (selectState.length > 0 && selectState[0] != undefined) {
          setselected(`+${selectState.length.toLocaleString('en-GB', { minimumIntegerDigits: 2 })}`);
        }
        else {
          setselected("")
        }
      } else {
        setselected("")
      }
    }
  }, [selectState, numericDisplay])

  return (
    <div className="accordion__section">
      <button className={`accordion ${active} flexBox`} onClick={toggleAccordion}>
        <div className="accordion__div">
          <div className={externalDisplay ? "snags_accordion__title" : "accordion__title"} >{Title}
            {
              externalDisplay ?
                externalDisplay :
                numericDisplay ?
                  (selected ? <div className="TextFont">({selected})</div> : null)
                  :
                  <span>{selectState}</span>
            }
          </div>
          <img
            src={arrowIcon}
            alt="Arrow"
            className={`${rotate}`}
            id="arrow_icon"
          ></img>
        </div>
        {(title.toLowerCase() === "completion over") && <div className={"from_date"}>{viewDate}</div>}
      </button>
      {active && <Divider style={{ height: "2px", margin: "0 5%", marginTop: "-2px" }} />}
      <div
        style={{ maxHeight: `${height}` }}
        className="accordion__content"
      >
        {toggle && children}
      </div>
    </div>
  );
}

export default memo(Accordion);
