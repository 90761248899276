import React, { PropsWithChildren } from "react";
import { Typography } from "@material-ui/core";
import commonStyles from '../ReportsStyles'


function TowerReports({ children }: PropsWithChildren<{}>) {
  const commonClasses = commonStyles();
  return (
    <div>
      <Typography variant={"h5"} className={commonClasses.heading12px}>{"detailed reports by block"}</Typography>
      <Typography className={commonClasses.normalText12px}>
        Graphical & tabular reports for <b>a block</b> with details on status
        updates / delays, work completion etc. with options to drill down to a
        specific activity or unit.
      </Typography>
      <div>{children}</div>
    </div>
  );
}
export default React.memo(TowerReports);
