import { type } from 'os';
import React from 'react'

import useStyles from "./menuListStyles";

export const MenuListModal = (props?) => {

    const { items, activeItem, onItemClicked } = props;
    const classes = useStyles();

    const isActive = (item) => {
        return activeItem === item.key;
    }

    const getItemList = (items) => {

        if (!items || items.length < 1) return null;

        return (
            items.map((item, index) => {
                const listStyles = [classes.listItems];

                if (isActive(item)) listStyles.push(classes.active);
                
                return (
                    <li
                        className={listStyles.join(" ")}
                        key={index}
                        onClick={() => onItemClicked({phase: item, activeItem: item.key})}
                    >   
                        <div>{item.label}</div>
                        { isActive(item) && (<div className={classes.activeIndicator}></div>)}
                    </li>
                )
            })
        )
    }

    return (
        <div className={classes.container}>
            <ul style={{ paddingLeft: "0px" }}>
                {getItemList(items)}
            </ul>
        </div>
    )
}