import { makeStyles, createStyles } from "@material-ui/core/styles";
import { extendStyles } from "../../utils/utils";

const styledObj = {
    modal: {
        height: "100%",
        width: "100%",
        top: 0,
        left: 0
    },
    modalOverlay: {
        height: "100%",
        width: "100%",
        backgroundColor: "rgb(47 62 108 / 85%)",
        left: "0px",
        top: "0px",
        zIndex: 1001,
        transform: "translate3d(0, 0, 1001px)", // Handled for iphone (Safari, Moz)
        overflow: "scroll"
    },
    contentCenter: {
        display: "flex",
        justifyContent: "center",
        // alignItems: "center" 
    },
    modalContent: {
        zIndex: 1000,
        transform: "translate3d(0, 0, 1000px)", // Handled for iphone (Safari, Moz)
        borderRadius: "2px",
        boxShadow: "0 3px 16px 0 rgba(0, 0, 0, 0.26)"
    },
};

styledObj["modalBody"] = extendStyles(styledObj.modalContent, {
    backgroundColor: "white",
    padding: "15px",
    transform: "translate3d(0, 0, 1001px)", // Handled for iphone (Safari, Moz)
    zIndex: 1001
});

const useStyles = makeStyles(() => createStyles(styledObj));

export default useStyles;