import React, { memo } from 'react';
import { useObserver } from "mobx-react-lite";
import SkipNextIcon from '@material-ui/icons/SkipNext';
import useStyles from "./FatTableStyle";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { MenuItem, Select } from '@material-ui/core';
import prevDisabled from "../../assets/images/pagination/prevDisabled.svg";
import prevPage from "../../assets/images/pagination/prevPage.svg";
import firstPage from "../../assets/images/pagination/firstPage.svg";
import firstPageDisabled from "../../assets/images/pagination/firstPageDisabled.svg";
import { useStore } from "../../models/ProvideModel";

export interface IFTablePaginate {
  totalCount: number;
  page: number;
  setPage: (val?: number) => void;
  size: number;
  setSize: (val?: number) => void;
};

const __OPTS = [10, 20, 30, 40, 50, 100];

function noOfPages(totalCount, size) {
  var pagenumber: any = [];
  var counter: number = 1
  for (counter; counter <= (Math.ceil(totalCount / size)); counter++) {
    pagenumber.push((counter).toLocaleString('en-GB', { minimumIntegerDigits: 2 }))
  }
  return pagenumber
}
function Paginate({ totalCount, page, size, setPage, setSize }: IFTablePaginate) {
  const store = useStore();
  const classes = useStyles({ totalCount, section: store.params.section });

  return useObserver(() => (
    <div className={classes[store.responsiveUtils.getClassNameBasedOnViewport('mainDiv')]}>
      <div className={classes.secondDiv}>
        <div className={classes.rectanglediv}
          onClick={page === 0 ? () => { } : () => { setPage(0); }}
        >
          <img src={page === 0 ? firstPageDisabled : firstPage} alt="first_page" />
        </div>
        <div className={classes.rectanglediv}
          onClick={page === 0 ? () => { } : () => { if (page > 0) { setPage!(page - 1); } }}
        >
          <img src={page === 0 ? prevDisabled : prevPage} alt="previous_page" />
        </div>
        <Select style={{ border: "solid 1px #d1d1d1" }}
          value={(page).toString() === "NaN" ? "" : (page + 1).toLocaleString('en-GB', { minimumIntegerDigits: 2 })}
          onChange={e => {
            setPage(Number(e.target.value) - 1);
          }}
          className={classes.pageField}
          MenuProps={{ style: { width: "160px", maxHeight: "430px" } }}
        >
          <MenuItem disabled className={classes.selectStatus}>
            <span>page Numbers</span>
          </MenuItem>
          {noOfPages(totalCount, size).map((key) =>
            <MenuItem key={key} value={key} className={classes.selectedItem}> {key}</MenuItem>
          )
          }
        </Select>
        <div className={classes.rectanglediv}
          onClick={(page + 1) * size >= totalCount ? () => { } : () => { if ((page + 1) < Math.ceil(totalCount / size)) { setPage!(page + 1); } }}
        >
          {(page + 1) * size >= totalCount ?
            <PlayArrowIcon rx={2} className={classes.invisibleButton} style={{ width: 15 }} />
            :
            <PlayArrowIcon rx={2} className={classes.visibleButton} style={{ width: 15 }} />
          }
        </div>
        <div className={classes.rectanglediv}
          onClick={page === Math.ceil(totalCount / size) - 1 ? () => { } : () => { setPage(Math.ceil(totalCount / size) - 1); }}
        >
          {page === Math.ceil(totalCount / size) - 1 || totalCount === 0 ?
            <SkipNextIcon rx={2} className={classes.invisibleButton} style={{ width: 20 }} />
            :
            <SkipNextIcon rx={2} className={classes.visibleButton} style={{ width: 20 }} />
          }
        </div>
      </div>
      <div className={classes.divider}></div>
      {totalCount === 0 ? null : <>
        <div style={{ display: "flex", alignSelf: "center" }}>
          <Select style={{ border: "solid 1px #d1d1d1" }}
            value={size}
            onChange={e => {
              setSize(Number(e.target.value));
              setPage();
            }}
            className={classes.inputField}
            MenuProps={{ style: { width: "160px" } }}
          >
            <MenuItem disabled className={classes.selectStatus}>
              <span>Number of the page</span>
            </MenuItem>
            {__OPTS.map(pageSize =>
              <MenuItem key={pageSize} value={pageSize} className={classes.selectedItem}> {pageSize}</MenuItem>
            )
            }
          </Select>
          <span className={classes.ItemsPerPage}>Items per page</span>
        </div>
        <div className={classes.divider}></div></>}
      <div className={classes.NumberOfItems}>
        <p className={classes.totalNumberOfItems}>{totalCount === 0 ? <>{totalCount} &mdash; {totalCount}</> : <> {page * size + 1} &mdash; {Math.min(totalCount, (page + 1) * size)} </>} of {totalCount} items</p>
      </div>
    </div>
  ));
}

export default memo(Paginate);
