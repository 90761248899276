import React, { memo } from "react";
import { useHistory } from 'react-router';
import { useStore } from "../../models/ProvideModel";
import { useObserver } from "mobx-react-lite";
import useStyles from './PowerBIPageStyles';
import { Grid } from '@material-ui/core';

const PowerBIPage = () => {
  const store = useStore();
  const classes = useStyles();
  const history = useHistory();

  React.useEffect(() => {
    //Get the dashboard items for the user..
    store.userReportDetails.getUserReportDetails()
  }, [])

  return useObserver(() =>
    <div className={classes.root}>
      <Grid container>
        {!!store.userReportDetails.reportDetails ?
          store.userReportDetails.reportDetails.map((report, index) => {
            // console.log(report)
            return (
              <Grid item xs={12} style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", margin: "auto" }} key={index}>
                <button key={index} 
                  onClick={() => history.push(`/powerbi/${report.embedConfig.group_id}/${report.embedConfig.report_id}`)}
                  className={classes.apply} style={{marginBottom: 10}}> {report.dashboardName} </button>
              </Grid>
            )
          })
          :
          <Grid item xs={12} style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", margin: "auto" }}>
          <p style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", margin: "auto" }}>
          No Reports found for the user!</p>
          </Grid>
        }
      </Grid>
    </div>
  );
};

export default memo(PowerBIPage);
