import React from 'react'
import { useObserver } from 'mobx-react-lite'
import useStyles from "./Common";
import eerorImg from '../../assets/images/brwsrSupprt/errorImage.png';
import { useStore } from '../../models/ProvideModel';

export interface INoDataFoundProps<T extends object = {}> {
    blockCount?: number
  };

function NoDataFound(props?: INoDataFoundProps) {
    const store = useStore()
    const { fullScreenMode } = store.responsiveUtils;
    const classes = useStyles({ fullScreenMode });
    const dots: any = [".", ".", ".", "."]
    const mobilefullScreenMode = (fullScreenMode) => {
        return classes.rootsm
    }
    return useObserver(() => (
        <div className={store.params.graphType === "table" ? store.responsiveUtils.currentViewport.isLg ? classes.root : mobilefullScreenMode(store.responsiveUtils.fullScreenMode) : classes.Brickroot} >
            <div className={classes.mainDiv}>
                <img className={classes.imgStyle} src={eerorImg} height={store.responsiveUtils.currentViewport.isLg ? "" : 95} width={store.responsiveUtils.currentViewport.isLg ? "" : 74}/>
                { store.params.section === "snags" || store.params.section === "tasks" ? <>
                    <span className={store.responsiveUtils.currentViewport.isLg ? classes.snagsfontSize : classes.snagsfontSizeSm}>
                        0 (zero) Results found for the filters selected, Please check the filters on the top of the table to modify the selection.
                    </span>
                    {dots.map((key, i) => {
                        return <span key={i} style={{ display: "flex", justifyContent: "center", fontSize: store.responsiveUtils.currentViewport.isLg ? "18px" : "12px" }}>{key}</span>
                    })}
                    <span className={store.responsiveUtils.currentViewport.isLg ? classes.textSize : classes.textSizeSm}>
                        Please note the 'Block' filter has been applied, <span style={{fontWeight: "bold", fontSize:"15px"}}>{props?.blockCount}</span> number of blocks have been selected
                    </span>
                </>
                    : <><span className={store.responsiveUtils.currentViewport.isLg ? classes.fontSize : classes.fontSizeSm}>Nothing to see here</span>
                        <span className={store.responsiveUtils.currentViewport.isLg ? classes.textSize : classes.textSizeSm}>How do construction workers party?</span>
                        {dots.map((key, i) => {
                            return <span key={i} style={{ display: "flex", justifyContent: "center", fontSize: store.responsiveUtils.currentViewport.isLg ? "18px" : "12px" }}>{key}</span>
                        })}
                        <span className={store.responsiveUtils.currentViewport.isLg ? classes.textSize : classes.textSizeSm}>They raise the roof</span>
                    </>}
            </div>
        </div>
    ));
}

export default React.memo(NoDataFound)