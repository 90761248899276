import { createActionTrackingMiddleware2, isActionContextThisOrChildOf, IActionTrackingMiddleware2Call, Instance, ISerializedActionCall, applyAction, getRelativePath, IMiddlewareHandler, IPatchRecorder, recordPatches, applyPatch, IMiddlewareEvent, getType } from 'mobx-state-tree';
import { Filters } from './Common';

//middleware for coordinating loading states for the centralized fetch command
// handleLoading middleware's applyPatch is causing reactions related to changes
// via async actions to not run
export const handleLoading: IMiddlewareHandler = (call, next, _) => {
    if (call.name === 'fetchQuery') {
        const { type, parentEvent } = call;
        const callingModel = getType((parentEvent as IMiddlewareEvent).parentEvent!.context);
        const loading = ((parentEvent as IMiddlewareEvent).parentEvent!.context as Instance<typeof callingModel> & { loading: any }).loading;
        switch (type) {
            case 'flow_spawn': if (!loading) {
                applyPatch(parentEvent!.parentEvent!.context, { op: 'replace', path: '/loading', value: true });
            }
                break;
            // Initial implementation that sets loading to false once client returns
            // result. Still not sure which one is better.

            // case 'flow_resume':
            //     if (parentEvent?.parentEvent?.args.every(a => a === undefined)) {
            //   if (loading) {
            //     applyPatch(parentEvent!.parentEvent!.context, { op: 'replace', path: '/loading', value: false });
            //   }
            // }
            //     break;
            case 'flow_return':
                if (loading) {
                    applyPatch(parentEvent!.parentEvent!.context, { op: 'replace', path: '/loading', value: false });
                }
                break;
            case 'flow_resume_error':
                if (loading) {
                    applyPatch(parentEvent!.parentEvent!.context, { op: 'replace', path: '/loading', value: false });
                }
                throw new Error(`[mst] Error when using graphql query on mst node: /${getRelativePath(call.tree, parentEvent!.parentEvent!.context)}\nMessage: ${call.args}`);
            // For error handling. Useful in the futre for weird queries.
            //case 'flow_resume_error':
            default:
        }
    }
    next(call);
};

//middleware for communicating changes in filters efficiently

export const coordinateFilters: IMiddlewareHandler = createActionTrackingMiddleware2<{ patches: IPatchRecorder }>({
    filter(call) {
        // only call the methods above for actions that were not being recorded,
        // but do not call them for child acions (which inherit a copy of the env)
        if (call.env) {
            // already recording
            return false;
        }

        return true;
    },
    onStart(call: IActionTrackingMiddleware2Call<{ patches: IPatchRecorder }>) {
        const patches = recordPatches(call.tree, (_patch, _inversePatch, actionContext) => {
            return !!actionContext && isActionContextThisOrChildOf(actionContext, call.id);
        });
        patches.resume();
        const { name, args } = call;
        const context = call.context as Instance<typeof Filters>;
        call.env = { patches };
        const diff = new Map<string, ISerializedActionCall[]>([['add', []], ['remove', []]]);
        const internalArgs = args[0] || [];
        switch (name) {
            case 'setStatus': (diff.get('add') as ISerializedActionCall[])
                .push({ name: 'pushStatus', path: '', args: [internalArgs.filter(arg => !context.hasStatus(arg))] });
                (diff.get('remove') as ISerializedActionCall[])
                    .push(...context.status.reduce((acc, st) => (
                        !internalArgs.includes(st) ? [...acc, { name: 'popStatus', args: [st], path: '' }]
                            : acc
                    ), []));
                break;
            default:
        };
        if (name === 'setStatus') {
            if ((diff.get('add') as ISerializedActionCall[]).filter((op: ISerializedActionCall) => op.args![0].length > 0).length > 0)
                applyAction(context, [...diff.get('add')!, ...diff.get('remove')!]);
            else if ((diff.get('remove') as ISerializedActionCall[]).length > 0) {
                applyAction(context, [...diff.get('remove')!]);
            }
        }
    },
    onFinish(call, err) {
        const recorder = call.env!.patches;
        call.env = undefined;
        recorder.stop();
        if (err !== undefined) {
            recorder.undo();
        }
    }
});
