import { useObserver } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import { DateRangePicker } from 'react-date-range';
import "../../assets/css/calendar.css";
import { useStore } from "../../models/ProvideModel";
import { useModal, Modal, ModalBody, ModalContent, ModalOverlay } from "../modal";
import useStyles from "./CalenderStyle";
import { useHistory, useLocation } from "react-router";
import { extendMoment, MomentRange } from "moment-range";
import * as originalMoment from 'moment';
import './calender.scss'
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
type IMoment = typeof originalMoment;
const moment: MomentRange & IMoment = extendMoment(originalMoment);


const Calendar = () => {
  const [filterModalState, setFilterModalState] = React.useState(false);
  const classes = useStyles();
  const store = useStore();
  const { isOpen } = useModal({ isOpen: filterModalState, closeOnOverlayClick: true });
  const location = useLocation();
  const { search } = useMemo(() => location, [
    location.search,
    location.pathname,
  ]);
  const searchParam = useMemo(() => new URLSearchParams(search), [search]);
  const { push } = useHistory();

  const [state, setState] = useState([{
    startDate: searchParam.getAll(store.filters.calenderOpen)[1] ? new Date(searchParam.getAll(store.filters.calenderOpen)[1]) : new Date(),
    endDate: searchParam.getAll(store.filters.calenderOpen)[0] ? new Date(searchParam.getAll(store.filters.calenderOpen)[0]) : new Date(),
    key: 'selection'
  }]);

  useEffect(() => {
    if (store.filters.calenderOpen != null) {
      setFilterModalState(!filterModalState);
    }
    setState([{
      startDate: searchParam.getAll(store.filters.calenderOpen)[1] ? new Date(searchParam.getAll(store.filters.calenderOpen)[1]) : new Date(),
      endDate: searchParam.getAll(store.filters.calenderOpen)[0] ? new Date(searchParam.getAll(store.filters.calenderOpen)[0]) : new Date(),
      key: 'selection'
    }])
  }, []);

  const onFilterModalClose = () => {
    setFilterModalState(!filterModalState);
  }

  const closeModel = () => {
    store.filters.setCalenderOpen(false)
    setFilterModalState(false);
  }

  const OnClickOk = () => {
    const id = store.filters.calenderOpen
    searchParam.delete(id);
    searchParam.append(id, moment(state[0].endDate).format("D MMM YYYY"));
    searchParam.append(id, moment(state[0].startDate).format("D MMM YYYY"));
    store.filters.setCalenderOpen(false)
    push({ ...location, search: searchParam.toString() });
  }

  const onState = (item) => {
    setState([item.selection])
  };

  useEffect(() => () => {
    store.filters.setCalenderOpen(false)
  }, [])

  return useObserver(() => (
    <Modal
      isOpen={isOpen}
      isCentered={false}
      onClose={onFilterModalClose}
      // closeOnOverlayClick={true}
      position={{ top: "22%", left: "29%" }}
    >
      <ModalOverlay>
        <div className={classes.mainDiv}>
          <ModalContent>
            <ModalBody style={{ padding: "15px 5px", marginTop: "12px" }}>
              <>
                <DateRangePicker
                  onChange={onState}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={1}
                  ranges={state}
                  direction="horizontal"
                />
                <div style={{ display: "flex", justifyContent: "space-around" }}>
                  <div className={classes.backButton} onClick={closeModel}>CANCEL</div>
                  <div className={classes.backButton} onClick={OnClickOk}>OK</div>
                </div>
              </>
            </ModalBody>
          </ModalContent>
        </div>
      </ModalOverlay>
    </Modal>
  ));
}

export default Calendar;
