import { types, getRoot, unprotect, protect, IAnyModelType, Instance, IAnyStateTreeNode, IMaybe, IArrayType, IReferenceType, ISimpleType, IType, _NotCustomized, IMaybeNull, IModelType, ITypeUnion, IMapType, IOptionalIType, ValidOptionalValues } from 'mobx-state-tree';
import { ComponentProps, MemoExoticComponent } from 'react';
import { LateStoreModel } from './DataStore';
import { getIdFromParam, Pages } from './enums';
import BS from "../components/filter/ByStatus";
import BT from "../components/filter/ByTower";
import BTS from "../components/filter/ByTowerSnags";
import BA from "../components/filter/ByActivities";
import TN from '../components/tileNumeral/Dropdown';
import SF from "../components/sort/SortFilter";
// import VW from "../components/viewPanel/View";
import BG from "../components/buttonGroup/ButtonGroup"
import PO from "../components/ProgressOver";
import BF from "../components/filter/ByFilter";
import TS from "../components/filter/sort";
import EP from "../components/downloadPDF";
import EE from "../components/exportExcel"
import ST from "../components/filter/SpaceType";
import PT from "../components/filter/PhaseType";
import { sortingStrategyName } from '../utils/utils';
import FL from "../containers/footer/LandingFooter";
import FB from "../containers/footer/BarGraphFooter";
import FS from "../containers/footer/StructuresFooter"
import DR from "../components/downloadReports";
import RQ from "../components/ReportsQ/ReportsQ";
import { IChecklistReportsDownload } from './MultiSelect';
import { IDownload } from './Download';



// Store the models that will be unioned
let unionModels: { [K: string]: ReturnType<typeof InfoPanelModule>; } = {};
// Helper type for above
export type IUnionModels = typeof union extends Array<infer T> ? T extends IModelType<infer P, infer O, infer C, infer S> ? ITypeUnion<_NotCustomized, _NotCustomized, IModelType<P, O, C, S>> : never : never;

// Important interfaces
export type IComponent = MemoExoticComponent<(props: Record<string, any>) => JSX.Element>;

export interface IMeta<T extends IAnyModelType, V extends ComponentProps<any>> extends Function {
    (self: Instance<T>): V
};

// Code meant to be consumed internally
// checker function that should evaluate to true for every paramsToId value
const __id_checker = (val: string | number): val is Pages => {
    switch (val) {
        case Pages.DASHBOARD_STRUCTURES_TOWER_LANDING:
        case Pages.DASHBOARD_STRUCTURES_TOWER_FULLSCREEN:
        case Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN:
        case Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN_BARCHART:
        case Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN_SM:
        case Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN_BARCHART_SM:
        case Pages.DASHBOARD_FINISHING_TOWER_LANDING:
        case Pages.DASHBOARD_FINISHING_TCA_LANDING:
        case Pages.DASHBOARD_FINISHING_TCA_LANDING_BARATHEON:
        case Pages.DASHBOARD_FINISHING_TCA_FULLSCREEN:
        case Pages.DASHBOARD_FINISHING_TCA_FULLSCREEN_BARATHEON:
        case Pages.SNAGS_TABLE:
        case Pages.SNAGS_TABLE_FULLSCREEN:
        case Pages.UNIT_ACT_INFO_TABLE:
        case Pages.LEGACY_FINISHING_TOWER_FULLSCREEN_TABLE:
        case Pages.CHECKLIST_REPORTS_TABLE:
        case Pages.FORM_DATA_TABLE:
        case Pages.LEGACY_TABLE: return true;
        default: return false;
    }
}

// metadata about Status filter
const __statusView = [
    { id: 'completed', name: 'Completed' },
    { id: 'started', name: 'Ongoing' },
    { id: 'notStarted', name: 'Not Started' },
    { id: 'delayed', name: 'Delayed' }
];


// Generic InfoPanel Component modules

// Placeholder for any InfoPanel component
const InfoPanelPiece = types.model<{ component: IType<IComponent, IComponent, IComponent>; }>({
    component: types.frozen<IComponent>(),
});

const __BaseInfo: IModelType<{ component: IType<IComponent, IComponent, IComponent>; field: ISimpleType<string>; }, {}, _NotCustomized, _NotCustomized> = types.compose<{ component: IType<IComponent, IComponent, IComponent>; }, {}, _NotCustomized, _NotCustomized, { field: ISimpleType<string>; }, {}, _NotCustomized, _NotCustomized>("BaseInfoPanelModel", InfoPanelPiece, types.model({ field: types.identifier }));

const __Info: typeof __BaseInfo extends IModelType<infer P, infer O, infer C, infer S> ? IModelType<Pick<P & { props: IMaybeNull<IType<ComponentProps<any>, ComponentProps<any>, ComponentProps<any>>>; }, keyof P | 'props'>, O, C, S> : never = types.compose<{ component: IType<IComponent, IComponent, IComponent>; field: ISimpleType<string>; }, {}, _NotCustomized, _NotCustomized, { props: IMaybeNull<IType<ComponentProps<any>, ComponentProps<any>, ComponentProps<any>>>; }, {}, _NotCustomized, _NotCustomized>("GenericInfoPanelModel", __BaseInfo, types.model<{ props: IMaybeNull<IType<ComponentProps<any>, ComponentProps<any>, ComponentProps<any>>>; }>({ props: types.maybeNull(types.frozen<ComponentProps<any>>()) }));

function InfoPanelModule<V>(meta?: IMeta<IAnyModelType, V>): typeof __Info extends IModelType<infer P, infer O, infer C, infer S> ? IModelType<P, O, C, S> | IModelType<Pick<P & { type: IType<string | null | undefined, string, string> }, Exclude<keyof P | 'type', 'props'>>, { readonly props: ComponentProps<any> }, _NotCustomized, _NotCustomized> : never {
    if (meta) {
        const model = types.compose(`InfoWith${meta.name}`, __BaseInfo, types.model({ type: types.frozen<string>(meta.name) })).views(self => ({ get props(): V { return meta!.apply(null, [getRoot(self) as Instance<typeof LateStoreModel>]); } }));
        unionModels[meta.name] = model;
        return model;
    }
    return __Info;
}



// interim models

// const FilterByTower = types.model('ByTower', {}).views(self => ({
//     get towers() {


//         return getRoot(self).towers.map(({ id, name }) => ({ id, name }));
//     },
//     get meta() {
//         return self;
//     }
// }));


const FilterByTowerForSnags: IMeta<typeof LateStoreModel, { readonly data: { id: string, name: string }[] }> = (self) => ({
    data: (self.menus.blocks || []).map(({ id, name }: { id: string; name: string; }) => ({ id, name })).sort(sortingStrategyName)
});

const FilterByPhase: IMeta<typeof LateStoreModel, { readonly data: { id: string, name: string }[]; readonly phase: string; readonly spaceType: string; section: string; }> = (self) => ({
    data: self.params.phasesWithNames.map(({ id, name }: { id: string; name: string; }) => ({ id, name })),
    phase: self.params.phase,
    spaceType: self.params.spaceType,
    section: self.params.section
});
const FilterByTower: IMeta<IAnyModelType, { data: { id: string, name: string }[] }> = self => { return self.params.section === 'dashboard' ? { data: self.currentDashboard?.towers || [] } : { data: self.towers }; };

const FilterBySpaceType: IMeta<typeof LateStoreModel, { readonly data: { id: string, name: string }[]; readonly phase: string; readonly spaceType: string; section: string; }> = (self) => {
    return ({
        data: (self.params.spaceTypes).map(({ id, name }: { id: string; name: string; }) => ({ id, name })),
        phase: self.params.phase,
        spaceType: self.params.spaceType,
        section: self.params.section
    })
};

const FilterByActivity: IMeta<typeof LateStoreModel, { readonly data: { id: number, name: string }[]; readonly meta: number[]; stages: { stage: string; order: number; length: number; index: number }[] }> = self => {
    if (self.currentDashboard?.graph?.columns?.length) {
        const dash = self.currentDashboard?.graph;
        if (!dash) { return { data: [], meta: [], stages: [] }; }
        return { data: dash?.columnSorted.map(({ name }, id: number) => ({ id, name })), meta: dash.indicesCol, stages: dash.stages };
        // return { data: Array.from(source?.columnSorted, ({ name }: { name: string; }, precedence: number): { id: number; name: string } => ({ id: precedence, name })), meta: source?.indicesCol, stages: source?.stages };
    }
    else { return { data: [], meta: [], stages: [] }; }
};

const DownloadTehReports: IMeta<typeof LateStoreModel, { readonly disabled: boolean; download: () => Promise<any>; }> = self => ({
    disabled: self.checklistMultiSelect.disabled,
    download: self.checklistMultiSelect.download
});

const DownloadQ: IMeta<typeof LateStoreModel, { readonly data: IChecklistReportsDownload[]; poll(): void; clear(): void; }> = self => ({
    data: Array.from(self.checklistMultiSelect.downloadQ),
    poll: self.checklistMultiSelect.pollStatuses,
    clear: self.checklistMultiSelect.cleanQ
});

const PdfDownloadQ: IMeta<typeof LateStoreModel, { readonly data: IDownload[]; poll(): void; clear(): void; }> = self => ({
    data: Array.from(self.download.downloadQ),
    poll: self.download.pollStatuses,
    clear: self.download.cleanQ
});



// Initialize frozen values for the FilterByStatus map
// __statusView.forEach(([id, name]) => { FilterByStatus.put.call(FilterByStatus, { id, name }); });

// const ByTower = InfoPanelModule.create({
//     field: 'tower',
//     component: BT,
//     // meta: FilterByTower
// }, FilterByTower);

const ByTowerPanel = InfoPanelModule<{ readonly data: { id: string, name: string }[] }>(FilterByTower);

const ByTowerSnagsPanel = InfoPanelModule<{ readonly data: { id: string, name: string }[] }>(FilterByTowerForSnags);
const ByPhasePanel = InfoPanelModule<{ readonly data: { id: string, name: string }[]; readonly phase: string; readonly spaceType: string; }>(FilterByPhase);
const BySpaceTypePanel = InfoPanelModule<{ readonly data: { id: string, name: string }[]; readonly phase: string; readonly spaceType: string; }>(FilterBySpaceType);
const ByActivityPanel = InfoPanelModule<{ readonly data: { id: number, name: string }[]; readonly meta: number[]; }>(FilterByActivity)


const DownloadReportsPanel = InfoPanelModule<{ readonly disabled: boolean; readonly download: () => Promise<any>; }>(DownloadTehReports)

const DownloadList = InfoPanelModule<{ readonly data: IChecklistReportsDownload[]; poll(): void; clear(): void; }>(DownloadQ);
const PdfDownloadList = InfoPanelModule<{ readonly data: IDownload[]; poll(): void; clear(): void; }>(PdfDownloadQ);
// const ByStatus = SmartInfoPanelModule.create().set('structures-landing', {
//     id: 'structures-landing',
//     module: cast()
// });

// Holds dispatch logic, and helper for the union types.
const dispatcher = sn => sn.type ? unionModels[sn.type] : sn.props ? __Info : __Info;
let union = Array.from(Object.keys(unionModels), k => unionModels[k]);
union.push(__Info);
const AbstractInfoPanel: IUnionModels = types.union({ dispatcher }, ...union);
// Smart Info Panel module
const __SmartInfoPanelModule = types.model<{
    id: ISimpleType<string>;
    partition: ISimpleType<number>;
    module: IMaybe<IReferenceType<typeof AbstractInfoPanel>>;
}>("SmartInfoPanelRegistry", {
    id: types.refinement(types.identifier, val => __id_checker(val)),
    partition: types.refinement(types.number, val => Number.isInteger(val)),
    module: types.safeReference(types.late(() => AbstractInfoPanel))
});
const SmartInfoPanelModule: IMapType<typeof __SmartInfoPanelModule> = types.map(__SmartInfoPanelModule);


// initialize function for setting frozen values for the components Dropdown
function createPanels(this: Instance<typeof InfoPanel>): void {
    const Display = InfoPanelModule<{}>().create({
        field: 'tile',
        component: TN
    });
    const ByStatus = InfoPanelModule<{}>().create({
        field: 'status',
        component: BS,
        props: { data: __statusView }
    });
    const YSort = InfoPanelModule<{}>().create({
        field: 'ySort',
        component: SF
    });
    const ByActivity = ByActivityPanel.create({
        field: 'activity',
        component: BA
    });
    const ByTower = ByTowerPanel.create({
        field: 'tower',
        component: BT
    });
    const ByTowerSnags = ByTowerSnagsPanel.create({
        field: 'tower-snags',
        component: BTS
    });

    const DownloadReports = DownloadReportsPanel.create({
        field: 'reports',
        component: DR
    });
    const DownloadReportsQ = DownloadList.create({
        field: 'reportsQ',
        component: RQ
    });
    const PdfDownloadReportsQ = PdfDownloadList.create({
        field: 'pdfReportsQ',
        component: RQ
    });
    const ButtonGroup = InfoPanelModule<{}>().create({
        field: 'buttongroup',
        component: BG
    });
    // const Views = InfoPanelModule<{}>().create({
    //     field: 'views',
    //     component: VW
    // });
    const ByDelta = InfoPanelModule<{}>().create({
        field: 'delta',
        component: PO
    });
    const BySpaceType = BySpaceTypePanel.create({
        field: 'spacetype',
        component: ST,
    });
    const ByPhaseType = ByPhasePanel.create({
        field: 'phasetype',
        component: PT,
    });
    const ByFilter = InfoPanelModule<{}>().create({
        field: 'filter',
        component: BF,
    });
    const TableSort = InfoPanelModule<{}>().create({
        field: 'sort',
        component: TS,
    });
    const BarGraphFooter = InfoPanelModule<{}>().create({
        field: 'bargraph',
        component: FB,
    });
    const LandingFooter = InfoPanelModule<{}>().create({
        field: 'landinggraph',
        component: FL,
    });
    const StructuresFooter = InfoPanelModule<{}>().create({
        field: 'landingStructuregraph',
        component: FS,
    });
    const downloadPDF = InfoPanelModule<{}>().create({
        field: 'PDF',
        component: EP,
    });
    const exportExcel = InfoPanelModule<{}>().create({
        field: 'excel',
        component: EE,
    });

    const PDFPDFPage = SmartInfoPanelModule.create();
    unprotect(PDFPDFPage);
    PDFPDFPage.merge(
        [[Pages.LEGACY_FINISHING_TOWER_FULLSCREEN_TABLE, {
            id: Pages.LEGACY_FINISHING_TOWER_FULLSCREEN_TABLE,
            partition: 4,
            module: 'PDF'
        }],
        [Pages.UNIT_ACT_INFO_TABLE, {
            id: Pages.UNIT_ACT_INFO_TABLE,
            partition: 4,
            module: 'PDF'
        }],
        [Pages.SNAGS_TABLE, {
            id: Pages.SNAGS_TABLE,
            partition: 4,
            module: 'PDF'
        }],
        ]
    );
    const ExportExcelPage = SmartInfoPanelModule.create();
    unprotect(ExportExcelPage);
    ExportExcelPage.merge(
        [[Pages.LEGACY_FINISHING_TOWER_FULLSCREEN_TABLE, {
            id: Pages.LEGACY_FINISHING_TOWER_FULLSCREEN_TABLE,
            partition: 5,
            module: 'excel'
        }],
        [Pages.UNIT_ACT_INFO_TABLE, {
            id: Pages.UNIT_ACT_INFO_TABLE,
            partition: 5,
            module: 'excel'
        }],
        [Pages.SNAGS_TABLE, {
            id: Pages.SNAGS_TABLE,
            partition: 5,
            module: 'excel'
        }],
        [Pages.FORM_DATA_TABLE, {
            id: Pages.FORM_DATA_TABLE,
            partition: 5,
            module: 'excel'
        }]
        ]
    );


    const FloorSort = SmartInfoPanelModule.create();
    unprotect(FloorSort);
    FloorSort.merge([[Pages.DASHBOARD_STRUCTURES_TOWER_LANDING, {
        id: Pages.DASHBOARD_STRUCTURES_TOWER_LANDING,
        partition: 1,
        module: 'ySort'
    }],
    [Pages.DASHBOARD_STRUCTURES_TOWER_FULLSCREEN, {
        id: Pages.DASHBOARD_STRUCTURES_TOWER_FULLSCREEN,
        partition: 1,
        module: 'ySort'
    }],
    [Pages.DASHBOARD_FINISHING_TCA_LANDING, {
        id: Pages.DASHBOARD_FINISHING_TCA_LANDING,
        partition: 1,
        module: 'ySort'
    }],
    [Pages.DASHBOARD_FINISHING_TCA_FULLSCREEN, {
        id: Pages.DASHBOARD_FINISHING_TCA_FULLSCREEN,
        partition: 1,
        module: 'ySort'
    }],
    [Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN, {
        id: Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN,
        partition: 1,
        module: 'ySort'
    }],
    [Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN_SM, {
        id: Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN_SM,
        partition: 1,
        module: 'ySort'
    }]
    ]);

    const ByStatusFilter = SmartInfoPanelModule.create()
    unprotect(ByStatusFilter);
    ByStatusFilter.merge(
        [[Pages.DASHBOARD_STRUCTURES_TOWER_LANDING, {
            id: Pages.DASHBOARD_STRUCTURES_TOWER_LANDING,
            partition: 2,
            module: 'status'
            // module: ByStatus as SnapshotOrInstance<typeof ByStatus>
        }],
        [Pages.DASHBOARD_FINISHING_TCA_LANDING, {
            id: Pages.DASHBOARD_FINISHING_TCA_LANDING,
            partition: 2,
            module: 'status'
            // module: ByStatus as SnapshotOrInstance<typeof ByStatus>
        }],
        [Pages.DASHBOARD_FINISHING_TCA_LANDING_BARATHEON, {
            id: Pages.DASHBOARD_FINISHING_TCA_LANDING_BARATHEON,
            partition: 2,
            module: 'status'
            // module: ByStatus as SnapshotOrInstance<typeof ByStatus>
        }],
        [Pages.DASHBOARD_FINISHING_TCA_FULLSCREEN, {
            id: Pages.DASHBOARD_FINISHING_TCA_FULLSCREEN,
            partition: 2,
            module: 'status'
            // module: ByStatus as SnapshotOrInstance<typeof ByStatus>
        }],
        [Pages.DASHBOARD_FINISHING_TCA_FULLSCREEN_BARATHEON, {
            id: Pages.DASHBOARD_FINISHING_TCA_FULLSCREEN_BARATHEON,
            partition: 2,
            module: 'status'
            // module: ByStatus as SnapshotOrInstance<typeof ByStatus>
        }],
        [Pages.DASHBOARD_STRUCTURES_TOWER_FULLSCREEN, {
            id: Pages.DASHBOARD_STRUCTURES_TOWER_FULLSCREEN,
            partition: 2,
            module: 'status'
            // module: ByStatus as SnapshotOrInstance<typeof ByStatus>
        }],
        [Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN, {
            id: Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN,
            partition: 2,
            module: 'status'
            // module: ByStatus as SnapshotOrInstance<typeof ByStatus>
        }],
        [Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN_BARCHART, {
            id: Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN_BARCHART,
            partition: 2,
            module: 'status'
            // module: ByStatus as SnapshotOrInstance<typeof ByStatus>
        }],
        [Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN_SM, {
            id: Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN_SM,
            partition: 2,
            module: 'status'
            // module: ByStatus as SnapshotOrInstance<typeof ByStatus>
        }],
        [Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN_BARCHART_SM, {
            id: Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN_BARCHART_SM,
            partition: 2,
            module: 'status'
            // module: ByStatus as SnapshotOrInstance<typeof ByStatus>
        }],
        [Pages.DASHBOARD_FINISHING_TOWER_LANDING, {
            id: Pages.DASHBOARD_FINISHING_TOWER_LANDING,
            partition: 2,
            module: 'status'
            // module: ByStatus as SnapshotOrInstance<typeof ByStatus>
        }]
        ]
    );

    const ByTowerFilter = SmartInfoPanelModule.create();
    unprotect(ByTowerFilter);
    ByTowerFilter.merge(
        [[Pages.DASHBOARD_STRUCTURES_TOWER_LANDING, {
            id: Pages.DASHBOARD_STRUCTURES_TOWER_LANDING,
            partition: 2,
            module: 'tower'
            // module: ByTower as SnapshotOrInstance<typeof ByTower>
        }],
        [Pages.DASHBOARD_FINISHING_TCA_LANDING, {
            id: Pages.DASHBOARD_FINISHING_TCA_LANDING,
            partition: 2,
            module: 'tower'
        }],
        [Pages.DASHBOARD_FINISHING_TCA_LANDING_BARATHEON, {
            id: Pages.DASHBOARD_FINISHING_TCA_LANDING_BARATHEON,
            partition: 2,
            module: 'tower'
        }],
        [Pages.DASHBOARD_FINISHING_TOWER_LANDING, {
            id: Pages.DASHBOARD_FINISHING_TOWER_LANDING,
            partition: 2,
            // module: ByTower as SnapshotOrInstance<typeof ByTower>
            module: 'tower'
        }]
        ]
    );

    const ByTowerSnagsFilter = SmartInfoPanelModule.create();
    unprotect(ByTowerSnagsFilter);
    ByTowerSnagsFilter.merge([[Pages.SNAGS_TABLE, {
        id: Pages.SNAGS_TABLE,
        partition: 0,
        module: 'tower-snags'
    }]
    ]);

    const ByActivityFilter = SmartInfoPanelModule.create();
    unprotect(ByActivityFilter);
    ByActivityFilter.merge(
        [[Pages.DASHBOARD_FINISHING_TOWER_LANDING, {
            id: Pages.DASHBOARD_FINISHING_TOWER_LANDING,
            partition: 2,
            module: 'activity'
        }],
        [Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN, {
            id: Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN,
            partition: 2,
            module: 'activity'
        }],
        [Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN_SM, {
            id: Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN_SM,
            partition: 2,
            module: 'activity'
        }],
        [Pages.DASHBOARD_FINISHING_TCA_FULLSCREEN, {
            id: Pages.DASHBOARD_FINISHING_TCA_FULLSCREEN,
            partition: 2,
            module: 'activity'
        }],
        [Pages.DASHBOARD_FINISHING_TCA_FULLSCREEN_BARATHEON, {
            id: Pages.DASHBOARD_FINISHING_TCA_FULLSCREEN_BARATHEON,
            partition: 2,
            module: 'activity'
        }],
        [Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN_BARCHART, {
            id: Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN_BARCHART,
            partition: 2,
            module: 'activity'
        }],
        [Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN_BARCHART_SM, {
            id: Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN_BARCHART_SM,
            partition: 2,
            module: 'activity'
        }],
        ]
    );

    const ByTableFilter = SmartInfoPanelModule.create();
    unprotect(ByTableFilter);
    ByTableFilter.merge(
        [[Pages.LEGACY_TABLE, {
            id: Pages.LEGACY_TABLE,
            partition: 2,
            module: 'filter'
        }],
        [Pages.SNAGS_TABLE, {
            id: Pages.SNAGS_TABLE,
            partition: 2,
            module: 'filter'
        }],
        [Pages.SNAGS_TABLE_FULLSCREEN, {
            id: Pages.SNAGS_TABLE_FULLSCREEN,
            partition: 2,
            module: 'filter'
        }],
        [Pages.UNIT_ACT_INFO_TABLE, {
            id: Pages.UNIT_ACT_INFO_TABLE,
            partition: 2,
            module: 'filter'
        }],
        [Pages.LEGACY_FINISHING_TOWER_FULLSCREEN_TABLE, {
            id: Pages.LEGACY_FINISHING_TOWER_FULLSCREEN_TABLE,
            partition: 2,
            module: 'filter'
        }],
        [Pages.CHECKLIST_REPORTS_TABLE, {
            id: Pages.CHECKLIST_REPORTS_TABLE,
            partition: 2,
            module: 'filter'
        }],
        [Pages.FORM_DATA_TABLE, {
            id: Pages.FORM_DATA_TABLE,
            partition: 2,
            module: 'filter'
        }]
        ]
    );

    const BySpaceTypeFilter = SmartInfoPanelModule.create();
    unprotect(BySpaceTypeFilter);
    BySpaceTypeFilter.merge(
        [[Pages.SNAGS_TABLE, {
            id: Pages.SNAGS_TABLE,
            partition: 0,
            module: 'spacetype'
        }]
        ]
    );

    const ByPhaseTypeFilter = SmartInfoPanelModule.create();
    unprotect(ByPhaseTypeFilter);
    ByPhaseTypeFilter.merge(
        [[Pages.SNAGS_TABLE, {
            id: Pages.SNAGS_TABLE,
            partition: 0,
            module: 'phasetype'
        }],
        [Pages.CHECKLIST_REPORTS_TABLE, {
            id: Pages.CHECKLIST_REPORTS_TABLE,
            partition: 0,
            module: 'phasetype'
        }]
        ]
    );
    const BarChartFooter = SmartInfoPanelModule.create();
    unprotect(BarChartFooter);
    BarChartFooter.merge(
        [[Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN_BARCHART, {
            id: Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN_BARCHART,
            partition: 3,
            module: 'bargraph'
        }],
        [Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN_BARCHART_SM, {
            id: Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN_BARCHART_SM,
            partition: 3,
            module: 'bargraph'
        }]
        ]
    );

    const LandingPageFooter = SmartInfoPanelModule.create();
    unprotect(LandingPageFooter);
    LandingPageFooter.merge(
        [[Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN, {
            id: Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN,
            partition: 3,
            module: 'landinggraph'
        }],
        [Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN_SM, {
            id: Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN_SM,
            partition: 3,
            module: 'landinggraph'
        }],
        [Pages.DASHBOARD_FINISHING_TOWER_LANDING, {
            id: Pages.DASHBOARD_FINISHING_TOWER_LANDING,
            partition: 3,
            module: 'landinggraph'
        }],
        [Pages.DASHBOARD_STRUCTURES_TOWER_FULLSCREEN, {
            id: Pages.DASHBOARD_STRUCTURES_TOWER_FULLSCREEN,
            partition: 3,
            module: 'landinggraph'
        }],
        [Pages.DASHBOARD_FINISHING_TCA_LANDING, {
            id: Pages.DASHBOARD_FINISHING_TCA_LANDING,
            partition: 3,
            module: 'landinggraph'
        }],
        [Pages.DASHBOARD_FINISHING_TCA_LANDING_BARATHEON, {
            id: Pages.DASHBOARD_FINISHING_TCA_LANDING_BARATHEON,
            partition: 3,
            module: 'landinggraph'
        }],
        [Pages.DASHBOARD_FINISHING_TCA_FULLSCREEN, {
            id: Pages.DASHBOARD_FINISHING_TCA_FULLSCREEN,
            partition: 3,
            module: 'landinggraph'
        }],
        [Pages.DASHBOARD_FINISHING_TCA_FULLSCREEN_BARATHEON, {
            id: Pages.DASHBOARD_FINISHING_TCA_FULLSCREEN_BARATHEON,
            partition: 3,
            module: 'landinggraph'
        }],
        ]
    );

    const LandingStructuresFooter = SmartInfoPanelModule.create();
    unprotect(LandingStructuresFooter);
    LandingStructuresFooter.merge(
        [[Pages.DASHBOARD_STRUCTURES_TOWER_LANDING, {
            id: Pages.DASHBOARD_STRUCTURES_TOWER_LANDING,
            partition: 3,
            module: 'landingStructuregraph'
        }]
        ]
    );

    const ByTableSort = SmartInfoPanelModule.create();
    unprotect(ByTableSort);
    ByTableSort.merge(
        [[Pages.LEGACY_TABLE, {
            id: Pages.LEGACY_TABLE,
            partition: 2,
            module: 'sort'
        }],
        [Pages.SNAGS_TABLE, {
            id: Pages.SNAGS_TABLE,
            partition: 2,
            module: 'sort'
        }],
        [Pages.SNAGS_TABLE_FULLSCREEN, {
            id: Pages.SNAGS_TABLE_FULLSCREEN,
            partition: 2,
            module: 'sort'
        }],
        [Pages.UNIT_ACT_INFO_TABLE, {
            id: Pages.UNIT_ACT_INFO_TABLE,
            partition: 2,
            module: 'sort'
        }],
        [Pages.LEGACY_FINISHING_TOWER_FULLSCREEN_TABLE, {
            id: Pages.LEGACY_FINISHING_TOWER_FULLSCREEN_TABLE,
            partition: 2,
            module: 'sort'
        }],
        [Pages.CHECKLIST_REPORTS_TABLE, {
            id: Pages.CHECKLIST_REPORTS_TABLE,
            partition: 2,
            module: 'sort'
        }],
        [Pages.FORM_DATA_TABLE, {
            id: Pages.FORM_DATA_TABLE,
            partition: 2,
            module: 'sort'
        }]
        ]
    );

    const ButtonGroupFilter = SmartInfoPanelModule.create();
    unprotect(ButtonGroupFilter);
    ButtonGroupFilter.merge([
        [Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN_SM, {
            id: Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN_SM,
            partition: 6,
            module: 'buttongroup'
        }],
        [Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN_BARCHART_SM, {
            id: Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN_BARCHART_SM,
            partition: 6,
            module: 'buttongroup'
        }],
        [Pages.LEGACY_FINISHING_TOWER_FULLSCREEN_TABLE, {
            id: Pages.LEGACY_FINISHING_TOWER_FULLSCREEN_TABLE,
            partition: 6,
            module: 'buttongroup'
        }]
    ]);

    // const ViewsPanel = SmartInfoPanelModule.create();
    // unprotect(ViewsPanel);
    // ViewsPanel.merge([
    //     [Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN, {
    //         id: Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN,
    //         partition: 0,
    //         module: 'views'
    //     }],
    //     [Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN_BARCHART, {
    //         id: Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN_BARCHART,
    //         partition: 0,
    //         module: 'views'
    //     }],
    //     [Pages.LEGACY_FINISHING_TOWER_FULLSCREEN_TABLE, {
    //         id: Pages.LEGACY_FINISHING_TOWER_FULLSCREEN_TABLE,
    //         partition: 0,
    //         module: 'views'
    //     }]
    // ]);

    const ByDeltaFilter = SmartInfoPanelModule.create();
    unprotect(ByDeltaFilter);
    ByDeltaFilter.merge([
        [Pages.DASHBOARD_STRUCTURES_TOWER_LANDING,
        {
            id: Pages.DASHBOARD_STRUCTURES_TOWER_LANDING,
            partition: 0,
            module: 'delta'
        }],
        [Pages.DASHBOARD_FINISHING_TCA_LANDING, {
            id: Pages.DASHBOARD_FINISHING_TCA_LANDING,
            partition: 0,
            module: 'delta'
        }],
        [Pages.DASHBOARD_FINISHING_TCA_LANDING_BARATHEON, {
            id: Pages.DASHBOARD_FINISHING_TCA_LANDING_BARATHEON,
            partition: 0,
            module: 'delta'
        }],
        [Pages.DASHBOARD_FINISHING_TCA_FULLSCREEN, {
            id: Pages.DASHBOARD_FINISHING_TCA_FULLSCREEN,
            partition: 0,
            module: 'delta'
        }],
        [Pages.DASHBOARD_FINISHING_TCA_FULLSCREEN_BARATHEON, {
            id: Pages.DASHBOARD_FINISHING_TCA_FULLSCREEN_BARATHEON,
            partition: 0,
            module: 'delta'
        }],
        [Pages.DASHBOARD_STRUCTURES_TOWER_FULLSCREEN, {
            id: Pages.DASHBOARD_STRUCTURES_TOWER_FULLSCREEN,
            partition: 0,
            module: 'delta'
        }],
        [Pages.DASHBOARD_FINISHING_TOWER_LANDING, {
            id: Pages.DASHBOARD_FINISHING_TOWER_LANDING,
            partition: 0,
            module: 'delta'
        }],
        [Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN, {
            id: Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN,
            partition: 0,
            module: 'delta'
        }],
        [Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN_SM,
        {
            id: Pages.DASHBOARD_FINISHING_TOWER_FULLSCREEN_SM,
            partition: 0,
            module: 'delta'
        }],
    ]);
    // const TileNumeral = SmartInfoPanelModule.create();
    // unprotect(TileNumeral);
    // TileNumeral.set(Pages.DASHBOARD_STRUCTURES_TOWER_LANDING, {
    //     id: Pages.DASHBOARD_STRUCTURES_TOWER_LANDING,
    //     partition: 0,
    //     module: 'tile'
    //     // module: {
    //     //     field: 'tile',
    //     //     component: TN
    //     // }
    // });

    const DownloadReportsButton = SmartInfoPanelModule.create();
    unprotect(DownloadReportsButton);
    DownloadReportsButton.merge([[Pages.CHECKLIST_REPORTS_TABLE,
    {
        id: Pages.CHECKLIST_REPORTS_TABLE,
        partition: 2,
        module: 'reports'
    }]]);

    const DownloadReportsQPanel = SmartInfoPanelModule.create();
    unprotect(DownloadReportsQPanel);
    DownloadReportsQPanel.merge([[Pages.CHECKLIST_REPORTS_TABLE,
    {
        id: Pages.CHECKLIST_REPORTS_TABLE,
        partition: 2,
        module: 'reportsQ'
    }
    ]]);

    const DownloadPdfReportsQPanel = SmartInfoPanelModule.create();
    unprotect(DownloadPdfReportsQPanel);
    DownloadPdfReportsQPanel.merge([[Pages.SNAGS_TABLE,
    {
        id: Pages.SNAGS_TABLE,
        partition: 2,
        module: 'pdfReportsQ'
    }],
    [Pages.SNAGS_TABLE_FULLSCREEN,
    {
        id: Pages.SNAGS_TABLE_FULLSCREEN,
        partition: 2,
        module: 'pdfReportsQ'
    }
    ],
    [Pages.UNIT_ACT_INFO_TABLE,
    {
        id: Pages.UNIT_ACT_INFO_TABLE,
        partition: 2,
        module: 'pdfReportsQ'
    }
    ],
    [Pages.LEGACY_TABLE,
    {
        id: Pages.LEGACY_TABLE,
        partition: 2,
        module: 'pdfReportsQ'
    }
    ],
    [Pages.LEGACY_FINISHING_TOWER_FULLSCREEN_TABLE,
    {
        id: Pages.LEGACY_FINISHING_TOWER_FULLSCREEN_TABLE,
        partition: 2,
        module: 'pdfReportsQ'
    }
    ]
    ]);

    //removed LinkPanel from const Panels
    const Panels: Instance<typeof SmartInfoPanelModule>[] = [ButtonGroupFilter, ByPhaseTypeFilter, BySpaceTypeFilter, ByTowerFilter, ByTowerSnagsFilter, PDFPDFPage, ExportExcelPage, ByStatusFilter, ByTableFilter, BarChartFooter, LandingPageFooter, LandingStructuresFooter, ByTableSort, ByActivityFilter, FloorSort, ByDeltaFilter, DownloadReportsButton, DownloadReportsQPanel, DownloadPdfReportsQPanel];
    Panels.forEach((node: IAnyStateTreeNode) => { protect(node); });
    //Removed Links as Instance<typeof __Info> from modules
    const Modules: Instance<ReturnType<typeof InfoPanelModule>>[] = [ButtonGroup as Instance<typeof __Info>, ByPhaseType as Instance<typeof unionModels['FilterByPhase']>, BySpaceType as Instance<typeof unionModels['FilterBySpaceType']>, ByTower as Instance<typeof unionModels['FilterByTower']>, ByStatus as Instance<typeof __Info>, ByFilter as Instance<typeof __Info>, BarGraphFooter as Instance<typeof __Info>, LandingFooter as Instance<typeof __Info>, StructuresFooter as Instance<typeof __Info>, TableSort as Instance<typeof __Info>, ByActivity as Instance<typeof unionModels['FilterByActivity']>, Display as Instance<typeof __Info>, downloadPDF as Instance<typeof __Info>, exportExcel as Instance<typeof __Info>, YSort as Instance<typeof __Info>, ByTowerSnags as Instance<typeof unionModels['FilterByTowerForSnags']>, ByDelta as Instance<typeof __Info>, DownloadReports, DownloadReportsQ, PdfDownloadReportsQ];
    this.modules.push(...Array.from(Modules, (mod: Instance<ReturnType<typeof InfoPanelModule>>) => mod as typeof mod extends IUnionModels ? Instance<IUnionModels> : never));
    this.panels.push(...Panels);
}
// const TileNumeral = types.map(TileNumeralDropdown).create();
// TileNumeral.set('structures', TileNumeralDropdown.create({ phase: 'structures', tiles: cast({ screen: 'landing', component: TN }) }));

//protect everything again. Orewa Mamoru


const InfoPanel = types.model<{ panels: IOptionalIType<IArrayType<typeof SmartInfoPanelModule>, ValidOptionalValues>; modules: IOptionalIType<IArrayType<typeof AbstractInfoPanel>, ValidOptionalValues> }>({
    panels: types.optional(types.array(SmartInfoPanelModule), []),
    modules: types.optional(types.array(AbstractInfoPanel), [])
}).views(self => ({
    get paramsToId(): Pages {
        // Insert decision logic for params such that each param is factored
        // in to create a unique id per use case
        // Currently only trivial case for phase+screen
        return getIdFromParam((getRoot(self) as Instance<typeof LateStoreModel>).params, (getRoot(self) as Instance<typeof LateStoreModel>).responsiveUtils.currentViewport.lg);
    }
})).views(self => ({
    get panelsList(): Instance<typeof __SmartInfoPanelModule>[] {
        return self.panels ? self.panels.reduce((acc: Instance<typeof __SmartInfoPanelModule>[], filt: Instance<typeof SmartInfoPanelModule>) =>
            filt && filt!.has(self.paramsToId) ? [...acc, filt && filt!.get(self.paramsToId)!] : acc
            , []) : [];
    }
})).views(self => ({
    get panelsListDivided(): {
        [K: number]: Instance<typeof AbstractInfoPanel>[];
    } | {} {
        return self.panelsList.reduce((acc: {} | { [K: number]: Instance<typeof InfoPanelModule> }[], panel: Instance<typeof __SmartInfoPanelModule>) => acc[panel.partition] ? { ...acc, [panel.partition]: [...acc[panel.partition], panel.module] } : { ...acc, [panel.partition]: [panel.module] }, {});
    }
})).actions(self => ({
    setInfoPanel() {
        if (self.modules.length === 0 && self.panels.length === 0) {
            createPanels.call(self);
        }
    }
})).actions(self => ({
    afterAttach() {
        self.setInfoPanel();
    }
})).postProcessSnapshot(_ => ({ panels: [] }));
// afterAttach() {
//     self.panelsList.forEach(filt => {
//         if (getPropertyMembers(filt.module).properties['root'])
//             filt.module.setRoot(getRoot(self) as Instance<typeof StoreModel>);
//     });
// }

export default InfoPanel;
