import React, { useCallback } from "react"
import { Box, Grid, Avatar } from "@material-ui/core"
import { useObserver } from "mobx-react-lite"
import { useHistory } from 'react-router';
import { useStore } from "../../models/ProvideModel";
import moment from "moment";

function AluFormActivityOverview({ classes, aluformData, pour }: any) {
  const { push } = useHistory()
  const store = useStore();
  const activityUrlpath = (id) => {
    return store.params.toPath({ section: "unit-act-info", screen: "fullscreen", spaceType: "tower", phase: "structures" }) + `${store.summary.towerData.towerId}?floor_idx=${store.summary.towerData.floor}&activity_type_id=${id}&pour_number=${pour}`
  }
  const onSnagUrlpath = (id) => {
    return store.params.toPath({ section: "snags", screen: "fullscreen", spaceType: "tower", phase: "structures" }) + `${store.summary.towerData.towerId}?floor_idx=${store.summary.towerData.floor}&activity_type_id=${id}&pour_number=${pour}`
  }

  const onActivityClick = useCallback((id, name) => {
    document?.getElementById(`${id}_${name}_activities`)?.removeAttribute("href");
    push(activityUrlpath(id));
  }, []);

  const onSnagClick = useCallback((id, name) => {
    document?.getElementById(`${id}_${name}_snags`)?.removeAttribute("href");
    push(onSnagUrlpath(id));
  }, []);

  return useObserver(() => (
    <div>
      {/* Activity Overview Heading */}
      <div id="activity-overview" className={classes.Headers}>
        {"Activity Overview"}
      </div>
      {/* Activity overview table */}
      <Box display="flex" justifyContent="center" paddingBottom="1rem">
        <Box className={classes.activityTableDiv} style={{ width: "75%" }}>
          <Grid container className={classes.tableHeadGrid}>
            <Grid item xs={3}>
              <span className={classes.tableHeading}>{"Activities"}</span>
            </Grid>
            <Grid item xs={2} className={classes.tableHead}>
              <span className={classes.tableHeading}>{"Status"}</span>
            </Grid>
            <Grid item xs={2} className={classes.tableHead} >
              <span className={classes.tableHeading}>{"Completion Date"}</span>
            </Grid>
            <Grid item xs={2} className={classes.tableHead} >
              <span className={classes.tableHeading}>{"Open/Total Snags"}</span>
            </Grid>
            <Grid item xs={3} className={classes.tableHead} >
              <span className={classes.tableHeading}>{"People Involved"}</span>
            </Grid>
          </Grid>
          {
            aluformData?.map((key) =>
              <Grid container key={key.id}>
                <Grid item xs={3} className={classes.tableRowGrid}>
                  <a className={classes.convActivityName}
                    id={`${key.id}_${key.name}_activities`}
                    href={`${window.location.href.split('/', 3).join('/')}${activityUrlpath(key.id)}`}
                    target="popup" style={{ textDecoration: "none" }}
                    onClick={(e) => { onActivityClick(key.id, key.name); return false }}>{key.name}</a>
                </Grid>
                <Grid item xs={2} className={classes.tableRowsAlign + " " + classes.tableRowGrid}>
                  <span className={classes.activityStatus}>
                    <Box display="flex">
                      {

                        <Avatar className={key.status === 1 ? classes.activityCompletedAvtar : null ||
                          key.status > 0 && key.status < 1 ? classes.activityOngoingAvtar : null ||
                            key.status === 0 ? classes.activityNotStartedAvtar : null
                        }></Avatar>

                      }
                      {
                        <span> {key.status === 1 ? "Completed" : null ||
                          key.status > 0 && key.status < 1 ? "On Going" : null ||
                            key.status === 0 ? "Not Started" : null}
                        </span>
                      }
                      {/* <span>{key.status * 100}</span> */}
                    </Box>
                  </span>
                </Grid>
                <Grid item xs={2} className={classes.tableRowsAlign + " " + classes.tableRowGrid}>
                  <span className={classes.activityStatus}>{key?.actual_end ? moment(key?.actual_end)?.format("DD MMM YYYY") : "___"} </span>
                </Grid>
                <Grid item xs={2} className={classes.tableRowsAlign + " " + classes.tableRowGrid}>
                  <a className={((key.progress?.snags?.open + key.progress?.snags?.closed) > 0) ? classes.convActivityName : classes.activityName}
                    id={`${key.id}_${key.name}_snags`}
                    href={`${window.location.href.split('/', 3).join('/')}${onSnagUrlpath(key.id)}`}
                    target="popup" style={{ textDecoration: "none" }}
                    onClick={() => onSnagClick(key.id, key.name)}>
                    {key.progress.snags.open}/{(key.progress.snags.open + key.progress.snags.closed)}
                  </a>
                </Grid>
                <Grid className={classes.tableRowsAlign + " " + classes.tableRowGrid} style={{ display: "flex", flexWrap: "wrap", width: "25%", color: "#585858", fontSize: "12px", fontFamily: "Source Sans Pro, sans-serif !important", letterSpacing: "normal", fontStretch: "normal" }}>
                  {
                    key.people && key.people.map((person, i) => {
                      if (person.name !== 'Activity Complete') {
                        return person.name
                      }
                    }
                    ).join(",")}

                </Grid>
              </Grid>
            )
          }
        </Box>
      </Box>
    </div>
  ))
}

export default React.memo(AluFormActivityOverview)
