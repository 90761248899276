import React, { memo } from 'react';
import useStyles from './ButtonGroupStyles';

export type ButtonGroupItem = {
    item: string,
    isActive: boolean,
    /**
    * Onclick for each Button
    */
    onBtnClicked?: () => void; 
};

export interface ButtonGroupProps {
    /**
    * Is it vertical or horizontal
    */
    vertical?: boolean;

    /**
    * Items array
    */
    elements: Array<ButtonGroupItem>;
}

function ButtonGroup({ vertical = true, elements }: ButtonGroupProps) {
    const classes = useStyles();

    const direction = vertical ? classes.vertical : classes.horizontal;

    const items = elements?.map((item: ButtonGroupItem) => {
        const activeClass = item.isActive ? classes.active : '';
        return (
            <div key={item?.item} className={[classes.item, activeClass].join(" ")} onClick={() => item.onBtnClicked?.()}>
                <img alt="icon" src={item.item} />
            </div>
        )
    });

    return (
        <>
            <div className={[classes.root, direction].join(' ')} >
                {items}
            </div>
        </>
    )
}

export default memo(ButtonGroup);