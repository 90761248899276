import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  FilterBase: {
    width: "184px",
    height: "67px",
    margin: "8px 0px",
    padding: "4px 8px 0 12px",
    borderRadius: "2px",
    border: "solid 1px #dddddd",
    backgroundColor: "var(--white-two)"
  },
  FilterBaseee: {
    width: "184px",
    height: "67px",
    margin: "8px 0px",
    padding: "4px 8px 0 12px",
    borderRadius: "2px",
    border: "solid 1px #dddddd",
    backgroundColor: "#f4f4f4",
    borderBottom: "2px solid darkblue"
  },
  BlockName: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "20px",
    margin: "0 0px 8px 0",
    fontFamily: "SourceSansPro",
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "1.25",
    letterSpacing: "normal",
    color: "var(--dark-grey-blue)"
  },
  Details: {

  },
  Rectangle: {
    width: "10px",
    height: "10px",
    margin: "3px 6px 12px 0",
    borderRadius: "1px",
    backgroundColor: "#ffc400"
  },
  Floors: {
    width: "45px",
    height: "15px",
    margin: "1px 8px 0px 6px",
    fontFamily: "SourceSansPro",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.25,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#585858"
  },
  Ellipse: {
    width: "10px",
    height: "10px",
    margin: "3px 6px 2px 0",
    border: "solid 2px #2fb597",
    borderRadius: "16px"
  },
  DashboardBase: {
    width: "1126px",
    height: "768px",
    borderRadius: "2px",
    backgroundColor: "#faf9f9"
  },
  Percentage: {
    width: "24px",
    height: "15px",
    margin: "0 0 0 6px",
    fontFamily: "SourceSansPro",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.25,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#585858"
  },
  input: {
    "& .MuiInputLabel-animated": {
      marginLeft: "23px"
    },
    "& .MuiInput-formControl": {
      display: "flex",
      flexDirection: "row-reverse"
    },
    "& .MuiInput-underline": {
      backgroundImage: "none",
      "&:hover:not($disabled):before": {
        borderBottom: "none",
      },
      "&:hover:not($disabled):after": {
        borderBottom: "none",
      },
      "&:hover": {
        borderBottom: "none",
      },
      "&:after": {
        borderBottom: "none",
      },
      "&:before": {
        borderBottom: "none",
      }
    }
  }
  // inputField: {
  //   "&:before": {
  //     fontSize: "44px",
  //     borderBottom: "none",
  //   },
  //   "& .MuiSelect-icon": {
  //     margin: "-2px -4px",
  //     backgroundImage: `url(${downArrowIcon}) !important`,
  //   },
  //   "& .MuiInputBase-input": {
  //     height: "-webkit-fill-available",
  //     display: "flex",
  //     flexDirection: "column",
  //     justifyContent: "center",
  //     padding: "0px 0px 0px !important",
  //     paddingTop: "0px !important",
  //     backgroundColor: "#ffffff",
  //   },
  //   "&.MuiInput-underline": {
  //     "&:hover:not($disabled):before": {
  //       borderBottom: "none",
  //     },
  //     "&:hover": {
  //       borderBottom: "none",
  //     },
  //     "&:after": {
  //       borderBottom: "none",
  //     },
  //     "&:before": {
  //       borderBottom: "none",
  //     },
  //   },
  //   "&:after": {
  //     borderBottom: "none",
  //   },
  // },
}));
export default useStyles;
