import React, { memo, forwardRef, RefObject, FunctionComponent, PropsWithoutRef, useCallback, useEffect, useState } from 'react';
import { getEnv, Instance, getSnapshot } from 'mobx-state-tree';
import { useObserver } from 'mobx-react-lite';
import { Tower } from '../../models/Tower';
import { VariableSizeList } from 'react-window';
import useStyles from './ScrollingLayoutStyles';
import { useHistory } from 'react-router';
import { useStore } from '../../models/ProvideModel';
import { reaction } from 'mobx';
import { Link } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton'
import { GOT } from '../../models/enums';

export interface ITowerHeadingScrollProps extends PropsWithoutRef<{}> {
  children: FunctionComponent;
  width: number;
  data: Instance<typeof Tower>[];
  itemSize: (index: number) => number;
  gutter: number;
  screen: string;
  phase: string;
  loading: boolean;
};
export interface ITowerHeadingScrollRef {
  myRef: RefObject<VariableSizeList>
};

export type ITowerHeadingScrollComponent = ITowerHeadingScrollProps & ITowerHeadingScrollRef;

function useRerouter() {
  const store = useStore();
  const params = store.params;
  const { push } = useHistory();
  const [paramsString, setParamsString] = useState<string>();
  useEffect(() => reaction(() => (params.path), (val: string) => { setParamsString(val); }), []);
  const rerouter = (tower: string) => () => {
    document?.getElementById(tower)?.removeAttribute("href");
    push(params.toPath({ ...getSnapshot(params), screen: 'fullscreen', tower, unitType: params.spaceType === 'tca' ? store.currentDashboard?.tab?.graphType === GOT.BARATHEON ? store.params.unitType : store.currentDashboard?.graphs?.get(tower)?.columnSortedFiltered[0]?.id : params.unitType }));
  };
  return rerouter;
}

const TowerHeadingScroll = memo(
  ({ width, data, screen, phase, myRef, itemSize, gutter, children, loading }: ITowerHeadingScrollComponent) => {
    const classes = useStyles({ screen, phase });
    const [hover, setHover] = useState<boolean>(false);
    const [linkId, setId] = useState<string>("");
    const store = useStore()
    const handleClick = useRerouter();

    const urlPath = (tower: string) => {
      return store.params.toPath({ ...getSnapshot(store.params), screen: 'fullscreen', tower, unitType: store.params.spaceType === 'tca' ? store.currentDashboard?.tab?.graphType === GOT.BARATHEON ? store.params.unitType : store.currentDashboard?.graphs?.get(tower)?.columnSortedFiltered[0]?.id : store.params.unitType })
    }

    const hoverState = (id) => {
      setId(id)
      setHover(true)
    }
    const bsSuffix = store.params.phase === "structures" && store.params.spaceType !== "tower" ? "_alt" : "";

    return useObserver(() => (
      <VariableSizeList
        ref={myRef}
        innerElementType={children}
        style={{ overflow: 'hidden', marginTop: screen === "landing" && phase === "structures" ? "5px" : "0px" }}
        layout="horizontal"
        height={30}
        width={0.9167 * width}
        itemCount={data.length}
        itemData={data}
        itemSize={itemSize}
      >
        {({ data, index, style }) => (
          <div
            style={{
              ...style,
              left: style.left! as number + gutter + 15,
              width: style.width! as number - gutter as number - getEnv(data[0]).axis[phase + bsSuffix][screen].left as number - getEnv(data[0]).axis[phase + bsSuffix][screen].right as number,
              // marginLeft: getEnv(data[0]).axis[phase][screen].left + 3,
              // marginRight: getEnv(data[0]).axis[phase][screen].right,
              textAlign: "center"
            }}
          >
            {(loading || data[index].loading) ?
              <Skeleton width={60} height={40} variant="text"></Skeleton> :
              <Link color="secondary" id={data[index].id}
                onMouseEnter={() => hoverState(data[index].id)}
                onMouseLeave={() => setHover(false)}
                className={classes.underlineHover}
                href={hover && linkId === data[index].id ? `${window.location.href.split('/', 3).join('/')}${urlPath(data[index].id)}` : ``}
                target="popup"
                onClick={handleClick(data[index].id)}>
                <span className={classes.towerTitle}>{data[index].name.toUpperCase()}</span>
              </Link>
            }
          </div>
        )}
      </VariableSizeList >
    ));
  }
);

export default forwardRef<VariableSizeList, ITowerHeadingScrollProps>(
  (props: ITowerHeadingScrollProps, ref: RefObject<VariableSizeList>) => (<TowerHeadingScroll {...props} myRef={ref} />));
