import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // display: "flex !important",
    // flexWrap: "wrap",
    // color: "rgba(0,0,0,0.8) !important",
    // paddingBottom: "2px !important",
    // paddingTop: "10px !important"
    fontFamily: "Source Sans Pro, sans-serif !important",
    padding: '0 2em',
    paddingBottom: "2em",
    height:"100%"
  },
  formControl: {
    // paddingBottom: "8px",
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  headerText: {
    fontSize: 12,
    lineHeight: 1.27,
    fontWeight: 'bold',
    color: '#585858',
    textTransform: 'uppercase',
    fontFamily: "Source Sans Pro, sans-serif !important",
    textAlign: 'left',
  },
  tableCell: {
    borderCollapse: 'collapse',
    border: '1px solid #d1d1d1',
    fontSize: '12px',
    padding: "12px 12px"
  },
  table: {
    borderCollapse: 'collapse',
    width: '100%',
    padding: '1rem',
  },
  tableHeader: {
    '& tr': {
      background: '#faf9f9',
    },
    '& th': {
      padding: '0 8px 16px 0',
      textAlign: 'left',
      backgroundColor: '#faf9f9',
      verticalAlign: 'top',
      '&:nth-child(n+1)': {
        width: "30px"
      }
      // width: '100px !important',
      //         '&:nth-child(-n+3)': {
      //     width: "20px !important"
      // },
      //   '&:first-child' :{
      //     width: "70px !important"
      //   },

      //   '&:nth-child(8n-6)' :{
      //     width: "100px !important",
      //   },
      // '&:last-child' :{
      //   width: "40px !important"
      // },
    }
  },
  tableBody: {
    '& tr:nth-child(odd)': {
      background: '#f4f4f4'
    }
  },
  printViewCell: {
    fontSize: "12px",
    lineHeight: 1.25,
    minWidth: "50px",
    pageBreakAfter: "always",
    pageBreakInside: "avoid",
    minHeight: "30px",
    display: "flex",
    alignItems: "center"
  },
  tableRow: {
    '& td': {
      height: 80,
      alignItems: "center",
      // width: '100px !important',
      //         '&:nth-child(-n+3)': {
      //     width: "20px !important"
      // },
      //   '&:first-child' :{
      //     width: "70px !important"
      //   },
      //   '&:nth-child(8n-6)' :{
      //     width: "100px !important",
      //   },
      // '&:last-child' :{
      //   width: "40px !important"
      // },
    }
  },
  statusCell: {
    display: 'flex'
  },
  arrowImg: {
    position: "absolute",
    // left: "60px",
    right: "-6px",
    top: "5px",
    width: "28px"
  },
  selectInput: {
    fontFamily: "Source Sans Pro, sans-serif !important",
    fontSize: "14px",
    cursor: "pointer",
    width: "100%"
  },
  searchSort: {
    top: 0,
    position: "sticky",
    background: "#fff",
    zIndex: 99999,
  },

  //Dropdown styles
  progressOver: {
    fontFamily: "Source Sans Pro, sans-serif !important",
    fontSize: "11px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.27,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#585858",
  },
  inputField: {
    // color:"#005eff",
    // fontWeight:"bold",
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "14px",
    "&:before": {
      borderBottom: "1px solid #005eff"
    },
    "&:after": {
      borderBottom: "1px solid #005eff",
      backgroundColor: "#fff"
    }
  },
  selectStatus: {
    fontSize: "11px !important",
    height: "30px",
    display: "flex",
    alignItems: "center",
    padding: "8px 16px 0px 16px",
  },
  selectedItem: {
    "&:first-child": {
      color: "black"
    },
    color: "#005eff",
    fontSize: "14px",
    minHeight: "36px",
    "&:hover": {
      fontWeight: "bold",
      color: "#005eff",
      marginLeft: "5px",
      marginRight: "5px"
    },
    fontFamily: "Source Sans Pro, sans-serif !important",
    "&:last-child": {
      marginBottom: 8
    },
  },
  noDataFound: {
    fontSize: "24px",
    textAlign: "center",
    fontWeight: 700,
    fontFamily: "Source Sans Pro, sans-serif !important"
  },
  boldDarkBlue24px: (props: { lg: boolean }) => ({
    fontSize: props.lg ? "24px" : "18px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.29,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#2f3e6c",
    padding: "20px 0px"
  }),
  snapshots: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    padding: 12,
    lineHeight: 1.7

  },
  boldGrey16px: {
    fontSize: "16px",
    fontWeight: 700,
    fontFamily: "Source Sans Pro, sans-serif !important",
    color: "#585858",
    lineHeight: 1.25,
    fontStretch: "normal",
    fontStyle: "normal",
    textTransform: "uppercase"
  },
  tableRowUnits: {
    '& td': {
      alignItems: "center",
      display: "flex",
      fontFamily: "Source Sans Pro, sans-serif !important",

      '&:nth-child(n+1)': {
        width: "30px"
      }
    }
  },
  statusCount: {
    alignSelf: "center",
    width: 280,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "14px",
    fontStyle: "normal",
    fontFamily: "Source Sans Pro, sans-serif !important",
    letterSpacing: "normal",
    fontWeight: "normal",
    color: "#585858"
  },
  printHeader: {
    borderBottom: "1px solid #005eff",
    fontWeight: "bold",
    fontSize: "10px",
    lineHeight: 1.3,
    height: "100%",
    display: "flex",
    alignItems: "flex-end",
    paddingBottom: "2px",
  },
})
);

export default useStyles;
