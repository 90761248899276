import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { extendStyles } from "../utils/utils";

const fPanel = {
    flexGrow: 1,
    flexShrink: 1,
    width: '15%',
    maxWidth: '19.5%',
    backgroundColor: "#fff",
    // display: 'flex',
    flexBasis: 'auto',
    overflow: "auto",
    zIndex: 1,
    resize: "horizontal"
};

const fullscreenpanel = {
    flexGrow: 1,
    flexShrink: 1,
    width: '15%',
    maxWidth: '19.5%',
    backgroundColor: "#faf9f9",
    // display: 'flex',
    flexBasis: 'auto',
    overflow: "auto",
    zIndex: 1
}

const fPanelSm = extendStyles(fPanel, {
    width: '8%',
    padding: '15px 8px',
    flexGrow: 0,
    flexShrink: 0,
    borderRight: '1px solid #efefef'
});

const fPanelClose = extendStyles(fPanel, {
    width: '5%',
    padding: '15px 8px',
    flexGrow: 0,
    flexShrink: 0,
    borderRight: '1px solid #efefef'
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: '99vh',
            overflow: 'hidden',
            position: "relative",
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            fontFamily: "Source Sans Pro, sans-serif !important",
            '@media print': {
                height: 'auto',
            },
        },
        rootSm: {
            // height: '100Vh',
            height: "100%",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            position: "absolute",
            overflow: 'hidden',
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            fontFamily: "Source Sans Pro, sans-serif !important",
            '@media print': {
                height: 'auto',
            },
        },
        filterIndicator: {
            backgroundColor: "#005eff",
            width: "7px",
            height: "7px",
            borderRadius: "50%",
            position: 'absolute',
            top: '11px',
            right: '16px'
        },
        fullscreenFilterIndicator: {
            top: "11px",
            right: "10px",
            width: "7px",
            height: "7px",
            position: "absolute",
            borderRadius: "50%",
            backgroundColor: "#005eff"
        },
        row: {
            display: 'flex',
            overflow: 'hidden',
            width: "80%",
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: 'auto',
            backgroundColor: "#e2e2e2",
        },
        column: {
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: 'auto',
            margin: 0,
            width: '80%',
            overflow: 'hidden',
            flexWrap: 'nowrap',
            flexDirection: 'column'
        },
        mainWindow: {
            display: 'flex',
            flexGrow: 1,
            minHeight: "2em",
            height: "100%"
        },
        windowPane: (props: { params: Array<String>, fullScreenMode: boolean, infopanel: boolean }) => ({
            width: props.infopanel ? "95%" : "76%",
            alignItems: "stretch",
            // height: '100%',
            flexGrow: 1,
            flexShrink: 1,
            display: 'flex',
            flexBasis: 'auto',
            backgroundColor: "#FAFAFA",
            // paddingTop: (props.params["screen"] === "landing" && props.params["phase"] === "finishing") ? "30px" : "0px",
        }),
        fullscreenBtn: (props: { fullScreenMode: boolean }) => ({
            position: 'absolute',
            bottom: props.fullScreenMode ? '35px' : '13px',
            right: '15px'
        }),
        filterIconBtn: {
            position: 'absolute',
            left: '10px',
            bottom: '34px'
        },
        filterPanel: (props: { params: Array<String>, fullScreenMode: boolean, fullscreenpages: boolean }) => props.fullscreenpages ? fullscreenpanel : fPanel,
        closeinfopanel: fPanelClose,
        filterPanelSm: fPanelSm,
        fullScrIco: (props: { fullScreenMode: boolean }) => ({
            height: props.fullScreenMode ? 23 : 22,
            width: props.fullScreenMode ? 17 : '',
            transform: props.fullScreenMode ? '' : 'rotate(40deg)'
        }),
        backBtn: {
            fontSize: 14,
            fontFamily: 'Source Sans Pro',
            fontWeight: "normal",
            alignSelf: "center",
            marginLeft: 0,
            top: -2
        },
        backDiv: {
            color: '#005eff',
            border: "none",
            textAlign: "center",
            marginRight: "8px",
            transform: "scaleX(-1)",
            padding: "0px",
            width: "80px",
            transition: "margin 0.5s",
            cursor: "pointer",
            "& span": {
                cursor: "pointer",
                display: "inline-block",
                position: "relative",
                transition: "0.5s",
                color: "white",
                "&:after": {
                    position: "absolute",
                    opacity: 0,
                    top: 0,
                    right: "-15px",
                    transition: "0.5s",
                },
            },
            "&:hover": {
                color: "#2f3e6c",
                "& span": {
                    paddingRight: "8px",
                    "&:hover": {
                        opacity: 1,
                        right: 0,
                        color: "#2f3e6c"
                    }
                }
            }
        },
        mainDiv: {
            display: "flex",
            flexDirection: "row-reverse",
            width: "100%"
        },
        changeImage: {
            position: "absolute",
            color: '#005eff',
            right: 0,
            top: "19px",
            transform: "scaleX(-1)",
            display: "flex",
            flexFlow: "row-reverse",
            width: "37px",
            zIndex: 101,
            alignItems: 'center'
        }
    })
);

export default useStyles;
