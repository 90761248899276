import React, { PropsWithChildren, memo } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

// Later, refactor the two shares of email and whatsapp into two files with this
// file being the index.
export interface IGenericPopup {
  isOpen: boolean;
  title: string;
  onClose(): void;
  actions: { type: string; onClick(): void; }[];
};
const useStyles = makeStyles((theme: Theme) => ({
  backDrop: {
    backdropFilter: "blur(30px)",
    opacity: ".85 !important"
  },
}));

const GenericPopup = ({ isOpen, onClose, title, actions, children }: PropsWithChildren<IGenericPopup>) => {
  const classes = useStyles();
  /* const { isOpen: openState } = useModal({ isOpen, closeOnOverlayClick: true }); */
  return (<Dialog
    open={isOpen}
    onClose={onClose}
    BackdropProps={{ classes: { root: classes.backDrop } }}
  >
    <DialogTitle>{title}️</DialogTitle>
    <DialogContent>
      <DialogContentText>{children}</DialogContentText>
    </DialogContent>
    <DialogActions>
      {
        actions.map(({ type, onClick }) => (
          <Button
            key={type}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => { onClick(); }}
          >
            {type}
          </Button>
        ))
      }
    </DialogActions>
  </Dialog>);
};

export default memo(GenericPopup);
