import React, { memo, useState, FormEvent, ChangeEvent } from "react";
import useStyles from "./PasswordPageStyles";
import { useStore } from "../../models/ProvideModel";
import { changePassword } from "../../api/Auth";
import { Button, Card, CardActions, CardContent, CardHeader, CircularProgress, IconButton, InputAdornment, Link, TextField } from "@material-ui/core";
import CommonSnackbar from "../../components/commonSnackbar/CommonSnackbar";
import { useObserver } from "mobx-react-lite";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useHistory } from "react-router";

const ChangePasswordPage = () => {
  const classes = useStyles();
  const store = useStore();
  const { push } = useHistory();
  const [pass, setPass] = useState<{ old: string; newI: string; newR: string; valid: boolean, errorMsg: string, loading: boolean }>({ old: "", newI: "", newR: "", valid: false, errorMsg: "", loading: false });
  const [alert, setAlert] = useState<{ open: boolean, type: "error" | "info" | "success" | "warning" | undefined, msg: string }>({ open: false, type: undefined, msg: "" });
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
    showRetyePassword: false,
    showNew: false
  });

  const handleClickShowPassword = (key: string) => {
    switch (key) {
      case "showPassword": setValues({
        ...values,
        showPassword: !values.showPassword,
      });
        break;
      case "showRetyePassword": setValues({
        ...values,
        showRetyePassword: !values.showRetyePassword,
      });
        break;
      case "showNew": setValues({
        ...values,
        showNew: !values.showNew,
      });
        break;
      default:
        break;
    };
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleSubmit = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    if (pass.old === pass.newI && pass.newI === pass.newR) {
      setPass({ ...pass, valid: true, errorMsg: "New password and old password should not be same." })
    }
    else {
      if (pass.newI === pass.newR) {
        setPass({ ...pass, loading: true })
        changePassword(store.auth.email, pass.old, pass.newI)
          .then((res) => {
            setAlert({ ...alert, open: true, type: "success", msg: "Password changed successfully, redirecting to login page." });
            setTimeout(() => {
              store.auth.logout();
            }, 3000);
          })
          .catch(err => {
            setAlert({ ...alert, open: true, type: "error", msg: "Something went wrong" });
          }).finally(() => {
            setTimeout(() => {
              setAlert({ ...alert, open: false, type: undefined, msg: "" });
            }, 5000);
            setPass({ ...pass, loading: false })
          })
      } else {
        setPass({ ...pass, valid: true, errorMsg: "Password mismatched" })
      }
    }
  };

  const loginButton = (<Button type="submit" color="primary" disabled={((!pass.newI || !pass.newR || !pass.old) || pass.loading) ? true : false} fullWidth className={(!pass.newI || !pass.newR || !pass.old) ? classes.applyDisabled : classes.button} >{pass.loading ? <CircularProgress size={20} style={{ color: "#FFFFFF" }} /> : 'Change password'} </Button>)

  return useObserver(() => (
    <div className={classes.root}>
      <Card className={store.responsiveUtils.currentViewport.isLg ? classes.card : classes.cardSm}>
        <form onSubmit={handleSubmit}>
          <CardHeader title="Change Password" />
          <CardContent style={{ paddingTop: 0 }}>
            <TextField
              label={<span className={classes.inputLabel}>Old Password</span>}
              placeholder="Old Password"
              fullWidth
              className={classes.inputField}
              margin={store.responsiveUtils.currentViewport.isLg ? "normal" : "none"}
              onChange={(ev: ChangeEvent<HTMLInputElement>) => { setPass({ ...pass, old: ev.target.value }); }}
              // InputLabelProps={{
              //   shrink: true,
              // }}
              type={values.showPassword ? 'text' : 'password'}
              InputProps={{
                classes: { input: classes.input, underline:classes.underline },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword("showPassword")}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      className={classes.Iconbutton}
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              value={pass.old}
              style={{ marginTop: "0px !important", marginBottom: "0px !important" }}
            />
            <TextField
              label={<span className={classes.inputLabel}>New Password</span>}
              placeholder="New Password"
              fullWidth
              className={classes.inputField}
              style={{ marginTop: "0px !important", marginBottom: "0px !important" }}
              onChange={(ev: ChangeEvent<HTMLInputElement>) => { setPass({ ...pass, newI: ev.target.value, errorMsg: "", valid: false }); }}
              // InputLabelProps={{
              //   shrink: true,
              // }}
              type={values.showNew ? 'text' : 'password'}
              margin={store.responsiveUtils.currentViewport.isLg ? "normal" : "none"}
              InputProps={{
                classes: { input: classes.input, underline:classes.underline },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword("showNew")}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      className={classes.Iconbutton}
                    >
                      {values.showNew ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              value={pass.newI}
              error={pass.valid}
              helperText={pass.errorMsg}
            />
            <TextField
              label={<span className={classes.inputLabel}>Confirm new Password</span>}
              placeholder="Confirm new Password"
              fullWidth
              className={classes.inputField}
              margin={store.responsiveUtils.currentViewport.isLg ? "normal" : "none"}
              style={{ marginTop: "0px !important", marginBottom: "0px !important" }}
              onChange={(ev: ChangeEvent<HTMLInputElement>) => { setPass({ ...pass, newR: ev.target.value, errorMsg: "", valid: false }); }}
              // InputLabelProps={{
              //   shrink: true,
              // }}
              type={values.showRetyePassword ? 'text' : 'password'}
              InputProps={{
                classes: { input: classes.input, underline:classes.underline },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword("showRetyePassword")}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      className={classes.Iconbutton}
                    >
                      {values.showRetyePassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              value={pass.newR}
              error={pass.old !== pass.newI && pass.valid}
              helperText={pass.old !== pass.newI && pass.errorMsg}
            />
          </CardContent>
          <CardActions>
            {loginButton}
          </CardActions>
          <div className={classes.link} onClick={() =>  push(`/menu`)}> Back to Menu</div>
        </form>
      </Card>
      <CommonSnackbar open={alert.open} type={alert.type} msg={alert.msg} />
    </div>
  ));
}

export default memo(ChangePasswordPage)
