import React, { useEffect, useState, useMemo, FunctionComponent, memo } from "react";
import { useLocation, useHistory, useParams } from "react-router";
import { useObserver } from "mobx-react-lite";
import moment from "moment";
import { Grid } from "@material-ui/core";
import { compose, transduce, map, filter, toArray } from "transducist";
import { ApolloClient } from "apollo-client";
import { NormalizedCacheObject } from "apollo-cache-inmemory";
import client from "../../utils/apolloClient";
import { GET_UNITS_BY_BLOCK, GET_UNITS_BY_BLOCK_HEADERS } from "../../utils/queries";
import FatTable from "../../components/fatTable/FatTable";
import TableMultiselect from "../../components/fatTable/TableMultiselect";
import TableBoolean from "../../components/fatTable/TableBoolean";
import Paginate from "../../components/fatTable/Pagination";
import LegacyUnitInfoSummary from "../../components/legacyUnitInfoSummary/LegacyUnitInfoSummary";
import DefaultCells from "../../components/DefaultCells";
import UnitSnagCell from "../../components/UnitSnagCell";
import { useSingleValueURLParam } from "../../utils/hooks";
import LoadingSpinner from "../../components/loadingSkelaton/LoadingSpinner";
import { StatusWithDelayedNumbers } from '../../components/table/Commonfunctions';
import useStyles from '../../components/fatTable/FatTableStyle'
import UnitNameCell from "../../components/TableCellComponents/UnitNameCell";
import ActivityStatusCell from "../../components/TableCellComponents/ActivityStatusCell";
import { useStore } from "../../models/ProvideModel";
import { calcStatus } from "../../utils/utils";
import ViewPanel from '../../components/viewPanel/View';
import ChecklistReportsPopup from "../../components/ChecklistReports/ChecklistReportsPopup";

const __DEFAULT = 100;

interface IQueryFilterParams {
  where: Record<string, any>;
  order_by: { [k: string]: "asc" | "desc" }[];
}
interface IQueryPageParams {
  limit: number;
  offset: number;
}

type IQueryParams = IQueryFilterParams & Partial<IQueryPageParams>;

export interface IColumnOptions {
  id: string;
  name: string;
  filter?: Function;
  Component?: FunctionComponent<{ [K: string]: any; }>;
  Cell?: FunctionComponent;
  print?: boolean;
  value?: string;
  isVisible?: boolean;
  accessor?: string;
  minWidth?: number;
  headerObj?: string;
  excelExport?: Function;
  isExcelField?: boolean;
  data?: { id: string; name: string; }[];
}

export function useCustomQueryParameters(
  UnitPerBlockFatcolumns: IColumnOptions[],
  filters: URLSearchParams
) {
  // const { blockId } = useParams();               // Use when block_id is a fk
  const [param, setParam] = useState<IQueryParams>();
  useEffect(() => {
    const sort = filters.getAll("sort");
    const descSort = filters.getAll("sortDesc");
    let variables: IQueryParams = {
      where: {},
      order_by: descSort.length === 0 && sort.length === 0 ? [{ block_id: "asc" }] : [],
    };
    for (const { id: field, filter } of UnitPerBlockFatcolumns) {
      if (sort.length > 0 && sort.includes(field)) {
        variables["order_by"].push({ [field]: "asc" });
      }
      if (descSort.length > 0 && descSort.includes(field)) {
        variables["order_by"].push({ [field]: "desc" });
      }
      const value = filters.getAll(field);
      if (value.length > 0) {
        if (filter) {
          variables["where"] = filter === snagBool ? {
            ...(variables["where"] || {}),
            ...filter.apply(null, value)
          } : {
            ...(variables["where"] || {}),
            [field]: { ...filter.apply(null, value) },
          };
        } else {
          if (value.length === 1) {
            const singleVal = value[0];
            variables["where"] = {
              ...(variables["where"] || {}),
              [field]: { _eq: singleVal },
            };
          } else {
            variables["where"] = {
              ...(variables["where"] || {}),
              [field]: { _in: [...value] },
            };
          }
        }
      }
    }
    setParam({ ...variables });
  }, [filters]);
  return param;
}

// function statusFilter(...inputs: string): {} {

// }

function delta(input1: string, input2: string): { _lt: string; _gt: string } {
  if (!(input1 && input2)) {
    throw new Error("date type filters must define a range");
  }
  const dates = [input1, input2].map((d) => moment(d));
  return {
    _lt: moment.max(...dates).endOf('day').toISOString(),
    _gt: moment.min(...dates).toISOString(),
  };
}

function range(input: string): { _gt?: number; _lt?: number } {
  const [clause, value] = input.split(/([0-9]+)/);
  switch (clause) {
    case "lt":
      return { _lt: Number.parseInt(value) };
    case "gt":
    default:
      return { _gt: Number.parseInt(value) };
  }
}

//          Doesn't work because attachment is an empty string instead of null
/* function bool(input: string): { _is_null: boolean } {
 *   return { _is_null: input === "true" };
 * } */

function bool(input: string): { _neq: string; } | { _eq: string; } {
  return input === "false" ? { _eq: "" } : { _neq: "" };
}

function snagBool(input: string): { snag_counts?: {}; _not?: { snag_counts: {} } } {
  return input === "false" ? { _not: { snag_counts: {} } } : { snag_counts: {} };
}

export const UnitPerBlockFatcolumns: IColumnOptions[] = [
  { id: "unit_id", value: "unit_name", name: "Unit Title", Component: TableMultiselect, Cell: UnitNameCell, print: true, minWidth: 80, isExcelField: true, excelExport: (elt: any) => elt['unit_name'] },
  { id: "completed", name: "Status", Cell: StatusWithDelayedNumbers, print: true, minWidth: 80, isExcelField: true, excelExport: (elt: any) => calcStatus(elt['completed'], elt['in_progress'], elt['total']) },
  { id: "inProgress", name: "Activity Status", Cell: ActivityStatusCell, print: true, minWidth: 80, isExcelField: true, excelExport: (elt: any) => `Completed: ${elt['completed']} | OnGoing: ${elt['in_progress']} | Not Started: ${elt['total'] - (elt['completed'] + elt['in_progress'])}` },
  { id: "snag_counts", value: "snag_counts", accessor: "snag_counts.count_open", name: "Open / Total Snags", print: true, Component: TableBoolean, filter: snagBool, Cell: UnitSnagCell, minWidth: 120, data: [{ id: "true", name: "Units with Snag Counts" }, { id: "false", name: "Units without Snag Counts" }], isExcelField: true, excelExport: (elt: any) => !elt['snag_counts'] ? '-' : `{Open : ${elt['snag_counts']['count_open']} | Closed : ${elt['snag_counts']['count_closed']} | For review : ${elt['snag_counts']['count_for_review']}}` },
];

const UnitPerBlockFat = () => {
  const [data, setData] = useState<any[]>([]);
  const [headersData, setHeadersData] = useState<{}>({});
  const [totalCount, setTotal] = useState<number>(0);
  const [activities, setAct] = useState<number>(0);
  useEffect(() => {
    if (!client.client) {
      client();
    }
  }, [client]);
  const { search, ...location } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const { push } = useHistory();
  const params = useCustomQueryParameters(UnitPerBlockFatcolumns, searchParams);
  const [print] = useSingleValueURLParam("view", "normal", searchParams);
  const [loading, setloading] = useState<boolean>(false)
  const [dataLoading, setDataLoading] = useState<boolean>(false)
  const { tower, spaceType, phase }: { tower?: string | undefined, spaceType?: string | undefined, phase?: string | undefined } = useParams();
  const pathParams = useMemo(() => tower ? ({ "block_id": { "_eq": tower } }) : ({}), [tower])
  const store = useStore();
  const { fullScreenMode } = store.responsiveUtils;
  const classes = useStyles({ print });

  const columnsConfig = useMemo(
    () =>
      transduce(
        UnitPerBlockFatcolumns,
        compose(
          filter(({ print: printV }) => (print === "print" ? !!printV : true)),
          filter(({ id }) => {
            switch (id) {
              case 'block_id': return !tower;
              case 'space_type_id': return !spaceType;
              case 'phase_id': return !phase;
              default: return true;
            }
          }),
          filter(({ id }) =>
            headersData[id] ? (headersData[id].length > 0 ? true : false) : true
          ),
          map(({ id, name, Component, Cell, isVisible, accessor, headerObj, minWidth, ...rest }) => ({
            name,
            Header:
              Component && print !== "print"
                ? Component
                : name,
            minWidth: minWidth || 150,
            id,
            accessor: accessor || id,
            headerObj,
            Cell: !!Cell ? Cell : DefaultCells,
            data: headersData[id] || null,
            ...rest
          }))
        ),
        toArray()
      ),
    [headersData, print, tower, phase, spaceType]
  );
  const [offset, setPage] = useSingleValueURLParam<number>(
    "page",
    0,
    searchParams,
    Number.parseInt,
    push,
    location
  );
  const [limit, setSize] = useSingleValueURLParam<number>(
    "pageSize",
    __DEFAULT,
    searchParams,
    Number.parseInt,
    push,
    location
  );
  useEffect(() => {
    if (client.client && pathParams.block_id) {
      store.exportTableFilters.changeHeaderDataLoading(true)
      const query = (client.client as ApolloClient<NormalizedCacheObject>)!
        .watchQuery({
          query: GET_UNITS_BY_BLOCK_HEADERS,
          variables: { where: { ...pathParams } },
        })
        .subscribe(({ data, loading }) => {
          if (!loading) {
            setHeadersData(
              UnitPerBlockFatcolumns.reduce(
                (acc, { id, Component }) =>
                  Component
                    ? { ...acc, [id]: data[id] && data[id].length > 0 ? data[id] : null }
                    : acc,
                {}
              )
            );
            setDataLoading(true);
            store.exportTableFilters.changeHeaderDataLoading(false)
          }
        });
      return () => {
        query.unsubscribe();
      };
    }
  }, [pathParams]);
  // Issue the query
  useEffect(() => {
    if (client.client && params && pathParams.block_id) {
      setloading(true)
      const variables: IQueryParams =
        print === "print"
          ? params
          : { ...params, limit, offset: offset * limit };
      const query = (client.client as ApolloClient<
        NormalizedCacheObject
      >)!.watchQuery<Array<any>, IQueryParams>({
        query: GET_UNITS_BY_BLOCK,
        variables: { ...variables, where: { ...variables.where, ...pathParams } },
      });
      query
        .result()
        .catch((error) => { console.error(error); setloading(false) })

      const observable = query.subscribe(({ data, loading }) => {
        if (!loading) {
          setData(data["units"]);
          setTotal(data["meta"]["total"]["count"]);
          setAct(data["meta"]["total"]["max"]["total"]);
          setloading(false)
        }
      });
      return () => {
        observable.unsubscribe();
      };
    }
  }, [params, offset, limit, print, pathParams]);


  const mobilefullScreenMode = (fullScreenMode) => {
    return !fullScreenMode ? classes.tablePanelSm : classes.tablePanelFs
  }

  useEffect(() => {
    store.downloadpdf.setReportName("Units Per Block")
    store.downloadpdf.setTotalCount(totalCount)
    store.exportTableFilters.setZeroRows(data.length <= 0 ? true : false)
    store.exportTableFilters.setColumns(UnitPerBlockFatcolumns)
    store.exportTableFilters.setParams(JSON.stringify(params))
    store.exportTableFilters.setPathParams(JSON.stringify(pathParams))
    store.exportTableFilters.setHeadersDataCount(Object.keys(headersData).length ? Object.keys(headersData).length : 0)
  }, [totalCount, params, pathParams, Object.keys(headersData).length])

  useEffect(() => {
    store.exportTableFilters.setColumnConfig(columnsConfig)
  }, [dataLoading])

  useEffect(() => () => {
    store.exportTableFilters.clearTableFiltersData()
  }, [])

  return useObserver(() => (
    <div
      style={{
        backgroundColor: "#faf9f9",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        display: "flex",
        flexGrow: 1,
        flexBasis: "auto",
        flexShrink: 1,
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          flexBasis: "auto",
          // overflow: "scroll",
          flexGrow: 1,
          flexShrink: 1,
          // height:"70vh"
        }}
      >
        <div className={store.responsiveUtils.currentViewport.isLg ? classes.tablePanel : mobilefullScreenMode(store.responsiveUtils.fullScreenMode)}>
          {(print !== "print") &&
            (store.responsiveUtils.currentViewport.isLg && <Grid container>
              <Grid item xs={12}>
                {store.params.phase === "finishing" ?
                  <div>
                    <ViewPanel />
                  </div> : null}
              </Grid>
            </Grid>)}
          <LegacyUnitInfoSummary block={store.currentTower?.name || ""} units={totalCount} activities={activities} />
          <ChecklistReportsPopup/>
          {loading ? <LoadingSpinner bgColor={'#faf9f9'} width={(print !== "print") ? "80%" : "100%"} /> : <FatTable columns={columnsConfig} data={data} print={print} />}
          {print !== "print" && (
            <Paginate
              totalCount={totalCount}
              page={offset}
              size={limit}
              setPage={setPage}
              setSize={setSize}
            />
          )}
        </div>
      </div>
    </div>
  ));
};

export default memo(UnitPerBlockFat);
