import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection:"column"
  },
  inputLabel: {
    fontWeight: "bolder",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.27,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#585858",
    textTransform: "uppercase"
    
  },
  input: {
    '&::placeholder': {
      fontSize:11
        }
  },
  underline:{
    display: "inline-flex"
  },
  Iconbutton: {
    "& .MuiSvgIcon-root": {
      fill: "#2F3E6C",
      "&:hover": {
        fill:"#005eff"
    }
    },
  },
  button: {
    fontSize: "12px",
    fontWeight: "normal",
    fontStyle: "normal",
    backgroundColor: "#005eff",
    color: "#ffffff",
    width: "300px",
    height: "40px",
    "&:hover": {
      backgroundColor: "#005eff"
    },
    textTransform:"capitalize"
  },
  applyDisabled: {
    fontSize: "12px",
    fontWeight: "normal",
    fontStyle: "normal",
    backgroundColor: "#eaeaea",
    color: "#585858",
    width: "300px",
    height: "40px",
    textTransform:"capitalize"
  },
  card: {
    padding: "50px 150px 100px 150px",
    width:"600px",
    minHeight: "500px"
  },
  cardSm:{
    padding: "18px 60px 17px 60px",
    width:"376px",
    minHeight: "337px"
  },
  link: {
    display: "flex",
    justifyContent: "center",
    marginTop:12,
    color: "rgb(0, 94, 255)",
    cursor: "pointer",
    fontFamily: "Source Sans Pro, sans-serif !important"
  }
}));

export default useStyles;
