import React, { useCallback, useEffect, useMemo } from "react";
import { useLocation, useHistory } from 'react-router';
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import useStyles from "./SortStyles";
import { useStore } from '../../models/ProvideModel';
import { useObserver } from 'mobx-react-lite';
import hoverDesc from "../../assets/images/Sort-Z-A-White.svg";
import defaultDesc from "../../assets/images/Sort Z-a.svg";
import selectedDesc from "../../assets/images/Sort Z-a-Selected.svg";
import hoverAscIcon from "../../assets/images/Sort-A-Z-White.svg";
import defaultAsc from "../../assets/images/Sort A-z.svg";
import selectedAsc from "../../assets/images/Sort A-z-Selected.svg";
import SortSmall from "../../assets/images/Sort - Small.svg"

const __internal_map = {
  ascending: true,
  descending: false,
  get key() { return (arg: boolean) => arg ? "ascending" : "descending" }
};

function Sort() {
  const location = useLocation();
  const { push } = useHistory();
  const filters = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const current = useMemo(() => filters.get('sort') === 'true', [location.search]);
  const [value, setValue] = React.useState<string>(() => __internal_map.key(current));
  const classes = useStyles();
  const [hoverAsc, setHoverAsc] = React.useState<Boolean>(false)
  const [hoverDsc, setHoverDsc] = React.useState<Boolean>(false);
  const params = useStore().params

  useEffect(() => {
    setValue(__internal_map.key(current))
  }, [current]);

  const handleChange = useCallback((_: any, order: React.SetStateAction<string>) => {
    if (order !== null) {
      setValue(order);
      filters.delete('sort');
      filters.append('sort', __internal_map[order as string]);
      push({ ...location, search: filters.toString() });
    }
  }, [value, location.search, location.pathname]);

  return useObserver(() => (
    <div className={classes.root}>
      <div className={classes.headerDiv}>
        <img src={SortSmall} />
        <label className={classes.sort}>SORT</label>
      </div>
      <div className={classes.floorDiv}>
        {params.graphType === 'barChart' ? <span className={classes.floor}>Sort Unit</span> :
          params.phase === "structures" && params.screen === "fullscreen" ?
            <span className={classes.floor}>Sort Floor</span>
            :
            <span className={classes.floor}>{params.phase === "structures" ? "Sort Floor" : "Floor Order"}(<span style={{ textTransform: "capitalize" }}>{value.toString().substring(0, 4)}</span>.)</span>}
        <ToggleButtonGroup
          size="small"
          value={value}
          exclusive
          onChange={handleChange}
        // style={{ background: "#faf9f9" }}
        >
          <ToggleButton className={classes.buttonToggleGroup} value="descending"
            onMouseOver={e => setHoverDsc(true)}
            onMouseLeave={e => setHoverDsc(false)}
          >
            {
              (hoverDsc && value === "ascending") ?
                <img src={hoverDesc} alt="Descending" />
                :
                <img src={value === "descending" ? defaultDesc : selectedDesc} alt="Descending" />
            }
          </ToggleButton>
          <ToggleButton className={classes.buttonToggleGroup} value="ascending"
            onMouseOver={e => setHoverAsc(true)}
            onMouseLeave={e => setHoverAsc(false)}
          >
            {
              (hoverAsc && value === "descending") ?
                <img src={hoverAscIcon} alt="Descending" />
                : <img src={value === "ascending" ? defaultAsc : selectedAsc} alt="Ascending" />
            }
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    </div>
  ));
}
export default React.memo(Sort)
