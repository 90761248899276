import React, { useEffect, useState, useMemo, memo } from "react";
import { useLocation, useHistory } from "react-router";
import { useSingleValueURLParam } from "../../utils/hooks";
import { useStore } from "../../models/ProvideModel";
import useStyles from '../../components/fatTable/FatTableStyle'
import ResetIcon from "../../assets/images/infoPanel/CloseIcon.svg";
import sortDesc from "../../assets/images/Sort-Z-A-White.svg";
import { columns as columnss } from "../../pages/exportSnagsPage/ExportSnagsPage"
import { UnitPerBlockFatcolumns } from "../../../src/containers/unitInfo/UnitPerBlockFat";
import { UnitInfoPagecolumns } from "../../../src/pages/unitInfoPage/UnitInfoPage";
import { CurrentStepcolumns } from "../../containers/stepInfo/StepPerUnitPerActivity"
import { FormDataColumns } from "../../pages/form-data-aggregate/FormDataAggregate";

const BySort = () => {
  const { push } = useHistory();
  const [filterParams, setfilterParams] = useState<any>()
  const location = useLocation();
  const { search } = useMemo(() => location, [
    location.search,
    location.pathname,
  ]);
  const store = useStore();
  const columns = store.params.section === "form-data" ? FormDataColumns : store.params.screen === "fullscreen" && store.params.phase === "finishing" ?
    store.params.section === "legacy" ? UnitPerBlockFatcolumns : UnitInfoPagecolumns :
    store.params.section === "snags" ? columnss : store.params.screen === "misc" ? CurrentStepcolumns : UnitInfoPagecolumns
  const searchParam = useMemo(() => new URLSearchParams(search), [search]);
  const [print] = useSingleValueURLParam("view", "normal", searchParam);
  const classes = useStyles({ print });

  // Issue the query
  const ascList = searchParam.getAll('sort');
  const descList = searchParam.getAll('sortDesc');

  const resetHandler = (id: string, key: string) => {
    switch (key) {
      case "resetSort":
        searchParam.delete('sort');
        searchParam.delete('sortDesc');
        ascList.forEach(val => {
          if (id === 'activity_type_id' && val === 'precedence') { // Special case Handled
            return null;
          }
          if (val === id) {
            return null;
          }
          searchParam.append('sort', val);
        });
        descList.forEach(val => {
          if (id === 'activity_type_id' && val === 'precedence') { // Special case Handled
            return null;
          }
          if (val === id) {
            return null;
          }
          searchParam.append('sortDesc', val);
        });
        break;
      case "resetAllSort":
        searchParam.delete('sort');
        searchParam.delete('sortDesc');
        break;
      default:
        break;
    }
    push({ ...location, search: searchParam.toString() });
  }
  useEffect(() => {
    const filterBySort: any[] = []
    columns.forEach(async function ({ id, name }) {
      if (ascList.concat(descList).find(name => name === id)) {
        filterBySort.push({ name, id })
      }
    })
    setfilterParams({ filterBySort });
  }, [searchParam]);

  const nonSupportedFilterIds = [
    "precedence"
  ];

  return (
    <div className={classes.infoPanelRoot}>
      <div className={classes.titleLable} style={{ marginTop: "0.625rem" }}>
        <div className={classes.filterIcondiv}>
          <img src={sortDesc} />
          <label className={classes.filterBy}>Sort By</label></div>
        {filterParams?.filterBySort?.length ?
          <div onClick={() => resetHandler("id", "resetAllSort")} className={classes.resetAll}>Reset</div>
          : null
        }
      </div>
      {
        filterParams?.filterBySort?.length ?
          filterParams?.filterBySort.map(({ name, id }, i) => {
            if (nonSupportedFilterIds.includes(id)) {
              return null;
            }

            return (
              <div key={name} className={classes.sortfilterRectangle}>
                <div className={classes.sortBlock}>
                  <span className={classes.blockNumber}>{(i + 1).toLocaleString('en-GB', { minimumIntegerDigits: 2 })}</span>
                  <div className={classes.columnText}>
                    <span style={{ color: "#585858" }}>{name}: </span>
                    {(id === "activity_type_id") && (<span>Precedence</span>)}
                    <span style={{ paddingLeft: "4px" }}> {descList.find(name => name === id) ? "Desc" : "Asc"} </span></div>
                </div>
                <img src={ResetIcon} alt="Reset" onClick={() => resetHandler(id, "resetSort")} style={{ cursor: "pointer", marginRight: "8px" }} />
              </div>
            )
          })
          :
          <div className={classes.emptyFilter}>
            <span className={classes.noFilter}>No Sorts Applied</span>
          </div>
      }
    </div>
  );
};

export default memo(BySort);
