import React from 'react'
import { renderString, SUPPORTTED_STATUS } from './StructuresFullscreen'
import { Box } from '@material-ui/core';
import moment from 'moment';
import useStyles from './BrickStyles';
import { Instance } from 'mobx-state-tree';
import { Brick } from '../../models/Brick/Brick';
import { useLocalStore } from 'mobx-react-lite';
import { IBrickProps } from './Brick';
export interface ISpecificBrickProps {
  brick: Instance<typeof Brick>;
};

function renderBorderColor(brick: ISpecificBrickProps['brick']) {
  switch (brick.status) {
    case 'notStarted': return '#d1d1d1';
    case 'started': return '#ffd300';
    case 'completed': return '#34d1ae';
    default: return 'null';
  };
}

export default function HoverStateComponent({ brick }: any) {
  const styleProps = useLocalStore(source => ({
    get filter() { return source.brick.filtered; },
    get strength() { return source.brick['strength'] || 1; }
  }), { brick });

  const { inProgress, completed, total, status } = brick;
  const notStarted = total - inProgress - completed;

  const getFormattedRemainingDays = (days) => {
    return `+ ${days} D `;
  }

  const brickProps: IBrickProps = {
    filter: styleProps.filter,
    strength: styleProps.strength,
    status: brick.status,
    width: brick.width,
    progressAsPercent: brick?.progressAsPercent?.split(' ')[0]
  }

  const classes = useStyles({ ...brickProps });
  const brickStatus = brick.status === SUPPORTTED_STATUS.STARTED || brick.status === SUPPORTTED_STATUS.COMPLETED

  const StructuresLanding = () => {
    return (
      <div
        className={classes.brickOpacity}
        style={{
          height: brickStatus ? "372px" : "160px",
          width: 172,
          cursor: 'pointer',
          borderRadius: "1px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          boxSizing: "content-box",
          border: `${"1px solid " + renderBorderColor(brick)}`
        }}
      >
        {brickStatus && (<div className={classes.brickProgressBg}></div>)}
        <div className={classes.brickContent}>
          <div>
            <div className={classes.brickName}><span>{brick.name}</span></div>
            <div className={!!brick.totalDelay ? classes.delayed : classes.display}>{renderString(brick)}</div></div>

          {brickStatus && <div>
            <div className={classes.date} style={{ fontWeight: "bold", textTransform: "capitalize" }}>{'Units Completed'}</div>
            <div className={classes.date}>{brick?.progressDetails?.units?.display || '-/-'}</div></div>
          }
          <div className={classes.mainDiv}>
            <div className={classes.normText}>
              <div className={classes.startDelayedCircle} style={{ marginTop: "2px" }}></div>
              <div className={classes.datesDiv}>
                <span style={{ paddingBottom: "1px" }}>Plan Start</span>
                <span>{brick?.plan?.from ? moment(brick?.plan?.from).format('DD[ ]MMM[ ]YY') : '—'}</span></div>
            </div>
            {brickStatus && <div className={classes.normText}>
              <div className={classes.circle} style={{ marginTop: "2px" }}></div>
              <div className={classes.datesDiv}>
                <span style={{ paddingBottom: "1px" }}>Actual Start</span>
                <div className={classes.remainingDays}>
                  <span>{!!brick.actual && !!brick.actual.from ? moment(brick.actual.from).format('DD[ ]MMM[ ]YY') : '—'}</span>
                  {brick.startDelay && <span style={{ color: "#ff7777" }}>{getFormattedRemainingDays(brick.startDelay)}</span>}
                </div></div>
            </div>}
            <div className={classes.normText}>
              <div className={classes.endDelayedRectangle} style={{ marginTop: "2px" }}></div>
              <div className={classes.datesDiv}>
                <span style={{ paddingBottom: "1px" }}>Plan End </span>
                <span>{brick?.plan?.to ? moment(brick?.plan?.to).format('DD[ ]MMM[ ]YY') : '—'}</span></div>
            </div>
            {brickStatus && <div className={classes.normText}>
              <div className={classes.rectangle} style={{ marginTop: "2px" }}></div>
              <div className={classes.datesDiv}>
                <span style={{ paddingBottom: "1px" }}>Actual End</span>
                <div className={classes.remainingDays}>
                  <span>{!!brick.actual && !!brick.actual.to ? moment(brick.actual.to).format('DD[ ]MMM[ ]YY') : '—'}</span>
                  {brick.endDelay && <span>{getFormattedRemainingDays(brick.endDelay)}</span>}
                </div></div>
            </div>}
          </div>
          {brickStatus && <>{brick.endDelay ? (<text x="130" y={288} className={classes.delayed}>
            + {brick.endDelay.toLocaleString('en', { minimumIntegerDigits: 2 })} D
          </text>)
            : null
          }
            <div>
              <div className={classes.display} style={{ fontWeight: 'bold', paddingBottom: "7px" }}>
                <span style={{ paddingRight: "2px" }}>Overall Completion</span> <span>{brick.progressAsPercent}</span></div>
              <div className={classes.date}>{'Activity Completed'}</div>
              <div className={classes.date}>{brick?.progressDetails?.activities?.display || '-/-'}</div></div></>}
        </div>
      </div>
    )
  }

  const FinishingHover = () => {
    return (
      <div
        className={classes.root}
        style={{
          height: "auto",
          width: 150,
          cursor: 'pointer',
          borderRadius: "1px",
          border: `${"1px solid " + renderBorderColor(brick)}`
        }}
      >
        <div className={classes.capHead4}><span>{brick.activity}</span></div>
        <Box display="flex" justifyContent="space-between" className={classes.capHead5} style={{ paddingBottom: "12px" }}><span>Floor {" "} {" "}</span> <span style={{ fontWeight: "normal" }}> {brick.screen === "landing" && brick.phase === "finishing" ? "All Floors" : (brick.yVal.id).toLocaleString('en-GB', { minimumIntegerDigits: 2 })}</span> </Box>
        <div style={{ paddingBottom: "12px" }}>
          <Box display="flex" justifyContent="space-between" className={classes.boldText12Px}><span>Total units:</span> <span> {total.toLocaleString('en-GB', { minimumIntegerDigits: 2 })}</span> </Box>
          <Box display="flex" justifyContent="space-between" alignItems='center' className={classes.normText12Px}><span>Completed: </span> <span> {completed > 0 ? completed.toLocaleString('en-GB', { minimumIntegerDigits: 2 }) : "—"}</span></Box>
          <Box display="flex" justifyContent="space-between" alignItems='center' className={classes.normText12Px}><span>Ongoing: </span> <span> {inProgress > 0 ? inProgress.toLocaleString('en-GB', { minimumIntegerDigits: 2 }) : "—"} </span> </Box>
          <Box display="flex" justifyContent="space-between" alignItems='center' className={classes.normText12Px}><span>Not Started: </span> <span> {notStarted > 0 ? notStarted.toLocaleString('en-GB', { minimumIntegerDigits: 2 }) : "—"}</span> </Box>
        </div>
        {
          status === "completed" &&
          <Box display="flex" flexDirection="column" className={classes.capHead5}><span> Completed On </span> <span style={{ fontWeight: "normal" }}> {brick.actual && brick.actual.to ? brick.actual.to.format('DD[ ]MMM[ ]YYYY') : "—"} </span> </Box>
        }
        {
          status === "started" &&
          <Box display="flex" flexDirection="column" className={classes.capHead5}><span> Planned Completion date </span> <span style={{ fontWeight: "normal" }}> {brick.plan && brick.plan.to ? brick.plan.to.format('DD[ ]MMM[ ]YYYY') : "—"} </span> </Box>
        }
      </div>
    )
  }

  return (
    brick.screen === "landing" && brick.phase === "structures" ?
      {
        completed: <StructuresLanding />,
        started: <StructuresLanding />,
        notStarted: <StructuresLanding />,
        default: <div>No status found...</div>
      }[brick?.status || 'default']
      :
      brick.phase === "finishing" ? <FinishingHover /> : null
  )
}
