import React, { useRef, useEffect, RefObject, useState, Dispatch, SetStateAction, memo } from "react";
import { observer } from "mobx-react-lite";
import { reaction } from "mobx";
import { getSnapshot } from 'mobx-state-tree';
import { useHistory } from 'react-router';
import { useStore } from "../../models/ProvideModel";
import Container from "../../containers/scrollingLayout/ScrollingLayout";
import useStyles from './FullScreenPageStyles';
import TabTiles from "../../containers/tabTiles/tabTiles"
import UnitTypeSummary from "../../containers/Summary/unitTypeSummary"
import NoDataFound from '../../components/noDataFound/TcaNoDataFound';
import { GOT } from "../../models/enums";
import ByUnitType from "../../components/filter/ByUnitType";
import ByTowerTca from "../../components/filter/ByTowersTca";
import { sortingStrategyName } from "../../utils/utils";
import arrow from "../../assets/images/dooku/SmallArrow.svg"

function update(divRef: RefObject<HTMLDivElement>, setWidth: Dispatch<SetStateAction<number>>, setHeight: Dispatch<SetStateAction<number>>, isLg: any): void {
  if (divRef.current) {
    const box = divRef.current!.getBoundingClientRect() as DOMRect;
    setWidth(box.width - (isLg ? 60 : 30));
    setHeight(box.height - (isLg ? 100 : 50));
  }
}

const FullScreenTcaFinishingPage = () => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const classes = useStyles();
  const store = useStore();
  const { push } = useHistory();
  const Summary = store.summary.Summary;
  const divRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const tabData: any = JSON.parse(localStorage.getItem('tabs') || "[]").filter(({ phase, spaceType }) => phase === store.currentDashboard?.phase && spaceType === store.currentDashboard?.spaceType);

  const screenProps: any = {
    isLg: store.responsiveUtils.currentViewport.isLg,
    isSm: store.responsiveUtils.currentViewport.isSm,
    isXs: store.responsiveUtils.currentViewport.isXs
  };

  useEffect(() => reaction(() => [store.currentTower, store.params.path, store.responsiveUtils.fullScreenMode, store.totalRows, store.totalColumns, store.currentDashboard?.loading], () => {
    // setImmediate because the damn ref is not mounting onto the div when
    // update is run. Correct at some point. This is a potential memory leak.
    setImmediate(() => update(divRef, setWidth, setHeight, store.responsiveUtils.currentViewport.isLg));
  }), []);
  /**
   * 
   * Reload once Fullscrenn mode changed.
   * 
   */
  useEffect(() => {
    update(divRef, setWidth, setHeight, store.responsiveUtils.currentViewport.isLg);
  }, []);
  /**
   * 
   * Reload once Fullscrenn mode changed.
   * 
   */
  /* useEffect(() => {
   *   update(divRef, setWidth, setHeight);
   * }, [store.responsiveUtils.fullScreenMode])
   */

  let IsMacOs = false;
  if (navigator.appVersion.indexOf("Mac") !== -1) IsMacOs = true;
  /* if (!store.currentTower || store.currentDashboard?.loading) {
   *   return (
   *     <div className={classes.root} style={{ justifyContent: "center", alignItems: "center" }}>
   *       <LoadingSkeleton />
   *     </div>
   *   );
   * } */
  /* if (['brickGraph', 'barChart'].includes(store.params.graphType) && store.currentTower && store.currentDashboard?.hasData) { */
  function CallbackFunctionToFindTowerById(towerName) {
    if (towerName.id === store.params.tab) {
      return towerName.name
    }
  }
  var towerName = tabData.find(CallbackFunctionToFindTowerById);

  return (
    <div className={classes.root}>
      <div className={classes.dropdownDiv} style={{ width: store.responsiveUtils.currentViewport.isLg ? "75%" : "63%" }}>
        <div style={{ width: "250px", marginLeft: "22px" }}>
          <ByUnitType tab={towerName?.name} data={tabData} />
        </div>
        <img src={arrow} height={30} width={30}></img>
        <div style={{ width: "250px" }}> <ByTowerTca data={store.currentDashboard?.towers.slice().sort(sortingStrategyName)} /></div>
      </div>
      <div className={classes.mainDiv} style={{ display: "flex", height: "90%", paddingTop: "24px" }}>
        {!(store.currentDashboard?.loading || store.currentDashboard?.tab?.loading) && !store.currentDashboard?.tab?.hasData && <NoDataFound />}
        <>
          <TabTiles
            data={store.currentDashboard?.tabsList || []}
            title="LIST OF UNIT TYPES"
            screen="fullscreen"
            searchString="unit types"
            selected={store.params.unitType}
            handleClick={(id: string) => { push(store.params.toPath({ ...getSnapshot(store.params), unitType: id })); }}
            href={(id) => id !== undefined ? `${window.location.href.split('/', 3).join('/')}${store.params.toPath({ ...getSnapshot(store.params), unitType: id })}` : ``}
            loading={(store.currentDashboard?.loading || store.currentDashboard?.tab?.loading)}
            screenProps={screenProps}
          />
          <div ref={divRef} className={classes.landing} style={{ width: "78%" }}>
            <UnitTypeSummary
              data={store.currentDashboard?.tabsList || []}
              selected={store.currentDashboard?.tab?.graphs.get(store.params.unitType)?.name || store.params.unitType}
              floors={store.currentDashboard?.rows?.length}
              screenProps={screenProps}
            />
            {['brickGraph', 'barChart', 'baratheon'].includes(store.params.graphType) && store.currentTower ?
              <Container
                width={width}
                height={height}
                IsMacOs={IsMacOs}
                screen={store.params.screen}
                phase={store.params.phase}
                loading={store.loading || store.currentDashboard?.loading || store.currentDashboard?.tab?.loading}
                label={store.currentDashboard?.tab?.graphType === GOT.STARK ? "Floors" : null}
              />
              : null
            }
          </div></>
      </div>
      <div className={classes.fix}></div>
      <Summary {...store.summary.props} />
    </div>
  );
};

export default observer(FullScreenTcaFinishingPage);
