import React, {
  memo,
  useCallback,
  useMemo,
  useEffect
} from "react";
import { useHistory, useLocation } from "react-router";
import { putAsync } from "csp-with-ts";
import useAccordion from "./UseAccordionHook";
import { SelectedState } from './SelectedState'
import RadioButton from "./RadioButton";
import { DefaultList } from "./DefaultAccordianRender";

const byParam: string = "Phase";
export interface IByPhase {
  data: { id: string; name: string }[];
  phase: string;
  spaceType: string;
  section: string;
}

const ViewPhase = ({ phases, sel, current }) =>
  (<div><span>{sel ?
    phases.find(({ id }) => id === sel)?.name :
    phases.find(({ id }) => id === current)?.name}</span> </div>);


function PhaseType({ data, phase, spaceType, section }: IByPhase) {
  const { search } = useLocation();
  const towers = useMemo(() => (new URLSearchParams(search)).getAll('block_id').map(val => `block_id=${val}`).join('&'), [search]);
  const { push } = useHistory();
  const initialState = useCallback(() => phase ? [phase] : [], [phase]);

  const [state, ByPhaseFilter, relay] = useAccordion<{ id: string; name: string; }, 'id'>(DefaultList, { multiple: false, apply: true, accordian: true, reset: false }, initialState);
  useEffect(() => { putAsync(relay, { type: 'replace', payload: phase ? [phase] : [] }); }, [phase]);

  const disabled = useMemo(() => {
    if (state[0] && state[0] === phase) { return true; }
    return false;
  }, [state, phase]);

  const applyHandler = () => {
    // maybe generalize this later sometime.
    if (state[0]) {
      // Breaking my own rule a little here
      if (section === "checklist-reports") { push(`/${section}/${state[0]}`); }
      else { push(`/${section}/${state[0]}/${spaceType}?${towers}`); }
    }
  };

  return (
    <ByPhaseFilter
      options={data}
      Render={RadioButton}
      callback={applyHandler}
      disabled={disabled}
      // title={byParam}
      title={<SelectedState title={byParam} state={state} filterType={"phaseType"} />}
      externalDisplay={<ViewPhase phases={data} sel={state[0]} current={phase} />}
    />
  );
};

export default memo(PhaseType);
