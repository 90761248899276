import React, { memo, useCallback, useMemo } from "react";
import { Radio, MenuItem, ListItemText } from "@material-ui/core";
import useStyles from "../radioSortFilter/RadioSortFilterStyles";
import commonStyles from "./FilterStyles";
import clsx from "clsx";

const RadioButton = memo(
  ({
    value,
    id,
    handleClick,
    name,
  }: {
    value: string[];
    id: string;
    handleClick: (id: string | number) => void;
    name: string;
  }) => {
    const checked = useMemo(() => {
      return (value as Array<
        typeof value extends Array<infer T> ? T : never
      >).includes(id);
    }, [id, value]);

    const classes = useStyles();
    const commonClasses = commonStyles({ checked });
    const handleClickCallback = useCallback(() => {
      handleClick(id);
    }, []);

    return (
      <MenuItem
        key={id}
        value={id}
        className={commonClasses.menuItems}
        onClick={handleClickCallback}
      >
        <Radio
          className={classes.root}
          checked={checked}
          color="default"
          checkedIcon={
            <span className={clsx(classes.icon, classes.checkedIcon)} />
          }
          icon={<span className={classes.icon} />}
          value={name}
        />
        <ListItemText
          style={{
            paddingLeft: "4px",
            whiteSpace: "pre-wrap",
            lineHeight: 1.25,
          }}
          primary={name}
        />
      </MenuItem>
    );
  }
);

export default RadioButton;
