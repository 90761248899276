import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import arrowDisabledIcon from "../../../assets/images/Arrow-Circle-Close-Disabled.svg";
import arrowCloseIcon from "../../../assets/images/Arrow-Circle-Close.svg";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: "Source Sans Pro, sans-serif !important",
    },
    textTransform: {
      textTransform: "uppercase"
    },
    dialogBox: {
      backgroundColor: "#fff",
    },
    dialogBoxLg: {
      backgroundColor: "#fff",
      "& .MuiDialog-paperFullScreen": {
        backgroundColor:"#faf9f9"
      }
    },
    appBar: {
      position: 'sticky',
      // height: "88px !important",
      backgroundColor: "#ffffff !important",
      color: "#005eff !important",
      boxShadow: "none !important",
      fontFamily: "Source Sans Pro, sans-serif !important",
      padding: "0px !important",

    },
    toolbar: {
      padding: "0px !important"
    },
    header: {
      flex: 1,
      color: "#005eff",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "80%",
      fontFamily: "Source Sans Pro, sans-serif !important",

    },
    headerFinishing: {
      color: "#005eff",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      fontFamily: "Source Sans Pro, sans-serif !important",
      paddingLeft: '16%'
    },
    unitSnapshot: {
      fontSize: "12px",
      fontWeight: "bold",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#585858",
      paddingTop: "17px",
      paddingBottom: "8px",
      fontFamily: "Source Sans Pro, sans-serif !important",
    },
    unitTitle: {
      height: "48px",
      padding: "12px",
      fontSize: "20px",
      fontWeight: "bold",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#585858",
      fontFamily: "Source Sans Pro, sans-serif !important",
    },
    rightContent: {
      // width: "20%",
      height: "88px",
      // paddingTop: "40px",
      display: "flex",
      justifyContent: "flex-end",
      // paddingRight: "16px",
      // paddingLeft: "16px",
      alignItems: "center",
      fontFamily: "Source Sans Pro, sans-serif !important",
    },
    rightContentFinishing: {
      width: "20%",
      minHeight: "48px",
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "32px",
      paddingLeft: "16px",
      alignItems: "baseline",
      fontFamily: "Source Sans Pro, sans-serif !important",
    },
    mainDiv: {
      display: "flex",
      height: "100%",
      overflow: "hidden"
    },
    mainDivFinishing: {
      // display: "flex",
      height: "100%",
      backgroundColor:"#faf9f9"
    },
    leftDivStructurs: {
      width: "100%",
      // overflowY: "scroll",
      marginRight: "20px",
      marginLeft: "20px",
    },
    Headers: {
      height: "80px",
      fontSize: "4em",
      fontWeight: "bold",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "1.25",
      letterSpacing: "normal",
      textAlign: "center",
      color: "#eaeaea",
      marginTop: "12px",
      marginBottom: "16px",
      fontFamily: "Source Sans Pro, sans-serif !important",
      textTransform:"capitalize"
    },
    flexBoxCenter: {
      display: "flex",
      justifyContent: "center",
    },
    flexBoxSpaceBetween: {
      display: "flex",
      justifyContent: "space-between",
    },

    summaryDetailsList: {
      display: "flex",
      flexDirection: "column",
      paddingBottom: "24px",
      "&:nth-child(4n)": {
        paddingBottom: "0px"
      }
    },
    summaryDetailsListBoldItems: {
      fontSize: "11px",
      fontWeight: "bold",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: 1.27,
      letterSpacing: "normal",
      textAlign: "left",
      fontFamily: "Source Sans Pro, sans-serif !important",
      color: "#585858",
      paddingBottom: "8px",
      textTransform: "uppercase"

    },
    summaryDetailsListNormalItems: {
      fontSize: "14px",
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: 1.29,
      letterSpacing: "normal",
      textAlign: "left",
      fontFamily: "Source Sans Pro, sans-serif !important",
      color: "#585858",
      textTransform:"capitalize"
    },
    endDelayAvtar: {
      height: "12px !important",
      width: "8px !important",
      backgroundColor: "#ff3535 !important",
      alignSelf: "center !important",
      borderRadius: "1px !important",
      marginRight: "6px !important"
    },
    avtarCompleted: {
      width: "10px !important",
      height: "10px  !important",
      borderRadius: "1px !important",
      backgroundColor: "#34d1ae !important",
      alignSelf: "center !important",
      marginRight: "6px !important"
    },
    avtarOngoing: {
      width: "10px !important",
      height: "10px !important",
      borderRadius: "1px !important",
      backgroundColor: "#ffd300 !important",
      alignSelf: "center !important",
      marginRight: "6px !important"
    },
    avtarNotStarted: {
      width: "10px !important",
      height: "10px !important",
      borderRadius: "1px !important",
      backgroundColor: "#eaeaea !important",
      alignSelf: "center !important",
      marginRight: "6px !important",
      border: "1px solid #bfbfbf !important"
    },
    activityCompletedAvtar: {
      width: "10px !important",
      height: "10px !important",
      borderRadius: "1px !important",
      backgroundColor: "#34d1ae !important",
      alignSelf: "center !important",
      marginRight: "8px !important"
    },
    activityOngoingAvtar: {
      width: "10px !important",
      height: "10px !important",
      borderRadius: "1px !important",
      backgroundColor: "#ffd300 !important",
      alignSelf: "center !important",
      marginRight: "8px !important"
    },
    activityNotStartedAvtar: {
      width: "10px !important",
      height: "10px !important",
      borderRadius: "1px !important",
      backgroundColor: "#eaeaea !important",
      alignSelf: "center !important",
      marginRight: "8px !important",
      border: "1px solid #bfbfbf !important"
    },
    delayedAvtar: {
      width: "10px !important",
      height: "10px !important",
      alignSelf: "center !important",
      marginRight: "6px !important",
      background: "#ff3535 !important"
    },
    divider: {
      width: "1px",
      height: "103px",
      borderRadius: "10px",
      backgroundColor: "#d1d1d1",
      alignSelf: "center",
      margin: "0px 65px"
    },
    summaryTableDiv: {
      width: "554px",
      // height: "151px",
      borderRadius: "2px",
      backgroundColor: "#f5f5f5",
      padding: "0px 48px 24px 48px"
    },
    summaryDetails: {
      width: "60%",
      padding: "0px 24px"
    },
    summaryTableBox: {
      width: "152px",
      padding: "0px 0px 0px 24px",
      borderRight: "1px solid #d1d1d1",
      marginTop: "24px",
      "&:nth-child(3n)": {
        borderRight: "none",
      }
    },
    summaryDetailsGrid: {
      borderRight: "1px solid #d1d1d1"
    },
    tableHeading: {
      fontFamily: "Source Sans Pro, sans-serif !important",
      fontSize: "14px",
      fontWeight: "bold",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: 1.29,
      letterSpacing: "normal",
      textAlign: "left",
      color: "#585858",
      textTransform:"capitalize"
    },
    tableRow: {
      fontFamily: "Source Sans Pro, sans-serif !important",
      // height: "15px",
      fontSize: "12px",
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: 1.25,
      letterSpacing: "normal",
      textAlign: "left",
      color: "#585858",
      textTransform:"capitalize"
      // display: "flex",
      // justifyContent: "space-between",
    },
    columnsDiv: {
      display: "flex",
      flexDirection: "column",
      width: "99px",
      // padding: "20px",
    },
    activityColumnsDiv: {
      display: "flex",
      flexDirection: "column",
      width: "170px",
      // padding: "20px 0px",
    },
    activityName: {
      // minWidth:"250px",
      height: "18px",
      fontSize: "12px",
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: 1.29,
      letterSpacing: "normal",
      textAlign: "left",
      color: "#585858",
      // color: "#005eff",
      fontFamily: "Source Sans Pro, sans-serif !important",
      // cursor: "pointer",
      textTransform:"capitalize"
    },
    convActivityName: {
      minWidth:"250px",
      height: "18px",
      fontSize: "12px",
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: 2,
      letterSpacing: "normal",
      textAlign: "left",
      // color: "#585858",
      color: "#005eff",
      fontFamily: "Source Sans Pro, sans-serif !important",
      cursor: "pointer",
      textTransform:"capitalize"
    },
    activityStatus: {
      height: "18px",
      fontSize: "12px",
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: 1.29,
      letterSpacing: "normal",
      textAlign: "left",
      color: "#585858",
      fontFamily: "Source Sans Pro, sans-serif !important",
      paddingBottom: "25px",
      
    },
    pageDivider: {
      width: "1px",
      borderRadius: "10px",
      backgroundColor: "#d1d1d1",
      marginRight: "10px"
    },
    activityTableDiv: {
      
      // height: "568px",
      width: "80%",
      border: "solid 1px #d1d1d1",
      // padding: "24px 25px",
      paddingTop: "24px",
      paddingRight: "25px",
      paddingLeft: "25px",
      // overflow: "auto"
    },

    peopleInvolvedTableDiv: {
      width: "20%",
      height: "156px",
      border: "1px solid #d1d1d1",
      borderRadius: "1px",
      padding: "0px 20px",
      display: "flex",
      marginBottom: "50px"
    },
    peopleInvolvedTable: {
      width: "557px",
      border: "1px solid #d1d1d1",
      padding: "20px"
    },
    rightDiv: {
      width: "20%",
    },
    tableRowsAlign: {
      paddingLeft: "12px",
      borderLeft: "1px solid #d1d1d1",
    },
    tableHead: {
      paddingLeft: "10px",
    },
    tableHeadGrid: {
      paddingBottom: "16px"
    },
    summaryTableHeadGrid: {
      paddingBottom: "16px",
      "&:last-child": {
        paddingBottom: "0px",
      }
    },
    tableRowGrid: {
      paddingBottom: "12px"
    },
    activityTableDivider: {
      width: "1px",
      height: "486px",
      borderRadius: "10px",
      backgroundColor: "#d1d1d1",
      alignSelf: "center",
      margin: "0px 19px"
    },
    detailsButton: {
      width: "124px",
      height: "32px",
      borderRadius: "2px",
      border: "solid 1px #005eff",
      backgroundColor: "#ffffff",
      padding: "0px !important",
      "&:hover": {
        backgroundColor: "#ffffff"
      }
    },

    //Finishing Activity Styles

    finishingBox: {
      borderLeft: "1px solid #d1d1d1",
      padding: "0px 16px 0px 12px",
      width: "100%",
      "&:first-child": {
        border: "none",
        padding: "0px"
      }
    },
    cellSpacing: {
      paddingBottom: "32px",
      "&:last-child": {
        paddingBottom: "0px"
      }
    },
    cellHeading: {
      width: "62px",
      height: "30px",
      fontFamily: "Source Sans Pro, sans-serif !important",
      fontSize: "12px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.25",
      letterSpacing: "normal",
      textAlign: "left",
      color: "#585858",
      textTransform: "uppercase"
      // paddingBottom:"32px",
    },
    delay: {
      color: theme.palette.error.main,
    },
    backBtn: {
      fontSize: 14,
      fontFamily: 'Source Sans Pro',
      fontWeight: "normal",
      alignSelf: "center",
      marginLeft: 0,
    },
    backDiv: {
      color: '#005eff',
      border: "none",
      textAlign: "center",
      marginRight:"8px",
      transform:"scaleX(-1)",
      padding: "0px",
      width: "80px",
      transition: "margin 0.5s",
      cursor: "pointer",
      "& span": {
        cursor: "pointer",
        display: "inline-block",
        position: "relative",
        transition: "0.5s",
        "&:after": {
          position: "absolute",
          opacity: 0,
          top: 0,
          right: "-15px",
          transition: "0.5s",
        },
      },
      "&:hover": {
        color:"#585858",
        "& span": {
          paddingRight: "8px",
          "&:hover": {
            opacity: 1,
            right: 0,
            color:"#585858"
          }
        }
      }
    },
    changeImage: {
      position:"absolute",
      color: '#005eff',
      right:0,
      background:
          `url(${arrowDisabledIcon})  no-repeat`,
      display: "inline-block",
      "&:hover": {
        background:
        `url(${arrowCloseIcon})  no-repeat`,
        color:"#585858"
      },
      transform:"scaleX(-1)"
    },
    bottomPadding: {
      paddingBottom: '12px',
    }

  }),
);

export default useStyles