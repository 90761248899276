import React from "react";
import { useObserver } from 'mobx-react-lite';
import useStyles from "./ScrollingLayoutStyles";
import downArrow from '../../assets/images/downloadIcons/downArrow.svg';

export interface INoofFlooreOrUnitsViews {
  label: string | null
};
const NoofFlooreOrUnits = ({ label }: INoofFlooreOrUnitsViews) => {
  const classes = useStyles();

  return useObserver(() => (
    <div className={classes.floorCountRootDiv}>
      {label !== null && <><span className={classes.FloorFont}>{label}</span>
        <img src={downArrow} height={14}></img></>
      }
    </div >
  ));
};

export default NoofFlooreOrUnits;