import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import ApolloClient from "apollo-client";
import { NormalizedCacheObject } from "apollo-cache-inmemory";
import client from "../../utils/apolloClient";
import { GET_SNAGS_SUMMARY } from "../../utils/queries";
import { IQueryParams } from "../../pages/exportSnagsPage/ExportSnagsPage";
import useStyles from "./TableSnagInformationStyles";
import { useStore } from '../../models/ProvideModel';
import LoadingSpinner from "../loadingSkelaton/LoadingSpinner";


const TableSnagInformation = ({ pathParams, params, totalCount, loading }: { params?: IQueryParams; pathParams: { [K: string]: any; }; totalCount: number; loading?: boolean }) => {
  const store = useStore();
  const [data, setData] = useState<{ [K: string]: number; }>({});
  /* let blocks: any = [];
   * let units: any = [];
   * let unitTypes: any = [];
   * let ActivityTypes: any = []; */
  useEffect(() => {
    if (!client.client) {
      client();
    }
  }, [client]);

  useEffect(() => {
    if (client.client && params && pathParams['space_type_id'] && pathParams['phase_id'] && store.params.phase.toLowerCase() !== "none" && store.params.spaceType.toLowerCase() !== "none") {
      const query = (client.client as ApolloClient<NormalizedCacheObject>)!
        .watchQuery({ query: GET_SNAGS_SUMMARY, variables: { where: { ...params.where, block: { property_id: { _in: store.projectInfo.currentProject.properties.map(({ id }) => id) } }, ...pathParams } } })
        .subscribe(({ data, loading }) => {
          if (!loading) {
            setData(() =>
              Object.keys(data).reduce(
                (acc, id) => ({ ...acc, [id]: data[id].total.count }),
                {}
              )
            );
          }
        });
      return () => {
        query.unsubscribe();
      };
    }
  }, [pathParams, params]);
  const classes = useStyles();
  return (
    <div className={classes.rootDiv}>
      {Object.keys(data).length > 0 || loading == false ?
        <Grid container className={classes.GridBox}>
          <Grid key="1" item xs={2} style={{ flexBasis: "auto", display: "flex", marginRight: "18px" }}>BLOCK: <span className={classes.PropertyValue}>{data['block_id']?.toLocaleString('en-GB', { minimumIntegerDigits: 2 })}</span></Grid>
          <Grid key="2" item xs={2} style={{ flexBasis: "auto", display: "flex", marginRight: "18px" }}>UNITS: <span className={classes.PropertyValue}>{data['unit_id']?.toLocaleString('en-GB', { minimumIntegerDigits: 2 })}</span></Grid>
          <Grid key="3" item xs={3} style={{ flexBasis: "auto", display: "flex", marginRight: "18px" }}>UNIT TYPES: <span className={classes.PropertyValue}>{data['unit_type']?.toLocaleString('en-GB', { minimumIntegerDigits: 2 })}</span></Grid>
          <Grid key="4" item xs={3} style={{ flexBasis: "auto", display: "flex", marginRight: "18px" }}>ACTIVITY TYPES: <span className={classes.PropertyValue}>{data['activity_type_id']?.toLocaleString('en-GB', { minimumIntegerDigits: 2 })}</span></Grid>
          <Grid key="5" item xs={2} style={{ flexBasis: "auto", display: "flex", marginRight: "18px" }}>{store.params.section === "tasks" ? "TASKS:" : "SNAGS:"} <span className={classes.PropertyValue}>{totalCount.toLocaleString('en-GB', { minimumIntegerDigits: 2 })}</span></Grid>
        </Grid> :
        loading === true && <div
          style={{
            background: "#fff",
            width: "100%",
            marginTop: "10px",
          }}
        >
          <LoadingSpinner />
        </div>}
    </div>
  );
}
export default React.memo(TableSnagInformation);
