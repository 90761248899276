import React, { memo } from 'react'
import { useStore } from '../../models/ProvideModel';
import { useHistory } from 'react-router';
import { Link } from '@material-ui/core';
import { getSnapshot } from 'mobx-state-tree';
import { useObserver } from 'mobx-react-lite';

const UnitNameCell = (props) => {
  const store = useStore();
  const params = store.params
  const unit_id = props?.cell?.value
  const UnitName: string = props?.row?.original?.unit_name;
  const history = useHistory();
  const Urlpath: any = store.params.toPath({ ...getSnapshot(store.params), section: "unit-act-info", spaceType: "tower" }) + `?unit_id=${unit_id}&sort=precedence&sort=activity_type_id`

  const handleShow = (e) => {
    document?.getElementById(UnitName)?.removeAttribute("href");
    history.push(Urlpath);
  }

  return useObserver(() => (
    <div style={{ display: "flex", height: "100%", alignItems: "center" }}>
      <Link
        // component="link"
        id={UnitName}
        variant="body2"
        href={`${window.location.href.split('/', 3).join('/')}${Urlpath}`}
        target="popup"
        onClick={(e) => { handleShow(e); return false }}
        style={{ textAlign: "left", color: "#005eff" }}>
        <span>{UnitName}</span>
      </Link>
    </div>
  ))
}

export default memo(UnitNameCell)
