import React, { useState, memo, useEffect } from "react";
// import "../components/switchTowersDD/animation.scss";
import RenderImagesCell from "../RenderImagesCell";
import moment from "moment";

const PhotoSubmitNew = props => {
  const imageData = props.cell.row.original['data'];
  const addedBy = props.cell.row.original['photo_added_by'];
  const addedOn = props.cell.row.original['photo_added_on']
  const [imageSources, setImageSources] = useState<string[]>([])

//   if (!imageData) {
//     return <div> — </div>;
// }

  useEffect(() => {
    if(imageData) {
      const { submit_inspection_photo_url: imageFileName, submit_inspection_description: inspectionDescription } = imageData;
        setImageSources(prev => [...prev, imageFileName])
    }
  }, [])

  return imageData ? <div>
    <RenderImagesCell images={imageSources} />
    <div style={{ marginTop: "1em" }}>
                    <span style={{ fontSize: "1em", marginTop: "10px" }}>{ imageData.submit_inspection_description ||"-"}</span><br />
                    <span style={{ fontSize: "0.875em" }}>{addedBy} | {moment(addedOn).format("D MMM YYYY")} | {moment(addedOn).format("hh:mm A")}</span>
                </div>
  </div> : <div> — </div>;;
}

export default memo(PhotoSubmitNew);
