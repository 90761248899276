import { getRoot, Instance, isStateTreeNode, SnapshotIn, types, getParent } from 'mobx-state-tree';
import { Filters, Params } from './Common';
import { Brick } from './Brick/Brick';
import { CellAddress, CellAddressActivities } from './Brick/CellAddress';
import { LateStoreModel } from './DataStore';
import { BrickTypes, CellAddressType, GOT } from './enums';
import { getColumnsFiltered, getIndicesCol, getStages } from '../utils/utils';

type ISortingStrategy<T> = (a: T, b: T) => number;

const sortingStrategy: ISortingStrategy<Instance<typeof CellAddress>> = (a, b) => {
    if (typeof a.id === "number" && typeof b.id === 'number')
        return a.id - b.id;
    return (a.name as string).localeCompare(b.name as string);
};
const sortingStrategyRev: ISortingStrategy<Instance<typeof CellAddress>> = (a, b) => {
    if (typeof a.id === "number" && typeof b.id === 'number')
        return b.id - a.id;
    return (b.name as string).localeCompare(a.name as string);
};
const sortingWithPrecedence: ISortingStrategy<Instance<typeof CellAddressActivities>> = (a, b) => {
    return a.precedence! - b.precedence! === 0 ? sortingStrategy(a, b) : a.precedence! - b.precedence!;
}

// const __dispatcher = (val: any) => {
//     switch (val.type) {
//         case GOT.STARK: return Stark;
//         case GOT.BARATHEON: return Baratheon;
//         default: throw new Error("unimplemented");
//     }
// };

// timing getBrick and mapping views showed that mapping is recreating the Map
// everytime, which takes ~3ms per call, and then get takes another ~2 ms.
// Changing strategy to directly add a volatile layout prop which contains the
// object. This is very ad hoc and Proof of Concept. It WILL break for mutation actions.
export const BricksCollection = types.model("BricksCollection", {
    id: types.identifier,
    name: types.string,
    type: types.frozen<GOT>(),
    bricks: types.array(Brick),
    brickType: types.frozen<BrickTypes>(),
    columns: types.array(CellAddress),
    columnType: types.frozen<CellAddressType>(),
    rows: types.array(CellAddress),
    rowType: types.frozen<CellAddressType>()
}).volatile(_ => ({ layout: {} })).views(self => ({
    get floorSort(): boolean {
        return (getRoot(self) as Instance<typeof LateStoreModel>).filters.floorSort;
    }
})).views(self => ({
    get rowSorted() {
        if (self.rowType === CellAddressType.NULL) { return Array.from(self.rows); }
        const sort = self.rowType === CellAddressType.FLOOR_NUM ? self.floorSort ? sortingStrategyRev : sortingStrategy : sortingStrategyRev;
        const rows = self.rowType === CellAddressType.TOWER_STRING && (getRoot(self) as Instance<typeof LateStoreModel>).params.screen === "landing" ? self.rows.filter(r => (getRoot(self) as Instance<typeof LateStoreModel>).towersList.find(t => t.id === r.id)) : self.rows;
        return Array.from(rows).sort(sort);
    },
    get columnSorted() {
        if (self.columnType === CellAddressType.NULL) { return Array.from(self.columns); }
        const sort = self.columnType === CellAddressType.ACT_STRING ? sortingWithPrecedence : sortingStrategy;
        return Array.from(self.columns).sort(sort);
    }
})).views(self => ({
    get filterRef(): Instance<typeof Filters> {
        return (getRoot(self) as Instance<typeof LateStoreModel>).filters
    },
    get params(): Instance<typeof Params> {
        return (getRoot(self) as Instance<typeof LateStoreModel>).params
    }
})).views(self => ({
    get columnsFiltered(): Instance<typeof CellAddress>[] {
        return getColumnsFiltered({ columnType: self.columnType, columns: self.columns, columnSorted: self.columnSorted, filterRef: self.filterRef });
    },
    get indicesCol(): number[] {
        return getIndicesCol({ columnType: self.columnType, columns: self.columns, columnSorted: self.columnSorted, filterRef: self.filterRef });
    },
    get stages(): { stage: string; length: number; index: number; order: number }[] {
        if (self.columnType === CellAddressType.ACT_STRING) {
            return getStages({ columnSorted: self.columnSorted as Instance<typeof CellAddressActivities>[] });
        }
        else { return []; }
    }
})).views(self => ({
    get columnSortedFiltered() {
        return self.columnsFiltered.sort(sortingWithPrecedence);
    }
})).views(self => ({
    getBrick(xVal: number, yVal: number): Instance<typeof Brick> | null {
        if (self.type === GOT.BARATHEON) {
            if (self.rowType === CellAddressType.NULL) {
                return self.layout[`${self.columnSortedFiltered[xVal - 1]?.id}`] || null;
            }
            else if (self.columnType === CellAddressType.NULL) {
                const normalizedY = self.rowSorted[self.rowSorted.length - yVal - 1]?.id || 0;
                return (xVal - 1) % 3 ? null : self.layout[`${normalizedY}`] || null;
            }
        }
        let res: Instance<typeof Brick> | undefined;
        // reversing normalizedY semantics
        // const normalizedY = self.rowSorted[self.rowSorted.length - yVal - 1]?.id || 0;
        // trying out using the parent rows
        const parentRows = getParent(self, 2)['rows'] || [];
        const normalizedY = parentRows[parentRows.length - yVal - 1]?.id || 0;
        //TODO: is this check needed?
        // const check = normalizedY >= self.rowExtremities.min && normalizedY <= self.rowExtremities.max;
        const check = true;
        if (!(xVal === (self.columnSortedFiltered.length + 1) || xVal === 0 || !check)) {
            // if (self.columnSortedFiltered[xVal - 1] && self.rowSorted[self.rowSorted.length - yVal - 1]) {
            if (self.columnSortedFiltered[xVal - 1] && parentRows[parentRows.length - yVal - 1]) {
                res = self.layout[`${self.columnSortedFiltered[xVal - 1].id}_${normalizedY}`];
            }
        }
        const hasBrick: boolean = !!res && isStateTreeNode(res) && Brick.is(res);
        return hasBrick ? res! : null;
    }
})).actions(self => ({
    setLayout({ columns, rows, columnType, rowType }: { columns: SnapshotIn<typeof CellAddress>[]; rows: SnapshotIn<typeof CellAddress>[]; minMax: { maxVal: { max: number; }; minVal: { min: number; } }; columnType: CellAddressType; rowType: CellAddressType; }) {
        self.columnType = columnType;
        self.rowType = rowType;
        self.columns.clear();
        self.columns.push(...columns);
        self.rows.clear();
        self.rows.push(...rows);
    },
    setBricks(brickType: BrickTypes, bricks: SnapshotIn<typeof Brick>[]) {
        self.brickType = brickType;
        self.bricks.clear();
        self.bricks.push(...bricks);
    },
    setLayoutVariable() {
        if (self.type === GOT.STARK) {
            const map = {};
            if (self.bricks.length > 0) {
                self.bricks.forEach((unit: Instance<typeof Brick>) => {
                    map[`${unit.xVal.id}_${unit.yVal.id}`] = unit;
                });
            }
            self.layout = { ...map };
        }
        else if (self.type === GOT.BARATHEON) {
            const map = {};
            if (self.rowType === CellAddressType.NULL) {
                self.bricks.forEach((unit: Instance<typeof Brick>) => {
                    map[`${unit.xVal.id}`] = unit;
                });
            }
            else if (self.columnType === CellAddressType.NULL) {
                self.bricks.forEach((unit: Instance<typeof Brick>) => {
                    map[`${unit.yVal.id}`] = unit;
                });
            }
            self.layout = { ...map };
        }
    }
})).actions(self => ({ afterAttach() { self.setLayoutVariable(); } }));

export interface IBricksCollection extends Instance<typeof BricksCollection> { };
// export type IBricksCollection = Instance<typeof BricksCollection>;
