import React, { memo } from 'react';
import { Button } from '@material-ui/core';
import useStyles from './ChecklistReports/ChecklistReportsStyle'

const DownloadReports = ({ disabled, download }: { disabled: boolean; download: () => Promise<any>; }) => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      color="primary"
      size="small"
      disabled={disabled}
      onClick={() => { download(); }}
      className={disabled ? classes.downloadButton: classes.downloadButtonVisible}
    >
      Export Now
    </Button >
  )
};

export default memo(DownloadReports);
