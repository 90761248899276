import React, { memo, useState, KeyboardEvent, useEffect, FormEvent } from "react";
import useStyles from "./forgetPasswordStyle";
import { useObserver } from 'mobx-react-lite';
import { useStore } from '../../models/ProvideModel';
import { Grid, TextField, Button, CircularProgress } from "@material-ui/core";
import fbtLogoIcon from '../../assets/images/FBTLogo.svg';
import { useMediaQuery } from "react-responsive";
import { LG_DEVICE_QUERY, SM_LANDSCAPE_QUERY, XS_LANDSCAPE_QUERY } from "../../utils/responsive";
import CommonSnackbar from "../../components/commonSnackbar/CommonSnackbar";

const ForgetPassword = (props) => {
    const store = useStore();

    const isLg = useMediaQuery(LG_DEVICE_QUERY);
    const isSm = useMediaQuery(SM_LANDSCAPE_QUERY);
    const isXs = useMediaQuery(XS_LANDSCAPE_QUERY);
    /**
     * Responsiveness queries related hook
     */
    useEffect(() => {
        store.responsiveUtils.currentViewport.setLg(isLg);
        store.responsiveUtils.currentViewport.setSm(isSm);
        store.responsiveUtils.currentViewport.setXs(isXs);
    }, []);

    const [userName, setUserName] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState<{ open: boolean, type: "error" | "info" | "success" | "warning" | undefined, msg: string }>({ open: false, type: undefined, msg: "" });
    const classes = useStyles();

    const handleSubmit = (ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        setLoading(true)
        store.auth.forgetPassword(userName)
            .then((res) => {
                setAlert({ ...alert, open: true, type: "success", msg: "Password is sent your mail." });
            })
            .catch(err => {
                setAlert({ ...alert, open: true, type: "error", msg: "Something went wrong" });
            }).finally(() => {
                setLoading(false)
                // setTimeout(() => {
                //     setAlert({ ...alert, open: false, type: undefined, msg: "" });
                // }, 5000);
            })
    }

    const sentMailButton = loading ?
        (<Button type="submit" color="primary" disabled={true} fullWidth className={isLg ? (!userName) ? classes.applyDisabled : classes.button : !userName ? classes.applyDisabledSm : classes.buttonSm} >
            <CircularProgress size={20} style={{ color: "#FFFFFF" }} /></Button>)
        :
        (<Button type="submit" color="primary" disabled={((!userName) || loading) ? true : false} fullWidth className={isLg ? (!userName) ? classes.applyDisabled : classes.button : (!userName) ? classes.applyDisabledSm : classes.buttonSm} > Send Email </Button>)

    const baseClass = (e) => {
        return classes.baseSm
    }
    const RedirectToLoginPage = () => {
        props?.onStatusChange(false)
    }

    return useObserver(() => (
        <div className={classes.root}>
            <Grid container className={isLg ? classes.base : baseClass(isSm)}>
                <Grid item xs={12} style={{ marginBottom: "19px" }}>
                    <img src={fbtLogoIcon} alt="FalconBricks" />
                </Grid>
                <Grid item xs={12} style={{ height: "315px" }}>
                    <form
                        name="form"
                        className={classes.formGroup}
                        onSubmit={handleSubmit}
                    >
                        <div
                            className={`form-group${submitted && !userName ? " has-error" : ""
                                }`}
                        >
                            <TextField
                                label={<span className={classes.inputLabel}>User name</span>}
                                placeholder="Type Here"
                                fullWidth
                                className={classes.inputField}
                                margin="normal"
                                onChange={e => setUserName(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                type="email"
                                autoComplete="email"
                                InputProps={{ classes: { input: classes.input } }}
                            />
                        </div>

                        <div className={classes.formGroup}>
                            <div style={{ marginTop: "15px" }} >
                                {sentMailButton}
                            </div>
                            {/* <CommonSnackbar open={alert.open} type={alert.type} msg={alert.msg} /> */}
                            <div className={classes.snackBarDiv}>
                                <div style={{ color: alert.type === "success" ? "green" : "red" }}>{alert.msg}</div>
                                <div className={classes.RedirectToLoginPage} onClick={RedirectToLoginPage}>Back To Login Page</div></div>
                            {/* {store.auth.error && <div className={classes.invalidForm}><span>{store.auth.error}</span></div>} */}
                        </div>
                    </form>
                </Grid>
            </Grid>
        </div >
    ));
};

export default ForgetPassword;
