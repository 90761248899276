import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../models/ProvideModel';
import useStyles from './ChecklistReportsStyle'
import DownloadReports from "../../components/downloadReports";
import { Button } from '@material-ui/core';
import { ModalBody, Modal, ModalContent, ModalOverlay, useModal } from '../modal';

export interface IChecklistReportSummary {
  loading?: boolean;
  disabled?: boolean
};
// TODO: add state changes based on individual toggles if they lead to select all
const ChecklistReportsPopup = ({ loading, disabled }: IChecklistReportSummary) => {
  const store = useStore();
  const classes = useStyles();
  const [filterModalState, setFilterModalState] = React.useState(false);
  const { isOpen } = useModal({ isOpen: filterModalState, closeOnOverlayClick: true });
  useEffect(() => {
    setFilterModalState(true);
  }, []);
  const onFilterModalClose = () => {
    setFilterModalState(!filterModalState);
    store.checklistMultiSelect.setZipPopupOpen(false)
  }
  const Disabled = () => {
    // store.checklistMultiSelect.changeLoading(false)
    store.checklistMultiSelect.setZipPopupOpen(false)
    store.exportTableFilters.setPdfPopupOpen(false)
  }

  return loading ?
    (<div>Loading...</div>)
    :
    store.checklistMultiSelect.zipPopupOpen || store.exportTableFilters.pdfPopupOpen ? (
      <Modal
        isOpen={isOpen}
        isCentered={true}
        onClose={onFilterModalClose}
        closeOnOverlayClick={true}
        position={{ top: 20, left: "35%" }}
      >
        <ModalOverlay style={{ backgroundColor: "rgba(47, 62, 108, 0.9)" }}>
          <ModalContent style={{ width: "30%" }}>
            <ModalBody style={{ padding: "0px 0px" }}>
              <div className={classes.secondPopup}>
                <div style={{ display: "flex", flexDirection: "column", marginBottom: "28px" }}>
                  <span className={classes.textDiv}>Exporting {store.params.section === "checklist-reports" ? store.checklistMultiSelect.checklistItems.length?.toLocaleString('en-GB', { minimumIntegerDigits: 2 }) :
                    store.downloadpdf.totalCount?.toLocaleString('en-GB', { minimumIntegerDigits: 2 })
                  } {store.params.section === "unit-act-info" ? "Unit Activities" : store.params.section === "legacy" ? "Detailed - By units" : store.params.section} to a PDF</span>
                  <span className={classes.secondTextDiv}>Please wait the export could take a few mins....You can track the progress from the panel on the left side</span></div>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={Disabled}
                  className={classes.button}>
                  CLOSE
                     </Button>
                {/* <DownloadReports disabled={store.checklistMultiSelect.disabled} download={store.checklistMultiSelect.download} />  */}
              </div >
            </ModalBody>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    ) : <></>;
};

export default observer(ChecklistReportsPopup);



