import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            fontFamily: "Source Sans Pro, sans-serif !important",
            fontStretch: "normal",
            fontStyle: "normal",
            letterSpacing: "normal",
            // width:"240px"
        },
        normText11px: {
            fontFamily: "Source Sans Pro, sans-serif !important",
            fontSize: "11px",
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: 1.27,
            letterSpacing: "normal",
            textAlign: "left",
            color: "#808080",
        },
        towerTitle: (props: { store: any }) => ({
            color: "#005eff",
            fontSize: "14px",
            fontWeight: 500,
            fontFamily: "Source Sans Pro, sans-serif !important",
            textAlign: "center",
            cursor: "pointer",
            textTransform: "capitalize",
            lineHeight: 1.29,
            "&:hover": {
                color: "#585858",
            },
        }),
        underlineHover: {
            height: "30px",
            paddingTop: "4px",
            display: "inline-block",
            textDecoration: " none",
            /* Set up the hover */
            /* If you aren't using autoprefix, remember to prefix the gradient for other browsers */
            // backgroundImage: "linear-gradient(#005eff, #005eff)",
            backgroundSize: "0px 1px, auto",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center bottom",
            transition: "all .2s ease-out",
            cursor: "pointer",
            "&:hover": {
                backgroundSize: "100% 2px, auto",
                textDecoration: "none"
            },
        },
        hideScroll: {
            "&::-webkit-scrollbar": {
                display: "none"
            }
        },
        capHeadBold24px: {
            width: "100%",
            borderRadius: "1px",
            height: "40px",
            backgroundColor: "#fafafa",
            color: "#005eff",
            fontSize: "24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontWeight: "bold",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: 1.29,
            letterSpacing: "normal",
            cursor: "pointer",
            // display: "inline-block",
            textDecoration: " none",
            textAlign: "center",
            /* Set up the hover */
            /* If you aren't using autoprefix, remember to prefix the gradient for other browsers */
            backgroundImage: "linear-gradient(#005eff, #005eff)",
            backgroundSize: "28px 1px, auto",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center bottom",
            transition: "background-image,background-position,background-size .2s ease-out",
            // transition: "width .8s ease",
            "&:hover": {
                backgroundSize: "100% 2px, auto",
                textDecoration: "none",

            },
            // borderBottom:"2px solid #005eff"
        },
        towerSelected: {
            width: "100%",
            borderRadius: "1px",
            height: "50px",
            backgroundColor: "#f4f4f4",
            color: "#005eff",
            fontSize: "16px",
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: 1.29,
            letterSpacing: "normal",
            cursor: "pointer",
            textDecoration: " none",
            padding: "16px",
            justifyContent: "space-between",
            /* Set up the hover */
            /* If you aren't using autoprefix, remember to prefix the gradient for other browsers */
            // backgroundImage: "linear-gradient(#005eff, #005eff)",
            // backgroundSize: "100% 2px, auto",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center bottom",
            minWidth: "123px",
            // transition: "background-image,background-position,background-size .2s ease-out",
        },
        currentTowerName: {
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            maxWidth: "95px"
        },
        towerTitleSelected: {
            color: "#585858",
            fontSize: "14px",
            fontWeight: 700,
            fontFamily: "Source Sans Pro, sans-serif !important",
            textAlign: "center",
            textTransform: "capitalize",
            lineHeight: 1.29,
            cursor: "context-menu"
        },
        textAnimate: {
            minHeight: 40,
            textAlign: "center",
            whiteSpace: "nowrap",
            width: "100%",
            transition: "width .8s ease",
            "&:hover": {
                width: 0
            }
        },
        rootSeleted: {
            fontFamily: "Source Sans Pro, sans-serif !important",
            fontStretch: "normal",
            fontStyle: "normal",
            letterSpacing: "normal",
            width: "120px",
        }
    })
);

export default useStyles;
