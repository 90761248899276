import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  backDrop: {
    backdropFilter: "blur(30px)",
    opacity: ".85 !important"
  },
  dialogPaddings: {
    padding: "1.25rem"
  },
  dialogTitleFlex: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    fontSize: '1.25rem'
  },
  closeDiv: {
    display: "flex",
    justifyContent: "flex-end",
    textAlign: "end",
    flexGrow: 1
  },
  close: {
    paddingRight: ".3em",
    cursor: "pointer",
    "&:hover": {
      fontSize: "1.1em",
      color: "blue"
    }
  },
  titleDescription: {
    fontSize: '0.8rem',
    paddingTop: '0.7rem',
    paddingBottom: '0.8rem'
  },
  numberInput: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  },
  actionStyles: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "1rem"
  },
  messageStyle: {
    fontSize: "0.65rem",
    color: "rgb(52, 171, 129)",
    display: "inline-block",
    whiteSpace: "pre-line",
    width: "75%"
  },
  buttonStyles: {
    display: "flex",
    borderRadius: "0px",
    fontSize: "13px",
    textAlign: "center",
    flexDirection: "column",
    justifyContent: "center",
    cursor: "pointer"
  },
  textArea: {
    width: "100%",
    marginTop: "14px",
    borderTop: 0,
    borderRight: 0,
    borderLeft: 0,
    fontFamily: "Roboto, Helvetica, Arial, sans-serif !important",
    fontSize: 12
  },
  charLeft: {
    fontSize: 8,
    fontFamily: "Roboto, Helvetica, Arial, sans-serif !important",
    color: "#adb5bd"
  }
}));
