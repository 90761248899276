import React, { memo } from 'react';
import { Grid } from "@material-ui/core";
import useStyles from "./detailBasementBlockStyles";
import arrow from "../../assets/images/dooku/SmallArrow.svg"
import { GOT } from '../../models/enums';


export interface ITab {
  id: string;
  name: string;
  selected: boolean;
  graphType: GOT;
  stats: any[];
  /* handleClick(tower?: string): void; */
  screen: string;
  screenProps?: any;
  /* completion?: number; */
  /* status: 'started' | 'completed' | 'notStarted' | 'default' | 'completedwithdelay' | 'startedwithdelay'; */
};

/* export const SUPPORTTED_STATUS = {
 *   COMPLETED: 'completed',
 *   STARTED: 'started',
 *   NOT_STARTED: 'notStarted',
 *   StartedWithDelay: 'startedwithdelay',
 *   CompletedWithDelay: 'completedwithdelay',
 *   DEFAULT: 'default'
 * } */

export const DetailBasementBlock = memo((props: ITab) => {
  // TODO: Correct props in styles.
  const classes = useStyles({ ...props });
  return (
    <Grid container className={classes.FilterBase}>
      <Grid container className={classes.BlockName}>
        <div className={classes.tabDiv}>
          <div className={classes.TabName}>
            <span>{props.name}</span>
            {!props.selected &&
              <img src={arrow} height={20} width={20}
              ></img>}
          </div>
          {props.screen === "landing" && <div className={classes.graphTypeDetails}>{props.graphType === GOT.BARATHEON ? "BY BLOCK" : "BY FLOORS/BLOCK"}</div>}
        </div>
      </Grid>
      <Grid container style={{ display: "flex", flexDirection: "row", paddingBottom: props.screen === "landing" ? "0px" : "4px" }} className={classes.Details}>
        {props?.stats?.length > 0 && <Grid container style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
          {/* Commented out till tab and unit-type level statuses can be generated.
              (props?.status === 'completedwithdelay' || props?.status === 'startedwithdelay') ? <div className={classes.Rectangle}>
              <div className={classes.delayedRectangle}></div>
               </div> : <div className={classes.Rectangle}></div> */}
          <div className={classes.Rectangle}></div>
          <div className={classes.Floors}><span style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>{props?.stats?.length} {props.screen === 'landing' ? "Blocks" : "Activities"}</span></div>
        </Grid>}
        {/* <Grid container style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "50%" }}>
          <div className={classes.Ellipse}>
            <CircularProgress variant="determinate" value={props?.completion} color="secondary" size={12} thickness={7} />
          </div>
          <div className={classes.Percentage}>{props?.completion}%</div>
        </Grid> */}
      </Grid>
    </Grid>
  );
});
