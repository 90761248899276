import { types } from 'mobx-state-tree';

export const ViewPorts = types.model('ViewPorts', {
    lg: types.optional(types.boolean, false),
    sm: types.optional(types.boolean, false),
    xs: types.optional(types.boolean, false)
}).views((self) => ({
    get isLg() {
        return self.lg;
    },
    get isSm() {
        return self.sm;
    },
    get isXs() {
        return self.xs;
    },
    get isXsOrSm() {
        return self.xs || self.sm;
    }
})).actions((self) => ({
    setLg(value) {
        self.lg = value;
    },
    setSm(value) {
        self.sm = value;
    },
    setXs(value) {
        self.xs = value;
    }
}))

export const ResponsiveUtils = types.model('ResponsiveUtils', {
    fullScreenMode: types.optional(types.boolean, false),
    currentViewport: types.optional(ViewPorts, {}),
    portrait: types.optional(types.boolean, false),
    fatDropdownFlag: types.optional(types.boolean, false)
})
    .views((self) => ({
        /**
        * get class name based on viewport size `lg`, `sm`, `xs`
        * @param className = the class name that needed to be responsive
        * @param smOrXs = true, if small and extra small device will have same look.
        * @returns `className`, `classNameSm`, `classNameXs`
        */
        getClassNameBasedOnViewport(className, smOrXs = false) {
            if (!className) return `${className}`;
            if (self.currentViewport.isLg) {
                return `${className}`;
            } else if (self.currentViewport.isSm || smOrXs === true) {
                return `${className}Sm`;
            } else if (self.currentViewport.isXs && smOrXs === false) {
                return `${className}Xs`;
            }
        },
        get isPortrait(): boolean {
            return self.currentViewport.isLg ? false : self.portrait;
        }
    }))
    .actions(self => ({
        toggleFullscreenMode() {
            self.fullScreenMode = !self.fullScreenMode;
        },
        setPortrait(inp: boolean) {
            self.portrait = inp;
        },
        openFatDropdown() {
            self.fatDropdownFlag = true;
        },
        closeFatDropdown() {
            self.fatDropdownFlag = false;
        }
    }));

export type IresponsiveUtils = typeof ResponsiveUtils;
