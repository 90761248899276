import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  overviewBox: {
    maxWidth: "280px",
    minWidth:"150px",
    borderRadius: "2px",
    minHeight: "118px",
    backgroundColor: "#f4f4f4",
    textTransform: "capitalize",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    marginTop:"16px"
  },
  overviewBoxSm: {
    maxWidth: "100%",
    minWidth:"100%",
    borderRadius: "2px",
    minHeight: "118px",
    backgroundColor: "#f4f4f4",
    textTransform: "capitalize",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    marginTop:"16px"
  },
  overviewLabelSm: {
    maxWidth: '280px'
  },
  overviewName: {
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    textAlign: "left",
    fontSize: "24px",
    padding: "16px 16px 0 11px",
  },
  underlineAnim: {
    /* Style the buttons that are used to open and close the accordion panel */
    backgroundColor: "#f4f4f4",
    color: "#2f3e6c",
    cursor: "pointer",
    border: "none",
    outline: "none",
    textDecoration: "none",
    backgroundSize: "0 3px, auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center bottom",
    transition: "all 0.2s ease-out",
    borderRadius: "1px",
    "&:hover": {
      backgroundImage: "linear-gradient(#b1b1b1, #b1b1b1)",
      backgroundSize: "90% 4px, auto",
      fontWeight: "bold",
      color: "#005eff",
      backgroundColor:"#faf9f9",
    },
  },
  allBlocks: {
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "2.67",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#2f3e6c",
  },
  bottomDiv: {
    display:"flex",
    flexDirection:"row",
    justifyContent:"space-between",
    padding:"0 16px 10px 52px"
  }
}));

export default useStyles;
