export const colors = {
    default: "#fff",
    black: "#1d1d1d",
    darkGreyBlue: "#2f3e6c",
    brownishGrey: "#686868",
    white: "#d8d8d8",
    warmGrey: "#9f9f9f",
    whiteTwo: "#efefef",
    washedOutGreen: "#b2ea79",
    sunYellow: "#ffce32",
    whiteThree: "#edebeb",
    lightBlueGrey: "#e0f7f2",
    completed: '#34d1ae',
    OnGoing: '#fff6cc',
    notStarted: '#e9e9e9',
    plainWrong: '#e90901'
};

export const borderColors = {
    completed: '#34d1ae',
    OnGoing: '#ffd300',
    notStarted: '#d1d1d1',
    plainWrong: '#e90901'
}

// Structures TCA and ECA will be taking values from structures bricks because
// of these old keys. Should we change?
export const brickPalette = {
    structures: {
        landing: {
            completed: '#34d1ae',
            started: '#ffd300',
            notStarted: '#e9e9e9',
            plainWrong: '#e90901'
        },
        fullscreen: {
            completed: '#e0f7f2',
            started: '#fff5c9',
            notStarted: '#eaeaea66',
            plainWrong: '#e90901'
        }
    },
    structures_alt: {
        landing: {
            completed: '#e0f7f2',
            started: '#ffd300',
            notStarted: '#eaeaea66',
            plainWrong: '#e90901'
        },
        fullscreen: {
            completed: '#e0f7f2',
            started: '#ffd300',
            notStarted: '#eaeaea66',
            plainWrong: '#e90901'
        }
    },
    finishing: {
        landing: {
            completed: '#e0f7f2',
            started: '#ffd300',
            notStarted: '#eaeaea66',
            plainWrong: '#e90901'
        },
        fullscreen: {
            completed: '#e0f7f2',
            started: '#ffd300',
            notStarted: '#eaeaea66',
            plainWrong: '#e90901'
        }
    },
    handover: {
        landing: {
            completed: '#e0f7f2',
            started: '#ffd300',
            notStarted: '#eaeaea66',
            plainWrong: '#e90901'
        },
        fullscreen: {
            completed: '#e0f7f2',
            started: '#ffd300',
            notStarted: '#eaeaea66',
            plainWrong: '#e90901'
        }
    },
    none: {
        landing: {
            completed: '#e0f7f2',
            started: '#ffd300',
            notStarted: '#eaeaea66',
            plainWrong: '#e90901'
        },
        fullscreen: {
            completed: '#e0f7f2',
            started: '#ffd300',
            notStarted: '#eaeaea66',
            plainWrong: '#e90901'
        }
    }
};

export const axis = {
    structures: {
        landing: {
            top: 0.15,
            bottom: 0.08,
            left: 30,
            right: 80
        },
        fullscreen: {
            top: 10,
            bottom: 10,
            left: 0,
            right: 40
        }
    },
    structures_alt: {
        landing: {
            top: 0.15,
            bottom: 0.08,
            left: 10,
            right: 10
        },
        fullscreen: {
            top: 0,
            bottom: 10,
            left: 10,
            right: 10
        }
    },
    finishing: {
        landing: {
            top: 0.15,
            bottom: 0.08,
            left: 10,
            right: 10
        },
        fullscreen: {
            top: 0,
            bottom: 10,
            left: 10,
            right: 10
        }
    },
    handover: {
        landing: {
            top: 0.15,
            bottom: 0.08,
            left: 10,
            right: 10
        },
        fullscreen: {
            top: 0,
            bottom: 10,
            left: 10,
            right: 10
        }
    },
    none: {
        landing: {
            top: 0,
            bottom: 0,
            left: 1,
            right: 1
        },
        fullscreen: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
        }
    }
};

export const brick = {
    structures: {
        landing: {
            width: 128,
            height: 53
        },
        fullscreen: {
            width: 140,
            height: 120,
        }
    },
    structures_alt: {
        landing: {
            width: 100,
            height: 50
        },
        fullscreen: {
            width: 140,
            height: 54
        }
    },
    finishing: {
        landing: {
            width: 100,
            height: 50
        },
        // TODO: changed for demo only. 20, AUG - 2021
        // fullscreen: {
        //     width: 100,
        //     height: 40
        // },
        fullscreen: {
            width: 100,
            height: 54
        }
    },
    handover: {
        landing: {
            width: 100,
            height: 50
        },
        // TODO: changed for demo only. 20, AUG - 2021
        // fullscreen: {
        //     width: 100,
        //     height: 40
        // },
        fullscreen: {
            width: 100,
            height: 54
        }
    },
    none: {
        landing: { x: 1, y: 1 },
        fullscreen: { x: 1, y: 1 },
    }
};

export const scale = {
    structures: {
        landing: {
            x: 10, y: 10
        },
        fullscreen: {
            x: 15, y: 10
        }
    },
    structures_alt: {
        landing: {
            x: 8, y: 16
        },
        // TODO: changed for demo only. 20, AUG - 2021
        // fullscreen: {
        //     x: 8, y: 16
        // }
        fullscreen: {
            x: 16, y: 16
        }
    },
    finishing: {
        landing: {
            x: 8, y: 16
        },
        // TODO: changed for demo only. 20, AUG - 2021
        // fullscreen: {
        //     x: 8, y: 16
        // }
        fullscreen: {
            x: 16, y: 16
        }
    },
    handover: {
        landing: {
            x: 8, y: 16
        },
        // TODO: changed for demo only. 20, AUG - 2021
        // fullscreen: {
        //     x: 8, y: 16
        // }
        fullscreen: {
            x: 16, y: 16
        }
    },
    none: {
        landing: { x: 1, y: 1 },
        fullscreen: { x: 1, y: 1 },
    }

};

export const __hardcoded = {
    "1_planned": "notStarted",
    "3_active": "started",
    "8_done": "completed"
};

export type IHardcoded = { [P in keyof typeof __hardcoded]: typeof __hardcoded[P] };


const __opacities = {
    0: 0.01,
    25: 0.15,
    50: 0.3,
    75: 0.5
};

export const opacities = new Proxy(__opacities, {
    get: (obj: typeof __opacities, prop: string) => {
        const orig = obj[prop];
        if (orig !== undefined) { return orig; }
        else {
            if (Number.isNaN(parseInt(prop, 10))) { return 1; }
            const retIndex = Object.keys(obj).findIndex(k => !Number.isNaN(parseInt(k, 10)) && parseInt(k, 10) - parseInt(prop, 10) > 0) - 1;
            return retIndex > -1 ? obj[Object.keys(obj)[retIndex]] : 0.5;
        }
    }
});
