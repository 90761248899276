import { makeStyles, Theme } from "@material-ui/core/styles";
import BlueImage from '../../assets/images/brwsrSupprt/Group.svg'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    // justifyContent: "center",
    alignItems: "center",
    flexDirection: "row"
  },
  leftSidePanel: {
    width: "240px",
    height: "100%",
    background: "#faf9f9",
    padding: "12px 103px 0px 12px",
    flex: 1,
    display: "flex",
    marginLeft: "15px",
    cursor: "pointer",
    transition: "0.5s",
    position: "relative",
    "&:after": {
      position: "absolute",
      opacity: 0,
      top: 0,
      right: "-15px",
      transition: "0.5s",
    },
    "&:hover": {
      color: "#005eff",
      paddingLeft: "8px",
      opacity: 1,
      right: 0,
    }
  },
  propertyName: {
    margin: "13px 0 8px 8px",
    fontSize: "12px",
    fontWeight: "normal",
    lineHeight: 1.25,
    letterSpacing: "normal",
    color: "#808080"
  },
  homeButton: {
    marginRight: "9px",
    outline: "solid 0px #005eff",
    transition: "outline-width 0.1s linear",
    width: "26px",
    height: "26px",
    marginLeft: "9px",
    objectFit: "contain",
  },
  homeButtonWOimg: {
    marginLeft: "16px",
    outline: "solid 0px #005eff",
    transition: "outline-width 0.1s linear",
    objectFit: "contain",
    "&:hover": {
      fontWeight: "bold",
      color: "#585858",
      cursor: "pointer",
      backgroundColor: "rgb(255, 255, 255)",
      outlineWidth: "1px"
    }
  },
  mainDiv: {
    display: "flex",
    flexDirection: "column",
    height: "100vh"
  },
  headerText: {
    size: "14px",
    color: "#2f3e6c",
    fontWeight: "bold",
    lineHeight: 1.29
  },
  menuClick: {
    color: "#2f3e6c",
    display: "flex",
    overflow: "hidden",
    position: "relative",
    flexWrap: "nowrap",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  headerRight: {
    background: "#f4f4f4",
    height: "60px",
    display: "flex",
    width: "1126px",
    justifyContent: "space-between",
    padding: "0px 12px"
  },
  secondMainDiv: {
    width: "806px",
    height: "478px",
    backgroundImage: `url(${BlueImage}) !important`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingLeft: "150px"
  },
  secondMainDivSm: {
    width: "806px",
    height: "348px",
    backgroundImage: `url(${BlueImage}) !important`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingLeft: "138px"
  },
  fontSize: {
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "28px",
    lineHeight: 1.29,
    letterSpacing: "normal",
    // textAlign: "center",
    color: "#ffffff",
    fontWeight: "bold"
  },
  fontSizeSm: {
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "25px",
    lineHeight: 1.29,
    letterSpacing: "normal",
    // textAlign: "center",
    color: "#ffffff",
    fontWeight: "bold"
  },
  textSize: {
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "14px",
    lineHeight: 1.28,
    letterSpacing: "normal",
    // textAlign: "center",
    color: "#ffffff",
    fontWeight: "bold"
  },
  NormaltextSize: {
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "14px",
    lineHeight: 1.28,
    letterSpacing: "normal",
    // textAlign: "center",
    color: "#ffffff",
    // fontWeight:"bold"
  },
  BoldtextSize: {
    color: "#ffffff",
    fontSize: "28px",
    fontFamily: "Source Sans Pro, sans-serif",
    lineHeight: 1.28,
    letterSpacing: "normal"
  },
  BoldtextSizeSm: {
    color: "#ffffff",
    fontSize: "22px",
    fontFamily: "Source Sans Pro, sans-serif",
    lineHeight: 1.28,
    letterSpacing: "normal"
  },
}));
export default useStyles;
