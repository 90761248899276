import { useState, useCallback, useEffect } from 'react';
import { IChan, putAsync, go } from 'csp-with-ts';
import { IUseMux } from './UseAccordionHook';

export default function useConnectToState<T extends Record<string, any>, K extends keyof T>(relay: IChan<IUseMux<T, K>>, broadcast: IChan<T[K][]>) {
  const [internalState, setInternal] = useState<T[K][]>([]);
  const setInternalValue = useCallback((val: T[K] | T[K][]) => { putAsync<IUseMux<T, K>>(relay, Array.isArray(val) ? { type: 'replace', payload: val } : { type: 'select', payload: val }); }, []);
  useEffect(() => go<T[K][]> `<! ${broadcast} ${function* () { while (true) { const val = yield; setInternal(val); } }}`, []);

  return { state: internalState, setState: setInternalValue };
}

