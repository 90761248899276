import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
    },
    userProfile: {
      display:"flex",
      justifyContent:"space-between",
      lineHeight:2.75
    },
    userNameText: {
      alignSelf:"center",
      fontSize:"14px",
      textTransform:"capitalize"
    },
    profileBottom: {
      display:"flex",
      justifyContent:"space-between",
    },
    logout: {
      backgroundColor: "rgb(255, 255, 255)",
      color: "#005eff",
      textTransform: "capitalize",
      "&:hover": {
        // backgroundColor: "rgb(255, 255, 255)"
      },
    },
    paperWidth: {
      width: 275
    },
    propertySelect: {
      fontWeight:"bold",
      color:"#005eff",
      cursor:"pointer",
      "&:hover": {
        fontWeight:"bold",
        color:"#585858",
        cursor:"pointer",
        backgroundColor: "rgb(255, 255, 255)"
      }
    },
    menuButton: () =>  ({
      marginRight:-8, 
      borderRadius:0, 
      padding:0,
      height:32, 
      width:32
    }),
    title: () => ({
    //   backgroundColor: props.backgroundColor,
      flexGrow: 1
    })
  })
);

export default useStyles;
