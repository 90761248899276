import { getRoot, Instance, types } from 'mobx-state-tree';
import { IDownloadPDFProps } from "../components/downloadPDF"
import { LateStoreModel } from './DataStore';

const TableState = types.model({
    totalCount: types.maybe(types.number),
    headerName: ''
})
    .actions(self => ({
        setTotalCount(val: number) {
            self.totalCount = val
        },
        setReportName(val: string) {
            self.headerName = val
        }
    }))
    .views(self => (
        {
            get title(): string {
                return (getRoot(self) as Instance<typeof LateStoreModel>).responsiveUtils.currentViewport.isLg ? "EXPORT [PDF]" : "[PDF]"
            },
            get reportName(): string {
                return self.headerName ? self.headerName : ""
            },
            get limit(): number | string {
                return self.totalCount ? self.totalCount : ""
            }
        }
    ))

export default TableState;
