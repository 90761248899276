import React, { useState, memo, useEffect } from "react";
import "../components/switchTowersDD/animation.scss";
import RenderImagesCell from "./RenderImagesCell";

const SnagImages = props => {
  const attachment_old = props.cell.row.original['attachment'];
  const attachment_v2 = props.cell.row.original['attachment_v2'];
  const [imageSources, setImageSources] = useState<string[]>([])

  useEffect(() => {
    if (attachment_v2.length) {
      attachment_v2.map(({ filename }) => { setImageSources(prev => [...prev, filename]) })
    } else if (attachment_old) {
      setImageSources(prev => [...prev, attachment_old])
    }
  }, [])
  return <RenderImagesCell images={imageSources} />;
}

export default memo(SnagImages);
