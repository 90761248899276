import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { IBrickProps } from "./Brick";
import { getRectangleBackgroundColorBasedOnStatus, getRectangleBorderColorBasedOnStatus, getStructuresFullscreenBgBasedOnStatus } from "./CommonAreaBrickStyls";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        brickOpacity: (props: { filter: boolean; strength?: number; }) => ({
            fillOpacity: !props.filter ? 0.2 : 1,
            strokeOpacity: !props.filter ? 0.2 : 1,
            "&:hover": {
                transform: !props.filter ? 'scale(1.0)' : 'scale(1.1)',
                zIndex: 1000,
            }
        }),
        brickFinOpacity: (props: { filter: boolean; strength?: number }) => ({
            fillOpacity: !props.filter ? Math.min(0.2, (props.strength || 1)) : (props.strength || 1),
            strokeOpacity: props.filter ? 0.2 : 1,
            "&:hover": {
                transform: props.filter ? 'scale(1.0)' : 'scale(1.1)',
                zIndex: 1000,
            }
        }),
        root: {
            padding: "12px",
            fontFamily: "Source Sans Pro, sans-serif !important",
        },
        display: {
            fontSize: "12px",
            fontFamily: "Source Sans Pro, sans-serif !important",
            color: "#585858",
            // fontWeight: 700,
            lineHeight: 1.25,
        },
        completeddisplay: {
            fontSize: "13px",
            fontFamily: "Source Sans Pro, sans-serif !important",
            fill: "white",
            // fontWeight: 700,
            lineHeight: 1.5,
        },
        delayed: {
            fontSize: "12px",
            fontWeight: "normal",
            color: "#585858",
            fontFamily: "Source Sans Pro, sans-serif !important",
        },
        brickName: {
            fontSize: "14px",
            fontWeight: "bold",
            color: "#585858",
            fontFamily: "Source Sans Pro, sans-serif !important",
            textTransform: "uppercase",
            lineHeight: 1.29,
            letterSpacing: "normal"
        },
        date: {
            fontSize: "12px",
            fontFamily: "Source Sans Pro, sans-serif !important",
            color: "#585858",
            fontWeight: "normal",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: 1.25,
            letterSpacing: "normal",
            textAlign: "left",
        },
        normal: {
            fill: "transparent",
        },
        capHead4: {
            fontSize: "14px",
            fontFamily: "Source Sans Pro, sans-serif !important",
            color: "#585858",
            fontWeight: 700,
            lineHeight: 1.29,
            letterSpacing: "normal",
            textTransform: "uppercase",
            paddingBottom: "12px",
        },
        capHead5: {
            fontSize: "12px",
            fontFamily: "Source Sans Pro, sans-serif !important",
            color: "#585858",
            fontWeight: 700,
            lineHeight: 1.29,
            letterSpacing: "normal",
            textTransform: "uppercase",
        },
        normText12Px: {
            fontSize: "12px",
            fontFamily: "Source Sans Pro, sans-serif !important",
            color: "#575757",
            fontWeight: "normal",
            lineHeight: 1.29,
            letterSpacing: "normal"
        },
        normText: {
            fontSize: "12px",
            fontFamily: "Source Sans Pro, sans-serif !important",
            color: "#575757",
            fontWeight: "normal",
            lineHeight: 1.29,
            letterSpacing: "normal",
            display: "flex",
            flexDirection: "row"
        },
        datesDiv: {
            lineHeight: 1.29,
            display: "flex",
            flexDirection: "column",
            marginLeft: "3px",
            width: "100%"
        },
        mainDiv: {
            height: "50%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between"
        },
        boldText12Px: {
            fontSize: "12px",
            fontFamily: "Source Sans Pro, sans-serif !important",
            color: "#575757",
            fontWeight: 700,
            lineHeight: 1.29,
            letterSpacing: "normal",
        },
        filled: {
            fill: "#ff7777",
            stroke: "#ff3535",
            strokeWidth: 1
        },
        brickContent: {
            padding: "16px 12px 16px 16px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            zIndex: 100,
            height: "100%"
        },
        remainingDays: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
        },
        brickProgressBg: (props: IBrickProps) => {
            return {
                position: 'absolute',
                width: "inherit",
                minHeight: props.progressAsPercent == 100 ? "99.5%" : `${props.progressAsPercent}%`,
                background: getStructuresFullscreenBgBasedOnStatus(props.status),
                boxSizing: "content-box"
            }
        },
        endDelayedRectangle: (props: IBrickProps) => {
            return {
                width: "6px",
                height: "10px",
                border: `1px solid ${getRectangleBorderColorBasedOnStatus(props.status)}`,
                backgroundColor: getRectangleBackgroundColorBasedOnStatus(props.status),
                marginLeft: '2px',
                marginRight: "7px"
            }
        },
        rectangle: (props: IBrickProps) => {
            return {
                width: "6px",
                height: "10px",
                border: "solid 1px #ea3434",
                backgroundColor: "#ff7777",
                marginLeft: '2px',
                marginRight: "7px"
            }
        },
        circle: (props: IBrickProps) => {
            return {
                width: "8px",
                height: "8px",
                borderRadius: "50%",
                border: "solid 1px #ea3434",
                backgroundColor: "#ff7777",
                marginLeft: '1px',
                marginRight: "5px"
            }
        },
        startDelayedCircle: (props: IBrickProps) => {
            return {
                width: "8px",
                height: "8px",
                borderRadius: "50%",
                border: `1px solid ${getRectangleBorderColorBasedOnStatus(props.status)}`,
                backgroundColor: getRectangleBackgroundColorBasedOnStatus(props.status),
                marginLeft: '2px',
                marginRight: "5px"
            }
        }
    }));
export default useStyles;
