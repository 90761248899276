import React, { memo, useCallback, useEffect, useState } from 'react';
import { useModal, Modal, ModalBody, ModalContent, ModalOverlay } from "../../components/modal";
import useStyles from "./WPUPopupStyles";
import { ProgressBar, ProgressBarProps } from '../progressBar/ProgressBar';
import { GET_PROGRESS_UPDATE } from "../../utils/queries";
import LoadingSpinner from "../loadingSkelaton/LoadingSpinner";
import client from "../../utils/apolloClient";
import { ApolloClient } from "apollo-client";
import { NormalizedCacheObject } from "apollo-cache-inmemory";
import { useObserver } from 'mobx-react-lite';
import UrlComponent from './Urlcomponent'
import FsLightbox from "fslightbox-react";
import config from "../../configs/clientConfig";
import { useStore } from '../../models/ProvideModel';

export interface IWPU {
  id: string;
  unit: string;
  activity: string;
  progress: number;
  status: string;
};

export interface Irow {
  activity_id: string;
  created_at: string;
  created_by: string;
  data: any;
};

const WPU = (props) => {
  const baseURL = config.baseURL;
  const [rows, setRows] = useState<any>([]);
  const [filterModalState, setFilterModalState] = React.useState(false);
  const [toggle, setToggle] = React.useState(false);
  const [onClickevent, setonClickevent] = React.useState(false);
  const [onMouseevent, setonMouseevent] = React.useState(false);
  const [image, setImage] = useState<any>("");
  const store = useStore();
  const { isLg, isXs } = store.responsiveUtils.currentViewport;
  const classes = useStyles({ isLg });
  useEffect(() => {
    if (!client.client) {
      client();
    }
  }, [client]);
  const { isOpen } = useModal({ isOpen: filterModalState, closeOnOverlayClick: true });

  const onFilterModalClose = () => {
    setFilterModalState(!filterModalState);
  }

  useEffect(() => {
    setFilterModalState(true);
  }, []);

  const closeModel = () => {
    setFilterModalState(false);
    props.onSelectLanguage(false);
  }

  const onFilterModalOverlay = () => {
    props.onSelectLanguage(false);
  }

  const openFlexbox = (key) => {
    setonClickevent(true)
    setToggle(!toggle)
    setImage(key);
  }

  const OnMouseopenFlexbox = (key) => {
    setonMouseevent(true)
    setToggle(!toggle)
    setImage(key);
  }

  const OnMouseleaveFlexbox = (key) => {
    setonMouseevent(false)
    setImage(key);
  }

  const pbProps: ProgressBarProps = {
    value: props.wpuProbs.progress,
    status: props.wpuProbs.status,
    primary: "#ffc400",
    background: "#ffffff",
    divider: "#ffc400",
    default: "#34d1ae",
    width: 200,
    popup: true
  };

  useEffect(() => {
    if (client.client) {
      const query = (client.client as ApolloClient<NormalizedCacheObject>)!
        .watchQuery({ query: GET_PROGRESS_UPDATE, variables: { activity_id: props.wpuProbs.id } })
        .subscribe(({ data, loading }) => {
          if (!loading) {
            setRows(data.master2_activity_history)
          }
        });
      return () => {
        query.unsubscribe();
      };
    }
  }, []);

  var sum = pbProps.value
  const dummy: any[] = []
  rows.forEach(({ activity_id, created_at, created_by, data }: Irow) => {
    if(data.input_value === undefined){
      dummy.push(<UrlComponent data={data}
        user={created_by} created={created_at} activity={activity_id} openFlexbox={openFlexbox} OnMouseopenFlexbox={OnMouseopenFlexbox} OnMouseleaveFlexbox={OnMouseleaveFlexbox} />)
    }
    else{
      dummy.push(<UrlComponent data={data} sum={sum}
        user={created_by} created={created_at} activity={activity_id} openFlexbox={openFlexbox} OnMouseopenFlexbox={OnMouseopenFlexbox} OnMouseleaveFlexbox={OnMouseleaveFlexbox} />)
    }
    sum =  sum - data.input_value
    })


  return useObserver(() => (
    <>
      {(onClickevent || onMouseevent) && <FsLightbox
        key={image}
        toggler={toggle}
        sources={[`${baseURL}large/${image}`]}
        type="image"
        onClose={() => { setonClickevent(false); setonMouseevent(false) }}
      />}
      <Modal
        isOpen={isOpen}
        isCentered={true}
        onClose={onFilterModalClose}
        onOverlayClick={onFilterModalOverlay}
        closeOnOverlayClick={true}
      // position={{ top: 20, left: "35%" }}
      >
        <ModalOverlay style={{ backgroundColor: "rgba(47, 62, 108, 0.9)" }}>
          <ModalContent>
            <ModalBody style={{ padding: "0px 0px" }}>
              <div className={classes.handleContainer}>
                <div className={classes.titlediv}>
                  <span className={classes.workStatusdiv}> WORK STATUS COMPLETION </span> </div>
                <div className={classes.divider}></div>
                <div style={{ height: isLg ? "20.7%" : "24.7%", width: "100%" }}>
                  <div className={classes.UnitName}>
                    <div className={classes.Unit}>{props.wpuProbs.unit}</div>
                    <div className={classes.miniDivider}></div>
                    <div className={classes.ActivityName}>{props.wpuProbs.activity}</div>
                  </div>
                  <div className={classes.divider}></div>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: isLg ? "3%" : "1%", marginBottom:isLg ? "0px" : "1px" }}>
                    <div className={classes.ProgressCompletion}>TOTAL PROGRESS COMPLETION</div>
                    {props.wpuProbs.progress === 0 && (
                      <div>-</div>
                    )}
                    {props.wpuProbs.progress > 0 && (
                      <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", width: "213px" }}>
                        <ProgressBar {...pbProps} />
                        <div style={{ fontSize: "14px", marginBottom: "2px" }}> {pbProps.value}% </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className={classes.divider}></div>
                <div className={classes.rowsRoot}>
                  {rows.length === 0 && (<div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center" }}> <LoadingSpinner bgColor="#f4f4f4" /> </div>)}
                  {dummy}
                </div>
                <div className={classes.divider}></div>
                <div className={classes.close} onClick={closeModel}>Close</div>
              </div>
            </ModalBody>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  ));
};

export default memo(WPU);

