import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'

function LoadingSkelaton() {
    var arr: any = [];
    while (arr.length < 12) {
        var r: any = Math.floor(Math.random() * 100) + 1;
        if (arr.indexOf(r) === -1) arr.push(r);
    }
    return (
        <div style={{ display: "flex" }}>
            {
                arr.map(key => {
                    return (
                        <table key={key}>

                            <tbody>
                                <tr>
                                    <td>
                                        <Skeleton variant="rect" animation="wave" width={76} height={35} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Skeleton variant="rect" animation="wave" width={76} height={35} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Skeleton variant="rect" animation="wave" width={76} height={35} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Skeleton variant="rect" animation="wave" width={76} height={35} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Skeleton variant="rect" animation="wave" width={76} height={35} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Skeleton variant="rect" animation="wave" width={76} height={35} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Skeleton variant="rect" animation="wave" width={76} height={35} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Skeleton variant="rect" animation="wave" width={76} height={35} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Skeleton variant="rect" animation="wave" width={76} height={35} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Skeleton variant="rect" animation="wave" width={76} height={35} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Skeleton variant="rect" animation="wave" width={76} height={35} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Skeleton variant="rect" animation="wave" width={76} height={35} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Skeleton variant="rect" animation="wave" width={76} height={35} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    )
                })
            }
        </div>
    )
}

export default LoadingSkelaton