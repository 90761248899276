import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../models/ProvideModel';
import { ChecklistSelectStates, ChecklistSelectActions } from '../models/MultiSelect';

export interface IChecklistReportSummary {
  count: number;
  loading: boolean;
  getData(): Promise<any>;
};
// TODO: add state changes based on individual toggles if they lead to select all
const ChecklistReportSummary = ({ count, loading, getData }: IChecklistReportSummary) => {
  const store = useStore();
  return loading ?
    (<div>Loading...</div>)
    :
    (
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", fontSize: "1.15em", width: "35%", alignItems: "center", paddingLeft: "19px" }}>
        {store.checklistMultiSelect.checklistItems.length?.toLocaleString('en-GB', { minimumIntegerDigits: 2 })} out of {count} reports selected
        <span style={{ color: "#005eff", cursor: "pointer", marginLeft: "8px" }}
          onClick={() => {
            if (store.checklistMultiSelect.checklistState === ChecklistSelectStates.ALL) {
              store.checklistMultiSelect.addItems({ action: ChecklistSelectActions.CLEAR, payload: { items: [] } });
            }
            else {
              store.checklistMultiSelect?.changeSelectedState(true)
              getData()
                .then(({ data: { data } }) => {
                  store.checklistMultiSelect.addItems({
                    action: ChecklistSelectActions.REPLACE,
                    payload: {
                      items: data.map(({ id, unit, activity, block_name }) => ({ id, fileName: (block_name + "-" + unit + "-" + activity + ".pdf").replaceAll("/", "-") })),
                      state: ChecklistSelectStates.ALL
                    }
                  });
                });
            }
          }}>{store.checklistMultiSelect.checklistState === ChecklistSelectStates.ALL ? "Deselect All" : "Select All"}</span>
      </div >);
};

export default observer(ChecklistReportSummary);
