import React from 'react'
import { TextField } from '@material-ui/core';

function SearchFilter(handleSearchChange) {
    return (
      <div style={{padding: "0 16px", width:"100%"}}>
        <TextField id="standard-basic" label="Search" value={handleSearchChange.value}  onChange={handleSearchChange.handleSearchChange} style={{width:"97%"}}/>
      </div>
    )
}
export default React.memo(SearchFilter);