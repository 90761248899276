import React, { useEffect, useMemo, useState } from 'react';
import { observer, useObserver } from 'mobx-react-lite';
import { Checkbox, TextField } from '@material-ui/core';
import { useStore } from '../../models/ProvideModel';
import useStyles from "../filter/FilterStyles";
import clsx from "clsx";
import { ChecklistSelectStates, ChecklistSelectActions } from '../../models/MultiSelect';
import { reaction } from 'mobx';
import { getFileNameFromRow } from '../../utils/utils';

const PageSelectCheckbox = ({ column: { value }, rows }) => {
  const store = useStore();
  const classes = useStyles({ selectAll: store.checklistMultiSelect.selectAll });
  const [particularPageSelected, setParticularPageSelected] = useState<boolean>(true);
  const [selectedItems, setSelectedItems] = useState<boolean>(false);
  const items = useMemo(() => rows.map(({ original }) => ({ id: original[value], fileName: getFileNameFromRow(original) })), [rows, value]);
  useEffect(() => {
    setParticularPageSelected(true)
  }, [store.checklistMultiSelect.checklistItems.length]);

  useEffect(() => reaction(() => (store.checklistMultiSelect.checklistItems), str => {
    setParticularPageSelected(true)
  }), [store.checklistMultiSelect.checklistItems]);

  if (particularPageSelected === true) {
    for (var i = 0; i < items.length; i++) {
      const val = store.checklistMultiSelect.checklistItems?.find(({ id }) => id === items[i]?.id);
      if (val) {
      }
      else {
        setParticularPageSelected(false)
      }
    }
  }
  if (selectedItems === false) {
    for (var i = 0; i < items.length; i++) {
      const val = store.checklistMultiSelect.checklistItems?.find(({ id }) => id === items[i]?.id);
      if (val) {
        setSelectedItems(true)
      }
    }
  }
  useEffect(() => {
    if (particularPageSelected == false) {
      store.checklistMultiSelect.changeChecklistState(ChecklistSelectActions.CLEAR)
    }
  }, []);
  const handleChange = () => {
    switch (store.checklistMultiSelect.checklistState) {
      case ChecklistSelectStates.PAGE_SELECTED:
        store.checklistMultiSelect.addItems({ action: ChecklistSelectActions.CLEAR, payload: { items: items } });
        break;
      case ChecklistSelectStates.EMPTY:
        store.checklistMultiSelect.addItems({
          action: ChecklistSelectActions.REPLACE,
          payload: {
            state: ChecklistSelectStates.PAGE_SELECTED,
            items
          }
        });
        break;
      case ChecklistSelectStates.ALL:
        store.checklistMultiSelect.addItems({ action: ChecklistSelectActions.TOGGLE, payload: { items: items } })
        break;
      case ChecklistSelectStates.DEFAULT:
        store.checklistMultiSelect.addItems({
          action: ChecklistSelectActions.REPLACE,
          payload: {
            state: ChecklistSelectStates.PAGE_SELECTED,
            items
          }
        });
        break;
    }
  };

  return useObserver(() => (
    <div className={classes.checkboxUi}>
      <Checkbox
        checked={store.checklistMultiSelect.checklistState === ChecklistSelectStates.ALL || particularPageSelected === true}
        indeterminate={store.checklistMultiSelect.checklistState === ChecklistSelectStates.DEFAULT && particularPageSelected === false}
        indeterminateIcon={
          <span
            className={clsx(classes.icon, classes.indeterminateCheckedIcon)}
          />
        }
        checkedIcon={
          <span className={clsx(classes.icon, classes.checkedIcon)} />
        }
        icon={<span className={classes.icon} />}
        onChange={store.checklistMultiSelect.selectAll ? () => { } : handleChange}
      /></div>
  ));
};

export default observer(PageSelectCheckbox);
