import * as React from 'react';
import ConventionalActivityOverview from './ConventionalActivityOverview';
import AluFormActivityOverview from './AluFormActivityOverview';
import FinishingActivityOverview from './FinishingActivityOverview';
import { useStore } from '../../models/ProvideModel';
import { useObserver } from 'mobx-react-lite';
import LoadingSpinner from '../../components/loadingSkelaton/LoadingSpinner';

// * Harsha: Entry Point to Activitiy Overview
export interface IAppProps {
  loading: boolean;
  pourType: string;
  phase: string;
  spaceType: string;
  classes: any;
}

function ActivityOverview(props: IAppProps) {

  const store = useStore();

  // TODO: Rakesh currently pour_number in tables is not corresponding to the
  // same in bricks. Currently implementing a very flimsy and brittle workaround.
  return useObserver(() => props.loading ? (<LoadingSpinner />)
    : props.phase === "structures" && props.spaceType === "tower" ? (
      <>
        {{
          conventional: (
            // * Harsha:Disable for now
            <ConventionalActivityOverview {...props} pour={store.summary?.brick?.xVal?.id?.toLocaleString('en-GB', { minimumIntegerDigits: 2 })} conventionalData={store.summary.conventionalData} />
          ),
          aluminium: (
            <AluFormActivityOverview {...props} pour={(store.summary?.brick?.xVal?.id?.toLocaleString('en-GB', { minimumIntegerDigits: 2 }))} aluformData={store.summary?.aluformData} />
          )
        }[props.pourType]}
      </>
    )
      :
      (<FinishingActivityOverview {...props} summary={store.summary} />)

  )
  /* return useObserver(() => !props.loading ?
   *   <>
   *     {{
   *       structures: (
   *         // * Harsha: only structures
   *         <>
   *           {{
   *             conventional: (
   *               // * Harsha:Disable for now
   *               <ConventionalActivityOverview {...props} pour={store.summary?.brick?.xVal?.id?.toLocaleString('en-GB', { minimumIntegerDigits: 2 })} conventionalData={store.summary.conventionalData} />
   *             ),
   *             aluminium: (
   *               <AluFormActivityOverview {...props} pour={(store.summary?.brick?.xVal?.id?.toLocaleString('en-GB', { minimumIntegerDigits: 2 }))} aluformData={store.summary?.aluformData} />
   *             )
   *           }[props.pourType]}
   *         </>
   *       ),
   *       finishing: (
   *         // * only finishing 
   *         <FinishingActivityOverview {...props} summary={store.summary} />
   *       )
   *     }[props.phase]}
   *   </>
   *   :
   *   <>
   *     <LoadingSpinner />
   *   </>
   * ) */
}

export default React.memo(ActivityOverview)
