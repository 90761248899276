import { makeStyles, Theme } from "@material-ui/core/styles";
const useStyles = makeStyles((theme: Theme) => ({
    mainDiv: {
        background: "#ffff",
        width: "22%",
        minHeight: "55px",
        display: "flex",
        alignItems: "center",
        position: "fixed",
        bottom: "52%",
        left: "43%",
        border: "2px solid gray",
        zIndex: 1,
        padding: "12px"
    },
    textDiv: {
        color: "black",
        fontSize: "16px",
        textTransform: "capitalize",
        width: "100%",
        fontWeight: "bold"
    },
    secondTextDiv: {
        color: "black",
        fontSize: "15px",
        textTransform: "capitalize",
        width: "100%",
        marginTop: "10px"
    },
    secondPopup: {
        position: "relative",
        height: "186px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignSelf: "center",
        alignItems: "center",
        padding: "22px",
        maxWidth: "500px"
    },
    button: {
        borderRadius: "2px",
        cursor: "pointer",
        color: "#005eff",
        backgroundColor: "#fff",
        height: "40px",
        textTransform: "capitalize",
        width: "65px",
        border: "2px solid rgb(0 94 255 / 53%)",
        "&:hover": {
            color: "#7b68ee",
            backgroundColor: "#fff"
        }
    },
    downloadButton: {
        borderRadius: "2px",
        display: "flex",
        color: "#808080 !important",
        backgroundColor: "#f4f4f4 !important",
        height: "28px",
        textTransform: "capitalize",
        width: "93%",
        alignSelf: "flex-end",
        fontSize: "12px",
        margin: "20px 0 20px 15px",
        "&:hover": {
            color: "#7b68ee",
            backgroundColor: "#fff"
        }
    },
    downloadButtonVisible: {
        borderRadius: "2px",
        display: "flex",
        color: "#005EFF !important",
        backgroundColor: "#f4f4f4 !important",
        height: "28px",
        textTransform: "capitalize",
        width: "93%",
        alignSelf: "flex-end",
        fontSize: "12px",
        margin: "20px 0 20px 15px",
        "&:hover": {
            color: "#7b68ee",
            backgroundColor: "#fff"
        }
    }
}));
export default useStyles;
