import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useStore } from '../../models/ProvideModel';
import { useObserver } from 'mobx-react-lite';
import { Redirect, useLocation } from "react-router";
import ProjectPropertPanel from '../../components/menuNavigation/projectPropertPanel/ProjectPropertPanel';
import MenuPanel from '../../components/menuNavigation/menuPanel/MenuPanel';
import useStyles from './NavigationStyles';
import { reaction } from 'mobx';
import LoadingSpinner from '../../components/loadingSkelaton/LoadingSpinner';
const timerFn = (fn: Dispatch<SetStateAction<boolean>>) => { fn(false); };

const NavigationPage = () => {
  const store = useStore();
  const classes = useStyles();
  const [spin, setSpin] = useState<boolean>(false);
  const location = useLocation();

  /* useEffect(() => { store.menus.getMenu(); }, []); */

  useEffect(() => {
    setSpin(true);
    const timer = setTimeout(timerFn, 2000, setSpin);
    return () => {
      if (timer) { clearTimeout(timer); }
    };
  }, [location.search, location]);
  useEffect(() => reaction(() => [store.projectInfo.currentProject, store.projectInfo.currentProject && store.projectInfo.currentProject.properties.length],
    () => {
      if (store.projectInfo.currentProject &&
        !store.projectInfo.currentProject.loading &&
        store.projectInfo.currentProject.properties.length) {
        /* store.menus.getMenu(); */
      }
    }), []);

  return useObserver(() => (
    <div className={classes.root}>
      <ProjectPropertPanel propertyList={store.menus.getPropertyList} projectName={store.projectInfo.currentProject.name} />
      {store.menus.loading ? !spin && <LoadingSpinner width={"80%"} /> : <MenuPanel phases={store.menus.getDistinctMenu} />}
    </div>
  ))
}

export default NavigationPage;
