import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    fontFamily: "Source Sans Pro, sans-serif",

  },
  formControl: {
    width: "100%"
  },
  progressOver: {
    fontFamily: "Source Sans Pro, sans-serif !important",
    fontSize: "11px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.27,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#585858",
  },
  selectedItem: {
    "&:first-child": {
      color:"black"
    },
    color: "#005eff",
    fontSize: "14px",
    minHeight: "36px",
    "&:hover": {
      fontWeight: "bold",
      color: "#005eff",
      marginLeft: "5px",
      marginRight: "5px"
    },
    fontFamily: "Source Sans Pro, sans-serif !important",
    "&:last-child": {
      marginBottom:"8px"
    },

  },
  selectStatus: {
    fontSize: "11px",
    height: "30px",
    display: "flex",
    alignItems: "center",
    padding:"8px 16px 0px 16px",
    position:"sticky",
    top:0
  },
  customDate: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "40px",
    paddingLeft: "16px",
    paddingRight: "16px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor:"#edebeb"
    }
  },
  inputField: {
    // color:"#005eff",
    // fontWeight:"bold",
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "14px",
    "&:before": {
      borderBottom: "1px solid #005eff"
    },
    "&:after": {
      borderBottom: "1px solid #005eff"
    }
  },
  customDatePicker: {
    fontFamily: "Source Sans Pro, sans-serif !important",
    fontSize: "12px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.25,
    letterSpacing: "normal",
    textAlign: "right",
    color: "#005eff"
  },
  button: {
    margin: theme.spacing(0),
    color: "#005eff",
    backgroundColor: "#ffffff",
    border: "0px",
    "&:hover": {
      backgroundColor: "#ffffff",
      textDecoration: "none"
    }
  },
  contentPadding: {
    padding: "0px",
    "&:first-child": {
      paddingTop: "0px",
    },
    overflow: "hidden"
    // height: "335px",
  },
  dialogContent: {
    display:"flex",
    flexDirection:"column",
    width:"45%" },
  dialogTitle: {
    backgroundColor: "#ffffff",
    // height: "90px",
    margin: 0,
    padding: "16px 24px",
    display:"flex"
  },
  divider: {
    width: "275px",
  height: "2px",
  backgroundColor:"#edebeb",
  alignSelf: "center",
  },
  dialogAction: {
    display: "flex",
    justifyContent: "space-between"
  },
  progressBottom: {
    paddingTop: "4px",
    // display: "flex",
    // justifyContent: "space-between",
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "11px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.3,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#585858",
    textTransform: "uppercase",
  },
  calendarTitle: {
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "14px",
    fontWeight: "bold",
    textTransform:"uppercase",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.14,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#585858",
  },
  calendarValue: {
    fontFamily: "Source Sans Pro, sans-serif",
    fontSize: "16px",
    fontWeight: "normal",
    textTransform:"uppercase",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.14,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#585858",
  },
  verticalDivider: {
    height:"30px",
    width:"4px",
    backgroundColor:"#edebeb",
    marginLeft: "10%",
    marginRight: "12%",

  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default useStyles;
