import { makeStyles } from "@material-ui/core/styles";
import { ProgressBarProps } from "../progressBar/ProgressBar";

const useStyles = makeStyles((props: ProgressBarProps) => {
    return {
        dateAndtime: {
            width: "131px",
            maxWidth: "171px",
            margin: "8px 0 5px 0",
            // fontFamily: "SourceSansPro",
            fontSize: "11px",
            lineHeight: 1.27,
            color: "#585858",
            display: "flex",
            justifyContent: "space-between"
        },
        activityProgressRectangle: {
            // width: "221px",
            height: "30px",
            margin: "0 0 7px",
            // padding: "8px 113px 8px 10px",
            backgroundColor: "#e8e8e8",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        },
        activityProgressname: {
            fontSize: "11px",
            fontWeight: "bold",
            color: "#585858",
            marginLeft: "10px"
        },
        percentageRectangle: {
            width: "100px",
            height: "30px",
            // padding: "6px 10px",
            backgroundColor: "#d0d0d0"
        },
        remarks: {
            margin: "0 0 8px 0",
            // fontFamily: "SourceSansPro",
            fontSize: "16px",
            width: "300px",
            wordWrap: "break-word",
            color: "#2f3e6c"
        },
        inputValue: {
            // margin: "4px 0 5px 0",
            fontSize: "14px",
            fontWeight: "bold",
            color:  "#585858",
            display: "flex",
            justifyContent: "center",
            marginTop: "5px"
        },
        image: {
            height: 64,
            width: 64,
            marginBottom: "5px",
            marginTop: "2px",
            cursor:"pointer",
            "&:hover": {
                height: 72,
                width: 72,
                marginLeft: "0px",
            }
        },
        brokenImage: {
            height: 64,
            width: 64,
            marginBottom: "5px",
            marginTop: "2px"
        },
        divider: {
            height: "1px",
            backgroundColor: "#d1d1d1"
        },
        miniDivider: {
            width: "1px",
            height: "9px",
            margin: "2px 0 0 0",
            backgroundColor: "#585858"
        }
    }
});

export default useStyles;
