import React from 'react'
import { useStore } from '../models/ProvideModel';
import { useHistory } from 'react-router';
import { Link } from '@material-ui/core';
import { getSnapshot } from 'mobx-state-tree';

const UnitSnagCell = (props) => {
  const { push } = useHistory()
  const store = useStore();
  const params = store.params
  const { unit_id, block_id, activity_type_id } = props.row.original;
  const Urlpath: any = store.params.toPath({ ...getSnapshot(store.params), section: "snags" }) + `?unit_id=${unit_id}`
  const onSnagClick = (e) => {
    document?.getElementById(unit_id)?.removeAttribute("href");
    e = e || window.event;
    if (e.button !== null) {
      push(Urlpath);
    }
  }
  if (!props.row.original[props.column.value]) {
    return (<div> — </div>)
  }
  else {
    const { count_open, count_closed, count_for_review } = props.row.original[props.column.value]

    return (
      <div>
        <Link id={unit_id}
          variant="body2"
          href={`${window.location.href.split('/', 3).join('/')}${Urlpath}`}
          target="popup"
          onClick={(e) => { onSnagClick(e); return false }}
          style={{ textAlign: "left", color: "#005eff" }}
        >
          {`${count_open} / ${Number.parseInt(count_open) + Number.parseInt(count_closed) + Number.parseInt(count_for_review)}`}
        </Link>
      </div>
    )
  }
}

export default UnitSnagCell
