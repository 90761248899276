import React from "react";
import { IconButton, MenuItem, Menu, Fade, Link } from "@material-ui/core";
import styles from "./MenuStyles";
import { useStore } from "../../models/ProvideModel";
import { useHistory } from "react-router";
import { useObserver } from "mobx-react-lite";
import menuWhiteIcon from '../../assets/images/Menu White.svg';
import menuIcon from '../../assets/images/Menu.svg';

function MenuComponent() {
  const store = useStore();
  const classes = styles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();
  const path = history.location.pathname;

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    store.auth.logout();
    history.push('/login')
  }

  const handleProject = () => {
    store.projectInfo.unsetProject();
    history.push('/')
  }
  return useObserver(() => (
    <div>
      <IconButton
        edge="start"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
        aria-label="menu"
        className={classes.menuButton}
      // style={{marginTop:"8px"}}
      >
        <img src={(path === "/projects") ? menuWhiteIcon : menuIcon} alt="menu" />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        TransitionComponent={Fade}
        style={{ paddingBottom: 8 }}
      >
        <MenuItem >
          <div className={classes.userNameText}>
            <span style={{ fontWeight: "bold", color: "#585858" }}>User: </span> <span>{store.auth.userName}</span>
          </div>
        </MenuItem>
        <MenuItem >
          <div className={classes.userNameText}>
            <span style={{ fontWeight: "bold", color: "#585858" }}>Customer:</span> <span>{store.auth.customerName}</span>
          </div>
        </MenuItem>
        {(path === "/projects") ? null :
          <MenuItem onClick={handleProject} >
            <Link
              className={classes.userNameText}
              color="secondary"
            >
              Select Project
                </Link>
          </MenuItem>
        }
        {(path === "/projects") ? null :
          <MenuItem onClick={() => history.push('/powerbi')}>
            <Link
              color="secondary"
              className={classes.userNameText}
            >
              PowerBI Reports
                </Link>
          </MenuItem>
        }
        <MenuItem onClick={handleLogout}>
          <Link
            color="secondary"
            className={classes.userNameText}
          >
            Logout
                </Link>
        </MenuItem>
      </Menu>
    </div>
  ));
}
export default MenuComponent
