import React, { memo, useMemo, useState, KeyboardEvent, useEffect } from "react";
import useStyles from "./login.styles";
import { useObserver } from 'mobx-react-lite';
import { useStore } from '../../models/ProvideModel';
import { Grid, TextField, Button, CircularProgress, IconButton, InputAdornment } from "@material-ui/core";
import { useHistory } from 'react-router';
import fbtLogoIcon from '../../assets/images/FBTLogo.svg';
import { useMediaQuery } from "react-responsive";
import { LG_DEVICE_QUERY, SM_LANDSCAPE_QUERY, XS_LANDSCAPE_QUERY, TAB_LANDSCAPE_QUERY } from "../../utils/responsive";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ForgetPassword from "../forgetPassword/forgetPassword";
import Animations from './animations'

const Login = memo(() => {
  const store = useStore();
  const history = useHistory();
  const isLg = useMediaQuery(LG_DEVICE_QUERY);
  const isSm = useMediaQuery(SM_LANDSCAPE_QUERY);
  const isXs = useMediaQuery(XS_LANDSCAPE_QUERY);
  const isTab = useMediaQuery(TAB_LANDSCAPE_QUERY)
  /**
   * Responsiveness queries related hook
   */
  useEffect(() => {
    store.responsiveUtils.currentViewport.setLg(isLg);
    store.responsiveUtils.currentViewport.setSm(isSm);
    store.responsiveUtils.currentViewport.setXs(isXs);
  }, []);

  const [userName, setUserName] = useState(store.auth.username ? store.auth.username : "");
  const [password, setPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [forgetPassword, setForgetPassword] = useState(false);
  // const { isSm ,isLg, isXs} = store.responsiveUtils.currentViewport
  const classes = useStyles({ isLg, isSm, isTab });

  useEffect(() => {
    if (store.auth.isAuthenticated === true) {
      history.push('/');
    }
  }, [history, store.auth.isAuthenticated])

  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = useMemo(() => ((
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<Element, MouseEvent> | KeyboardEvent<Element>
  ) => {
    e.preventDefault();
    if (!submitted) {
      setSubmitted(true);
      if (userName && password)
        store.auth.auth(userName, password).then(() => {
          history.push('/');
        });
    }
  }), [submitted, userName, password, store.auth, history]);

  const loginButton = store.auth.loading ?
    (<Button type="submit" color="primary" disabled={true} fullWidth className={isLg ? (!userName || !password) ? classes.applyDisabled : classes.button : (!userName || !password) ? classes.applyDisabledSm : classes.buttonSm} >
      <CircularProgress size={20} style={{ color: "#FFFFFF" }} /></Button>)
    :
    (<Button type="submit" color="primary" disabled={((!userName || !password) || store.auth.loading) ? true : false} fullWidth className={isLg ? (!userName || !password) ? classes.applyDisabled : classes.button : (!userName || !password) ? classes.applyDisabledSm : classes.buttonSm} > Login </Button>)

  const baseClass = (e) => {
    return classes.baseSm
  }

  const forgetPasswordconst = () => {
    setForgetPassword(true)
  }
  const status = () => {
    setForgetPassword(false)
  }

  return useObserver(() => (forgetPassword ?
    <ForgetPassword onStatusChange={status} />
    : <div className={classes.root}>
      <Grid container className={isLg ? classes.base : baseClass(isSm)}>
        <Grid item xs={12} style={{ marginBottom: "19px" }}>
          <img src={fbtLogoIcon} alt="FalconBricks" />
        </Grid>
        <Grid item xs={12} style={{ height: "315px" }}>
          <form
            name="form"
            className={classes.formGroup}
            onSubmit={handleSubmit}
          >
            <div
              className={`form-group${submitted && !userName ? " has-error" : ""
                }`}
            >
              <TextField
                label={<span className={classes.inputLabel}>User name</span>}
                placeholder="Type Here"
                fullWidth
                className={classes.inputField}
                margin="normal"
                onChange={e => setUserName(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                type="email"
                value={userName}
                autoComplete="email"
                InputProps={{ classes: { input: classes.input } }}
              />
            </div>
            <div
              className={`form-group${submitted && !password ? " has-error" : ""
                }`}
            ><div>
                <TextField
                  label={<span className={classes.inputLabel}>Password</span>}
                  placeholder="Type Here"
                  className={classes.inputField}
                  fullWidth
                  margin="normal"
                  name="password"
                  type={values.showPassword ? 'text' : 'password'}
                  onChange={e => setPassword(e.target.value)}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    classes: { input: classes.input, underline: classes.underline },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          className={classes.Iconbutton}
                        >
                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </div>
            </div>
            <div className={classes.forgetPassword} onClick={forgetPasswordconst}>Forgot Password</div>
            <div className={classes.formGroup}>
              <div style={{ marginTop: "15px" }} >
                {loginButton}
              </div>
              {store.auth.error && <div className={classes.invalidForm}><span>{store.auth.error}</span></div>}
              {
                (submitted && (!userName || !password)) &&
                <div className={classes.invalidForm}><span>your username or password is not correct</span></div>
              }
            </div>
          </form>
        </Grid>
      </Grid>
      {isLg && !isTab && <Animations />}
    </div >
  ));
});

export default Login;
