import { SnapshotIn, types, UnionOptions } from 'mobx-state-tree';
import { CellAddressType } from '../enums';

export const CellAddressNumId = types.model("CellAddressNumId", {
    id: types.identifierNumber,
    type: types.refinement(types.frozen<CellAddressType>(), val => val === CellAddressType.POUR_NUM || val === CellAddressType.FLOOR_NUM),
    name: types.string
});

export const CellAddressActivities = types.model("CellAddressAct", {
    id: types.identifier,
    type: types.frozen<CellAddressType>(CellAddressType.ACT_STRING),
    name: types.string,
    precedence: types.number,
    stage: types.string,
});

// TODO: Formalize the tower part. Currently too adhoc.
export const CellAddressStringId = types.model({
    id: types.identifier,
    type: types.refinement(types.frozen<CellAddressType>(), val => val === CellAddressType.TOWER_STRING || val === CellAddressType.UNIT_TYPE_STRING || val === CellAddressType.NULL),
    name: types.string,
    tower: types.optional(types.string, "")
});

export const CellAddressNull = types.model({ id: 0, type: CellAddressType.NULL, name: "" });

const __dispatch: UnionOptions = {
    dispatcher: ({ type }) => {
        switch (type) {
            case CellAddressType.ACT_STRING: return CellAddressActivities;
            case CellAddressType.POUR_NUM:
            case CellAddressType.FLOOR_NUM: return CellAddressNumId;
            case CellAddressType.UNIT_TYPE_STRING:
            case CellAddressType.TOWER_STRING: return CellAddressStringId;
            case CellAddressType.NULL: return CellAddressNull;
            default: throw new Error("please give a valid type of Cell Address");
        }
    }
};

export const CellAddress = types.union(__dispatch, CellAddressStringId, CellAddressNumId, CellAddressActivities, CellAddressNull);

export function snToCellAddressNumId(type: CellAddressType.FLOOR_NUM | CellAddressType.POUR_NUM, { id, name }: { id: number; name?: string }): SnapshotIn<typeof CellAddressNumId> {
    if (type === CellAddressType.FLOOR_NUM) {
        return { type, id, name: name || id.toLocaleString('en', { minimumIntegerDigits: 2 }) };
    }
    else {
        return { type, id, name: name || `Pour ${id}` };
    }
}

export function snToCellAddressActivities({ id, name, precedence, activity }: { id: string; name: string; precedence?: number; activity: { stage?: string; } }): SnapshotIn<typeof CellAddressActivities> {
    return { type: CellAddressType.ACT_STRING, id, name, precedence: precedence || 0, stage: activity.stage || "ee" };
}

export function snToCellAddressStringId(type: CellAddressType.TOWER_STRING | CellAddressType.UNIT_TYPE_STRING, { id, name, block, tower }: { id: string; name?: string; block?: { name: string; }; tower?: string; }): SnapshotIn<typeof CellAddressStringId> {
    if (type === CellAddressType.TOWER_STRING) {
        return { type, id, name: block?.name || '???' };
    }
    if (type === CellAddressType.UNIT_TYPE_STRING) {
        return tower ? { type, id, name: name || id, tower } : { type, id, name: name || id };
    }
    return { type, id, name: name || '???' };
}

export function snToCellAddressNull() {
    return { id: 0, type: CellAddressType.NULL, name: "" };
}
