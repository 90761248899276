import React from 'react'
import { useObserver } from 'mobx-react-lite'
import useStyles from "./Common";
import eerorImg from '../../assets/images/brwsrSupprt/errorImage.png';
import { useStore } from '../../models/ProvideModel';


function NoDataFound() {
  const store = useStore()
  const { fullScreenMode } = store.responsiveUtils;
  const classes = useStyles({ fullScreenMode });
  const dots: any = [".", ".", ".", "."]
  const mobilefullScreenMode = (fullScreenMode) => {
    return classes.rootsm
  }
  return useObserver(() => (
    <div className={store.params.graphType === "table" ? store.responsiveUtils.currentViewport.isLg ? classes.root : mobilefullScreenMode(store.responsiveUtils.fullScreenMode) : classes.Brickroot} >
      <div className={classes.mainDiv}>
        <img src={eerorImg} height={store.responsiveUtils.currentViewport.isLg ? "" : 62} />
        <span className={store.responsiveUtils.currentViewport.isLg ? classes.fontSize : classes.fontSizeSm}>Nothing to see here</span>
        <span className={store.responsiveUtils.currentViewport.isLg ? classes.textSize : classes.textSizeSm}>There doesn't seem to be any data for These Particular Block in The Current Tab.</span>
        {dots.map((key, i) => {
          return <span key={i} style={{ display: "flex", justifyContent: "center", fontSize: store.responsiveUtils.currentViewport.isLg ? "18px" : "12px" }}>{key}</span>
        })}
        <span className={store.responsiveUtils.currentViewport.isLg ? classes.textSize : classes.textSizeSm}>Maybe try another Block from the dropdown?</span>
      </div>
    </div>
  ));
}

export default React.memo(NoDataFound)
