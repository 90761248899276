import React, {
  useState,
  useCallback,
  FunctionComponent,
  Props,
  useMemo,
  memo,
  useEffect,
  MemoExoticComponent,
  ReactNode,
} from "react";
// import Accordion from '../accordion/Accordion';
import { Button, Link } from "@material-ui/core";
import { chan, go, putAsync, IChan, IStream, sliding } from 'csp-with-ts';
import Provider, { DefaultList } from './DefaultAccordianRender';
import useStyles from "./FilterStyles";
import Accordion from "../accordion/Accordion";
import NonAccordianSelect from "../nonAccordianSelect/NonAccordianSelect";
import { useStore } from '../../models/ProvideModel';
import { useHistory, useLocation } from "react-router";
import SelectAll from './SelectAll'
import LoadingSpinner from "../loadingSkelaton/LoadingSpinner";
import Calendarimg from '../../assets/css/Calendar.svg'


export interface IOptions<T extends Record<string, any>> {
  children?: ReactNode;
  Render: FunctionComponent<Record<string, any>>;
  options: T[];
  title: any;
  callback?: () => void; //function sig with no arg
  disabled?: boolean;
  sortSelected?: string | null;
  dateRangeSelect?: string[] | null
  externalDisplay?: JSX.Element;
  titleProps?: any;
  formData?: boolean
}
//
export interface IOpts {
  apply?: boolean;
  multiple?: boolean;
  title?: JSX.Element;
  accordian?: boolean;
  numericDisplay?: boolean;
  reset?: boolean;
}


export interface IList<T extends Record<string, any>, K extends keyof T> extends Props<FunctionComponent<T>> {
  state: any[];
  setState: (id: T[K]) => void;
  RenderFn: FunctionComponent<Record<string, any>>;
  options: T[];
};

export type IUseMux<T extends Record<string, any>, K extends keyof T> = { type: 'toggle'; payload?: boolean; } | { type: 'select'; payload: T[K]; } | { type: 'replace'; payload: T[K][]; };

interface IReturn<T extends Record<string, any>, K extends keyof T> extends Array<T[K][] | IChan<IUseMux<T, K>> | MemoExoticComponent<(props: IOptions<T> & Record<string, any>) => JSX.Element>> {
  [0]: T[K][];
  [1]: MemoExoticComponent<(props: IOptions<T> & Record<string, any>) => JSX.Element>;
  [2]: IChan<IUseMux<T, K>>;
};


function useMux<T extends Record<string, any>, K extends keyof T>(relay: IChan<IUseMux<T, K>>, multi: boolean = true, initialState?: () => T[K][]): { state: T[K][]; broadcast: IChan<T[K][]>; toggle: IChan<boolean>; } {
  const [state, setState] = useState<T[K][]>([]);
  const [toggle, setToggle] = useState<boolean>(false);
  const broadcast = useMemo(() => chan<T[K][]>(sliding(1)), []);
  const toggleCh = useMemo(() => chan<boolean>(), []);
  const dispatch = useCallback((action: IUseMux<T, K>) => {
    switch (action.type) {
      case 'toggle': if (action.payload !== undefined) { setToggle(action.payload); }
      else { setToggle(t => !t); }
        break;
      case 'replace': setState(action.payload); break;
      case 'select': setState(st => {
        const ind = st.indexOf(action.payload);
        return ind !== -1 ? st.slice(0, ind).concat(st.slice(Math.min(ind + 1, st.length))) :
          multi ? [...st, action.payload] : [action.payload]
      }); break;
    }
  }, []);

  useEffect(() => go<IUseMux<T, K>>`<! ${relay} ${function* () { while (true) { dispatch(yield); } }} `, []);
  useEffect(() => { return () => { broadcast.close(); toggleCh.close(); }; }, []);
  useEffect(() => { putAsync(toggleCh, toggle); if (toggle) { setState(st => [...st]); } else { if (initialState) { setState(initialState()) } } }, [toggle]);
  useEffect(() => { putAsync(broadcast, state); }, [state]);
  return { state, broadcast, toggle: toggleCh };
}


export function useGetState<T extends IStream, S extends IStream = T>(ch: IChan<T, S>): S | undefined {
  const [state, setState] = useState<S>();
  useEffect(() => go`<! ${ch} ${function* () { while (true) { setState(yield); } }}`, []);
  return state;
}

// Its a react hook, it return a State and dynamincally generated react component.
export default function useAccordion<T extends Record<string, any>, K extends keyof T>(
  // idToCheck?: K,
  RenderFn: FunctionComponent<Record<string, any>> = DefaultList,
  opts?: IOpts,
  initialState?: () => T[K][]
): IReturn<T, K> {
  const relay = useMemo(() => chan<IUseMux<T, K>>(), []);
  const multi = useMemo(() => !(opts && opts.multiple === false) ? true : false, [opts]);
  useEffect(() => { return () => { relay.close(); }; }, []);
  const { state, broadcast, toggle } = useMux<T, K>(relay, multi, initialState);
  useEffect(() => {
    if (initialState) {
      putAsync(relay, { type: 'replace', payload: initialState() });
    }
  }, []);
  const location = useLocation();
  const { search } = useMemo(() => location, [
    location.search,
    location.pathname,
  ]);
  const Menu = useMemo(() => !(opts && opts.accordian === true) ? NonAccordianSelect : Accordion, [opts]);
  const store = useStore();
  const classes = useStyles({ isLg: !!store.responsiveUtils.currentViewport.isLg, isAccordian: (opts && opts.accordian === true) });
  const Return = useMemo(() => memo(({ Render, options, children, callback, reset, disabled, title, sortSelected, titleProps, externalDisplay, formData, headersCount, snagSortApplied, ...props }: IOptions<T> & Record<string, any>) => {
    const onApply = () => {
      setTimeout(() => store.responsiveUtils.closeFatDropdown());
      if (callback) callback();
      /* TODO: Does this introduce other tables problems? */
      /* putAsync(relay, { type: 'toggle', payload: true }); */
    }
    const resetAll = () => { reset() };

    const onToggle = (datefilter) => {
      store.filters.setCalenderOpen(datefilter)
    };

    return (
      <Menu
        title={!(opts && opts.accordian === true) ? title.props.title : title}
        toggle={toggle}
        relay={relay}
        broadcast={broadcast}
        sortSelected={sortSelected}
        dateRangeSelect={title?.props?.dateRange}
        numericDisplay={opts?.numericDisplay}
        externalDisplay={externalDisplay}
        formData={formData}
      >
        {!(opts && opts.accordian === true) ? <div className={classes.resetFilter}>
          <span className={classes.normText11px}>{!(opts && opts.accordian === true) ? title.props.title : " "}</span>
          {(opts && opts.reset !== false) && <Link style={{ color: "#005eff", cursor: "pointer", backgroundImage: "none" }} onClick={resetAll}>Reset</Link>}
        </div> : null}
        {children}
        {!(snagSortApplied && title.props.title.toLowerCase() === "status") && <>
          <div className={classes.selectAllDummyDiv}>
            {!(opts?.multiple === false) &&
              <SelectAll options={options} broadcast={broadcast} relay={relay} isAccordian={!!(opts && opts.accordian === true)} />
            }
          </div>
          <
            >{options?.length > 0 ?
              <div className={classes.optionsMenuContainer}>
                <Provider
                  relay={relay}
                  broadcast={broadcast}
                  RenderList={RenderFn}
                  RenderFn={Render}
                  options={options}
                  {...props}
                />
                {title?.props?.dateRange &&
                  <div style={{ display: "flex", cursor: "pointer", padding: "7px 22px" }} onClick={() => onToggle(titleProps?.urlId || "")}>
                    <img src={Calendarimg} />
                    <div className={classes.CustomDatePicker}>Custom date picker</div>
                  </div>
                }
              </div> :
              options?.length === 0 && store.exportTableFilters.headerDataLoading === false ?
                <div
                  style={{
                    background: "#fff",
                    width: "100%",
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "center"
                  }}
                >
                  Data is not there for this Dropdown.
              </div>
                : <div
                  style={{
                    background: "#fff",
                    width: "100%",
                  }}
                >
                  <LoadingSpinner />
                </div>
            }</>
          {!(opts && opts.apply === false) && (
            <div className={!(opts && opts.accordian === true) ? classes.applyDiv : classes.applyAccDiv}>
              <Button
                disabled={disabled}
                className={!(opts && opts.accordian === true) ? disabled ? classes.applyDisabled : classes.apply : opts.reset === false ? classes.applyTableAccordian : classes.applyAccordian}
                fullWidth
                onClick={onApply}
              >
                Apply
              </Button>
              {(opts && opts.accordian === true && !(opts.reset !== undefined && opts.reset === false)) && <Button size="small" className={classes.clear} onClick={resetAll}>Reset</Button>}
            </div>
          )}</>}
      </Menu >
    )
  }), []);
  return [state, Return, relay];
}
