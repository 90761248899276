import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  sortWrapper: {
    padding: "0px 16px 8px 16px",
    zIndex: 99999,
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    flexBasis: "auto",
    position: "sticky",
    top: 33,
  },
  sortWrapperSm: {
    padding: "0px 16px 8px 16px",
    top: 33,
    position: "sticky",
    zIndex: 99999,
    backgroundColor: "#fff",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    flexBasis: "auto",
  }
}));

export default useStyles;
