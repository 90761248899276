import { useState, useEffect, useCallback, useReducer } from 'react';
import { LocationDescriptorObject, LocationState } from 'history';
import moment from 'moment';

export interface IURLParamHookReturn<T extends any = string> extends Array<any> {
    [0]: T;
    [1]: (val?: T) => void;
};


type ICustomMap<T extends any = string> = (val: string) => T extends Array<infer S> ? S : T;

export function useSingleValueURLParam<T extends any = string>(param: string, defaultState: T, searchParam: URLSearchParams, conversionFn?: T extends string ? undefined : ICustomMap<T>, router?: (loc: LocationDescriptorObject) => void, location?: LocationState, serialToState?: (val: URLSearchParams) => T): IURLParamHookReturn<T> {
    const convert = serialToState || ((val: URLSearchParams) => val.has(param) ? conversionFn ? conversionFn(val.get(param)!) as T : val.get(param) : defaultState);
    const [state, setState] = useState<T>(() => convert(searchParam) as T);
    useEffect(() => {
        const serializedState: string | null = searchParam.get(param);
        if (serializedState === null) { setState(defaultState); }
        else {
            if (conversionFn) { setState(_ => conversionFn(serializedState) as T); }
            else { setState(serializedState as unknown as T); }
        }
    }, [searchParam]);
    const reroute = useURLParamRerouter<T>(param, searchParam, router, location);
    return [state, reroute];
}

export function useURLParamRerouter<T extends any = string>(param: string, searchParam: URLSearchParams, router?: (loc: LocationDescriptorObject) => void, location?: LocationState): (val?: T) => void {
    const reroute = useCallback((val?: T) => {
        if (router && location) {
            searchParam.delete(param);
            if (val !== undefined) {
                searchParam.append(param, (val as any).toString());
            }
            router({ ...location, search: searchParam.toString() });
        }
    }, [searchParam, location]);
    return reroute;
}
