import React, { useState, FunctionComponent, useMemo } from 'react';
import Sort from './Sort';

interface ISortHookReturns extends Array<any> {
  [0]: string | null;
  [1]: FunctionComponent<{ selected: string | null; callback: (val: string | null) => void; isLg?: boolean; }>;
};
// currently hardcoded to check all key values concatted into a string. Change later.
export default function useSort(initial?: string | null, options?: { id: string; name: string; }[]): ISortHookReturns {
  const [selected, setSelected] = useState<string | null>(() => initial || null);
  const Component = useMemo(() => ({ selected: selec, callback, isLg }: { selected: string | null; callback: (val: string | null) => void; isLg?: boolean; }) => <Sort callback={callback} setOption={setSelected} selected={selec} options={options} isLg={isLg} />, []);
  return [selected, Component];
}
