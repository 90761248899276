import { useCallback, useState } from 'react'


export interface UseModalProps {
    /**
    * If `true`, the modal when be opened.
    */
    isOpen: boolean
    /**
    * If `true`, the modal position will be centered.
    */
    isCentered?: boolean
    /**
    * If `isCentered`, is set to false the modal position will be based on this param.
    */
    position?: ContentPositionOffset
    /**
     * Callback invoked to close the modal.
     */
    onClose?: () => void
    /**
     * If `true`, the modal will close when the overlay is clicked
     * @default true
     */
    closeOnOverlayClick?: boolean
    /**
     * If `true`, the modal will close when the `Esc` key is pressed
     * @default true
     */
    closeOnEsc?: boolean
    /**
    * Callback fired when the overlay is clicked.
    */
    onOverlayClick?(): void
}

export type ContentPositionOffset = {
    top?: string | number,
    right?: string | number,
    bottom?: string | number,
    left?: string | number 
}

/**
 * Modal hook that manages all the logic for the modal dialog widget
 * and returns prop getters, state and actions.
 *
 * @param props
 */

export const useModal = (props: UseModalProps) => {
    const { isOpen, onClose, closeOnOverlayClick, onOverlayClick: onOverlayClickProp, isCentered, position } = props;

    const [modalState, setModalState] = useState(isOpen);

    const onOverlayClick = useCallback(
        (event: MouseEvent) => {
            event.stopPropagation();
            if (closeOnOverlayClick) onClose?.();
            onOverlayClickProp?.();
        },
        [isOpen, closeOnOverlayClick, onOverlayClickProp]
    )

    const getOverlayProps = useCallback((props?: {}) => ({
        ...props,
        onClick: (event) => onOverlayClick(event)
    }), [onOverlayClick])

    const getContentPosition = useCallback((props?: ContentPositionOffset) =>
        (isCentered ? undefined : { ...props, position: 'absolute' }),
        [isCentered]
    );

    return {
        isOpen,
        isCentered,
        onClose,
        getOverlayProps,
        getContentPosition,
        position
    }

}

export type UseModalReturn = ReturnType<typeof useModal>

