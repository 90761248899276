import React, { memo, useState, ReactElement, useEffect } from "react";
import useStyles from "./FlyMenuStyles";
// import Fade from "react-reveal/Fade";
import './FlyMenu.scss';
import { useStore } from '../../../models/ProvideModel';
import arrowIconHover from '../../../assets/images/menuNavigation/Arrow-hover.svg';
import arrowIcon from '../../../assets/images/menuNavigation/Arrow-default.svg';
import { useObserver } from "mobx-react-lite";
export interface IFlyMenuProps {
  name: string;
  count?: number;
  children: any;
  openHandler: (arg1: ReactElement | null, arg2?: number, arg3?: number) => void;
  row: number;
  selected: boolean;
  col: number;
  spaceType: string;
  phase: string;
  columns: number;
}

function FlyMenu(props: IFlyMenuProps) {

  const [open, setopen] = useState(false);
  const [hover, sethover] = useState(false)
  const store = useStore();
  const classes = useStyles({ disabled: props?.selected, brickCount: props?.count });
  const padding : number = store.responsiveUtils.currentViewport.isLg ? 196 : 173;
  const leftPadding: number = props.col === 0 ? 0 : props.col < 3 ? props.columns === 3 ? 0 : props.col * props.columns > 3 ? 0 : props.col * padding : (3 - props.columns) * 213.5;
  useEffect(() => {
    if (store.params.phase === props.phase && store.params.spaceType === props.spaceType) {
      props.openHandler(
        <div className={classes[store.responsiveUtils.getClassNameBasedOnViewport('reports', true)]} style={{ left: leftPadding }}>{props.children}</div>
        , props.row, props.col)
    }
  }, [])
  const toggle = () => {
    props.openHandler(
      <div className={classes[store.responsiveUtils.getClassNameBasedOnViewport('reports', true)]} style={{ left: leftPadding, marginLeft : store.responsiveUtils.currentViewport.isLg ? 0 : leftPadding}}>{props.children}</div>
      , props.row, props.col)
  };
  return useObserver(() => (
    <div className={`${store.responsiveUtils.currentViewport.isLg ? classes.root : classes.rootSm} ${classes.underlineAnim} ${open ? 'project-active' : ''} ${props.selected && 'c-box c-box--arrow-bottom'}`}
      onMouseEnter={() => sethover(true)}
      onMouseLeave={() => sethover(false)}
      onClick={toggle}>
      <div className={store.responsiveUtils.currentViewport.isLg ? classes.space_type : classes.space_typeSm}><div>{props.name}</div><img src={hover ? arrowIconHover : arrowIcon} alt="arrow default" className={"arrowIcon"}></img></div>
      {!!props.count && <div className={store.responsiveUtils.currentViewport.isLg ? classes.blockCount : classes.blockCountSm}>Blocks</div>}
    </div>
  ));
}

export default memo(FlyMenu);
