import { makeStyles } from "@material-ui/core/styles";
import { ProgressBarProps } from "./ProgressBar";

const useStyles = makeStyles((props: ProgressBarProps) => {
    return {
        primaryCont: (props: ProgressBarProps) => {
            return {
                display: "flex",
                width: props.width ? `${props.width}px` : '100%',
                height: props.height ? `${props.height}px` : '10px',
                background: props.background,
                border: props.value >= 100 && props.status === "Completed" ? `2px solid ${props.default}` : `2px solid ${props.primary}`
            }
        },
        progressIndicator: (props: ProgressBarProps) => {
            return {
                height: '100%',
                width: props.value > 100 ? "100%" : `${Number(props.value)}%`,
                minWidth: props.value > 100 ? "100%" : `${Number(props.value)}%`,
                background: props.value >= 100 && props.status === "Completed" ? props.default : props.primary
            }
        },
        divider: (props: ProgressBarProps) => {
            return {
                height: '100%',
                width: '2px',
                background: props.divider
            }
        },
        root: (props: ProgressBarProps) => {
            return {
                width: props.popup ? "66%" : "100%",
                display: props.popup ? "flex" : "",
                flexDirection: props.popup ? "row" : "unset",
                justifyContent: props.popup ? "space-between" : "",
                alignItems: props.popup ? "center" : ""
            }
        }
    }
});

export default useStyles;
