import React, { useState } from "react";
import { observer, useObserver } from "mobx-react-lite";
import moment from "moment";
export function secondsDiff(d1, d2) {
  let millisecondDiff = d2 - d1;
  let secDiff = Math.floor((d2 - d1) / 1000);
  return secDiff;
}
export function minutesDiff(d1, d2) {
  let seconds = secondsDiff(d1, d2);
  let minutesDiff = Math.floor(seconds / 60);
  return minutesDiff;
}
export function hoursDiff(d1, d2) {
  let minutes = minutesDiff(d1, d2);
  let hoursDiff = Math.floor(minutes / 60);
  return hoursDiff;
}
export function differenceTime(status, createdDate, lastUpdated) {
  var today = new Date().getTime();
  const diffTime: any = status?.toLowerCase() !== "close" ? hoursDiff(createdDate.getTime(), today) : hoursDiff(createdDate.getTime(), lastUpdated.getTime());
  return moment(createdDate).format("D MMM YYYY") != moment(lastUpdated).format("D MMM YYYY") && (diffTime / 24) < 1 ? Math.ceil(diffTime / 24) : Math.floor(diffTime / 24)
}
const SnagAge = (props) => {
  var createdDate = new Date(props.cell.value);
  var lastUpdated = new Date(props.cell.row.original.last_updated);

  const statusColor = (status) => {
    if (status === "close") {
      return "rgb(0, 94, 255)"
    }
    else if (status === "open") {
      return "#F52FF5"
    }
    else {
      return "#00bcd4"
    }
  }

  return useObserver(() => (
    <div style={{ color: statusColor(props.cell.row.values.status?.toLowerCase()) }}>
      {differenceTime(props.cell.row.values.status, createdDate, lastUpdated)} Days
    </div>
  ));
};

export default observer(SnagAge);
