import React, { memo, useState } from "react";
import useStyles from "./ProjectPropertPanelStyles";
import PropertySelection from "../dropdowns/propertySelection/ProjectProperty";
import { useStore } from "../../../models/ProvideModel";
import { useHistory } from "react-router";
import projectPlaceholder from '../../../assets/images/projectSelection/Project_Placeholder_Image.svg';
import logoutIcon from '../../../assets/images/menuNavigation/Logout.svg';
import fbtLogoIcon from "../../../assets/images/FBTLogo.svg";
import changePassIcon from '../../../assets/images/menuNavigation/changepass.svg'
import { useObserver } from "mobx-react-lite";

// import Fade from 'react-reveal/Fade'
function ProjectPropertPanel(props) {
  const history = useHistory();
  const store = useStore();
  const [hover, sethover] = useState(false)
  const classes = useStyles(hover);

  const handleLogout = () => {
    store.auth.logout();
    history.push('/login')
  }
  return useObserver(() => (
    <div className={store.responsiveUtils.currentViewport.isLg ? classes.root : classes.rootSm}>
      {/* <Fade  left cascade> */}
      <div>
        <div style={{ marginBottom: "8px", paddingLeft: "11px"}}>
          <img
            onError={function defaultImage(img) {
              img.currentTarget.onerror = null;
              img.currentTarget.src = projectPlaceholder
            }}
            src={
              store.projectInfo.projects[0].builder_image
                ? store.projectInfo.projects[0].builder_image
                : projectPlaceholder
            }
            alt="Builder"
            className={!store.responsiveUtils.currentViewport.isLg ? classes.imgSm : classes.imgLg}
          />
        </div>
        <div key="personName" style={{ marginTop: "10px", paddingLeft: "8px" }}>
          <span className={classes.personName}>{store.auth.userName}</span>
        </div>
        <div key="customerName" style={{ marginTop: "12px", paddingLeft: "8px" }}>
          <span className={classes.customerName}>{store.auth.customerName}</span>
        </div>
        <div className={classes.projectProperty}
          onMouseEnter={() => sethover(true)}
          onMouseLeave={() => sethover(false)}
        >
          <PropertySelection name={"Project Name"}>
            {props.projectName}
          </PropertySelection>
          <div className={classes.horizantalDivider}></div>
          <PropertySelection name={"Property Name"}>
            <span>{(props.propertyList as Array<string>).join(", ")}</span>
          </PropertySelection>
        </div>
        {/* </Fade> */}
      </div>
      <div>
        <div key="logout" className={classes.logoutContainer} onClick={handleLogout}>
          <img src={logoutIcon} alt="logout" /><span className={classes.logout}>{"Logout"}</span>
        </div>
        <div key="changePass" className={classes.logoutContainer} onClick={() => history.push('/changepassword')}>
          <img src={changePassIcon} alt="change pass" /><span className={classes.logout}>{"Change Password"}</span>
        </div>
        <img
          src={fbtLogoIcon}
          width={106}
          height={48}
          alt="FBTLogo"
          style={{ paddingLeft: "5px", paddingTop: store.responsiveUtils.currentViewport.isLg ? "12px" : "18px" }}
        />
      </div>
    </div>
  ));
}
export default memo(ProjectPropertPanel);
