import React from 'react'
import { FormControlLabel, Checkbox, MenuItem } from '@material-ui/core'
import useStyles from "./MultiSelectStyle"
import clsx from 'clsx'
 
function searchingFor(search) {
    return function (x) {
      return x && x.toLowerCase().includes(search.toLowerCase())
    }
}
function CheckboxComponent(options) {

  // const searchResult = React.useMemo(() => {
  //   return (
  //     options.options.filter(person =>
  //       person.toLowerCase().includes(options.searchText)
  //   ))
  // }, [options.searchText]);
    const classes = useStyles();
    
    return (
        <div style={{ display: "flex", flexDirection: "column", padding: "1rem 0" }}>
          {
            options.options.filter(searchingFor(options.searchText)).map((option: any, i: number) => {
              return <MenuItem key={i} className={classes.menuItems} >
                <FormControlLabel
                className={classes.formControlRoot}
                style={{whiteSpace:"pre-line"}}
                control={
                  <Checkbox
                    checked={options.filterValue.includes(option)}
                    onChange={options.handleChangeMultiple}
                    value={option}
                    checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                    icon={<span className={classes.icon} />}
                  />
                }
                label={option}
              />
              </MenuItem>
            })
          }
        </div>
    )
}

export default React.memo(CheckboxComponent)