import React, {
  memo,
  useMemo,
  useCallback,
  ReactChild,
  useEffect,
} from "react";
import { useHistory, useLocation } from "react-router";
import { putAsync } from "csp-with-ts";
import useAccordion, { IList } from "./UseAccordionHook";
import { MenuItem, Checkbox, ListItemText } from "@material-ui/core";
import useStyles from "./FilterStyles";
import clsx from "clsx";
import Check from './Checkbox';
import { indicesToEncoded } from "../../utils/utils";
import SelectedState from "./SelectedState";
import useSearch from "../search/useSearch";


const byParam: string = "Activity";
export interface IByActivity {
  data: { id: number; name: string; }[];
  meta: number[];
  stages: { stage: string; length: number; order: number; index: number }[];
}

// function ByActivity({ data, meta, stages, ...rest }: IByActivity) {
//   const { push } = useHistory();
//   const location = useLocation();
//   const { pathname, searchParam } = useMemo(() => location, [location.search, location.pathname]);
//   const setActivity = useCallback((newS: string) => {
//     push({ ...location, search: newS });
//   }, [search, pathname]);
//   return useObserver(() => (
//     <FilterPanel compare={search} meta={meta} arrayList={data} stages={stages} byParam="activity" callback={setActivity} />
//   ));
// }

export interface FPProps {
  compare: string;
  arrayList: { id: number; name: string }[];
  meta?: number[];
  stages?: { stage: string; length: number; order: number; index: number }[];
  byParam: string;
  callback: (newS: string) => void;
}

enum TOGGLE {
  EMPTY,
  PARTIAL,
  FULL,
}

interface IStage {
  children: ReactChild[];
  st: { stage: string; length: number; order: number; index: number; };
  sort: { id: number; name: string; }[];
  setValue: (arg?: any) => void;
  stageValue: number[];
}
// Search causes stage associations to go crazy
const Stage = memo(({ sort, stageValue, st, setValue, children }: IStage) => {
  const classes = useStyles({});
  const sortArr = useMemo(() => sort.map(({ id }) => id), [!!sort]);
  const sortVal = useMemo(() => stageValue.sort((a: number, b: number) => a - b > 0 ? 1 : -1), [stageValue]);
  const [xFirst, xLast] = useMemo(
    () => [
      sortVal.findIndex(
        (val: number | string) =>
          val >= st.index && val < st.index + st.length
      ),
      sortVal.findIndex(
        (val: number | string) => val >= st.index + st.length
      ) === -1 || 0
        ? sortVal.length - 1
        : sortVal.findIndex(
          (val: number | string) => val >= st.index + st.length
        ) - 1,
    ],
    [sortVal]
  );
  const stageState: TOGGLE = useMemo(
    () =>
      xFirst === -1
        ? TOGGLE.EMPTY
        : xLast === -1
          ? sort.slice(xFirst).length === st.length
            ? TOGGLE.FULL
            : TOGGLE.PARTIAL
          : sort.slice(xFirst, xLast + 1).length === st.length
            ? TOGGLE.FULL
            : TOGGLE.PARTIAL,
    [xFirst, xLast, sort, st]
  );

  const handleClick = useCallback(() => {
    switch (stageState) {
      case TOGGLE.EMPTY:
        setValue([
          ...stageValue,
          ...sortArr.slice(st.index, st.index + st.length),
        ] as string[] | number[]);
        break;
      case TOGGLE.PARTIAL:
        if (stageValue.length === 1) {
          setValue([
            ...sortArr.slice(
              st.index,
              st.index + st.length
            ),
          ] as string[] | number[]);
          break;
        }
        if (xLast === -1) {
          setValue([
            ...stageValue.slice(xFirst),
            ...sortArr.slice(
              st.index,
              st.index + st.length
            ),
          ] as string[] | number[]);
        } else {
          setValue([
            ...stageValue.slice(0, xFirst),
            ...stageValue.slice(xLast + 1),
            ...sortArr.slice(
              st.index,
              st.index + st.length
            ),
          ] as string[] | number[]);
        }
        break;
      default:
        setValue([
          ...stageValue.slice(0, xFirst),
          ...stageValue.slice(xFirst + st.length),
        ] as string[] | number[]);
    }
  }, [stageValue, stageState]);

  return (
    <>
      <MenuItem
        key={`${st.stage}`}
        value={`${st.stage}`}
        className={classes.menuItems}
        style={{ paddingLeft: 3, textTransform: "uppercase" }}
        onClick={handleClick}
      >
        <Checkbox
          checked={stageState === TOGGLE.FULL}
          indeterminate={stageState === TOGGLE.PARTIAL}
          indeterminateIcon={
            <span
              className={clsx(classes.icon, classes.indeterminateCheckedIcon)}
            />
          }
          checkedIcon={
            <span className={clsx(classes.icon, classes.checkedIcon)} />
          }
          icon={<span className={classes.icon} />}
        />
        <ListItemText
          style={{
            paddingLeft: "4px",
            whiteSpace: "pre-wrap",
            lineHeight: 1.29,
            fontWeight: "bold",
          }}
          primary={st.stage}
        />
      </MenuItem>
      {children}
    </>
  );
});

const StagesList = memo(({ options, setState, state, RenderFn, stages, searchCheck }: IList<{ id: number; name: string; }, 'id'> & { stages: IByActivity['stages']; searchCheck: number; }) => (
  searchCheck === options.length
    ?
    <div style={{ width: "100%", height: "100%", overflow: "auto" }}>
      {stages.map((st, i) => (
        <Stage
          st={st}
          sort={options as { id: number; name: string; }[]}
          stageValue={state}
          setValue={setState}
          key={st.stage + i}
        >
          {options
            .slice(st.index, stages[i + 1]?.index)
            .map(({ name, id }) => (
              <RenderFn key={id} handleClick={setState} name={name} id={id} value={state} />
            ))}
        </Stage>
      ))
      }
    </div>
    :
    <div style={{ width: "100%", height: "100%", overflow: "auto" }}>
      {options
        .map(({ name, id }) => (
          <RenderFn key={id} handleClick={setState} name={name} id={id} value={state} />
        ))}
    </div>
));

function ByActivity({ data, meta, stages }: IByActivity) {
  const [newOptions, Search] = useSearch<{ id: number; name: string; }>(data, { title: byParam });
  const initialState = useCallback(() => [...meta], [meta]);
  const [state, ByActivity, relay] = useAccordion<{ id: number; name: string; }, 'id'>(StagesList, { accordian: true }, initialState);
  useEffect(() => { putAsync(relay, { type: 'replace', payload: [...meta] }); }, [meta]);
  const location = useLocation();
  const classes = useStyles({});

  const { search } = useMemo(() => location, [
    location.search,
    location.pathname,
  ]);
  const { push } = useHistory();
  const searchParam = useMemo(() => new URLSearchParams(search), [search]);
  const disabled = useMemo(() => {
    if (state.length === meta.length) {
      return state.length === 0
        ? true
        : state.every((el: number) =>
          meta.includes(el)
        );
    } else {
      return false;
    }
  }, [state, meta]);

  const applyCallback = useCallback(
    () => {
      if (state.length > 0) {
        searchParam.delete(byParam.toLowerCase());
        searchParam.append(
          byParam.toLowerCase(),
          indicesToEncoded(state as number[], data.length)
        );
      } else {
        searchParam.delete(byParam.toLowerCase());
      }
      push({ ...location, search: searchParam.toString() });
    },
    [state],
  )
  const applyReset = () => {
    searchParam.delete(byParam.toLowerCase());
    push({ ...location, search: searchParam.toString() });
  }
  useEffect(() => {
    if (meta.length !== state.length) {
      putAsync(relay, { type: 'replace', payload: meta });
    }
  }, [meta]);

  return (
    <ByActivity searchCheck={data.length} options={newOptions} Render={Check} callback={applyCallback} reset={applyReset} stages={stages} title={<SelectedState title={byParam} state={state} filterType="activity" />} disabled={disabled} >
      <div className={classes.stickyHeader}>
        <Search />
      </div>
    </ByActivity>
  );
}
export default memo(ByActivity);
