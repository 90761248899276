import * as React from 'react';
import { useStore } from '../../models/ProvideModel';
import { useHistory } from 'react-router';
import { useObserver } from 'mobx-react-lite';
import useStyles from './PowerBIStyles';
import useCommonStyles from "../../components/table/CommonTableStyles";
import { Grid } from '@material-ui/core';
import { getPowerBiToken } from '../../api/Auth';
import { models } from 'powerbi-client'
import LoadingSpinner from '../../components/loadingSkelaton/LoadingSpinner';
import noDataIcon from '../../assets/images/nodata.png';
// * Component Renders Power BI Report
export default function PowerBI(props: any) {
  const store = useStore();
  const classes = useStyles();
  const [url, setUrl] = React.useState(null);
  const [error, setError] = React.useState('');

  React.useEffect(() => {
    if (store.userReportDetails.powerbiDetails.length == 0)
      store.userReportDetails.getUserReportDetails()
    if (!!props.match.params.groupId && !!props.match.params.reportId) {
      //Request access-token for the powerbi report from portal-server..
      getPowerBiToken(props.match.params.groupId, props.match.params.reportId)
        .then((response) => {
          setUrl(response.data.url)
        })
        .catch((e) => {
          setError(e.message)
        })
    }
  }, [props.match.params.groupId, props.match.params.reportId])

  return useObserver(() =>
    <Grid container direction="row" className={classes.root}>
      <Grid item xs={12} style={{ marginLeft: 20, marginBottom: 10, marginTop: 10 }} >
        <div aria-label="breadcrumb" className={classes.title} >
          {/* <div className={commonClasses.changeImage} onClick={() => history.push(`/powerbi`)}>
                        <span className={commonClasses.backBtn} />
                    </div> */}
          <span>{
            !!store.userReportDetails.reportDetails &&
            store.userReportDetails.reportDetails.map((rep) => {
              if (rep.embedConfig.report_id == props.match.params.reportId)
                return rep.dashboardName
            })
          }</span>
        </div>
      </Grid>
      <Grid item xs={12} style={{ height: "93%" }} >
        <Grid container direction="column" style={{ height: "100%" }}>
          {url ?
            <PowerBiComponent reportId={props.match.params.reportId} url={url} />
            : (error) ?
              <div className={classes.noData}>
                <img src={noDataIcon} alt="nodata" />
                <div>
                  <p>
                    Oops! Something went wrong while getting the report for you.
                                </p>
                </div>
              </div>
              :
              <LoadingSpinner />
          }
        </Grid>
      </Grid>
    </Grid>
  )
}
//Component that mounts the powerbi report in a DIV element..
function PowerBiComponent({ reportId, url }) {
  const myRef = React.useRef<HTMLDivElement>(null);
  const config = React.useMemo(() => ({
    type: "report",
    tokenType: models.TokenType.Embed,
    accessToken: url.token,
    embedUrl: url.embedUrl,
    id: reportId,
    permissions: models.Permissions.All,
    // settings: { filterPaneEnabled: false, navContentPaneEnabled: false }
  }), [reportId, url])

  React.useEffect(() => {
    if (!!myRef.current) {
      window.powerbi.embed(myRef.current, config);
    }
    return () => {
      if (!!myRef && !!myRef.current) {
        window.powerbi.reset(myRef.current);
      }
    }
  }, [config, !!myRef.current]);
  return (<div style={{ height: "100%", marginLeft: 20, marginRight: 20 }} ref={myRef} />);
}
