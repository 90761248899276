import React, { memo, useEffect } from "react";
import { Switch, Route } from "react-router";
import { useObserver } from "mobx-react-lite";
import { reaction } from 'mobx';
import { useHistory } from 'react-router';
import { useStore } from "../../models/ProvideModel";
import LandingPageTower from "./LandingPageTower";
import LandingPageTca from "./LandingPageTca";
import { getSnapshot } from "mobx-state-tree";
import { GOT, getThrones } from "../../models/enums";

const LandingPage = () => {
  const store = useStore();
  const { replace } = useHistory();
  useEffect(() => {
    store.getTowers();
    store.getDashboard();
  }, []);
  useEffect(() => reaction(() => [store.params.spaceType, store.params.graphType, store.params.tower, store.params.phase, store.params.screen, store.params.tab, store.params.unitType, store.currentDashboard?.tabsList?.length], () => {
    store.getTowers();
  }), []);

  useEffect(() => reaction(() => ({ spaceType: store.params.spaceType, phase: store.params.phase, tabsList: store.currentDashboard?.tabsList, graphType: store.params.graphType, tab: store.params.tab }), ({ spaceType, tabsList, tab }) => {
    if ((spaceType === 'tca' || spaceType === 'eca' || spaceType === "basement") && tab === "none" && tabsList?.length) {
      replace(store.params.toPath({ ...getSnapshot(store.params), tab: tabsList[0]?.id, graphType: store.currentDashboard?.tabs?.get(tabsList[0]?.id)?.graphType === GOT.BARATHEON ? GOT.BARATHEON : "brickGraph" }));
    }
  }), []);

  useEffect(() => reaction(() => ({ gt: store.params.graphType, tab: store.currentDashboard?.tab }), ({ gt, tab }) => {
    const graphType = getThrones(gt);
    if (tab?.graphType && graphType !== tab?.graphType) {
      replace(store.params.toPath({ ...getSnapshot(store.params), graphType: tab?.graphType }));
    }
  }), []);

  useEffect(() => {
    store.getTowers();
  }, []);
  // reaction does not run first time
  useEffect(() => reaction(() => [store.params.spaceType, store.params.graphType, store.params.tower, store.params.phase, store.params.screen, store.params.tab, store.params.unitType, store.currentDashboard?.tabsList?.length], () => {
    store.getDashboard();
  }), []);

  // Limit number of number of space types that can display TCA dashboards for now.
  return useObserver(() => (
    <Switch>
      <Route exact path="/dashboard/finishing/tower/landing" component={LandingPageTower} />
      <Route exact path="/dashboard/structures/tower/landing" component={LandingPageTower} />
      <Route exact path="/dashboard/finishing/tca/landing/:tab?/:graphType?" component={LandingPageTca} />
      <Route exact path="/dashboard/:phase/tca/landing/:tab?/:graphType?" component={LandingPageTca} />
      <Route exact path="/dashboard/:phase/eca/landing/:tab?/:graphType?" component={LandingPageTca} />
      <Route exact path="/dashboard/:phase/basement/landing/:tab?/:graphType?" component={LandingPageTca} />
    </Switch>
  ));
}

export default memo(LandingPage);
