import React, {
  memo,
  useCallback,
  useMemo,
  useEffect
} from "react";
import { useHistory, useLocation } from "react-router";
import { putAsync } from "csp-with-ts";
import useAccordion from "./UseAccordionHook";
import Checkbox from "./Checkbox";
import { SelectedState } from "./SelectedState";
import { DefaultList } from "./DefaultAccordianRender";
export interface IByTower {
  data: { id: string; name: string }[];
}

function ByTower({ data }: IByTower) {
  const location = useLocation();
  const { search, pathname } = useMemo(() => location, [
    location.search,
    location.pathname,
  ]);
  const { push } = useHistory();

  const searchParam = useMemo(() => new URLSearchParams(search), [search]);
  const current: number[] | string[] = useMemo(
    () => searchParam.getAll("tower"),
    [search]
  );

  const initialState = useCallback(() => current, [current]);
  const [state, ByTowerFilter, relay] = useAccordion<{ id: string; name: string; }, 'id'>(DefaultList, { multiple: true, apply: true, accordian: true }, initialState);

  useEffect(() => { putAsync(relay, { type: 'replace', payload: [...current] }); }, [current]);

  const disabled = useMemo(() => {
    if (state.length === current.length) {
      return state.length === 0
        ? true
        : state.every((el: string | number) =>
          (current as Array<typeof el>).includes(el)
        );
    } else {
      return false;
    }
  }, [state, current]);

  const applyHandler = useCallback(() => {

    searchParam.delete("tower");
    state.forEach((v) => {
      searchParam.append("tower", v);
    });
    push({ ...location, search: searchParam.toString() });
  }, [state]);
  const applyReset = () => {
    searchParam.delete("tower");
    push({ ...location, search: searchParam.toString() });
  }
  return (
    <ByTowerFilter
      options={data}
      Render={Checkbox}
      disabled={disabled}
      callback={applyHandler}
      reset={applyReset}
      // title="Tower"
      title={<SelectedState title={"Tower"} state={state} filterType={"tower"} />}
    />
  );
}
export default memo(ByTower);
