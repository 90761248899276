import * as React from 'react';
import { Button, makeStyles, CircularProgress } from '@material-ui/core';
import { downloadExcel } from '../api/Auth';
import { useStore } from '../models/ProvideModel';
import { useObserver } from 'mobx-react-lite';
import moment from 'moment';

export interface IDownloadExcelProps {
    columns: any,
    data: any
}


const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
        fontFamily: "Source Sans Pro, sans-serif !important",
        fontSize: "12px",
        fontWeight: 600,
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: 1.30,
        letterSpacing: "normal",
        background: "#005EFF",
        borderRadius: "2px",
        boxShadow: "none !important",
        textTransform: "capitalize",
        height: "28px",
        width: "120px",
        "&:hover": {
            backgroundColor: "#0454db",
            textDecoration: "none"
        }
    },
    buttonSm:{
        margin: theme.spacing(1),
        fontFamily: "Source Sans Pro, sans-serif !important",
        fontSize: "12px",
        fontWeight: 600,
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: 1.30,
        letterSpacing: "normal",
        background: "#005EFF",
        borderRadius: "2px",
        boxShadow: "none !important",
        textTransform: "capitalize",
        height: "28px",
        width: "83px",
        "&:hover": {
            backgroundColor: "#0454db",
            textDecoration: "none"
        }
    }
}));

export default function DownloadExcel(props: IDownloadExcelProps) {

    const classes = useStyles();
    const store = useStore();
    const [loading, setLoading] = React.useState(false);
    // const [ready, setReady] =   React.useState(false);
    const { fullScreenMode } = store.responsiveUtils;

    let tower = (!!store.currentTower && store.currentTower.name) ? store.currentTower.name : store.summary.towerDetails.towerName;

    let activity_name = store.params.activity ?
        (!!store.unitActivityDetails?.unitActivityComboDetails && store.unitActivityDetails?.unitActivityComboDetails[0]?.activity_name) ?
            store.unitActivityDetails?.unitActivityComboDetails[0]?.activity_name :
            (!!store.unitActivityDetails?.steps && store.unitActivityDetails?.steps[0]?.activity_name) ?
            store.unitActivityDetails?.steps[0]?.activity_name : '' : null

    let unit_name = store.params.unit ?
        (!!store.unitActivityDetails?.unitActivityComboDetails && store.unitActivityDetails?.unitActivityComboDetails[0]?.unit_name) ?
            store.unitActivityDetails?.unitActivityComboDetails[0]?.unit_name : 
            (!!store.unitActivityDetails?.steps && store.unitActivityDetails?.steps[0]?.unit_name) ?
            store.unitActivityDetails?.steps[0]?.unit_name : '' : null

    const handleData = (data, customerId, tower, activity_name, unit_name) => {

        setLoading(true)

        let values = data.map((key) => {
            return key.values
        });

        let deducedData = JSON.parse(JSON.stringify(values))

        for (let key in deducedData) {
            deducedData[key].Tower = tower;

            if (store.params.activity && store.params.unit) {
                deducedData[key].Activity_Name = activity_name;
                deducedData[key].Unit_Name = unit_name;
            } else if (store.params.activity) {
                deducedData[key].Activity_Name = activity_name;
            } else if (store.params.unit)
                deducedData[key].Unit_Name = unit_name;

            if (deducedData[key].hasOwnProperty('lastUpdated') && deducedData[key].lastUpdated !== null)
                deducedData[key].lastUpdated = moment(deducedData[key].lastUpdated).format('DD-MMM-YYYY')

            if (deducedData[key].hasOwnProperty('planStart') && deducedData[key].planStart !== null)
                deducedData[key].planStart = moment(deducedData[key].planStart).format('DD-MMM-YYYY')

            if (deducedData[key].hasOwnProperty('planEnd') && deducedData[key].planEnd !== null)
                deducedData[key].planEnd = moment(deducedData[key].planEnd).format('DD-MMM-YYYY')

            if (deducedData[key].hasOwnProperty('actualStart') && deducedData[key].actualStart !== null)
                deducedData[key].actualStart = moment(deducedData[key].actualStart).format('DD-MMM-YYYY')
            
            if (deducedData[key].hasOwnProperty('actual_start') && deducedData[key].actual_start !== null)
                deducedData[key].actual_start = moment(deducedData[key].actual_start).format('DD-MMM-YYYY')

            if (deducedData[key].hasOwnProperty('actualEnd') && deducedData[key].actualEnd !== null)
                deducedData[key].actualEnd = moment(deducedData[key].actualEnd).format('DD-MMM-YYYY')

        }

        downloadExcel(deducedData, customerId).then((res) => {

            setLoading(false)
            // setReady(true)
            window.location = res.data.reXportPath;
        })
    }

    const smMode = (isSm) => {
        return isSm ? "17%" : "10%"
    }

    return useObserver(() =>
        <p style={{ position: "absolute", top: "-12px", right: store.responsiveUtils.currentViewport.isLg ? "10%" : smMode(store.responsiveUtils.currentViewport.isSm) }}>
            {!fullScreenMode && <Button
                variant="contained"
                color="primary"
                size="small"
                className={store.responsiveUtils.currentViewport.isLg ? classes.button : classes.buttonSm}
                onClick={() => handleData(props.data, store.auth.customerId, tower, activity_name, unit_name)}
            >
                {/* { !ready? 'Generate Excel' : 'Download Excel'} */}
                {loading ? <CircularProgress size={14} style={{ color: "#fff" }} /> : "Download Excel"}
            </Button>}
        </p>
    )
}
