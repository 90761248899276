import React, {
  memo,
  useCallback,
  useMemo,
  useEffect
} from "react";
import { useHistory, useLocation } from "react-router";
import { putAsync } from "csp-with-ts";
import useAccordion from "./UseAccordionHook";
/* import useStyles from "./FilterStyles"; */
/* import { SelectedState } from './SelectedState' */
import Checkbox from "./Checkbox";
import { DefaultList } from "./DefaultAccordianRender";
import { SelectedState } from "./SelectedState";

export interface IBySpaceType {
  data: { id: string; name: string }[];
  phase: string;
  spaceType: string;
}

function SpaceType({ data }: IBySpaceType) {
  const location = useLocation();
  const { search } = location;
  const searchParam = useMemo(() => new URLSearchParams(search), [search]);
  const current = useMemo(() => new URLSearchParams(search).getAll('block_id'), [search]);
  const popupMenu = useMemo(() => new URLSearchParams(search).get('popup_menu'), [search]);
  const { push } = useHistory();
  const initialState = useCallback(() => current, [current]);

  const [state, ByTowerFilter, relay] = useAccordion<{ id: string; name: string; }, 'id'>(DefaultList, { multiple: true, accordian: true, reset: false }, initialState);
  useEffect(() => { putAsync(relay, { type: 'replace', payload: [...current] }); }, [current]);
  const disabled = useMemo(() => {
    if (state.length === current.length) {
      return state.length === 0
        ? true
        : state.every((el: string | number) =>
          (current as Array<typeof el>).includes(el)
        );
    } else {
      return false;
    }
  }, [state, current]);

  useEffect(() => {
    if(data.length > 0){
    putAsync(relay, { type: 'toggle', payload: true });}
  }, [popupMenu,data.length])

  const applyHandler = useCallback(() => {
    searchParam.delete("block_id");
    state.forEach((v) => {
      searchParam.append("block_id", v);
    });
    push({ ...location, search: searchParam.toString() });
  }, [state]);

  // const applyReset = () => {
  //   searchParam.delete("block_id");
  //   push({ ...location, search: searchParam.toString() });
  // }

  return (
    <ByTowerFilter
      options={data}
      Render={Checkbox}
      callback={applyHandler}
      disabled={disabled}
      // reset={applyReset}
      title={<SelectedState title={"Block"} state={state} filterType={"block"} />}
    />
  );
};

export default memo(SpaceType);
