import React, {
    memo,
    useCallback,
    useMemo,
    useEffect
} from "react";
import { useHistory } from "react-router";
import { putAsync } from "csp-with-ts";
import useAccordion from "./UseAccordionHook";
import { SelectedState } from "./SelectedState";
import { DefaultList } from "./DefaultAccordianRender";
import RadioButton from "./RadioButton";
import SelectedItem from "./SelectedItem";
import { useStore } from "../../models/ProvideModel";
import { getSnapshot } from "mobx-state-tree";
export interface IByTower {
    data: { id: string; name: string }[];
}

const ViewUnits = ({ units, sel, current, desktop }) =>
    <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}><span style={{ fontSize: desktop ? "26px" : "19px" }}>{sel ?
        units?.find(({ id }) => id === sel)?.name :
        units?.find(({ id }) => id === current)?.name}</span> </div>

function ByTowerTca({ data }: IByTower) {
    const store = useStore();
    const { push } = useHistory();

    const current: any = useMemo(
        () => [store.params.tower],
        [store.params.tower]
    );
    const currentTower: any = data?.find(({ id }) => id === store.params.tower)?.name

    const initialState = useCallback(() => current, [current]);
    const [state, ByTowerFilter, relay] = useAccordion<{ id: string; name: string; }, 'id'>(DefaultList, { multiple: false, apply: false, accordian: false, reset: false }, initialState);

    useEffect(() => { putAsync(relay, { type: 'replace', payload: [...current] }); }, [current]);

    const disabled = useMemo(() => {
        if (state.length === current.length) {
            return state.length === 0
                ? true
                : state.every((el: string | number) =>
                    (current as Array<typeof el>).includes(el)
                );
        } else {
            return false;
        }
    }, [state, current]);

    useEffect(() => {
        putAsync(relay, { type: 'toggle', payload: false });
    }, [store.params.tower])

    const applyHandler = useCallback(() => {
        push(store.params.toPath({ ...getSnapshot(store.params), screen: 'fullscreen', tower: state[0], unitType: null }));
    }, [state]);

    useEffect(() => {
        if (state[0] !== store.params.tower && store.params.tower !== null && typeof state[0] === 'string') {
            push(store.params.toPath({ ...getSnapshot(store.params), tower: state[0], unitType: null }))
        }
    }, [state])

    return (
        <ByTowerFilter
            options={data}
            Render={SelectedItem}
            disabled={disabled}
            callback={applyHandler}
            // title="Tower"
            title={<SelectedState title={"Block"} state={state} filterType={"tower"} />}
            externalDisplay={<ViewUnits units={data} sel={state[0]} current={current[0]} desktop={store.responsiveUtils.currentViewport.isLg} />}
        />
    );
}
export default memo(ByTowerTca);
