import React, { memo, FunctionComponent, useState, useEffect, useRef, useCallback } from 'react';
import { IChan } from 'csp-with-ts';
import { FixedSizeList, FixedSizeListProps, ListChildComponentProps, VariableSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { IList, IUseMux } from './UseAccordionHook';
import useConnect from './UseConnectToStateHook';
import LoadingSpinner from '../loadingSkelaton/LoadingSpinner';

const Provider = memo(<T extends Record<string, any>, K extends keyof T>({ broadcast, relay, RenderList, ...props }: { broadcast: IChan<T[K][]>, relay: IChan<IUseMux<T, K>>; RenderList: FunctionComponent<Record<string, any>>; options: T[]; RenderFn: FunctionComponent<Record<string, any>> }) => {
  const { state, setState } = useConnect<T, K>(relay, broadcast);
  return (<RenderList
    state={state}
    setState={setState}
    {...props}
  />);
});

export default Provider;

export const DefaultList = memo(({ state, setState, options, RenderFn }: IList<{ id: string, name: string; }, 'id'>) => {
  const [ret, setRet] = useState<JSX.Element[]>([]);
  useEffect(() => {
    const cancel = setTimeout(() => {
      setRet(() => options?.map(({ id, name }) => (
        <RenderFn
          key={id}
          id={id}
          value={state}
          handleClick={setState}
          name={name}
        />
      )
      ));
    });
    return () => {
      if (cancel) {
        clearTimeout(cancel);
      }
    }
  }, [options, state, setState, RenderFn]);
  // Replace Loading div with actual component
  return options?.length && !ret?.length ? <LoadingSpinner width="100%" /> :
    <div style={{ width: "100%", height: "100%", overflow: "auto" }}>
      {ret}
    </div>;
});

// TODO: This straight up cheats with some size calculations, and makes a lot of
// assumptions, which hopefully shouldn't be broken as the tables are fairly stable UI and feature-wise.
// However, if there are weird Filter bugs for Unit Title, this is where to look.
const MemoizedRender = memo(({ data, index, style }: ListChildComponentProps) => {
  const { RenderFn, options, state, setState } = data;
  return (
    <div style={style}>
      <RenderFn
        key={options[index].id}
        id={options[index].id}
        value={state}
        handleClick={setState}
        name={options[index].name}
      />
    </div>
  );
});

function __hackyVariableSizeCalculator(str: string, width: number) {
  const len = str.length - Math.ceil((str.match(/\s+/g)?.length || 0) / 3);
  const lineGuesstimate = width > 250 ? 31 : 28;
  const noOfLines = Math.floor(len / lineGuesstimate);
  if (!noOfLines) { return 42; }
  const ret = 42 + (new Array(noOfLines)).fill(16, 0, noOfLines).reduce((acc, v, i) => acc + v + (i + 6) * i);
  return ret;
}

export const WindowedList = memo(({ state, setState, options, RenderFn }: IList<{ id: string, name: string; }, 'id'>) => {
  const naiveData = { state, setState, options, RenderFn };
  const sizer = useCallback((width: number) => (index: number) => __hackyVariableSizeCalculator(options[index].name, width), [options]);
  return (
    <div style={{ display: "flex", flexBasis: "auto", flexGrow: 1, width: "100%", height: "100%" }}>
      <AutoSizer
        style={{ height: "100%", width: "100%", overflow: "auto" }}
      >
        {({ height, width }) => (
          <VariableSizeList
            height={height}
            width="100%"
            itemCount={options.length}
            itemSize={sizer(width)}
            itemData={naiveData}
            style={{ height: "100%" }}
          >
            {MemoizedRender}
          </VariableSizeList>
        )
        }
      </AutoSizer>
    </div >
  );
});
