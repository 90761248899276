import {makeStyles, Theme} from '@material-ui/core/styles';
import 'typeface-source-sans-pro'
const useStyles = makeStyles((theme: Theme) => ({
    root:{
      fontFamily: "Source Sans Pro, sans-serif",
    },
    heading12px: {
        fontFamily: "Source Sans Pro, sans-serif",
        fontSize: "12px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.25",
        letterSpacing: "normal",
        textAlign: "left",
        color: "#585858",
        textTransform:"uppercase",
        paddingLeft:"7px"
    },
    normalText12px: {
        fontFamily: "Source Sans Pro, sans-serif",
        fontSize: "12px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.25",
        letterSpacing: "normal",
        textAlign: "left",
        color: "#808080",
        marginTop:"4px",
        padding:"0 25px 16px 7px"
    }
}))

export default useStyles;