import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        CustomDatePicker: {
            margin: "5px 0 4px 59px",
            fontFamily: "SourceSansPro",
            fontSize: "12px",
            textAlign: "right",
            color: "#005eff"
        },
        datesdiv: {
            display: "flex",
            flexDirection: "column"
        },
        spandiv: {
            fontSize: "11px",
            fontWeight: "bold",
            color: "#585858"
        },
        dateFormat: {
            fontSize: "16px",
            color: "#585858"
        },
        detailsdiv: {
            display: "flex",
            // width: "312px",
            justifyContent: "space-around",
            alignItems: "center"
        },
        divider: {
            width: "1px",
            height: "36px",
            // margin: "3px 40px 17px 46px",
            backgroundColor: "gray"
        },
        horizantalDivider: {
            // width: "252px",
            height: "1px",
            margin: "12px 17px 12px 15px",
            backgroundColor: "gray"
        },
        backButton: {
            fontSize: "16px",
            color: "#005eff",
            cursor: "pointer"
        },
        mainDiv: {
            display: "flex",
            flexDirection: "row-reverse",
            width: "100%"
        },
    })
);

export default useStyles;
