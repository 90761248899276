import React, { Children, PropsWithChildren } from "react";
import { Grid } from "@material-ui/core";
// import TowerStatus from "../towerStatus/TowerStatus";
import useStyles from "./ReportsStyles";
import { useStore } from '../../../models/ProvideModel';
import { useObserver } from "mobx-react-lite";

export interface IReportsProps { }

function Reports({ children }: PropsWithChildren<{}>) {
  const classes = useStyles();
  const store = useStore();
  return useObserver(() => (
    <Grid
      container
      style={{ display: "flex", flexDirection: "column", padding: "24px", marginTop: "12px" }}
      className={classes.root}
    >
      {!Children.toArray(children).length ? <Grid style={{ display: "flex", justifyContent: "space-evenly" }}>There are no Reports to show</Grid> :
        <Grid container style={{ paddingTop: (store.responsiveUtils.currentViewport.isXsOrSm) ? "" : "24px" }}>
          {Children.toArray(children).map((child, i) => {
            let stylesSm = {};
            if ((store.responsiveUtils.currentViewport.isXsOrSm) && i > 0) {
              stylesSm = { paddingTop: "24px"};
            } else {
              if (store.responsiveUtils.currentViewport.isLg) {
                stylesSm = { maxWidth: "297px"};
              } 
            }
            return (
              <Grid item xs={12} key={i} style={stylesSm}>
                {child}
              </Grid>
            );
          })}
        </Grid>
        }
    </Grid>
  ));
}
export default React.memo(Reports);
