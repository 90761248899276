import * as React from 'react';
import { ITower } from '../../models/Tower';
import { useStore } from '../../models/ProvideModel';
import { useHistory } from 'react-router';
import { useObserver } from 'mobx-react-lite';
import { TableRow, TableCell, TableHead, TableBody, Grid, Breadcrumbs, Link } from '@material-ui/core';
import MaUTable from '@material-ui/core/Table'
import useCommonStyles from "../../components/table/CommonTableStyles";
import { useTable, useSortBy, useFilters, useFlexLayout } from 'react-table'
import { FixedSizeList } from 'react-window';
import useWindowDimensions from '../../components/windowDimentions/windowDimentionsHook';
import { StatusRender, snagCountToString, DateRender, DaysFilter, inBetweenPastDays, DateRenderDelay } from '../../components/table/Commonfunctions';
import SelectColumnFilter from '../../components/multiSelectionBox/SelectColumnFilter';
import NoDataFound from '../../components/noDataFound/NoDataFound';
import DownloadExcel from '../../components/downloadExcel';
import LoadingSpinner from '../../components/loadingSkelaton/LoadingSpinner';

// * Component Renders Activity Per Block - Activity Info Page

export interface IUnitsPerActivityProps {
  classes: any,
  tower: ITower,
  activity: any,
}

function Table({ columns, data, classes, height, params }) {

  const defaultColumn = React.useMemo(
    () => ({ Filter: " " }), []
  )

  const {
    getTableProps, getTableBodyProps, headerGroups, rows, prepareRow
  } = useTable(
    { columns, data, defaultColumn },
    useFlexLayout,
    useFilters,
    useSortBy
  );

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index]
      prepareRow(row)
      return (
        <TableRow className={((params.screen === 'unitInfo' && params.activity && !params.unit) || (params.screen === 'activityInfo' && !params.activity && params.unit)) ? classes.tableRowUnits : classes.tableRow} {...row.getRowProps({ style, })}>
          {row.cells.map((cell, i) => {
            return <TableCell key={i} className={classes.tableCell} {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
          })}
        </TableRow>
      )
    },
    [classes.tableCell, classes.tableRow, prepareRow, rows]
  )

  // if (rows.length === 0) {
  //   return <div>No data</div>
  // }

  return (
    <>
      <DownloadExcel columns={columns} data={rows} />
      <MaUTable className={classes.table} {...getTableProps()} stickyHeader>
        <TableHead className={((params.screen === 'unitInfo' && params.activity && !params.unit) || (params.screen === 'activityInfo' && !params.activity && params.unit)) ? classes.tableHeaderUnits : classes.tableHeader}>
          {headerGroups.map((headerGroup, i) => (
            <TableRow {...headerGroup.getHeaderGroupProps()} key={i}>
              {headerGroup.headers.map((column, i) => (
                <TableCell {...column.getHeaderProps()} key={i}>
                  <span>{column.render('Filter')}</span>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        {
          (rows.length !== 0) ?
            <TableBody className={classes.tableBody} {...getTableBodyProps()}>
              <FixedSizeList
                height={height - 225}
                itemCount={rows.length}
                itemSize={80}
                width={'100%'}
              >
                {RenderRow}
              </FixedSizeList>
            </TableBody>
            :
            <NoDataFound />
        }
      </MaUTable>
    </>
  )
}

export default function UnitsPerActivity(props: IUnitsPerActivityProps) {
  const store = useStore();
  const history = useHistory();
  const commonClasses = useCommonStyles({});
  const { height } = useWindowDimensions();

  const handleShow = React.useCallback((e, row) => {
    history.push(`/activityInfo/${store.params.phase}/${store.params.tower}/${row.original.unit_id}`)
  }, [history, store.params.phase, store.params.tower]
  )

  const currentStep = React.useCallback((e, row) => {
    history.push(`/activityInfo/${store.params.phase}/${store.params.tower}/${row.original.unit_id}/${row.original.activity_type_id}`)
  }, [history, store.params.phase, store.params.tower]
  )

  const columns = React.useMemo(() => [
    {
      Header: 'Unit Name',
      accessor: 'unit_name',
      Filter: SelectColumnFilter,
      filter: "includes",
      // Cell: ({row}) => (
      //   <div style={{display:"flex", height:"100%", alignItems:"center"}}>
      //     <Link
      //     // component="link"
      //     variant="body2"
      //     onClick={e => handleShow(e, row)}
      //     style={{textAlign: "left", color:"#005eff"}}
      //   >
      //    {row.values.unit_name}
      //   </Link></div>
      // )
    },
    {
      Header: 'Status',
      accessor: 'derivedStatus',
      Cell: StatusRender,
      Filter: SelectColumnFilter,
      filter: "includes",
    },
    {
      Header: 'Planned Start Date',
      accessor: 'planStart',
      Filter: DaysFilter,
      filter: inBetweenPastDays,
      Cell: DateRender
    },
    {
      Header: 'Planned End Date',
      accessor: 'planEnd',
      Filter: DaysFilter,
      filter: inBetweenPastDays,
      Cell: DateRenderDelay
    },
    {
      Header: 'Actual Start Date',
      accessor: 'actualStart',
      Cell: DateRender,
      Filter: DaysFilter,
      filter: inBetweenPastDays,
    },
    {
      Header: 'Actual End Date',
      accessor: 'actualEnd',
      Filter: DaysFilter,
      filter: inBetweenPastDays,
      Cell: DateRender
    },
    // {
    //   Header: '(Plan) Start/End Date',
    //   accessor:  'planEnd',
    //   Filter: DaysFilter,
    //   filter: inBetweenPastDays,
    //   Cell: CombinedPlanDateRenderDelay
    // },
    // {
    //   Header: '(Actual) Start/End Date',
    //   accessor: 'actualEnd',
    //   Filter: DaysFilter,
    //   filter: inBetweenPastDays,
    //   Cell: CombinedActualDateRenderDelay
    // },
    {
      Header: 'Last Updated',
      accessor: 'lastUpdated',
      Filter: DaysFilter,
      filter: inBetweenPastDays,
      Cell: DateRender
    },
    {
      Header: 'Currently With',
      accessor: 'current_user_name',
      Filter: SelectColumnFilter,
      filter: "includes",
    },

    {
      Header: 'Current Step',
      accessor: 'current_step',
      Filter: SelectColumnFilter,
      filter: "includes",
      Cell: ({ row }) => (
        <div style={{ display: "flex", height: "100%", alignItems: "center" }}>
          <Link
            // component="link"
            variant="body2"
            onClick={e => currentStep(e, row)}
            style={{ textAlign: "left", color: "#005eff", fontSize: "12px" }}
          >
            {row.values.current_step}
          </Link></div>
      ),
    },
    {
      Header: 'Open/Total Snags',
      accessor: snagCountToString,
      Filter: SelectColumnFilter,
      disableFilters: true
    },
  ], [handleShow])

  React.useEffect(() => {
    if (!!props.tower && !!props.activity) {
      store.unitActivityDetails.getUnitSummaryByActivity(props.tower, props.activity)
    }
  }, [props.activity, props.tower, store.unitActivityDetails])
  return useObserver(() => !store.unitActivityDetails.loading ?
    <div className={commonClasses.root}>
      <Grid container>
        <Grid item xs={12} style={{ display: "flex", alignItems: "center", padding: "20px 0" }} >
          <div className={commonClasses.changeImage} onClick={() => history.push(`/activityInfo/${store.params.phase}/${store.params.spaceType}/${store.params.tower}`)}>
            <span className={commonClasses.backBtn} />
          </div>
          <Breadcrumbs aria-label="breadcrumb" className={commonClasses.boldDarkBlue24px} style={{ paddingLeft: "12px" }}>
            <span>{!!store.currentTower && store.currentTower.name ? store.currentTower.name : props.tower}</span>
            <span>{!!store.unitActivityDetails.unitActivityComboDetails && store.unitActivityDetails.unitActivityComboDetails ? store.unitActivityDetails.unitActivityComboDetails[0].activity_name : props.activity}</span>
          </Breadcrumbs>
        </Grid>
      </Grid>
      {/* Activity overview table */}
      {!!store.unitActivityDetails && !!store.unitActivityDetails.unitActivityComboDetails &&
        <Table columns={columns} data={store.unitActivityDetails.unitActivityComboDetails} classes={commonClasses} height={height} params={store.params} />
      }
    </div>
    :
    <>
     <LoadingSpinner/>
    </>
  )
}


