import { makeStyles } from "@material-ui/core/styles";
import { ProgressBarProps } from "../progressBar/ProgressBar";

const useStyles = makeStyles((props: ProgressBarProps) => {
    return {
        handleContainer: (props: { isLg: boolean}) => ({
            width: props.isLg ? "640px" : "494px",
            height: props.isLg ? "720px" : "97vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center"
        }),
        titlediv:{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "59px",
            backgroundColor: "#efefef"
        },
        workStatusdiv:{
            fontSize: "14px",
            fontWeight: "bold",
            color: "#585858"
        },
        handle: {
            width: "64px",
            height: "6px",
            borderRadius: "10px",
            backgroundColor: "#f4f4f4"
        },
        miniDivider:{
            width: "1px",
            // height: "56px",
            backgroundColor: "#d1d1d1"
        },
        divider: {
            width: "100%",
            height: "1px",
            backgroundColor: "#d1d1d1"
        },
        UnitName: (props: { isLg: boolean}) => ({
            height: props.isLg ? "56px" : "39px",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row"
        }),
        Unit:{
            width:"27%",
            fontSize: "14px",
            fontWeight: "bold",
            color: "#585858",
            display: "flex",
            // justifyContent: "center",
            alignItems: "center",
            marginLeft: "20px"
        },
        ActivityName:{
            display: "flex",
            // justifyContent: "center",
            marginLeft: "30px",
            alignItems: "center",
            width:"73%",
            fontSize: "14px",
            fontWeight: "bold",
            color: "#585858"
        },
        ProgressCompletion:{
            // width: "194px",
            height: "18px",
            margin:"0 0 4px",
            // fontFamily: "SourceSansPro",
            fontSize: "14px",
            fontWeight: "bold",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: 1.29,
            letterSpacing: "normal",
            textAlign: "left",
            color: "#585858"
        },
        rowsRoot: (props: { isLg: boolean}) => ({
            height: props.isLg ? "67%" : "59%",
            overflow: "auto",
            backgroundColor: "#f4f4f4",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
            padding: "30px"
        }),
        close:(props: { isLg: boolean}) => ({
            display: "flex",
            // fontFamily: "SourceSansPro",
            fontSize: "16px",
            color: "#005eff",
            height: props.isLg ? "10.6%" : "13.6%",
            width:"100%",
            textAlign: "center",
            flexDirection: "column",
            justifyContent: "center",
            cursor:"pointer"
        })
    }
});

export default useStyles;
