import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: "23.7%",
        marginLeft: "17px"
    },
    FilterBase: {
        width: "184px",
        height: "32px",
        margin: "12px 24px 8px",
        padding: "6px 51px 6px 4px",
        borderRadius: "2px",
        border: "solid 1px #dddddd",
        backgroundColor: "var(--white-two)"
    },
    maindiv: (props: { isLg: boolean; }) => ({
        width: props?.isLg ? "208px" : "100%",
        minHeight: "100px",
        // margin: "12px 0 0px",
        padding: "0px 12px 20px",
        borderRadius: "2px",
        backgroundColor: "#faf9f9",
        maxHeight: props.isLg ? "80vh" : "84%",
        overflow: "auto"
    })
}));
export default useStyles;
