import React, { useState, useEffect, memo } from 'react';
import { useObserver } from 'mobx-react-lite';
import { reaction } from 'mobx';
import { getEnv } from 'mobx-state-tree';
import { GridChildComponentProps } from 'react-window';
import { useStore } from '../../models/ProvideModel';
import { IBrick } from '../../models/Brick/Brick';
import Brick from '../brick/Brick';
import LoadingSkeleton from '../loadingSkelaton/LoadingSkelaton';

export interface IDashboardComponentProps extends GridChildComponentProps {
  style: { top: number; left: number; width: number; height: number; };
  isScrolling: boolean;
};

export const Dashboard = memo(({ style, columnIndex, rowIndex, ...props }: IDashboardComponentProps) => {
  const [gutter, setGutter] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const store = useStore();
  useEffect(() => reaction(() => [store.params.phase, store.params.screen, store.params.spaceType], () => setGutter(getEnv(store).scale[store.params.phase][store.params.screen])), []);
  const brick: IBrick | null = rowIndex === 0 ? null : store.currentDashboard?.getBrick(columnIndex, rowIndex - 1);
  return useObserver(() =>
    !rowIndex ?
      (<div style={{ ...style, width: style.width! as number - gutter!.x, height: style.height! as number - gutter!.y, left: style.left! as number + gutter!.x, top: style.top! as number + gutter!.y }} />)
      :
      store.loading || store.currentDashboard?.loading
        ?
        (<LoadingSkeleton />)
        :
        !!brick ?
          (<Brick {...props} style={style} brick={brick} />)
          :
          (<div style={{ ...style, width: style.width! as number - gutter!.x, height: style.height! as number - gutter!.y, left: style.left! as number + gutter!.x, top: style.top! as number + gutter!.y }} />)
  );
});
