import React, { memo, useEffect, useMemo, useState } from 'react';
import { useModal, Modal, ModalBody, ModalContent, ModalOverlay } from "../../components/modal";
import useStyles from "./formDataStyles";
import client from "../../utils/apolloClient";
import { useObserver } from 'mobx-react-lite';
import { useStore } from '../../models/ProvideModel';
import { useParams } from "react-router";
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { FIELD_DATA } from '../../utils/queries';
import LoadingSpinner from '../loadingSkelaton/LoadingSpinner';
import config from '../../configs/clientConfig';
import curroptImgPlaceholder from "../../assets/images/table/Image Corrupted.svg";
import FsLightbox from "fslightbox-react";
import moment from 'moment';
import { RenderDigitalSignature } from '../table/Commonfunctions';

const __defaultColumn = {
  cell: {
    row: {
      values: {
        plan_end: "",
        plan_start: "",
        actual_end: "",
        actual_start: "",
      },
    },
    column: {
      inputType: "",
    },
    value: "",
  },
  row: {
    original: {
      status: "",
    },
  },
};

const FormDataPage = (props) => {
  const baseURL = config.baseURL;
  const [filterModalState, setFilterModalState] = React.useState(false);
  const [onMouseevent, setonMouseevent] = React.useState(false);
  const store = useStore();
  const [toggle, setToggle] = useState<boolean>(false);
  const { isLg } = store.responsiveUtils.currentViewport;
  const [imgKey, setImgkey] = useState('')
  const classes = useStyles({ isLg });
  const [error, setError] = useState<boolean>(false)

  useEffect(() => {
    if (!client.client) {
      client();
    }
  }, [client]);
  const { isOpen } = useModal({ isOpen: filterModalState, closeOnOverlayClick: true });
  const { tower, spaceType, phase }: { tower?: string | undefined, spaceType?: string | undefined, phase?: string | undefined } = useParams();
  const pathParams = useMemo(() => spaceType && phase ? tower ? ({ "space_type_id": { "_eq": spaceType }, "phase_id": { "_eq": phase }, "block_id": { "_eq": tower } }) : ({ "space_type_id": { "_eq": spaceType }, "phase_id": { "_eq": phase } }) : ({}), [tower, spaceType, phase]);
  const [data, setData] = useState<any[]>([]);
  let filteredArray: any[] = []
  let fieldArray: any[] = []
  let formDescription: any[] = []
  let formValues: { id: string; name: string; }[] = [];

  props.formDataProps.map(key =>
    Object.entries(key.input_data).map(key => {
      if (!fieldArray.includes(key[0])) {
        return (
          fieldArray.push(key[0]))
      }
    }
    ))
  useEffect(() => {
    if (client.client && pathParams['space_type_id'] && pathParams['phase_id'] && store.params.phase.toLowerCase() !== "none" && store.params.spaceType.toLowerCase() !== "none") {
      const query = (client.client as ApolloClient<NormalizedCacheObject>)!
        .watchQuery({ query: FIELD_DATA, variables: { where: { id: { _in: fieldArray } } } })
        .subscribe(({ data, loading }) => {
          if (!loading) {
            setData(data['field_config'])
          }
        });
      return () => {
        query.unsubscribe();
      };
    }
  })
  const loadCorreptImages = (event) => {
    event.currentTarget.onError = null;
    event.currentTarget.src = curroptImgPlaceholder;
  };
  const setImage = (key) => {
    setToggle(!toggle)
    setImgkey(key)
  }

  const OnMouseopenFlexbox = (key) => {
    setonMouseevent(true)
    setToggle(!toggle)
    setImage(key);
  }

  const OnMouseleaveFlexbox = (key) => {
    setonMouseevent(false)
  }

  const onFilterModalClose = () => {
    setFilterModalState(!filterModalState);
  }

  useEffect(() => {
    setFilterModalState(true);
  }, []);

  const onFilterModalOverlay = () => {
    props.onSelectLanguage(false);
  }
  const closeModel = () => {
    setFilterModalState(false);
    props.onSelectLanguage(false);
  }

  props.formDataProps.map(key =>
    data.map(dataKey => {
      Object.entries(key.input_data).map(item => {
        if (item[0] === dataKey?.data.id) {
          filteredArray.push([dataKey?.data?.label, item[1], key.form_config.form_description, dataKey.data.input_type])
          if (!formDescription.includes(key.form_config.form_description)) {
            formDescription.push(key.form_config.form_description);
            formValues.push({ id: key.form_id, name: key.form_config.form_description });
          }
        }
      })
    }))

  const dateTime = (type, value) => {
    if (type === "date") {
      return value.length ? "Date  - " + moment(value).format("D MMM YYYY") : "Date  - "
    }
    else if (type === "time") {
      return value.length ? "Time  - " + moment(value, moment.ISO_8601, true).format("hh:mm:ss a") : "Time  - "
    }
    else {
      return value.length ? "Date  - " + moment(value).format("D MMM YYYY") + " ," + "Time  - " + moment(value).format("hh:mm:ss a") : "Date  -    Time  -"
    }
  }
  return useObserver(() => (
    <>
      {(onMouseevent || imgKey) && <FsLightbox
        key={imgKey}
        toggler={toggle}
        sources={[`${baseURL}large/${imgKey}`]}
        type="image"
        onClose={() => { setImgkey('') }}
      />}
      <Modal
        isOpen={isOpen}
        isCentered={true}
        onClose={onFilterModalClose}
        onOverlayClick={onFilterModalOverlay}
        closeOnOverlayClick={true}
      >
        <ModalOverlay style={{ backgroundColor: "rgba(47, 62, 108, 0.9)" }}>
          <ModalContent>
            <ModalBody style={{ padding: "20px" }}>
              <div className={classes.handleContainer}>
                {formDescription.length || filteredArray.length ?
                  <> <div className={classes.handleSecondContainer}>
                    <div className={classes.UnitName}>
                      {formValues.map(({ id, name: formKey }) => {
                        return (
                          <div key={formKey}>
                            <div className={classes.titlediv}>
                              <div className={classes.secondTitlediv}>
                                <span className={classes.workStatusdiv} style={{ marginLeft: "2px" }}>{formKey}</span>
                                {id === props.editableForm && <span className={classes.workStatusdiv} style={{ fontSize: "15px" }}><a href={`/form-data-write/${props.unitActivityId}/${props.editableForm}`} className={classes.editFormLink}>Edit / Update data</a></span>}
                              </div>
                              <div className={classes.horizantalDiv}></div>
                            </div>
                            {filteredArray.map(item => {
                              if (formKey === item[2]) {
                                return (
                                  <div key={item[0]} className={classes.fieldData}>
                                    <div className={classes.ActivityName} style={{ fontWeight: "bold", textTransform: "none" }}>{item[0]}</div>
                                    {item[3].toLowerCase() === "photo" && item[1] !== "" ? item[1].map(key => {
                                      return (<><img
                                        src={`${baseURL}small/${key}`}
                                        alt={key}
                                        onMouseEnter={() => OnMouseopenFlexbox(key)}
                                        onMouseLeave={() => OnMouseleaveFlexbox(key)}
                                        onClick={() => !error && setImage(key)}
                                        onError={loadCorreptImages}
                                        className={classes.imgClass}
                                      />
                                      </>)
                                    }) :
                                      item[3].toLowerCase() === "user_digi_sign"
                                        ?
                                        <div className={classes.ActivityName}>
                                          <RenderDigitalSignature cell={{ ...__defaultColumn.cell, value: item[1] }} row={__defaultColumn.row} />
                                        </div>
                                        :
                                        <div className={classes.ActivityName}>{['date', 'time', 'datetime'].includes(item[3]?.toLowerCase()) ? dateTime(item[3].toLowerCase(), item[1]) : item[1]}</div>}
                                  </div>
                                )
                              }
                            })}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                    <div className={classes.closeDiv}>
                      <div className={classes.close} onClick={closeModel}>OK</div>
                    </div></> : <LoadingSpinner bgColor="white" />}
              </div>
            </ModalBody>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  ));
};

export default memo(FormDataPage);

