import React, { ChangeEvent, memo, useEffect } from "react";
import { IChan, putAsync } from "csp-with-ts";
import { TextField } from "@material-ui/core";
import SearchIcon from "../../assets/images/dooku/Search Icon.svg";
import useStyles from "../sideBar/sideBarStyles";

export interface ISearchBar {
  ch: IChan<string>;
  title?: string;
}

const SearchFilter = memo(({ ch, title }: ISearchBar) => {
  const classes = useStyles();
  useEffect(() => {
    putAsync(ch, "");
    return () => {
      putAsync(ch, "");
    }
  }, [])
  var icon = <img style={{ marginBottom: "7px" }} src={SearchIcon}></img>
  return (
    <TextField id="standard-basic" className={classes.input}
      InputProps={{ endAdornment: icon }}
      label={`Search by ${title}`}
      onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const val = e.target.value.toLowerCase();
        putAsync(ch, val);
      }}
      style={{ width: "100%" }}
    />
  );
});

export default SearchFilter;
