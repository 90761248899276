import React, { useState, memo } from "react";
import FsLightbox from "fslightbox-react";
import curroptImgPlaceholder from "../assets/images/table/Image Corrupted.svg";
import imgPlaceholder from "../assets/images/table/Image Placeholder.svg";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  image: {
    maxHeight: 150,
    maxWidth: 110,
    margin: '0.2em',
    '&:hover': {
      cursor: "pointer",
      opacity: 0.7
    }
  },
  noImage: {
    display: "flex",
    justifyContent: "center"
  }
}))

const baseURL = process.env.REACT_APP_IMAGE_BASE_URL;

const RenderImagesCell = (props: { images: string[] }) => {
  const imageSources = props.images;
  const [toggle, setToggle] = useState<boolean>(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
  const [corruptedIndex, setCorruptedIndex] = useState<number>(-1);
  const classes = useStyles();

  const loadCorreptImages = (event, idx) => {
    event.currentTarget.onError = null;
    event.currentTarget.src = curroptImgPlaceholder;
    setCorruptedIndex(idx)
  };

  const handleImageClick = (index) => {
    // console.log(`Selected Image Index: ${index}`);
    setSelectedImageIndex(index);
    setToggle(!toggle);
  };

  return (!imageSources || !imageSources.length) ? (
    <div className={classes.noImage}>
      <img
        src={imgPlaceholder}
        alt={"Image Placeholder.svg"}
      />
    </div>
  ) : (
    <div>
      <div className={classes.root}>
        {imageSources.map((image, index) => {
          return (
            <img
              key={`${image}-${index}`}
              // src={`${baseURL}small/${image}`}
              src={`${baseURL}small/${image}`}
              alt={image}
              onClick={(event) => {
                if (!event.currentTarget.complete || event.currentTarget.naturalWidth === 32) {
                  console.error(`Image does not exist or failed to load: ${image}`);
                  alert(`There's a problem loading the image: ${image}`);
                } else { handleImageClick(index); }
              }}
              onError={(event) => loadCorreptImages(event, index)}
              className={classes.image}
            />
          );
        })}
      </div>
      <FsLightbox
        toggler={toggle}
        // sources={imageSources.map(image => `${baseURL}large/${image}`)}
        sources={imageSources.filter((image, index) => (corruptedIndex !== -1 || index !== corruptedIndex)).map(image => `${baseURL}large/${image}`)}
        slide={selectedImageIndex + 1}
        type="image"
      />
    </div>
  );
};

export default memo(RenderImagesCell);