import React, { memo, useRef, useEffect, useState, RefObject, Dispatch, SetStateAction } from "react";
import { useObserver } from "mobx-react-lite";
import { reaction } from 'mobx';
import useStyles from "./LandingPageStyles";
import { useStore } from "../../models/ProvideModel";
import Container from '../../containers/scrollingLayout/ScrollingLayout';
import NoDataFound from "../../components/noDataFound/NoDataFound";

function update(divRef: RefObject<HTMLDivElement>, setWidth: Dispatch<SetStateAction<number>>, setHeight: Dispatch<SetStateAction<number>>): void {
  if (divRef.current) {
    const box = divRef.current!.getBoundingClientRect() as DOMRect;
    setWidth(box.width - 30);
    setHeight(box.height);
  }
}

const LandingPage: React.FC = () => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const classes = useStyles();
  const store = useStore();
  const Summary = store.summary.Summary;
  const divRef = useRef<HTMLDivElement>(null);

  // reaction does not run first time
  useEffect(() => reaction(() => [store.currentTower, store.params.tower, store.params.path, store.responsiveUtils.fullScreenMode, store.totalRows, store.totalColumns, store.currentDashboard?.loading], () => {
    setImmediate(() => update(divRef, setWidth, setHeight));
  }), []);

  useEffect(() => { update(divRef, setWidth, setHeight); }, []);

  let IsMacOs = false;
  if (navigator.appVersion.indexOf("Mac") !== -1) IsMacOs = true;
  return useObserver(() => (
    <div className={classes.root}>
      <div ref={divRef} className={classes.landing}>
        {
          !store.currentDashboard?.loading && store.towersList.length === 0 ?
            <NoDataFound />
            : <Container
              IsMacOs={IsMacOs}
              width={width}
              height={height}
              screen={store.params.screen}
              phase={store.params.phase}
              loading={store.currentDashboard?.loading || store.loading}
              label={store.params?.phase === "structures" ? "Floors" : "Towers"}
            />
        }
      </div>
      <div className={classes.fix}></div>
      <Summary {...store.summary.props} />
    </div >
  ));
}

export default memo(LandingPage);
