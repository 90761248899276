import { makeStyles, Theme, fade } from "@material-ui/core/styles";
import whiteTickIcon from "../../assets/images/Tick-White.svg";

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    margin: "14px",
  },
  search: {
    position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade("#005eff", 0.15),
      '&:hover': {
        backgroundColor: fade("#005eff", 0.25),
      },
      marginRight: 0,
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: 0,
        width: 'auto',
      },
    },
    searchIcon: {
      width: theme.spacing(7),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
      },
      inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
          width: 200,
        },
      },
      icon: {
        borderRadius: "1px !important",
        border: "1px solid #005eff !important",
        width: 16,
        height: 16,
      },
      checkedIcon: {
        backgroundColor: "#005eff !important",
        borderRadius: "1px !important",
        "&:before": {
          display: "block !important",
          width: 24,
          height: 24,
          backgroundImage:
            `url(${whiteTickIcon})`,
          marginLeft: "-4.7px !important",
          marginTop: "-5px !important",
          content: '""',
        }
      },

      applyDiv: {
        bottom:0,
        position:"sticky",
      },
      apply: {
        fontSize: "12px !important",
        fontWeight: "normal",
        fontStyle: "normal !important",
        backgroundColor: "#005eff !important",
        color: "rgb(255, 255, 255) !important",
        borderTopLeftRadius: "0px !important",
        borderTopRightRadius: "0px !important",
        height: "40px !important",
        textTransform:"capitalize"
      },
      applyDisabled:{
        fontSize: "12px !important",
        fontWeight: "normal",
        fontStyle: "normal !important",
        backgroundColor: "#ffffff !important",
        borderTopLeftRadius: "0px !important",
        borderTopRightRadius: "0px !important",
        height: "40px !important",
        borderTop:"1px solid #d1d1d1 !important",
        textTransform:"capitalize"
      },
      menuItems: {
        display: "flex !important",
        fontSize: "14px !important",
        color: "#585858",
        minHeight: "0px  !important",
        "&:hover": {
          fontWeight: "bold",
          color: "#005eff",
          marginLeft: "5px",
          marginRight: "5px"
        },
        fontFamily: "Source Sans Pro, sans-serif !important",
      },
      formControlRoot: {
        marginRight:"0px",
        width:"100%"
      }
})
);

export default useStyles;