import axios from 'axios';
import { captureException } from '@sentry/browser';


export default function timingMiddleware(urls: string[], cb: ({ url, duration, data }: { url: string; duration: number; data: any; }) => void) {
    axios.interceptors.request.use((config) => {
        if (urls.includes(config.url || "")) {
            config["metadata"] = { startTime: new Date() };
        }
        return config;
    }, function (error) {
        return Promise.reject(error);
    });

    axios.interceptors.response.use((response) => {
        if (urls.includes(response.config.url || "") && response.config["metadata"]) {
            response.config["metadata"]!.endTime = new Date();
            response["duration"] = response.config["metadata"]!.endTime - response.config["metadata"]!.startTime;
            cb({ url: response.config.url!, duration: response["duration"]!, data: response.data });
        }
        return response;
    }, function (error) {
        // error.config.metadata.endTime = new Date();
        // error.duration = error.config.metadata.endTime - error.config.metadata.startTime;
        captureException(error);
        return Promise.reject(error);
    });
}
