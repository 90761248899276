import React from 'react'
import { Button } from '@material-ui/core'
import useStyles from './MultiSelectStyle'
function ApplyButton(handleApplyClick) {
    const classes = useStyles();
    return (
        <div className={classes.applyDiv}>
        <Button color="primary" 
        disabled={handleApplyClick.disabled} 
        fullWidth
        // className={classes.apply} 
        className={handleApplyClick.disabled ? classes.applyDisabled : classes.apply} 
        onClick={handleApplyClick.handleApplyClick}
        > Apply </Button>
      </div>
    )
}

export default React.memo(ApplyButton)