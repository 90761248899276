import React, { memo, useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/styles";
import { useObserver, } from 'mobx-react-lite';
import { createStore, StoreProvider, useStore } from '../models/ProvideModel';
import { useCustomTheme } from "../themes/Theme";
import MainRouter from "../routers/MainRouter";
import "../themes/react-custom-scrollbar.css";
import { StoreModel } from "../models/DataStore";
import { Instance, IDisposer } from "mobx-state-tree";
import ScreenRotation from "../components/noDataFound/ScreenRotation";
import { useMediaQuery } from "react-responsive";
import { LG_DEVICE_QUERY, SM_LANDSCAPE_QUERY, XS_LANDSCAPE_QUERY, PORTRAIT_QUERY } from "../utils/responsive";
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import config from "../configs/clientConfig";
import { reaction } from "mobx";
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
// use this
//https://github.com/alexvcasillas/react-mobx-state-tree/blob/master/src/stores/github.js

if (config.environment === 'prd') {
  const LogRocketAppID: any = config.LogRocketKey
  LogRocket.init(LogRocketAppID);
  setupLogRocketReact(LogRocket);
}

const StoreWrapper = memo(({ children }) => {
  const { msTree, disposers }: { disposers: IDisposer[]; msTree: Instance<typeof StoreModel> } = createStore();
  useEffect(() => {
    msTree.auth.setCookieToken();
  }, [])

  useEffect(() => {
    return () => {
      msTree.channel.close();
      for (const disposer of disposers) {
        disposer();
      }
    }
  }, []);

  return (
    <StoreProvider value={msTree}>
      {children}
    </StoreProvider>
  );
});

const ThemeWrapper = memo(({ children }) => {
  const store = useStore();
  const isPortraitMode = useMediaQuery(PORTRAIT_QUERY);
  const isDesktop = useMediaQuery(LG_DEVICE_QUERY);
  const isSm = useMediaQuery(SM_LANDSCAPE_QUERY);
  const isXs = useMediaQuery(XS_LANDSCAPE_QUERY);
  /**
   * Responsiveness queries related hook
   */
  // useEffect(() => {
  //   const LogRocketAppID : any = config.LogRocketKey
  //   LogRocket.init(LogRocketAppID);
  //   setupLogRocketReact(LogRocket);
  //     LogRocket.identify(store.auth.customerName, {
  //     name: store.auth.userName,
  //     email: store.auth.email,
  //     // UserId:store.auth.customerName,
  //   });
  // },[])

  useEffect(() => {
    // if (config.environment === 'prd') {
      // if (store.auth.email !== '') {
        Sentry.init({
          dsn: "https://b7a961985ff4404f81343fc15fee78e6@o4504355704864768.ingest.sentry.io/4504497269899264",
          integrations: [new BrowserTracing()],
          tracesSampleRate: 1.0,
        });
      // }
    // }
  }, []);

  useEffect(() => {
    if (config.environment === 'prd') {
      if (store.auth.email !== '') {
        LogRocket.identify(store.auth.email, {
          name: store.auth.userName,
          email: store.auth.email,
          // UserId:str.auth.customerName,
        });
      }
    }
  }, []);
  useEffect(() => reaction(() => ({ isAuth: store.auth.isAuthenticated, userName: store.auth.userName, email: store.auth.email, customerName: store.auth.customerName }), str => {
    if (config.environment === 'prd') {
      if (str.email !== '') {
        LogRocket.identify(str.email, {
          name: str.userName,
          email: str.email,
          // UserId:str.auth.customerName,
        });
      }
    }
  }), []);

  useEffect(() => {
    store.responsiveUtils.currentViewport.setLg(isDesktop);
    store.responsiveUtils.currentViewport.setSm(isSm);
    store.responsiveUtils.currentViewport.setXs(isXs);
  }, []);
  useEffect(() => {
    store.responsiveUtils.setPortrait(isPortraitMode);
  }, [isPortraitMode]);

  const theme = useCustomTheme({ viewPort: { lg: isDesktop, sm: isSm, xs: isXs } });
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
});

const MainApp = memo(() => {
  const store = useStore();
  return useObserver(() => (
    (store.responsiveUtils.isPortrait && store.auth.isAuthenticated) ?
      <ScreenRotation />
      :
      <MainRouter />
  ));
});

const App = () => (
  <StoreWrapper>
    <ThemeWrapper>
      <MainApp />
    </ThemeWrapper>
  </StoreWrapper>
);

export default App;
