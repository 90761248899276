import React, { memo, useEffect, useCallback } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Text, Brush, ResponsiveContainer } from 'recharts';
import useStyles from './barChartStyles';
import { useStore } from '../../models/ProvideModel';
import { useObserver, useLocalStore } from 'mobx-react-lite';
import { getView } from '../../models/enums';
import { reaction } from 'mobx';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router';
import { getSnapshot } from 'mobx-state-tree';

const CustomizedXAxisTick = function (props) {
  const { x, y, payload, classes } = props
  return (
    <g transform={`translate(${x},${y})`}>
      <Text dy={10} dx={-5} fill="#005eff" className={classes} angle={-45}>{payload.value.length > 20 ? payload.value.substring(0, 19) + '...' : payload.value}</Text>
    </g>
  )
}

const BarGraph = memo(() => {
  const classes = useStyles();
  const store = useStore();
  const history = useHistory();
  useEffect(() => reaction(() => [store.params.phase, store.params.screen, store.params.spaceType, store.currentTower, store.filters.floorSort], () => {
    store.getTowers();
  }), []);

  const barHover = { cursor: "pointer" }
  const data = useLocalStore(() => ({
    get data() {
      if (!store.currentTower || store.currentDashboard?.loading) { return []; }
      const indexVal = store?.currentDashboard?.towers?.map((key, index) => {
        if (key.name === store.currentTower.name) {
          return index + 1
        }
        else return null
      })
      return store.currentDashboard?.graph?.columnSortedFiltered?.reduce((acc, { name }, id) => {
        const brick = store.currentDashboard.getBrick(id + 1, indexVal.filter(key => key !== null)[0] - 1);
        return [...acc, { id: brick?.xVal?.id, name, OnGoing: brick?.inProgress, Completed: brick?.completed, NotStarted: brick?.total - brick?.completed - brick?.inProgress, Total: brick?.total }];
      }
        , []);
    },
    get totalUnits() {
      //If total varies for different bars, this will not work. Use Max logic then.
      if (!store.currentTower || store.currentTower.loading) { return 0; }
      const indexVal = store?.currentDashboard?.towers?.map((key, index) => {
        if (key.name === store.currentTower.name) {
          return index + 1
        }
        else return null
      })
      return store.currentDashboard?.getBrick(1, indexVal.find(element => element !== null) - 1)?.total;
    },
    handleXClick(e) {
      const id = data.data.find(({ name }) => name === e.value)?.id;
      /* history.push(`/tables/${store.params.phase}/${store.params.spaceType}/${store.params.tower}?activity_type_id=${id}`); */
      history.push(store.params.toPath({ ...getSnapshot(store.params), section: "unit-act-info" }) + `?activity_type_id=${id}`);
    },
    handleBarSection(e, status) {
      /* history.push(`/tables/${store.params.phase}/${store.params.spaceType}/${store.params.tower}?status_desc=${status}&activity_type_id=${e.id}`); */
      history.push(store.params.toPath({ ...getSnapshot(store.params), section: "unit-act-info" }) + `?status_desc=${status}&activity_type_id=${e.id}`);
    }
  }));

  const handleHover = useCallback((e) => {
    if (e.active && e.payload != null && e.payload[0] != null) {
      var total = e.payload[0].payload["Total"]
      var activity = e.payload[0].payload["name"]
      var completed = e.payload[0].payload["Completed"]
      var inProgress = e.payload[0].payload["OnGoing"]
      var notStarted = e.payload[0].payload["NotStarted"]

      return (<div className={classes.customTooltip} style={{ border: `solid 1px ${inProgress > 0 ? '#ffd300' : notStarted > 0 ? '#d1d1d1' : '#34d1ae'}` }}>
        <span className={classes.capHead4}>{activity}</span>
        <div style={{ paddingBottom: "5px", paddingTop: "10px" }}>
          <Box display="flex" justifyContent="space-between" className={classes.boldText12Px}><span>Total units:</span> <span> {total}</span> </Box>
          <Box display="flex" justifyContent="space-between" alignItems='center' className={classes.normText12Px}><span className={classes.avatarComp} /><span>Completed: </span> <span style={{ marginLeft: "auto" }}> {completed > 0 ? completed.toLocaleString('en-GB', { minimumIntegerDigits: 2 }) : "—"}</span></Box>
          <Box display="flex" justifyContent="space-between" alignItems='center' className={classes.normText12Px}><div className={classes.avatarWip} /><span>Ongoing: </span> <span style={{ marginLeft: "auto" }}> {inProgress > 0 ? inProgress.toLocaleString('en-GB', { minimumIntegerDigits: 2 }) : "—"} </span> </Box>
          <Box display="flex" justifyContent="space-between" alignItems='center' className={classes.normText12Px}><div className={classes.avatarNotStart} /><span>Not Started: </span> <span style={{ marginLeft: "auto" }}> {notStarted > 0 ? notStarted.toLocaleString('en-GB', { minimumIntegerDigits: 2 }) : "—"}</span> </Box>
        </div>
      </div>);
    }
    else {
      return "";
    }
  }, []);

  const yAxisLabel = useCallback((axis) => {
    return ({
      value: 'UNITS', angle: -90, position: 'center', fill: "#585858", fontSize: "12px", fontFamily: "Source Sans Pro, sans-serif", fontWeight: 700, dx: axis === 'left' ? -5 : 5
    })
  }, [])

  return useObserver(() => (
    <div className={classes.root}>
      <div className={classes.chartDiv}>
        <ResponsiveContainer width={store.responsiveUtils.currentViewport.isLg && data?.data?.length < 10 ? data?.data?.length <= 2 ? "25%" : `${data.data.length * 10}%` : "100%"} height="100%" >
          <BarChart data={data?.data} margin={{ left: 10, top: 70 }}>
            <YAxis
              orientation="left"
              tickLine={false}
              type="number"
              domain={[0, data?.totalUnits]}
              interval={store.responsiveUtils.currentViewport.isLg ? 0 : 3}
              tickCount={15}
              axisLine={{ strokeWidth: 0.3 }}
              tick={true}
              label={yAxisLabel('left')}
              // label={<CustomizedYAxisLabel />}
              scale="linear"
              yAxisId="left"
              reversed={store.filters.floorSort}
            />
            <YAxis
              orientation="right"
              tickLine={false}
              type="number"
              domain={[0, data?.totalUnits]}
              interval={store.responsiveUtils.currentViewport.isLg ? 0 : 3}
              tickCount={15}
              axisLine={{ strokeWidth: 0.3 }}
              tick={true}
              label={yAxisLabel('right')}
              scale="linear"
              yAxisId="right"
              reversed={store.filters.floorSort}
            />
            <XAxis
              type="category"
              dataKey="name"
              orientation="top"
              axisLine={false}
              tickLine={false}
              tickMargin={10}
              interval={0}
              tick={<CustomizedXAxisTick classes={classes.xAxisStyle} />}
              textAnchor="end"
              travellerWidth={-10}
              allowDataOverflow={false}
              padding={{ left: 15, right: 15 }}
              onClick={data?.handleXClick}
            // reversed={store.filters.floorSort}
            />
            <Tooltip cursor={false} content={handleHover} />
            {/* <Legend verticalAlign="bottom" height={36}/> */}
            {(store.filters.hasStatus('completed')) ? <Bar yAxisId="left" dataKey="Completed" stackId="a" barSize={store.responsiveUtils.currentViewport.isLg ? data?.data?.length < 10 ? 50 : null : null} fill="#34d1ae" fillOpacity={store.filters.status.length ? !store.filters.hasStatus('completed') ? "0.05" : "1" : "1"} onClick={(e) => data?.handleBarSection(e, 'Completed')} style={barHover} /> : null}
            {(store.filters.hasStatus('started')) ? <Bar yAxisId="left" dataKey="OnGoing" stackId="a" barSize={store.responsiveUtils.currentViewport.isLg ? data?.data?.length < 10 ? 50 : null : null} fill="#ffd300" fillOpacity={store.filters.status.length ? !store.filters.hasStatus('started') ? "0.05" : "1" : "1"} onClick={(e) => data?.handleBarSection(e, 'On Going')} style={barHover} /> : null}
            {(store.filters.hasStatus('notStarted')) ? <Bar yAxisId="left" dataKey="NotStarted" stackId="a" barSize={store.responsiveUtils.currentViewport.isLg ? data?.data?.length < 10 ? 50 : null : null} fill="#eaeaea" fillOpacity={store.filters.status.length ? !store.filters.hasStatus('notStarted') ? "0.2" : "1" : "1"} onClick={(e) => data?.handleBarSection(e, 'Not Started')} style={barHover} /> : null}

            {(!store.filters.hasStatus('completed') && !store.filters.hasStatus('started') && !store.filters.hasStatus('notStarted')) ? <Bar yAxisId="left" barSize={store.responsiveUtils.currentViewport.isLg ? data?.data?.length < 10 ? 50 : null : null} dataKey="Completed" stackId="a" fill="#34d1ae" fillOpacity={store.filters.status.length ? !store.filters.hasStatus('completed') ? "0.05" : "1" : "1"} onClick={(e) => data?.handleBarSection(e, 'Completed')} style={barHover} /> : null}
            {(!store.filters.hasStatus('completed') && !store.filters.hasStatus('started') && !store.filters.hasStatus('notStarted')) ? <Bar yAxisId="left" barSize={store.responsiveUtils.currentViewport.isLg ? data?.data?.length < 10 ? 50 : null : null} dataKey="OnGoing" stackId="a" fill="#ffd300" fillOpacity={store.filters.status.length ? !store.filters.hasStatus('started') ? "0.05" : "1" : "1"} onClick={(e) => data?.handleBarSection(e, 'On Going')} style={barHover} /> : null}
            {(!store.filters.hasStatus('completed') && !store.filters.hasStatus('started') && !store.filters.hasStatus('notStarted')) ? <Bar yAxisId="left" barSize={store.responsiveUtils.currentViewport.isLg ? data?.data?.length < 10 ? 50 : null : null} dataKey="NotStarted" stackId="a" fill="#eaeaea" fillOpacity={store.filters.status.length ? !store.filters.hasStatus('notStarted') ? "0.2" : "1" : "1"} onClick={(e) => data?.handleBarSection(e, 'Not Started')} style={barHover} /> : null}

            <Brush
              data={data?.data}
              dataKey='name'
              height={store.responsiveUtils.currentViewport.isLg ? 30 : !store.responsiveUtils.currentViewport.isSm ? 40 : 60}
              stroke="#005eff"
              startIndex={0}
              endIndex={(store.responsiveUtils.currentViewport.isLg || data?.data?.length < 10) ? (parseInt(data?.data?.totalUnits) - 40) : 9}
              alwaysShowText={false}
              travellerWidth={24}
            >
              <BarChart dataKey='name' >
                {/* <Bar yAxisId="left" dataKey="Completed" stackId="a" fill="#34d1ae" animationEasing="ease" />
                <Bar yAxisId="left" dataKey="OnGoing" stackId="a" fill="#ffd300" animationEasing="ease" />
                <Bar yAxisId="left" dataKey="NotStarted" stackId="a" fill="#eaeaea" animationEasing="ease" /> */}

                {(store.filters.hasStatus('completed')) ? <Bar yAxisId="left" dataKey="Completed" stackId="a" fill="#34d1ae" barSize={store.responsiveUtils.currentViewport.isLg ? data?.data?.length < 10 ? 50 : null : null} animationEasing="ease" /> : null}
                {(store.filters.hasStatus('started')) ? <Bar yAxisId="left" dataKey="OnGoing" stackId="a" fill="#ffd300" barSize={store.responsiveUtils.currentViewport.isLg ? data?.data?.length < 10 ? 50 : null : null} animationEasing="ease" /> : null}
                {(store.filters.hasStatus('notStarted')) ? <Bar yAxisId="left" dataKey="NotStarted" stackId="a" fill="#eaeaea" barSize={store.responsiveUtils.currentViewport.isLg ? data?.data?.length < 10 ? 50 : null : null} animationEasing="ease" /> : null}

                {(!store.filters.hasStatus('completed') && !store.filters.hasStatus('started') && !store.filters.hasStatus('notStarted')) ? <Bar yAxisId="left" dataKey="Completed" stackId="a" fill="#34d1ae" barSize={store.responsiveUtils.currentViewport.isLg ? data?.data?.length < 10 ? 50 : null : null} animationEasing="ease" /> : null}
                {(!store.filters.hasStatus('completed') && !store.filters.hasStatus('started') && !store.filters.hasStatus('notStarted')) ? <Bar yAxisId="left" dataKey="OnGoing" stackId="a" fill="#ffd300" barSize={store.responsiveUtils.currentViewport.isLg ? data?.data?.length < 10 ? 50 : null : null} animationEasing="ease" /> : null}
                {(!store.filters.hasStatus('completed') && !store.filters.hasStatus('started') && !store.filters.hasStatus('notStarted')) ? <Bar yAxisId="left" dataKey="NotStarted" stackId="a" fill="#eaeaea" barSize={store.responsiveUtils.currentViewport.isLg ? data?.data?.length < 10 ? 50 : null : null} animationEasing="ease" /> : null}
              </BarChart>
            </Brush>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  ))
});
export default BarGraph;

