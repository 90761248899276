import React, { memo, useEffect } from "react";
import { reaction } from "mobx";
import { useObserver } from "mobx-react-lite";
import { getSnapshot } from "mobx-state-tree";
import { useHistory } from "react-router";
import { useStore } from "../../models/ProvideModel";
import useStyles from './OldUnitInfoPageStyles';

import UnitsPerActivity from "../../containers/unitInfo/UnitsPerActivity";
/* import UnitsPerBlock from "../../containers/unitInfo/UnitsPerBlock"; */
import StepPerUnitPerActivity from "../../containers/stepInfo/StepPerUnitPerActivity";
import UnitPerBlockFat from "../../containers/unitInfo/UnitPerBlockFat";

const OldUnitInfoPage = () => {
  const store = useStore();
  const classes = useStyles();
  const { replace } = useHistory();
  useEffect(() => { if (!store.currentTower) { store.getTowers(); } }, []);
  useEffect(() => reaction(() => [store.params.section, store.loading, store.towersList.length, store.currentTower], () => {
    if (!store.loading && store.towersList.length && !store.currentTower)
      replace(store.params.toPath({ ...getSnapshot(store.params), tower: store.towersList[0].id }));
  }), []);
  return useObserver(() =>
    <div className={classes.root}>
      {!!store.params.activity || !!store.params.unit ?
        <UnitsPerActivity classes={classes} tower={store.params.tower} activity={store.params.activity} />
        :
        // <UnitsPerBlock classes={classes} tower={store.params.tower} />
        <UnitPerBlockFat />
      }
    </div>
  );
};

export default memo(OldUnitInfoPage);
