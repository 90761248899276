import React from 'react'
import Radio, { RadioProps } from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import useStyles from './SortStyle'
import clsx from 'clsx';

function StyledRadio(props:RadioProps) {
  const classes = useStyles();
  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

function TableSortFilter(handleChangeSort) {  
  return (
    <div style={{ padding: "0px 16px 0 16px" }}>
      <FormControl component="fieldset">
        <RadioGroup aria-label="sort" name="tableSort" value={handleChangeSort.value} onChange={handleChangeSort.handleChangeSort}>
          <FormControlLabel value="asc" control={<StyledRadio />} label= { handleChangeSort.columnId === "Open/Total Snags" ? "Lowest to Highest" : "Sort by A-z"} />
          <FormControlLabel value="dsc" control={<StyledRadio />} label={handleChangeSort.columnId === "Open/Total Snags" ? "Highest to Lowest" : "Sort by Z-a"} />
        </RadioGroup>
      </FormControl>
    </div>
  )
}

export default React.memo(TableSortFilter);