import React from 'react';
import useStyles, {APPEARANCES} from './CircularButtonStyles';

export interface CircularButtonProps {
  /**
   * Is this the principal call to action on the page?
   */
  primary?: boolean;
  /**
   * What background color to use
   */
  backgroundColor?: string;
  /**
   * How large should the button be?
   */
  size?: 'small' | 'medium' | 'large' | 'filterIconBtn' | 'fullscreenBtn';
  /**
   * Button contents
   */
  children?: any;
  /**
   * button will be floating or not
   */
  floating?: boolean;
  /**
   * Optional click handler
   */
  onClick?: () => void;
}

export const getBtnType = (classes, primary: boolean) => {
  return primary ? classes[`btnType_${APPEARANCES.PRIMARY}`] : classes[`btnType_${APPEARANCES.DEFAULT}`];
}

export const getBtnSize = (classes, size) => {
  return classes[`btnSize_${size}`];
}

/**
 * Primary UI component for user interaction
 */
export const CircularButton: React.FC<CircularButtonProps> = ({
  primary = false,
  size = 'small',
  backgroundColor,
  floating = false,
  ...props
}) => {
  const classes = useStyles();  
  const mode = getBtnType(classes, primary);
  const btnSize = getBtnSize(classes, size);
  const flt = floating ? classes['btnState_floating'] : '';
  return (
    <button
      type="button"
      className={[classes.btn, btnSize, mode, flt].join(' ')}
      style={{ backgroundColor }}
      {...props}
    >
      {props.children}
    </button>
  );
};