import React, { memo, useEffect, } from "react";
import { Switch, Route } from 'react-router';
import { useStore } from "../../models/ProvideModel";
import { useObserver } from "mobx-react-lite";
import { getSnapshot } from "mobx-state-tree";
import { reaction } from "mobx";
import { useHistory } from "react-router";
import FullScreenTowersPage from "./FullScreenTowersPage";
import FullScreenTcaFinishingPage from "./FullScreenTcaFinishingPage";

const FullScreenPage = () => {
  const store = useStore();
  const { replace } = useHistory();
  useEffect(() => {
    store.getTowers();
    store.getDashboard();
  }, []);
  useEffect(() => reaction(() => [store.params.spaceType, store.params.phase, store.params.screen, store.params.graphType], () => {
    store.getTowers();
  }), []);
  useEffect(() => reaction(() => [store.loading, store.towersList.length, store.currentTower, store.currentDashboard?.towers?.length, store.filters.graphType, store.params.tab, store.params.unitType, store.currentDashboard?.tabsList], () => {
    if (!store.loading && store.currentTower && store.currentDashboard?.towers.length && store.currentDashboard?.tabsList?.length && !store.params.unitType) {
      replace(store.params.toPath({ ...getSnapshot(store.params), unitType: store.currentDashboard?.tabsList[0]?.id, graphType: store.currentDashboard?.tab?.graphType }));
    }
    else if (!store.loading && store.towersList.length && !store.currentTower && store.filters.graphType && store.currentDashboard?.towers?.length) {
      replace(store.params.toPath({ ...getSnapshot(store.params), tower: store.currentDashboard?.towers[0]?.id, graphType: store.filters.graphType }));
    }
  }), []);

  useEffect(() => {
    store.getTowers();
  }, []);
  // reaction does not run first time
  useEffect(() => reaction(() => [store.params.spaceType, store.params.graphType, store.currentTower, store.params.phase, store.params.screen, store.params.tab, store.params.unitType, store.currentDashboard?.tabsList?.length], () => {
    store.getDashboard();
  }), []);

  return useObserver(() =>
    <Switch>
      <Route exact path="/dashboard/finishing/tower/fullscreen/:tower?/:graphType?" component={FullScreenTowersPage} />
      <Route exact path="/dashboard/structures/tower/fullscreen/:tower?/:graphType?" component={FullScreenTowersPage} />
      <Route exact path="/dashboard/finishing/tca/fullscreen/:tab?/:tower?/:unitType?/:graphType?" component={FullScreenTcaFinishingPage} />
      <Route exact path="/dashboard/:phase/tca/fullscreen/:tab?/:tower?/:unitType?/:graphType?" component={FullScreenTcaFinishingPage} />
      <Route exact path="/dashboard/:phase/eca/fullscreen/:tab?/:tower?/:unitType?/:graphType?" component={FullScreenTcaFinishingPage} />
      <Route exact path="/dashboard/:phase/basement/fullscreen/:tab?/:tower?/:unitType?/:graphType?" component={FullScreenTcaFinishingPage} />
    </Switch>
  );
};

export default memo(FullScreenPage);
