import React from "react";
import useStyles from "./FatTableStyle";
import closeIcon from '../../assets/images/square-icon.svg';


const StatusSnags = (props) => {
  const classes = useStyles({});
    const status: string = props.cell.value;
    return (
        <div className={classes.statusRoot}>
            {status.toLowerCase() === "open" ?
                <div className={classes.openImg}></div>
                : status.toLowerCase() === "close" ?
                    <div style={{display: 'flex'}}>
                        <img src={closeIcon} className={classes.closeIcon} alt="colseIco"></img>
                    </div>
                    : <div className={classes.forReviewImg}></div>
            }
            <div className={classes.status}>{status.toLowerCase()}</div>
        </div>
    )
}
export default StatusSnags