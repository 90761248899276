import React, { memo, useMemo, useCallback } from "react";
import { useHistory, useLocation } from "react-router";
import useAccordion from "../filter/UseAccordionHook";
import Checkbox from "../filter/Checkbox";
import { DefaultList } from "../filter/DefaultAccordianRender";

const TempTitle = memo(({ title }: { title: string; }) => (<div>{title}</div>));

interface IFatColumnData {
  column: { id: string; name: string; data: { id: string; name: string; }[]; };
}

function Filter({ column: { id, name, data } }: IFatColumnData) {
  const location = useLocation();
  const { search } = useMemo(() => location, [
    location.search,
    location.pathname,
  ]);
  const { push } = useHistory();
  const searchParam = useMemo(() => new URLSearchParams(search), [search]);

  const current: string[] = useMemo(
    () => searchParam.get(id) === null ? [] : ["true" === searchParam.get(id) ? "true" : "false"],
    [search, id]
  );
  const initialState = useCallback(() => current, []);
  const [state, Filter] = useAccordion<{ id: string; name: string; }, 'id'>(DefaultList, { multiple: false }, initialState);
  const disabled = useMemo(() => state[0] === current[0], [state, current]);
  const applyHandler = useCallback(() => {
    searchParam.delete(id);
    searchParam.delete('page');
    if (state.length) {
      searchParam.append(id, state[0].toString());
    }
    push({ ...location, search: searchParam.toString() });
  }, [state, id]);
  const applyReset = () => {
    const ascList = searchParam.getAll('sort');
    const descList = searchParam.getAll('sortDesc');
    searchParam.delete('sort');
    searchParam.delete('sortDesc');
    ascList.forEach(val => {
      /* if (id === 'activity_type_id' && val === 'precedence') { // Special case Handled
       *   return null;
       * } */
      if (val === id) {
        return null;
      }
      searchParam.append('sort', val);
    });
    descList.forEach(val => {
      /* if (id === 'activity_type_id' && val === 'precedence') { // Special case Handled
       *   return null;
       * } */
      if (val === id) {
        return null;
      }
      searchParam.append('sortDesc', val);
    });
    searchParam.delete(id);
    push({ ...location, search: searchParam.toString() });
  }
  return (<Filter
    options={data}
    Render={Checkbox}
    disabled={disabled}
    callback={applyHandler}
    reset={applyReset}
    title={<TempTitle title={name} />}
  >
  </Filter>);
}

export default memo(Filter);
