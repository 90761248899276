import { makeStyles, Theme} from '@material-ui/core/styles';
import arrowIconHover from '../../../assets/images/menuNavigation/Arrow-hover.svg';

const useStyles = makeStyles((theme : Theme) => ({
    root: {
        fontFamily: "Source Sans Pro, sans-serif",
        "& span": {
            textOverflow: "ellipsis", 
            overflow: "hidden", 
            whiteSpace: "nowrap" 
        }
    },
  rootSm: {
        fontFamily: "Source Sans Pro, sans-serif",
        "& span": {
            textOverflow: "ellipsis", 
            overflow: "hidden", 
            whiteSpace: "nowrap" ,
            fontSize:"15px",
        }
    },
    tools: {
        fontFamily: "Source Sans Pro, sans-serif",
        // width:"184px",
        height: "60px",
        borderRadius: "2px",
        backgroundColor: "#ffffff",
        padding: "12px",
        fontSize: "16px",
        display:"flex", 
        flexDirection: "row",
        justifyContent:"space-between",
        transition: "font-weight .3s",
        "&:hover": {
          fontWeight: "bold",
          "& .arrowIcon": {
            background:`url(${arrowIconHover})`
          },
        }
      },
    toolsSm: {
        fontFamily: "Source Sans Pro, sans-serif",
        // width:"184px",
        height: "55px",
        width:"161px",
        borderRadius: "2px",
        backgroundColor: "#ffffff",
        padding: "12px",
        fontSize: "14px",
        display:"flex", 
        flexDirection: "row",
        justifyContent:"space-between",
        transition: "font-weight .3s",
        "&:hover": {
          fontWeight: "bold",
          "& .arrowIcon": {
            background:`url(${arrowIconHover})`
          },
        }
      },
      propertyTools: {
        width: "144px",
        height: "23px",
        fontFamily: "Source Sans Pro, sans-serif",
        fontSize: "18px",
        fontWeight: 900,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.28,
        letterSpacing: "normal",
        textAlign: "left",
        color: "#585858",
        textTransform:"uppercase",
        marginBottom: 16
      },
      space_type: {
        fontFamily: "Source Sans Pro, sans-serif",
        // fontSize: "16px",
        textTransform: "capitalize",
        display: "flex",
        justifyContent: "space-between",
        paddingBottom: "8px",
      },
      gridContainer: {
        display: "grid",
        gridTemplateColumns: "184px 184px 184px 184px 184px",
        gridGap: "10px"
      },
      gridContainerSm: {
        display: "grid",
        gridTemplateColumns: "164px 184px",
        gridGap: "10px"
      },
      underlineAnim: {
        /* Style the buttons that are used to open and close the accordion panel */
        // backgroundColor: "#faf9f9",
        color: "#005eff",
        fontWeight: "normal",
        cursor: "pointer",
        border: "none",
        outline: "none",
        textDecoration: "none",
        backgroundSize: "0 3px, auto",
        backgroundImage: "linear-gradient(#2f3e6c, #2f3e6c)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center bottom",
        transition: "all 0.2s ease-out",
        borderRadius: "1px",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.25",
        letterSpacing: "normal",
        textAlign: "left",
        fontFamily: "Source Sans Pro, sans-serif",
        "&:hover": {
          backgroundImage: "linear-gradient(#b1b1b1, #b1b1b1)",
          backgroundSize: "90% 2px, auto",
          fontWeight: "bold",
          fontFamily: "Source Sans Pro, sans-serif",
          color: "#005eff",
    
          // color: disabled ? "#2f3e6c" : "#005eff",
        },
      }
}))

export default useStyles;