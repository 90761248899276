import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: (props: { isLg: boolean; }) => ({
    width: "100%",
    marginTop: "7px",
    color: "rgba(0,0,0,0.8)",
    paddingLeft: "1rem",
    fontSize: props.isLg ? "13px" : "11px",
    fontWeight: "bold",
    backgroundColor: "#fffff"
  })
}));

export default useStyles;
