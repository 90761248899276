import React, { useCallback } from "react"
import { Box, Grid, Avatar } from "@material-ui/core"
import { useHistory } from 'react-router';
import { useStore } from "../../models/ProvideModel";
import { sortingWithPrecedence } from "../../utils/utils";
import moment from "moment";

function RenderRow({ classes, title, val, onActivityClick, onSnagClick, activityUrlpath, onSnagUrlpath }) {

  return (
    <Grid container key={val.id}>
      <Grid item xs={4}>
        <a className={classes.convActivityName}
          id={`${val.id}_${val.name}_activities`}
          href={`${window.location.href.split('/', 3).join('/')}${activityUrlpath(val.id)}`}
          target="popup" style={{ textDecoration: "none" }}
          onClick={() => onActivityClick(val.id)}>{val.name}</a>
      </Grid>
      <Grid item xs={3} className={classes.tableRowsAlign}>
        <span className={classes.activityStatus}>
          <Box display="flex">
            <Avatar className={val.status === 1 ? classes.activityCompletedAvtar : null ||
              (val.status > 0 && val.status < 1) ? classes.activityOngoingAvtar : null ||
                val.status === 0 ? classes.activityNotStartedAvtar : null
            }></Avatar>
            <span>{val.progress?.done}/{val.progress?.total} {title[0].toUpperCase() + title.slice(1)}</span>
          </Box>
        </span>
      </Grid>
      <Grid item xs={3} className={classes.tableRowsAlign}>
        <span className={classes.activityStatus}>{val?.actual_end ? moment(val?.actual_end).format("DD MMM YYYY") : "___"} </span>
      </Grid>
      <Grid item xs={2} className={classes.tableRowsAlign}>
        <a className={((val.progress?.snags?.open + val.progress?.snags?.closed) > 0) ? classes.convActivityName : classes.activityName}
          id={`${val.id}_${val.name}_snags`}
          href={`${window.location.href.split('/', 3).join('/')}${onSnagUrlpath(val.id)}`}
          target="popup" style={{ textDecoration: "none" }}
          onClick={() => { if (val.progress?.snags?.open + val.progress?.snags?.closed) { onSnagClick(val.id); } }}>{val.progress?.snags?.open}/{(val.progress?.snags?.open + val.progress?.snags?.closed)}</a>
      </Grid>
    </Grid>
  )
}

// conditional rendering for conventional andf aluform pour
function ConventionalActivityOverview({ classes, conventionalData, pour }: any) {
  const { push } = useHistory()
  const store = useStore();
  const activityUrlpath = (id, convDataItem) => {
    return store.params.toPath({ section: "unit-act-info", screen: "fullscreen", spaceType: "tower", phase: "structures" }) + `${store.summary.towerData.towerId}?floor_idx=${store.summary.towerData.floor}&activity_type_id=${id}&unit_type_id=${convDataItem}&pour_number=${pour}`
  }
  const onSnagUrlpath = (id, convDataItem) => {
    return store.params.toPath({ section: "snags", screen: "fullscreen", spaceType: "tower", phase: "structures" }) + `${store.summary.towerData.towerId}?floor_idx=${store.summary.towerData.floor}&activity_type_id=${id}&unit_type_id=${convDataItem}&pour_number=${pour}`
  }
  const onActivityClick = useCallback((id, convDataItem, name) => {
    document?.getElementById(`${id}_${name}_activities`)?.removeAttribute("href");
    push(activityUrlpath(id, convDataItem));
  }, []);

  const onSnagClick = useCallback((id, convDataItem, name) => {
    document?.getElementById(`${id}_${name}_snags`)?.removeAttribute("href");
    push(onSnagUrlpath(id, convDataItem));
  }, []);

  return (
    <div>
      {/* Activity Overview Heading */}
      <div id="activity-overview" className={classes.Headers}>{"Activity Overview"}</div>
      {/* Activity overview table */}
      <Box display="flex" justifyContent="center">
        <Box className={classes.activityTableDiv}>
          {
            conventionalData && Object.keys(conventionalData).sort().map((convDataItem, index) => (
              <div key={convDataItem + "_" + index} className={classes.bottomPadding}>
                <Grid container style={{ paddingBottom: 4 }}>
                  <Grid item xs={4}>
                    <span className={classes.tableHeading}>{convDataItem}</span>
                  </Grid>
                  <Grid item xs={3} className={classes.tableHead}>
                    <span className={classes.tableHeading}>{index === 0 ? "Members" : ""}</span>
                  </Grid>
                  <Grid item xs={3} className={classes.tableHead}>
                    <span className={classes.tableHeading}>{index === 0 ? "Completion Date" : ""}</span>
                  </Grid>
                  <Grid item xs={2} className={classes.tableHead} >
                    <span className={classes.tableHeading}>{index === 0 ? "Open/Total Snags" : ""}</span>
                  </Grid>
                </Grid>
                {
                  conventionalData[convDataItem].sort(sortingWithPrecedence).map((val: any) => <RenderRow key={val.id} classes={classes} title={convDataItem} val={val}
                    activityUrlpath={() => activityUrlpath(val.id, val['unit-member']?.type)}
                    onSnagUrlpath={() => onSnagUrlpath(val.id, val['unit-member']?.type)}
                    onActivityClick={() => onActivityClick(val.id, val['unit-member']?.type, val.name)} onSnagClick={() => onSnagClick(val.id, val['unit-member']?.type, val.name)} />)
                }
              </div>
            ))
          }
        </Box>
      </Box>
    </div>
  )
}

export default React.memo(ConventionalActivityOverview)
