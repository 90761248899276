import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flex: "0 0 auto",
            minHeight: "2em",
            display: "flex",
            flexWrap: "nowrap",
            minWidth: "2em",
            width: "105%",
            padding: "0 0 0 10px",
        },
        loadingSkeletondiv: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%"
        },
        SmloadingSkeletondiv: {
            height: "-webkit-fill-available",
            display: "flex",
            width: "-webkit-fill-available"
        },
        rootXsSm: {
            flex: "0 0 auto",
            display: "flex",
            flexWrap: "nowrap",
            minHeight: "2em",
            minWidth: "2em",
            width: "105%",
            padding: "0 0 0 10px",
            "& .MuiGrid-grid-xs-1": {
                maxWidth: "12.333333%",
                flexBasis: "12.333333%",
            },
            "& .MuiGrid-grid-xs-11": {
                maxWidth: "87.666667%",
                flexBasis: "87.666667%",
            }
        },
        blockGrid: {
            fontSize: "11px",
            fontWeight: "bold",
            padding: "3px 8px 0px 4%",
        },
        scrollX: {
            display: 'inline-block'
        },
        axisStyleLand: ({ phase }) => ({
            color: "#585858",
            fontSize: "13px",
            fontFamily: "Source Sans Pro, sans-serif !important",
            verticalAlign: "center",
            // paddingTop: "4px",
            paddingRight: phase === "finishing" ? "9px" : "0px",
            paddingLeft: phase === "finishing" ? "0px" : "23%",
            // paddingBottom: "7px",
            display: 'flex',
            alignItems: 'center',
            justifyContent: "center",
            borderRight: (phase === "finishing") ? "none" : "1px solid #d1d1d1"
        }),
        axisStyleFull: (props: { screen: string, phase: string }) => ({
            fontFamily: "Source Sans Pro, sans-serif !important",
            color: "#585858",
            fontSize: "16px",
            verticalAlign: "center",
            // paddingTop: "4px",
            paddingRight: "12px",
            textAlign: props.screen === "fullscreen" ? "center" : "end",
            display: 'flex',
            alignItems: 'center',
            justifyContent: props.screen === "fullscreen" ? "center" : "flex-end"
        }),
        axisLabelLand: {
            // display: 'inline',
            position: 'sticky',
            // top: '45%',
            transform: 'translateX(-20%) translateY(50%) rotate(-90deg)',
            fontFamily: "Source Sans Pro, sans-serif !important",
            color: "#585858",
            fontSize: "12px",
            fontWeight: 700,
            textAlign: "center",
            verticalAlign: "center",
            textTransform: "uppercase"
        },
        axisLabelFullscreen: {
            // display: 'inline',
            position: 'sticky',
            // top: '45%',
            transform: 'translateX(-20%) translateY(50%) rotate(-90deg)',
            fontFamily: "Source Sans Pro, sans-serif !important",
            color: "#585858",
            fontSize: "18px",
            fontWeight: 700,
            textAlign: "center",
            verticalAlign: "center",
            textTransform: "uppercase"
        },
        towerTitle: {
            color: "#005eff",
            fontSize: "14px",
            fontWeight: 700,
            fontFamily: "Source Sans Pro, sans-serif !important",
            textAlign: "center",
            cursor: "pointer",
            // marginRight:"4px",
            "&:hover": {
                color: "#585858",
            },
        },
        towerSubTitle: (props: { screen: string, phase: string }) => ({
            wordBreak: "break-word",
            color: (props.phase === "finishing") ? "#005eff" : "black",
            fontSize: "14px",
            paddingLeft: "2px",
            fontFamily: "Source Sans Pro, sans-serif !important",
            display: "flex",
            cursor: "pointer",
            justifyContent: props.phase === "finishing" ? "flex-start" : "center",
            height: 60,
            alignItems: props.phase === "finishing" ? "flex-start" : "unset",
            textOverflow: "ellipsis",
            overflow: "hidden",
            "&:hover": {
                fontWeight: (props.phase === "finishing") ? 600 : "normal"
            },
        }),
        towerSubTitle2: {
            display: "flex",
            justifyContent: "center",
            paddingRight: "13px",
            color: "#585858",
            fontSize: "14px",
            fontWeight: 800,
            fontFamily: "Source Sans Pro, sans-serif !important",
        },
        towerDisplayFull: {
            display: "flex",
            justifyContent: "center",
            fontFamily: "Source Sans Pro, sans-serif !important",
            // textAlign: "center",
        },

        // towerSubTitleFull: {
        //     color: "#585858",
        //     fontSize: "16px",
        //     // paddingLeft: "3px",
        //     fontWeight: 700,
        //     fontFamily: "Source Sans Pro, sans-serif !important",
        // }
        towerSubTitleFull: (props: { screen: string, phase: string }) => ({
            wordBreak: "break-word",
            color: (props.phase === "finishing") ? "#005eff" : "black",
            fontSize: (props.phase === "finishing") ? "14px" : "16px",
            paddingLeft: "2px",
            fontWeight: (props.phase === "finishing") ? 500 : 700,
            fontFamily: "Source Sans Pro, sans-serif !important",
            textAlign: (props.phase === "finishing") ? "left" : "center",
            cursor: "pointer",
            textOverflow: "ellipsis",
            overflow: "hidden",
            "&:hover": {
                fontWeight: (props.phase === "finishing") ? 600 : 700
            },
        }),
        towerSubTitle2Full: {
            wordBreak: "break-word",
            paddingRight: "13px",
            color: "#585858",
            fontSize: "16px",
            fontWeight: "normal",
            fontFamily: "Source Sans Pro, sans-serif !important",
        },
        underlineHover: {
            display: "inline-block",
            textDecoration: " none",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            /* Set up the hover */
            /* If you aren't using autoprefix, remember to prefix the gradient for other browsers */
            // backgroundImage: "linear-gradient(#005eff, #005eff)",
            // backgroundSize: "28px 1px, auto",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center bottom",
            transition: "all .2s ease-out",
            cursor: "pointer",
            backgroundColor: "#f4f4f4",
            width: "105px",
            height: "30px",
            paddingTop: "4px",
            border: "1px solid #80808021",
            "&:hover": {
                backgroundSize: "100% 2px, auto",
                textDecoration: "none",
                "& span": {
                    color: "#585858",
                }
            },
        },
        hideScroll: {
            "&::-webkit-scrollbar": {
                display: "none"
            }
        },
        fLP: {
            position: "absolute",
            fontFamily: "Source Sans Pro, sans-serif !important",
            fontSize: "14px",
            fontWeight: "bold",
            fontStretch: "normal",
            marginLeft: "10px",
            fontStyle: "normal",
            lineHeight: 1.25,
            letterSpacing: "normal",
            color: "#005eff",
            textDecoration: " none",
            width: "93px",
            height: "30px",
            padding: "0px 5px",
            // paddingTop: "4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#F4F4F4",
            textAlign: "center",
            /* Set up the hover */
            /* If you aren't using autoprefix, remember to prefix the gradient for other browsers */
            backgroundImage: "linear-gradient(#005eff, #005eff)",
            backgroundSize: "28px 1px, auto",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center bottom",
            transition: "all .2s ease-out",
            cursor: "pointer",
            top: "10%",
            "&:hover": {
                backgroundSize: "100% 2px, auto",
                textDecoration: "none",
                color: "#585858"
            },
        },
        other: ({ screen, phase }) => ({
            position: "absolute",
            fontFamily: "Source Sans Pro, sans-serif !important",
            top: (screen === "fullscreen") ? phase === "finishing" ? "20%" : "none" : "25%",
        }),
        floorCountRootDiv: {
            paddingTop: "16px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            fontSize: "13px",
            fontWeight: "bold",
            color: "#585858"
        },
        underlineOnHover: {
            cursor: "pointer",
            "&:hover": {
                backgroundImage: "linear-gradient(#005eff, #005eff)",
                backgroundSize: "0px 1px, auto",
                backgroundRepeat: "no-repeat",
                textDecoration: "none"
            }
        }
    })
);

export default useStyles;
