import React, {
  memo,
  useCallback,
  useMemo,
  useEffect
} from "react";
import { useHistory, useLocation } from "react-router";
import { putAsync } from "csp-with-ts";
import useAccordion, { IList } from "./UseAccordionHook";
import { ListSubheader } from "@material-ui/core";
import useStyles from "./FilterStyles";
import Checkbox from './Checkbox';
import Select, { SelectedState } from './SelectedState'
import useSearch from "../search/useSearch";
const byParam: string = "Status";

export interface IByStatus {
  data: { id: string; name: string }[];
}
const StatusList = memo(({ options, state, setState, RenderFn }: IList<{ id: string; name: string; }, 'id'>) => {
  const classes = useStyles({});
  const { pathname } = useLocation();
  let ret: JSX.Element[] = [];
  /* Temporary code to disable delayed filter in bar chart **/
  if (pathname.includes("barChart")) {
    options = options.filter((item) => item.name !== "Delayed");
  }
  options.forEach(({ id, name }) => {
    if (name === "Delayed") {
      ret.push(
        <ListSubheader
          key="with"
          className={classes.normText11px}
          style={{ paddingTop: 0, background: "#faf9f9", marginLeft: 10 }}
        >
          <span>And</span>
        </ListSubheader>
      );
    }
    ret.push(
      <RenderFn
        key={id}
        id={id}
        value={state}
        handleClick={setState}
        name={name}
      />
    );
  });
  return <>{ret}</>;
});

function ByStatus({ data }: IByStatus) {
  const location = useLocation();
  const { search, pathname } = useMemo(() => location, [
    location.search,
    location.pathname,
  ]);
  const { push } = useHistory();
  const searchParam = useMemo(() => new URLSearchParams(search), [search]);
  const current: number[] | string[] = useMemo(
    () =>
      searchParam.get("delayed")
        ? ["delayed", ...searchParam.getAll(byParam.toLowerCase())]
        : searchParam.getAll(byParam.toLowerCase()),
    [search]
  );
  const initialState = useCallback(() => current, [current]);
  const [state, ByStatusFilter, relay] = useAccordion<{ id: string; name: string; }, 'id'>(StatusList, { accordian: true }, initialState);
  useEffect(() => { putAsync(relay, { type: 'replace', payload: [...current] }); }, [current]);
  const disabled = useMemo(() => {
    if (state.length === current.length) {
      return state.length === 0
        ? true
        : state.every((el: string | number) =>
          (current as Array<typeof el>).includes(el)
        );
    } else {
      return false;
    }
  }, [state, current]);

  const applyHandler = useCallback(() => {
    if ((state as string[]).includes("delayed")) {
      searchParam.delete("delayed");
      searchParam.delete(byParam.toLowerCase());
      state.forEach((v) => {
        if (v === "delayed") searchParam.append("delayed", "true");
        else searchParam.append(byParam.toLowerCase(), v);
      });
    } else {
      searchParam.delete(byParam.toLowerCase());
      if (byParam.toLowerCase() === "status") searchParam.delete("delayed");
      state.forEach((v) => {
        searchParam.append(byParam.toLowerCase(), v);
      });
    }
    push({ ...location, search: searchParam.toString() });
  }, [state]);
  const applyReset = () => {
    searchParam.delete("delayed");
    searchParam.delete(byParam.toLowerCase());
    push({ ...location, search: searchParam.toString() });
  }
  return (
    <ByStatusFilter
      options={data}
      Render={Checkbox}
      callback={applyHandler}
      disabled={disabled}
      reset={applyReset}
      // title={byParam}
      title={<SelectedState title={byParam} state={state} filterType={"status"} />}
    />
  );
}
export default memo(ByStatus);
