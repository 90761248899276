import React, { useCallback, memo } from 'react'
import { useObserver, useLocalStore } from 'mobx-react-lite'
import { Grid, Paper, Popover, MenuList, Link } from "@material-ui/core";
import { useStore } from '../../models/ProvideModel';
import useStyles from "./SwitchTowerDDStyle";
import { getSnapshot } from 'mobx-state-tree';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import './animation.scss';
import upArrowIcon from '../../assets/images/ArrowUp.svg';
import downArrowIcon from '../../assets/images/ArrowDown.svg';
import { sortingStrategyName } from '../../utils/utils';

function useRerouter() {
  const store = useStore();
  const { push } = useHistory();
  const rerouter = (tower: string) => { push(store.params.toPath({ ...getSnapshot(store.params), screen: 'fullscreen', tower, unitType: null })); };
  return rerouter;
}

const SwitchTowersDD = memo(() => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const store = useStore();
  const params = store.params
  const { push } = useHistory();
  const classes = useStyles({ store });
  const towerName = store.currentTower && store.currentTower.name;

  const urlPath = (tower: string) => {
    return store.params.toPath({ ...getSnapshot(store.params), screen: 'fullscreen', tower })
  }

  const handleClick = (tower: string) => {
    document?.getElementById(tower)?.removeAttribute("href");
    push(urlPath(tower));
  };

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return useObserver(() => (
    <div className={classes.root}>
      {open ? (
        <div className={classes.rootSeleted}>
          <div aria-describedby={id} className={classes.towerSelected}>
            <span className={classes.currentTowerName}>{store.currentTower && store.currentTower.name}</span>
            <img src={upArrowIcon} alt="arrowU" />
          </div>
        </div>
      ) : (
        <div className={"examples"} onClick={handleOpen}>
          <div className={"example"}>
            <div className={clsx("hover", "hover-3")}>
              <ul className={"ulClass"}>
                <li className={"liClass"}>
                  {store.currentTower && store.currentTower.name}
                </li>
                <img className={"arrow"} src={downArrowIcon} alt="arrowDown" />
              </ul>
            </div>
          </div>
        </div>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Paper style={{ minWidth: "300px" }}>
          <MenuList
            // autoFocusItem={open}
            id="menu-list-grow"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Grid container>
              <Grid container item xs={12}>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "12px",
                  }}
                >
                  <div className={classes.normText11px}>Select Tower</div>
                </Grid>
                {Array.from(
                  store.currentDashboard?.towers || [],
                  // store.towers ? store.towers.values() : [],
                  ({
                    id,
                    name,
                    hasData,
                  }: {
                    id: string;
                    name: string;
                    hasData?: boolean;
                  }) => ({ id, name, hasData })
                )
                  // .filter((t) => t.hasData)
                  .sort(sortingStrategyName)
                  .map(({ id, name }: { id: string; name: string }) => {
                    return (
                      <Grid
                        item
                        xs={4}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          paddingBottom: "26px",
                        }}
                        key={id}
                      >
                        <div
                          className={
                            store.currentTower &&
                              towerName.toLowerCase() === name.toLowerCase()
                              ? " "
                              : classes.underlineHover
                          }
                          style={{ height: "30px", paddingTop: "4px" }}
                        >
                          <Link id={id}
                            target="popup"
                            href={`${window.location.href.split('/', 3).join('/')}${urlPath(id)}`}
                            onClick={store.currentTower &&
                              (towerName.toLowerCase() === name.toLowerCase()) ? () => { document?.getElementById(id)?.removeAttribute("href"); } : () => { handleClick(id); return false }}
                            className={
                              store.currentTower &&
                                towerName.toLowerCase() === name.toLowerCase()
                                ? classes.towerTitleSelected
                                : classes.towerTitle
                            }
                          >
                            {name}
                          </Link>
                        </div>
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
          </MenuList>
        </Paper>
      </Popover>
    </div>
  ));
});

export default React.memo(SwitchTowersDD)
