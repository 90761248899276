import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
    fontFamily: "Source Sans Pro, sans-serif !important",
    // backgroundColor: "#fff",
    width: "100%"
  },
  towerTitle : {
    display:"flex", 
    justifyContent: "center", 
    fontSize: "24px", 
    fontFamily: "Source Sans Pro, sans-serif !important", 
    color:"#005eff", 
    fontWeight:"bold",
  },
  xAxisStyle: {
    fontFamily: "Source Sans Pro, sans-serif", 
    fontSize: "12px", 
    textOverflow: "ellipsis", 
    // width:"50px",
    wordWrap:"break-word",
    overflow:"hidden", 
    cursor: "pointer",
    "&:hover": {
      fontWeight: 600
    },
  },
  customTooltip : {
    fontFamily: "Source Sans Pro, sans-serif !important", 
    backgroundColor: "#fff",
    height: "auto",
    width: 150,
    cursor: 'pointer',
    borderRadius: "1px",
    padding: "8px",
    border: "solid #585858 1px"
  },
  chartDiv : { 
    height: "98%",
    display: "flex",
    justifyContent: "center"
  },
  yTickStyle : {
    fontFamily: "Source Sans Pro",
   fontSize: "10px"
  },
  avatarComp: {
    width: "11px",
    height: "11px",
    alignSelf: "left",
    backgroundColor: "#34d1ae",
    marginRight: "6px"
  },
  avatarWip: {
    width: "11px",
    height: "11px",
    alignSelf: "left",
    backgroundColor: "#ffd300",
    marginRight: "6px"
  },
  avatarNotStart: {
    width: "11px",
    height: "11px",
    alignSelf: "left",
    backgroundColor: "#eaeaea",
    marginRight: "6px"
  },
  capHead4: {
    fontSize: "14px",
    fontFamily: "Source Sans Pro, sans-serif !important",
    color: "#585858",
    fontWeight: 700,
    lineHeight: 1.29,
    letterSpacing: "normal",
    textTransform: "uppercase",
    paddingBottom: "12px",
},
normText12Px: {
  fontSize: "12px",
  fontFamily: "Source Sans Pro, sans-serif !important",
  color: "#575757",
  fontWeight: "normal",
  lineHeight: 1.29,
  letterSpacing: "normal",
  paddingBottom: "2px"
},
boldText12Px: {
  fontSize: "12px",
  fontFamily: "Source Sans Pro, sans-serif !important",
  color: "#575757",
  fontWeight: 700,
  lineHeight: 1.29,
  letterSpacing: "normal",
  paddingBottom: "5px"
}

  })
);

export default useStyles;