import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import "typeface-source-sans-pro";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    reports: {
      width: "fit-content",
      // minHeight: "450px",
      borderRadius: "2px",
      backgroundColor: "#fff",
      flexGrow: 1,
      display: "block",
      fontFamily: "Source Sans Pro, sans-serif",
      position: "relative",
      backgroundImage: "linear-gradient(#2f3e6c, #2f3e6c)",
      backgroundSize: "95% 2px, auto",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center bottom"
    },
    reportsSm: {
      width: "fit-content",
      borderRadius: "2px",
      backgroundColor: "#fff",
      flexGrow: 1,
      display: "block",
      fontFamily: "Source Sans Pro, sans-serif",
      backgroundImage: "linear-gradient(#2f3e6c, #2f3e6c)",
      backgroundSize: "95% 2px, auto",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center bottom"
    },
    
    root: (props: { disabled: boolean, brickCount:any }) => ({
        padding: "12px",
        display: "flex",
        flexDirection: "column",
        // fontSize: "16px",
        backgroundColor: "#ffffff !important",
        margin: "16px 12px 0 0",
        width: "184px",
        height: props.brickCount === undefined ? "59px" : "87px",
        justifyContent: props.brickCount === undefined ? "center" : "unset",
        borderRadius: "2px",
        cursor: "pointer",
        fontFamily: "Source Sans Pro, sans-serif",
        "& span": {
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap"
        },
        "& .arrowIcon": {
          transition: "transform 0.3s",
          transform: `rotate(${props.disabled ? "180deg" : 0} )`
        }
    }),
    rootSm: (props: { disabled: boolean, brickCount:any }) => ({
        padding: "12px",
        display: "flex",
        flexDirection: "column",
        // fontSize: "16px",
        backgroundColor: "#ffffff !important",
        margin: "14px 12px 0 0",
        width: "161px",
        height: props.brickCount === undefined ? "53px" : "70px",
        justifyContent: props.brickCount === undefined ? "center" : "unset",
        borderRadius: "2px",
        cursor: "pointer",
        fontFamily: "Source Sans Pro, sans-serif",
        "& span": {
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap"
        },
        "& .arrowIcon": {
          transition: "transform 0.3s",
          transform: `rotate(${props.disabled ? "180deg" : 0} )`
        }
    }),
    btn_type: {
      color: "#005eff",
    },
    space_type: (props: { disabled: boolean,brickCount:any }) => ({
      fontFamily: "Source Sans Pro, sans-serif",
      fontSize: "16px",
      width: "100%",
      textTransform: "capitalize",
      display: "flex",
      justifyContent: "space-between",
      paddingBottom: props.brickCount === undefined ? "unset" :"8px",
      alignItems: "flex-start"
    }),
    space_typeSm: (props: { disabled: boolean }) => ({
      fontFamily: "Source Sans Pro, sans-serif",
      fontSize: "14px",
      textTransform: "capitalize",
      display: "flex",
      justifyContent: "space-between",
      paddingBottom: "8px",
      alignItems: "flex-start"
    }),
    blockCount: {
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.25",
      letterSpacing: "normal",
      textAlign: "left",
      color: "#585858",
    },
    blockCountSm: {
      fontSize: "7px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.25",
      letterSpacing: "normal",
      textAlign: "left",
      color: "#585858",
    },
    underlineAnim: (props: { disabled: boolean }) => ({
      /* Style the buttons that are used to open and close the accordion panel */
      backgroundColor: "#faf9f9",
      color: props.disabled ? "#2f3e6c" : "#005eff",
      fontWeight: props.disabled ? "bold" : "normal",
      cursor: "pointer",
      border: "none",
      outline: "none",
      textDecoration: "none",
      backgroundSize: props.disabled ? "90% 2px, auto" : "0 3px, auto",
      backgroundImage: "linear-gradient(#2f3e6c, #2f3e6c)",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center bottom",
      transition: "all 0.2s ease-out",
      borderRadius: "1px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.25",
      letterSpacing: "normal",
      textAlign: "left",
      fontFamily: "Source Sans Pro, sans-serif",
      zIndex: 0,
      "&:hover": {
        backgroundImage: props.disabled ? "linear-gradient(#005eff, #005eff)" : "linear-gradient(#b1b1b1, #b1b1b1)",
        backgroundSize: "90% 2px, auto",
        fontWeight: "bold",
        fontFamily: "Source Sans Pro, sans-serif",
        color: "#005eff",

        // color: disabled ? "#2f3e6c" : "#005eff",
      },
    }),
  }));
export default useStyles;
