import React from 'react';
import useStyles, {TYPE_DEFAULT, TYPE_PRIMARY} from './ButtonStyles';

export interface ButtonProps {
  /**
   * Is this the principal call to action on the page?
   */
  primary?: boolean;
  /**
   * What background color to use
   */
  backgroundColor?: string;
  /**
   * How large should the button be?
   */
  size?: 'small' | 'medium' | 'large';
  /**
   * Button contents
   */
  label: string;
  /**
   * button will be floating or not
   */
  floating?: boolean;
  /**
   * Optional click handler
   */
  onClick?(): void;
}

export const getBtnType = (classes, primary: boolean) => {
  return primary ? classes[`btnType_${TYPE_PRIMARY}`] : classes[`btnType_${TYPE_DEFAULT}`];
}

export const getBtnSize = (classes, size) => {
  return classes[`btnSize_${size}`];
}

/**
 * Primary UI component for user interaction
 */
export const Button: React.FC<ButtonProps> = ({
  primary = false,
  size = 'large',
  backgroundColor,
  label,
  floating = false,
  ...props
}) => {
  const classes = useStyles();  
  const mode = getBtnType(classes, primary);
  const btnSize = getBtnSize(classes, size);
  const flt = floating ? classes['btnState_floating'] : '';
  return (
    <button
      type="button"
      className={[classes.btn, btnSize, mode, flt].join(' ')}
      style={{ backgroundColor }}
      {...props}
    >
      {label}
    </button>
  );
};
