import React, { memo } from 'react';
import { useObserver } from 'mobx-react-lite';
import moment from 'moment';
import { ISpecificBrickProps } from './Brick'
import { useStructuresFullscreenStyles } from './CommonAreaBrickStyls';


export const SUPPORTTED_STATUS = {
  COMPLETED: 'completed',
  STARTED: 'started',
  NOT_STARTED: 'notStarted',
  DEFAULT: 'default'
}

export interface StructuresFullscreenBrickProps {
  /**
   * name of the Brick
   */
  name?: string;
  /**
   * height of each Brick
   */
  height: number;

  /**
   * width of each Brick
   */
  width: number;

  /**
   * status of each Brick
   */
  status: 'completed' | 'notStarted' | 'started' | 'default';

  /**
   * Required, when status is started.
   */
  total?: number;

  /**
   * Required, when status is started.
   */
  complete?: number;

  /**
   * Required, If status is started
   */
  completionRate?: number;
  endDelay?: any;
  startDelay?: number;
  progressAsPercent?: any
  renderString?: any;
  brickprops?: any;
  filter?: boolean
}

export function renderString(brick: ISpecificBrickProps['brick']) {
  switch (brick.status) {
    case 'notStarted': return brick.delayed ? `Start Delay By: ${brick.plan && brick.plan.diffTo ? brick.plan.diffTo.toLocaleString('en', { minimumIntegerDigits: 2 }) : "—"} D` : "—";
    case 'started': return `Ongoing For: ${brick.actual && brick.actual.diffFrom ? brick.actual.diffFrom.toLocaleString('en', { minimumIntegerDigits: 2 }) : "—"} D`;
    case 'completed': return `Slab Time: ${brick.actual && brick.actual.diff ? brick.actual.diff.toLocaleString('en', { minimumIntegerDigits: 2 }) : "—"} D`;
    default: return "—";
  };
}

const FullscreenBrick = (props: StructuresFullscreenBrickProps) => {
  // const classes = useStyles({ filter: brick.filtered });
  //   const completionRate = (brick.completed / brick.total) * 100
  //   if (brick.status === 'started') {
  //     if (completionRate === null || completionRate === undefined) {
  //         throw Error("Completion rate is required since it is in started state");
  //     }

  //     if(completionRate && (completionRate < 0 || completionRate > 100))  {
  //         throw Error("Invalid Completion Rate");
  //     }
  // }

  const classes = useStructuresFullscreenStyles({ ...props });

  const getStatusStrBasedOnStatus = (status) => {
    switch (status) {
      case SUPPORTTED_STATUS.COMPLETED:
        return status.toUpperCase();
      case SUPPORTTED_STATUS.NOT_STARTED:
        return 'STARTS';
      case SUPPORTTED_STATUS.STARTED:
        return `${props.completionRate?.toFixed(2)} %`;
      case SUPPORTTED_STATUS.DEFAULT:
        return "";
    }
  }

  const getSnags = () => {
    // if (!brick.snags) {
    //     return '--';
    // }
  }

  const getFormattedRemainingDays = (days) => {
    return `+ ${days} D `;
  }

  const getFormattedUnits = (units) => {
    return `${units} Units`;
  }

  const getDelaySymbolBasedOnType = (delayedType) => {
    switch (delayedType) {
      case 'start':
        return (<div className={classes.circle}></div>);
      case 'end':
        return (<div className={classes.rectangle}></div>)
      default:
        return '';
    }
  }

  return useObserver(() => (
    <div className={classes.brickContainer}>
      {props.status === SUPPORTTED_STATUS.STARTED && (<div className={classes.brickProgressBg}></div>)}
      <div className={classes.brickContent}>
        <div className={classes.brickName}>{props.name}</div>
        <div className={props.brickprops.plan && props.brickprops.planto && ((props.brickprops.actual && props.brickprops.actualto && props.brickprops.actualdiff! > 0) || (!(props.brickprops.actual && props.brickprops.actualto) && props.brickprops.actualdiff! > 0)) ? classes.delayed : classes.display}>{props.renderString}</div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className={props.endDelay === null && props.startDelay === null ? classes.endDelayedRectangle : classes.rectangle}></div>
          <div className={classes.date}>
            <span>{props.brickprops.plan && props.brickprops.planto ? moment(props.brickprops.planto).format('DD-MM-YYYY') : '—'}</span>
            {props.endDelay ? <span style={{ marginLeft: "3px" }}>
              [ {props.endDelay.toLocaleString('en', { minimumIntegerDigits: 2 })} D]
            </span> : null}
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className={props.endDelay === null && props.startDelay === null ? classes.startDelayedCircle : classes.circle}></div>
          <div className={classes.date}>
            <span>{props.brickprops.plan && props.brickprops.planfrom ? moment(props.brickprops.planfrom).format('DD-MM-YYYY') : '—'}</span>
            {props.startDelay ?
              <span style={{ marginLeft: "3px" }}>[ {props.startDelay.toLocaleString('en', { minimumIntegerDigits: 2 })} D]</span> : null}
          </div>
        </div>
        <div className={classes.display}>{props.progressAsPercent} %</div>
      </div>
    </div>
  ));
};

export default memo(FullscreenBrick);
