import { makeStyles, Theme } from "@material-ui/core/styles";

const getBackgroundColorBasedOnStatus = (status) => {
    switch (status) {
        case 'completed':
            return '#2fb597';
        case 'notStarted':
            return '#c3c3c3';
        case 'started':
            return '#ffc400';
        case 'default':
            return "#eaeaea";
    }
}

const getBorderColorBasedOnStatus = (status) => {
    switch (status) {
        case 'completed':
            return '#2fb597';
        case 'notStarted':
            return '#c3c3c3';
        case 'started':
            return '#ffc400';
        case 'default':
            return "#585858";
    }
}

const useStyles = makeStyles((theme: Theme) => ({
    verticalDivider: {
        width: "2px",
        height: "12px",
        backgroundColor: "#cecece",
        alignSelf: "center"
    },
    unitTypeDiv: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    TypeName: {
        margin: "0 18px 8px 0",
        fontSize: "24px",
        fontWeight: "bold",
        lineHeight: 1.29,
        letterSpacing: "normal",
        color: "#2f3e6c",
        textTransform: "capitalize"
    },
    activities: (props: { isLg: boolean; }) => ({
        display: "flex",
        width: props?.isLg ? "53%" : "35%",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingBottom: "7px"
    }),
    lessDetails: {
        fontSize: "12px",
        lineHeight: 1.25,
        letterSpacing: "normal",
        color: "#005eff",
        marginRight: "43px",
        cursor: "pointer"
    },
    rectangle: {
        display: "flex",
        flexDirection: "row",
        width: "53px",
        justifyContent: "space-between",
        alignItems: "center"
    },
    completedRectangle: {
        width: "10px",
        height: "10px",
        borderRadius: "1px",
        border: `solid 1px ${getBorderColorBasedOnStatus('completed')}`,
        backgroundColor: getBackgroundColorBasedOnStatus('completed')
    },
    defaultRectangle: {
        width: "10px",
        height: "10px",
        borderRadius: "1px",
        border: `solid 1px ${getBorderColorBasedOnStatus('default')}`,
        backgroundColor: getBackgroundColorBasedOnStatus('default')
    },
    startedRectangle: {
        width: "10px",
        height: "10px",
        borderRadius: "1px",
        border: `solid 1px ${getBorderColorBasedOnStatus('started')}`,
        backgroundColor: getBackgroundColorBasedOnStatus('started')
    },
    Noofactivities: {
        fontSize: "12px",
        fontWeight: "bold",
        lineHeight: 1.25,
        letterSpacing: "normal",
        color: "#585858"
    },
    dates: {
        display: "flex"
    },
    statusBlock: {
        display: "flex",
        width: "41%",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    snags: {
        fontSize: "12px",
        fontWeight: "bold",
        lineHeight: 1.25,
        letterSpacing: "normal",
        color: "#575757"
    },
    snagsCount: {
        fontSize: "12px",
        fontWeight: "bold",
        lineHeight: 1.25,
        letterSpacing: "normal",
        color: "#005eff",
        paddingLeft: "6px"
    },
    planDiv: {
        display: "flex",
        width: "161px",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingBottom: "4px"
    },
    planDate: {
        fontSize: "12px",
        lineHeight: 1.25,
        letterSpacing: "normal",
        color: "#585858"
    },
    date: {
        fontSize: "12px",
        fontWeight: "bold",
        lineHeight: 1.25,
        letterSpacing: "normal",
        color: "#585858",
        paddingLeft: "10px"
    },
    horizantalDivider: (props: { isLg: boolean; }) => ({
        width: "100%",
        height: "2px",
        margin: props?.isLg ? "12px 36px 16px 0" : "9px 36px 0px 0",
        borderRadius: "2px",
        backgroundColor: "#edebeb"
    })
}));

export default useStyles;
