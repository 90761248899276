import React, { PropsWithChildren } from "react";
import commonStyles from '../ReportsStyles'
import { Typography } from "@material-ui/core";


function AdditionalReports({ children }: PropsWithChildren<{}>) {
  const commonClasses = commonStyles();

  return (
    <div>
      <Typography variant={"h5"} className={commonClasses.heading12px}>{"Additional Report"}</Typography>
      <Typography className={commonClasses.normalText12px}>
        Graphical & tabular reports for <b>a tower</b> with details on status
        updates / delays, work completion etc. with options to drill down to a
        specific activity or unit.
      </Typography>
      <div>{children}</div>
    </div>
  );
}
export default React.memo(AdditionalReports);
