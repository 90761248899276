import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
    root: { 
        display: "flex", 
        background: "#faf9f9", 
        flexGrow: 1, 
        flexShrink: 1, 
        width: "100%", 
        flexBasis: "auto"
    },
    noData: { 
        display: "flex", 
        alignItems: "center", 
        justifyContent: "center", 
        width: "100%", 
        margin: "auto",
        fontSize: "16px", 
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1.29,
        letterSpacing: "normal",
        flexFlow: "column"
    },
    title : {
      fontSize: "24px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.29,
      letterSpacing: "normal",
      textAlign: "left",
      color: "#2f3e6c",
      },
}));

export default useStyles;
