import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme : Theme) => ({
    root: {
        display: "flex",
        background: "#fff",
        width:"100%",
        height: "100%"
    }
}))

export default useStyles;