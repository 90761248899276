import React, { useEffect, useMemo, useCallback } from "react";
import { useLocation, useHistory } from 'react-router';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import useStyles from "./DropdownStyles"
import { useStore } from "../../models/ProvideModel";
import useAccordion from "../filter/UseAccordionHook";
import { DefaultList } from "../filter/DefaultAccordianRender";
import RadioButton from '../filter/RadioButton'
import { SelectedState } from "../filter/SelectedState";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 208,
    },
  },
};

// Maybe formalize all infopanel interactions to have router push/replace
// handlers and params based states instead of getting state through URL?

type INumeralState = 'activities' | 'units' | 'days';
const tileArray = [{id: "activities", name: "Activities: Completed / Total"}, 
                    {id: "units", name: "Units: Completed / Total"},
                    {id: "days", name: "Days Remaining"}]
function Dropdown(): JSX.Element {
  const { push } = useHistory();
  const location = useLocation();
  const { pathname, search } = useMemo(() => location, [location.search, location.pathname]);
  const classes = useStyles();
  const searchParams = useMemo(() => (new URLSearchParams(search)), [search]);
  const params = useStore().params;
  const [state, RenderFn] = useAccordion<{ id: string; name: string; }, 'id'>(DefaultList, { multiple: false, apply: true, accordian: true });

  const current: number[] | string[] = useMemo(
    () =>
        searchParams.getAll('display'),
    [search]
  );
  const disabled = useMemo(() => {
 
    if (state.length === current.length) {
      return state.length === 0
        ? true
        : state.every((el: string | number) =>
          (current as Array<typeof el>).includes(el)
        );
    } else {
      return false;
    }
  }, [state, current]);

  const applyHandler = useCallback(() => {
    searchParams.delete('display');
    state.forEach((v) => {
      searchParams.append("display", v);
    });
    push({ ...location, search: searchParams.toString() });
  }, [state]);

  const applyReset = () => {
    searchParams.delete("display");
    push({ ...location, search: searchParams.toString() });
  }

  if (params.graphType === 'barChart') return <></>;
  return (
    // <div className={classes.root}>
      <RenderFn
        options={tileArray}
        Render={RadioButton}
        callback={applyHandler}
        disabled={disabled}
        reset={applyReset}
        // title="Ongoing Tile Info"
        title={<SelectedState title={"Ongoing Tile Info"} state={state} filterType={"display"}/>}
      />
    // </div>
  );
}
export default React.memo(Dropdown)
