import React from 'react'
import { useHistory } from 'react-router';
import { useObserver } from 'mobx-react-lite';
import arrowIcon from '../../../assets/images/menuNavigation/Arrow-default.svg';
import { useStore } from '../../../models/ProvideModel';
import { Link } from '@material-ui/core';

const PowerBILinks = ({ powerbiDetails, classes }) => {
  const history = useHistory();
  const store = useStore();
  return useObserver(() => (
    <>
      {
        powerbiDetails.map((report, index) =>
          <Link key={index}
            id={`${report.dashboardName}_${report.provider}`} target="popup"
            href={`${window.location.href.split('/', 3).join('/')}/powerbi/${report.embedConfig.group_id}/${report.embedConfig.report_id}`}
            onClick={() => { document?.getElementById(`${report.dashboardName}_${report.provider}`)?.removeAttribute("href"); history.push(`/powerbi/${report.embedConfig.group_id}/${report.embedConfig.report_id}`) }}
            className={`${store.responsiveUtils.currentViewport.isLg ? classes.tools : classes.toolsSm} ${classes.underlineAnim}`}>
            <div>{report.dashboardName}</div><img src={arrowIcon} className={'arrowIcon'} alt={'powerBi'} style={{ alignSelf: "end", transform: "rotate(270deg)" }} />
          </Link>
        )
      }
    </>
  ));
}

export default PowerBILinks
