import { makeStyles, createStyles } from "@material-ui/core/styles";
import { extendStyles } from "../../utils/utils";

export const SIZES = {
    SM: 'small',
    MD: 'medium',
    LG: 'large',
    FIB: 'filterIconBtn',
    FSB: 'fullscreenBtn'
}

export const APPEARANCES = {
    DEFAULT: 'default',
    PRIMARY: 'primary'
}

const stylesObj = {
    btn: {
        border: '2px solid #efefef',
        borderRadius: '50%',
        fontWeight: 700,
        outline: 'none',
        cursor: 'pointer',
    },
    [`btnType_${APPEARANCES.PRIMARY}`]: {},
    [`btnSize_${SIZES.LG}`]: {},
    [`btnSize_${SIZES.MD}`]: {},
    [`btnSize_${SIZES.SM}`]: {},
    [`btnSize_${SIZES.FIB}`]: {},
    [`btnSize_${SIZES.FSB}`]: {},
    [`btnState_floating`]: {}
};

stylesObj[`btnType_${APPEARANCES.DEFAULT}`] = extendStyles(stylesObj[`btn`], {
    backgroundColor: 'white',
    color: 'black',
});

stylesObj[`btnType_${APPEARANCES.PRIMARY}`] = extendStyles(stylesObj[`btn`], {
    backgroundColor: 'blue',
    color: 'white'
});

stylesObj[`btnSize_${SIZES.LG}`] = extendStyles(stylesObj[`btn`], {
    padding: '20px 22px'
});

stylesObj[`btnSize_${SIZES.MD}`] = extendStyles(stylesObj[`btn`], {
    padding: '17px 20px'
});

stylesObj[`btnSize_${SIZES.SM}`] = extendStyles(stylesObj[`btn`], {
    padding: '14px 16px'
});

stylesObj[`btnSize_${SIZES.FSB}`] = extendStyles(stylesObj[`btn`], {
    padding: '11px 15px'
});

stylesObj[`btnSize_${SIZES.FIB}`] = extendStyles(stylesObj[`btn`], {
    padding: '16px 18px'
});

stylesObj[`btnState_floating`] = extendStyles(stylesObj[`btn`], {
    zIndex: 100,
    boxShadow: '6px 3px 33px -12px rgba(0,0,0,0.75)'
});

const useStyles = makeStyles(() => createStyles(stylesObj));

export default useStyles;