import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => {
  return {
    mainDiv: {
      width: "100%",
      display: "flex",
      justifyContent: "center"
    },
    handleContainer: (props: { isLg: boolean }) => ({
      width: props.isLg ? "100%" : "425px",
      display: "flex",
      flexDirection: "column",
      // justifyContent: "space-between",
      alignItems: "center",
      /* minHeight: !props.isLg ? "266px" : "76vh",
       * maxHeight: !props.isLg ? "360px" : "100vh", */
      /* overflow: "scroll", */
      padding: 40,
      paddingBottom: 10
    }),
    handleSecondContainer: (props: { isLg: boolean }) => ({
      width: "100%",
      overflow: "auto",
      marginBottom: "10px",
      minHeight: props.isLg ? "216px" : "unset",
      maxHeight: props.isLg ? "300px" : "unset"
    }),
    handleScrollLayer: {
      display: "flex",
      flexDirection: "column",
      flexShrink: 1,
      flexGrow: 1,
      overflow: "scroll",
      width: "100%",
      alignItems: "center"
    },
    titleContainer: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 0
    },
    titlediv: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      /* height: "59px", */
      justifyContent: "center"
    },
    workStatusdiv: {
      fontSize: "35px",
      fontWeight: "bold",
      color: "#242222"
    },
    fieldData: ({ isLg }: { isLg: boolean }) => ({
      display: "flex",
      flexDirection: "column",
      justifySelf: "center",
      marginBottom: "8px",
      paddingTop: "2rem",
      width: isLg ? 800 : "80%"
    }),
    fieldDescription: {
      fontSize: 11
    },
    UnitName: (props: { isLg: boolean }) => ({
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column"
    }),
    ActivityName: (props: { isLg: boolean }) => ({
      display: "flex",
      // justifyContent: "center",
      // marginLeft: "5px",
      alignItems: "center",
      fontSize: props.isLg ? "16px" : "22px",
      color: "#0f0e0e",
      marginBottom: -2,
      textTransform: "none"
    }),
    ActivityNameDisabled: (props: { isLg: boolean }) => ({
      display: "flex",
      // justifyContent: "center",
      // marginLeft: "5px",
      alignItems: "center",
      fontSize: props.isLg ? "16px" : "22px",
      color: "#B3B3B3",
      textTransform: "none"
    }),
    closeDiv: {
      display: "flex",
      flexDirection: "column",
      height: "35px",
      justifyContent: "space-between",
      width: "100%",
      alignItems: "center"
    },
    close: {
      display: "flex",
      // fontFamily: "SourceSansPro",
      fontSize: "20px",
      color: "#005eff",
      textAlign: "center",
      flexDirection: "column",
      justifyContent: "center",
      cursor: "pointer",
      width: "60px",
      border: "2px solid rgb(0 94 255 / 53%)",
      height: "60px"
    },
    image: {
      height: 64,
      width: 64,
      marginBottom: "5px",
      marginTop: "2px",
      cursor: "pointer",
      "&:hover": {
        height: 72,
        width: 72,
        marginLeft: "0px",
      }
    },
    brokenImage: {
      height: 64,
      width: 64,
      marginBottom: "5px",
      marginTop: "2px"
    },
    imgClass: {
      maxHeight: 100,
      maxWidth: 100,
      marginBottom: "10px",
      cursor: "pointer",
      "&:hover": {
        maxHeight: 110,
        maxWidth: 110,
      }
    },
    submitCancelDiv: {
      display: "flex",
      justifyContent: "space-evenly",
      width: "100%"
    },
    submitbutton: {
      display: "flex",
      flexGrow: 1,
      fontSize: "12px",
      color: "white",
      background: "#005eee",
      textAlign: "center",
      flexDirection: "column",
      justifyContent: "center",
      cursor: "pointer",
      /* width: "60px", */
      maxWidth: "20%",
      border: "2px solid",
      height: "33px",
      borderRadius: "5px",
      "&:hover": {
        backgroundColor: "#1a6fef",
        color: "#faf9f9"
      },
      "&:disabled": {
        backgroundColor: "#d1d1d1",
        color: "#808080"
      },
    },
  }
});

export default useStyles;
