import React, { memo } from 'react'
import useStyles from "./FooterStyles";
import { Grid, Avatar, Box, Divider } from '@material-ui/core';
import { fullscreen } from "../../configs/legendConfig";
import { useStore } from "../../models/ProvideModel";
import { useObserver } from 'mobx-react-lite';
const StructuresFooter = () => {
  const store = useStore();
  const { isLg } = store.responsiveUtils.currentViewport;
  const classes = useStyles({ isLg });

  const legendFooter = fullscreen.map((lagendlist, index) => {
    return (
      <Box display="flex" key={index} justifyContent="center" style={{ alignItems: isLg ? "" : "center" }}>
        <Avatar style={{ backgroundColor: lagendlist.BGColor, border: `${"1px solid " + lagendlist.border}`, borderRadius: `${lagendlist.borderRadius}` }} className={classes.avtarWidth}></Avatar>
        <span style={{ minWidth: "59px" }}>{lagendlist.key}</span>
      </Box>
    )
  });
  return useObserver(() => (
    <div className={classes.root} style={{ zIndex: store.filters.infopanelOpen ? 0 : 1 }}>
      <Grid container className={classes.gridContainer}>
        <Box key="footerLegendBox" display="flex" justifyContent="center">
          <div key="footerLegendLabel" className={classes.legend}><span>Legend</span> </div><Divider orientation="vertical" />
          <div className={classes.legendContainer}>{legendFooter}
            <div key="footerLegendEndDelayed" className={classes.flexBox} >
              <Avatar className={classes.endDelayAvtar} /> <span style={{ width: isLg ? "" : "52px" }}>{"End Delay"}</span>
            </div></div>
        </Box>
      </Grid>
    </div>
  ))
}
export default memo(StructuresFooter);