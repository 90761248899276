import { useObserver } from 'mobx-react-lite';
import React, { memo } from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { useStore } from '../../models/ProvideModel';
import useStyles from "./ScrollbarStyles";

const VerticalScrollbar = memo((props) => {
  const classes = useStyles();
  const store = useStore();
  const lg = store.responsiveUtils.currentViewport.isLg;
  
  return useObserver(() => (
    <Scrollbars
      autoHeight
      // autoHeightMax={600}
      autoHeightMin={lg ? '84vh' : '76vh'}
      autoHeightMax={lg ? '84vh' : '76vh'}
      // autoHide
      renderTrackVertical={({ ...props }) =>
        <div {...props} className={classes.renderTrackVertical} />
      }
      renderThumbVertical={({ ...props }) =>
        <div {...props} className={classes.renderThumb} />
      }>
      {props.children}
    </Scrollbars>
  ))
});
export default VerticalScrollbar;
