import React from 'react'
import useStyles from "./ProgressBarStyles";

export interface ProgressBarProps {
    
    /**
     * Percentage value to render
     */
    value: number;
    status:string;

    /**
     * Outer color 
     */
    primary: string;
    

    /**
     * Default color 
     */
    default: string;    

    /**
     * Background color 
     */
    background: string;

    /**
     * Divider color 
     */
     divider: string;

    /**
     * Optional, default is 10px. 
     */
    height?: number;
    width?: number;
    popup?:boolean;
}

export const ProgressBar = (props: ProgressBarProps) => {
    const classes = useStyles({ ...props });
  
    return (
        <div className={classes.root}>
            <div className={classes.primaryCont}>
                <div className={classes.progressIndicator}></div>
                <div className={classes.divider}></div>
            </div>
        </div>
    );
}