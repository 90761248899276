import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import ApolloClient from "apollo-client";
import { NormalizedCacheObject } from "apollo-cache-inmemory";
import client from "../../utils/apolloClient";
import { GET_CHECKLIST_REPORTS_SUMMARY } from "../../utils/queries";
import { IQueryParams } from "../../pages/exportSnagsPage/ExportSnagsPage";
import useStyles from "./UnitInfoSummaryInformationStyles";
import LoadingSpinner from "../loadingSkelaton/LoadingSpinner";


const TableSnagInformation = ({ pathParams, params, totalCount, loading }: { params?: IQueryParams; pathParams: { [K: string]: any; }; totalCount: number; loading?: boolean }) => {
    const [data, setData] = useState<{ [K: string]: number; }>({});
    useEffect(() => {
        if (!client.client) {
            client();
        }
    }, [client]);

    useEffect(() => {
        if (client.client && params) {
            const query = (client.client as ApolloClient<NormalizedCacheObject>)!
                .watchQuery({ query: GET_CHECKLIST_REPORTS_SUMMARY, variables: { where: { ...params.where, ...pathParams } } })
                .subscribe(({ data, loading, errors }) => {
                    if (errors) { console.error(errors); }
                    else if (!loading) {
                        setData(() =>
                            Object.keys(data).reduce(
                                (acc, id) => ({ ...acc, [id]: data[id].total.count }),
                                {}
                            )
                        );
                    }
                });
            return () => {
                query.unsubscribe();
            };
        }
    }, [pathParams, params]);
    const classes = useStyles();

    return (
        <div className={classes.rootDiv}>
            {Object.keys(data).length > 0 ?
                <Grid container className={classes.GridBox}>
                    <Grid item xs={2} style={{ flexBasis: "auto", display: "flex", marginRight: "18px" }}>BLOCK: <span className={classes.PropertyValue}>{data['block_id']?.toLocaleString('en-GB', { minimumIntegerDigits: 2 })}</span></Grid>
                    <Grid item xs={2} style={{ flexBasis: "auto", display: "flex", marginRight: "18px" }}>UNITS: <span className={classes.PropertyValue}>{data['unit_id']?.toLocaleString('en-GB', { minimumIntegerDigits: 2 })}</span></Grid>
                    <Grid item xs={2} style={{ flexBasis: "auto", display: "flex", marginRight: "18px" }}>REPORTS: <span className={classes.PropertyValue}>{totalCount?.toLocaleString('en-GB', { minimumIntegerDigits: 2 })}</span></Grid>
                </Grid> : !loading && <div
                    style={{
                        background: "#fff",
                        width: "100%",
                        marginTop: "10px",
                    }}
                >
                    <LoadingSpinner />
                </div>}
        </div>
    );
}
export default React.memo(TableSnagInformation);
