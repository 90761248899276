import React from "react";
import useStyles from "./tabTilesStyles";
import SideBar from "../../components/sideBar/sideBar"
import Title from "../../components/title/Title"
import useSearch from "../../components/search/useSearch"
import { GOT } from "../../models/enums";

export interface ITabsList { id: string; name: string; graphType: GOT; stats: any[]; };
export interface ITabTiles {
  data: ITabsList[];
  title: string;
  searchString?: string;
  screen: string;
  handleClick(id: string): void;
  selected: string;
  href(id: string): any;
  loading?: boolean;
  screenProps?: any
};

const TabTiles = ({ data, title, searchString, screen, handleClick, selected, href, loading, screenProps }: ITabTiles) => {
  const classes = useStyles({ isLg: screenProps.isLg });
  const [searchData, SearchComponent] = useSearch<ITabsList>(data, { title: searchString || "blocks" });
  return (
    <div className={classes.root}>
      <Title noOfUnits={data.length} title={title} screenProps={screenProps} />
      <div style={{ padding: screenProps?.isLg ? "0px 12px 20px" : "0px 12px 0px" }}><SearchComponent /></div>
      <div className={classes.maindiv}>
        {!loading && data.length > 0 && <SideBar data={searchData} selected={selected} screen={screen} handleClick={handleClick} href={href} screenProps={screenProps} />}</div>
    </div>
  );
};

export default React.memo(TabTiles);
