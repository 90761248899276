import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    height: "100%",
    fontFamily: "Source Sans Pro, sans-serif !important",
    display: 'flex',
    flexDirection: 'column',
  },
  filterIcon: {
    height: "1.2em"
  },
  legendIcon: {
    height: "1.9em"
  },
  top: {
    borderBottom: '1px solid #faf9f9',
    display: 'flex',
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "1.5em"
  },
  bottom: {
    borderTop: '1px solid #faf9f9',
    display: 'flex',
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "1.5em"
  },
  center: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  handleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  handle: {
    width: "64px",
    height: "6px",
    borderRadius: "10px",
    backgroundColor: "#f4f4f4"
  },
  filterIndicator: {
    backgroundColor: "#005eff",
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    position: 'relative',
    top: '-5px',
    left: '5px'
  },
  backBtn: {
    fontSize: 14,
    fontFamily: 'Source Sans Pro',
    fontWeight: "normal",
    alignSelf: "center",
    marginLeft: 0,
    top: -2
  },
  backDiv: {
    color: 'white',
    border: "none",
    textAlign: "center",
    marginRight: "8px",
    transform: "scaleX(-1)",
    padding: "0px",
    width: "80px",
    transition: "margin 0.5s",
    cursor: "pointer",
    "& span": {
      cursor: "pointer",
      display: "inline-block",
      position: "relative",
      transition: "0.5s",
      color: "white",
      "&:after": {
        position: "absolute",
        opacity: 0,
        top: 0,
        right: "-15px",
        transition: "0.5s",
      },
    },
    "&:hover": {
      color: "#2f3e6c",
      "& span": {
        paddingRight: "8px",
        "&:hover": {
          opacity: 1,
          right: 0,
          color: "#2f3e6c"
        }
      }
    }
  },
  mainDiv: {
    display: "flex",
    flexDirection: "row-reverse",
    width: "100%"
  },
  changeImage: {
    position: "absolute",
    color: '#005eff',
    right: 0,
    top: "22px",
    transform: "scaleX(-1)",
    display: "flex",
    flexFlow: "row-reverse",
    width: "37px",
    zIndex: 101,
    alignItems: 'center'
  },
  legend: {
    bottom: "21px",
    position: "absolute",
    zIndex: 40,
    left: "20px",
    display: "flex",
    boxShadow: "0 3px 16px 0 rgb(0 0 0 / 26%)"
  },
  divider: {
    width: "2px",
    height: "16px",
    backgroundColor: "#edebeb",
    alignSelf: "center"
  },
  hide: {
    margin: "2px 15px 0",
    fontFamily: "SourceSansPro",
    fontSize: "13px",
    letterSpacing: "normal",
    color: "#005eff"
  },
  doubleRight: {
    marginTop: "23px",
    marginRight: "6px",
    cursor: "pointer"
  }
}));

export default useStyles;
