import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
const useStyles = makeStyles((theme?: Theme) =>
  createStyles({
    //Scrollbar styles
    renderTrackVertical: {
      height: "40%",
      top: "140px",
      right: "10px",
      position: "absolute",
      width: "4px !important",
      backgroundColor: "#edebeb",
      borderRadius: "3px",
      cursor: "pointer"
    },
    renderThumbVertical: {
      fill: "#005eff",
      rx: "3px"
    },
    renderTrackHorizontal: {
      height: "4px !important",
      top: "120px",
      // left: "50%",
      position: "absolute",
      width: "400px !important",
      backgroundColor: "#edebeb",
      borderRadius: "3px"
    },
    renderThumb: {
      backgroundColor: "#005eff",
      borderRadius: "3px"
    },
  }),
);

export default useStyles
