import React from 'react'

const DefaultCells = (props) => {
    return (
        <div>
            {props.row.original[props.column.value] || props.cell.value || "—"}
        </div>
    )
}

export default DefaultCells
