import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  // root: {
  //   display: "flex",
  //   flexWrap: "wrap",
  //   color: "rgba(0,0,0,0.8)",
  //   fontSize: "11px",
  //   backgroundColor: "rgb(255, 255, 255)",
  //   fontFamily:"Source Sans Pro, sans-serif",

  // },
  // formControl: {
  //   margin: "8px 0px 8px 8px",
  //   marginLeft: theme.spacing(0),
  //   width: "100%"
  // },
  // legendLabel: {
  //   fontFamily: "Source Sans Pro, sans-serif !important",
  //   fontSize: "14px !important",
  //   fontWeight: "bold",
  // }
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '41px',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#fff',
    border: "1px solid #005eff",
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
      border: "2px solid #005eff",
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#fff',
    width: "100%",
    height: "100%",
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      borderRadius: '41px',
      border: "2px solid #005eff",
      backgroundImage: 'radial-gradient(#2f3e6c,#2f3e6c 38%,transparent 52%)',
      content: '""',
    },
  },
}));

export default useStyles;
