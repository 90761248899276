import React from 'react'
import SnagImport from '../../containers/snagImport/SnagImport'

const SnagImportPage = () => {
  
  const snagImportURL = process.env.REACT_APP_APPSMITH_SNAG_IMPORT
  return (
    <div style={{width: '100%'}}>
        <SnagImport appURL={snagImportURL}/>
    </div>
  )
}

export default SnagImportPage