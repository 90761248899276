import React from "react"
import { Box, Grid, Avatar } from "@material-ui/core"
import moment from "moment";
import LoadingSpinner from "../loadingSkelaton/LoadingSpinner";
import { renderString } from "../brick/StructuresFullscreen";

// * used for both convetional and alu form for pour
function SummaryDetails({ classes, loading, data, totalDelay, phase }) {
  // if (loading) return <div>loading</div>
  // * remove the conditional login from here and put it in patent
  if (phase !== 'structures') return null
  return (
    loading ? <LoadingSpinner /> :
      <div>
        {/* Summary Heading */}
        <div id="summary" className={classes.Headers}>
          {"Summary"}
        </div>
        <Box display="flex" justifyContent="center">
          <Box className={classes.summaryDetails} display="flex" justifyContent="space-between">
            <Grid item xs={6} className={classes.summaryDetailsGrid}>

              <div className={classes.summaryDetailsList}>
                <span className={classes.summaryDetailsListBoldItems}>
                  PLANNED START DATE
                </span>

                <span className={classes.summaryDetailsListNormalItems}>
                  {!!data.plan && !!data.plan.to ? moment(data.plan.from).format('Do MMMM YYYY | MMM [W]') +
                    Math.ceil(moment(data.plan.from).date() / 7) : '—'
                  }
                </span>
              </div>
              <div className={classes.summaryDetailsList}>
                <span className={classes.summaryDetailsListBoldItems}>
                  PLANNED END DATE
                </span>

                <span className={classes.summaryDetailsListNormalItems}>
                  {!!data.plan && !!data.plan.to ? moment(data.plan.to).format('Do MMMM YYYY | MMM [W]') +
                    Math.ceil(moment(data.plan.to).date() / 7) : '—'
                  }
                </span>

              </div>
              {{
                completed: (
                  <div className={classes.summaryDetailsList}>
                    <span className={classes.summaryDetailsListBoldItems}>
                      PLANNED / ACTUAL SLAB COMPLETION TIME
                    </span>

                    <span className={classes.summaryDetailsListNormalItems}>
                      {data.plan && data.plan.diff ? data.plan.diff.toLocaleString('en', { minimumIntegerDigits: 2 }) + " Days" : "—"}
                      <span>{" / "}</span>
                      <span className={!!totalDelay ? classes.delay : ""}>
                        {data.actual && data.actual.diff ? data.actual.diff.toLocaleString('en', { minimumIntegerDigits: 2 }) + " Days" : " —"}
                      </span>
                    </span>

                  </div>
                ),
                started: (
                  <div className={classes.summaryDetailsList}>
                    <span className={classes.summaryDetailsListBoldItems}>
                      PLANNED SLAB COMPLETION TIME
                    </span>
                    <span className={classes.summaryDetailsListNormalItems}>
                      {data.plan && data.plan.diff ? data.plan.diff.toLocaleString('en', { minimumIntegerDigits: 2 }) + " Days" : "—"}
                    </span>

                  </div>
                ),
                notStarted: (
                  <div className={classes.summaryDetailsList}>
                    <span className={classes.summaryDetailsListBoldItems}>
                      PLANNED SLAB COMPLETION TIME
                    </span>

                    <span className={classes.summaryDetailsListNormalItems}>
                      {!!data.plan && !!data.plan.to ? moment(data.plan.to).diff(moment(data.plan.from), 'days') + 'Days' : '—'}
                    </span>

                  </div>
                ),
                dafault: (
                  <div className={classes.summaryDetailsList}>
                    <span className={classes.summaryDetailsListBoldItems}>
                      PLANNED
                    </span>

                    <span className={classes.summaryDetailsListNormalItems}>
                      !
                    </span>

                  </div>
                )
              }[data.status]}
              <div className={classes.summaryDetailsList}>
                <span className={classes.summaryDetailsListBoldItems}>
                  POUR TYPE
                </span>

                <span className={classes.summaryDetailsListNormalItems}>
                  {/* ! removed for now as we dont have correct data for manbhumF */}
                  {data?.pourType || '—'}
                </span>

              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.summaryDetailsList}>
                <span className={classes.summaryDetailsListBoldItems}>
                  ACTUAL START DATE
                </span>

                <span className={classes.summaryDetailsListNormalItems}>
                  {!!data.actual && !!data.actual.from ? moment(data.actual.from).format('Do MMMM YYYY | MMM [W]') +
                    Math.ceil(moment(data.actual.from).date() / 7) : '—'
                  }
                </span>

              </div>
              <div className={classes.summaryDetailsList}>
                <span className={classes.summaryDetailsListBoldItems}>
                  ACTUAL END DATE
                </span>

                <span className={classes.summaryDetailsListNormalItems}>
                  {!!data.actual && !!data.actual.to ? moment(data.actual.to).format('Do MMMM YYYY | MMM [W]') +
                    Math.ceil(moment(data.actual.to).date() / 7) : '—'
                  }
                </span>

              </div>
              <div className={classes.summaryDetailsList}>
                <span className={classes.summaryDetailsListBoldItems}>
                  STATUS
                </span>

                <span className={classes.summaryDetailsListNormalItems}>
                  < Box display="flex">
                    {
                      (data.status === "completed" || data.status === "started" || data.status === 'notStarted') &&
                      <Avatar className={data.status === "completed" ? classes.avtarCompleted : null ||
                        data.status === "started" ? classes.avtarOngoing : null ||
                          data.status === "notStarted" ? classes.avtarNotStarted : null
                      }></Avatar>
                    }
                    <span className={classes.summaryDetailsListNormalItems}>
                      {data.status}
                    </span>
                  </Box>
                </span>

              </div>
              <div className={classes.summaryDetailsList}>
                <span className={classes.summaryDetailsListBoldItems}>
                  PERCENTAGE COMPLETED
                </span>

                <span className={classes.summaryDetailsListNormalItems}>
                  {data.progress ? (((data.progress * 100) - Math.floor((data.progress * 100)) === 0) ? 
                   data.progress * 100 : (data.progress * 100).toFixed(2)) + '%' + ' [' + data.progressDetails.activities.display + ']' : '—'}
                </span>

              </div>
            </Grid>
          </Box>
        </Box>
      </div >
  )
}
export default React.memo(SummaryDetails)

