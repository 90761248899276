import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: "#2f3e6b",
    display: "flex",
    justifyContent: "center",
    // paddingTop: "24vh",
    alignItems: "center",
    height: "100%",
    minHeight: "100vh"
  },
  base: {
    justifyContent: "center",
    padding: "101px 150px",
    width: "600px!important",
    height: "500px",
    borderRadius: "2px",
    backgroundColor: "#ffffff",
    fontFamily: "Source Sans Pro, sans-serif !important",
  },
  baseSm: {
    justifyContent: "center",
    padding: "27px 64px",
    width: "358px!important",
    height: "314px",
    borderRadius: "2px",
    backgroundColor: "#ffffff",
    fontFamily: "Source Sans Pro, sans-serif !important",
  },
  formGroup: {
    display: "contents"
  },
  inputField: {
    "&:before": {
      borderBottom: "1px solid #005eff !important"
    },
    "&:after": {
      borderBottom: "1px solid #005eff !important"
    },
  },
  button: {
    fontSize: "12px",
    fontWeight: "normal",
    fontStyle: "normal",
    backgroundColor: "#005eff",
    color: "#ffffff",
    width: "300px",
    height: "40px",
    "&:hover": {
      backgroundColor: "#005eff"
    }
  },
  buttonSm: {
    fontSize: "12px",
    fontWeight: "normal",
    fontStyle: "normal",
    backgroundColor: "#005eff",
    color: "#ffffff",
    width: "230px",
    height: "40px",
    "&:hover": {
      backgroundColor: "#005eff"
    }
  },
  applyDisabled: {
    fontSize: "12px",
    fontWeight: "normal",
    fontStyle: "normal",
    backgroundColor: "#eaeaea",
    color: "#585858",
    width: "300px",
    height: "40px",
  },
  applyDisabledSm: {
    fontSize: "12px",
    fontWeight: "normal",
    fontStyle: "normal",
    backgroundColor: "#eaeaea",
    color: "#585858",
    width: "230px",
    height: "40px",
  },
  alert: {
    color: "#f44336",
    fontSize: "0.7rem"
  },
  invalidForm: {
    width: "100%",
    // height: "35px",
    textAlign: "center",
    backgroundColor: "#ff7777",
    border: "1px solid #ff3535",
    fontSize: "11px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: " normal",
    lineHeight: 1.27,
    letterSpacing: "normal",
    color: "#ffffff",
    padding: "4px",
    marginTop: "20px"
  },
  inputLabel: {
    fontWeight: "bolder",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.27,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#585858",
    textTransform: "uppercase"

  },
  // inputLabel: {
  //   textOverflow: 'ellipsis',
  //   whiteSpace: 'nowrap',
  //   overflow: 'hidden',
  //   width: '100%',
  //   color: 'red'
  // },

  input: {
    '&::placeholder': {
      fontSize: 11
    }
  },
  snackBarDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "19px"
  },
  RedirectToLoginPage: {
    fontSize: "14px",
    fontFamily: "Source Sans Pro",
    textTransform: "capitalize",
    color: "rgb(0, 94, 255)",
    cursor: "pointer"
  }
}));

export default useStyles;
