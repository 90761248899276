import React, { memo, useState, Suspense, FC, MouseEvent, MemoExoticComponent, useEffect, FunctionComponent, lazy, useCallback } from 'react';
import { useObserver, useLocalStore } from 'mobx-react-lite';
import { getRoot, Instance } from 'mobx-state-tree';
import { reaction } from 'mobx';
import { Brick, IBrick } from '../../models/Brick/Brick';
import useStyles from './BrickStyles';
import { Tooltip, withStyles, Zoom } from '@material-ui/core';
// import moment from 'moment';
import HoverStateComponent from './HoverStateComponent';
import { StoreModel } from '../../models/DataStore';
import { useStore } from '../../models/ProvideModel';
import { getBrickParam } from '../../models/enums';


const Fallback = memo(() => (<text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle"></text>));

export interface ISpecificBrickProps {
  brick: Instance<typeof Brick>;
};
const __renderMap = {
  StructuresLanding: lazy(() => import('./StructuresBrick')),
  StructuresFullscreen: lazy(() => import('./StructuresFullscreenBrick')),
  // FinishingFullscreen: lazy(() => import('./FinishingFullscreen')) // TODO: changed for demo only. 20, AUG - 2021
  FinishingFullscreen: lazy(() => import('./FinishingBrick'))
};

const renderMap = new Proxy(__renderMap, {
  get: function (obj, prop) {
    const ret = Reflect.get(obj, prop);
    if (ret === undefined) {
      switch (prop) {
        case 'FinishingLanding':
        case 'HandoverLanding':
        case 'FinishingLandingTca':
        case 'HandoverLandingTca':
        case 'StructuresLandingTca':
        case 'StructuresFullscreenTca':
        case 'FinishingFullscreenTca':
        case 'HandoverFullscreenTca':
        case 'FinishingLandingBasement':
        case 'HandoverLandingBasement':
        case 'StructuresLandingBasement':
        case 'StructuresFullscreenBasement':
        case 'FinishingFullscreenBasement':
        case 'HandoverFullscreenBasement':
        case 'FinishingLandingEca':
        case 'HandoverLandingEca':
        case 'StructuresLandingEca':
        case 'StructuresFullscreenEca':
        case 'FinishingFullscreenEca':
        case 'HandoverFullscreenEca': return Reflect.get(obj, 'FinishingFullscreen');
        default: return memo((props: any) => (<text x={0.1 * props.brick.width} y={props.brick.height / 2}>{JSON.stringify(props.brick.progressView)}</text>));
      }
    }
    return ret;
  }
});


function renderBorderColor(brick: ISpecificBrickProps['brick']) {
  switch (brick.status) {
    case 'notStarted': return '#d1d1d1';
    case 'started': return (brick.screen === "landing" && brick.phase === "structures") ? "#ffd300" :
      (brick.activityLastUpdated) ? "#006cff" : '#ffd300';
    case 'completed': return (brick.activityLastUpdated) ? "#006cff" : '#34d1ae';
    default: return 'null';
  };
}

const CustomizedTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#fff',
    minHeight: "120px",
    width: "auto",
    border: 'none',
  },
}))(Tooltip);

export interface IBrickCompProps {
  isScrolling: Boolean;
  style: { top: number; left: number; width: number; height: number; };
  brick: Instance<IBrick>;
};

export interface IBrickProps {
  filter?: any;
  strength?: any;
  status?: String;
  width?: number;
  progressAsPercent?: any
}

const BrickComp: MemoExoticComponent<FC<IBrickCompProps>> = memo(({ brick, ...props }: IBrickCompProps) => {
  const [open, setOpen] = useState(false);
  const store = useStore();
  const [Component, setComponent] = useState<FunctionComponent<ISpecificBrickProps>>(() => renderMap[getBrickParam(store.params)]);

  const { isLg } = store.responsiveUtils.currentViewport;

  const styleProps = useLocalStore(source => ({
    get filter() { return source.brick.filtered; },
    get strength() { return source.brick['strength'] || 1; }
  }), { brick });
  useEffect(() => reaction(() => ({ phase: brick.phase, screen: brick.screen, p: store.params.phase, s: store.params.screen, st: store.params.spaceType }), () => { setComponent(renderMap[getBrickParam(store.params)]); }), [brick.phase, brick.screen]);
  const clickHandler = useCallback((_: MouseEvent) => { brick && (getRoot(brick) as Instance<typeof StoreModel>).summary.openSummary(brick.id, brick.phase) }, [brick]);
  const classes = useStyles({ filter: styleProps.filter, strength: styleProps.strength });

  return useObserver(() => brick && (
    props.isScrolling ?
      (<svg
        className={classes.brickOpacity}
        style={{
          ...props.style,
          top: props.style.top + brick.padding.y,
          left: props.style.left + brick.padding.x,
          height: props.style.height - brick.padding.y,
          width: props.style.width - brick.padding.x,
          cursor: 'pointer',
        }}
      >
        <rect
          x={0}
          y={0}
          width={brick.width}
          height={brick.height}
          fill="whitesmoke"
        />
        <text x={brick.width / 2 - 10} y={brick.height / 2 + 2}>
          ...
        </text>
      </svg>
      )
      :
      (
        <CustomizedTooltip
          title={
            <HoverStateComponent brick={brick} />
          }
          TransitionComponent={Zoom}
          placement="right"
          onOpen={(_) => (((brick.screen === "fullscreen" && brick.phase === "structures") || !isLg)) ? setOpen(false) : setOpen(true)}
          onClose={(_) => setOpen(false)}
          open={open}
          disableHoverListener={!styleProps.filter ? true : false}
        >
          <div
            className={classes.brickOpacity}
            onClick={!styleProps.filter ? () => { } : clickHandler}
            style={{
              ...props.style,
              top: props.style.top + brick.padding.y,
              left: props.style.left + brick.padding.x,
              height: props.style.height - brick.padding.y,
              width: props.style.width - brick.padding.x,
              cursor: 'pointer',
            }}
          >
            <Suspense fallback={<Fallback />}>
              <Component
                brick={brick}
              />
            </Suspense>
          </div>
        </CustomizedTooltip>)

  ));
});

export default BrickComp;
