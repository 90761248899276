import { makeStyles, Theme } from "@material-ui/core/styles";

const reportStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: "12px 0 12px 15px"
  },
  downloadButton: {
    display: 'flex',
    padding: "8px",
    minWidth: "unset"
  },
  exportData: {
    display: "flex",
    flexGrow: 0,
    fontSize: "17px",
    fontWeight: "bold"
  },
  exportedTable: {
    background: "rgb(244 244 244)",
    width: "94%",
    display: "flex",
    // border: "2px solid #80808082",
    padding: "12px",
    flexDirection: "column",
    maxHeight: "300px",
    // overflow: "scroll",
    minHeight: "10px",
    position: "sticky"
  },
  refreshClearDiv: {
    justifyContent: "space-between",
    width: "78%",
    display: "flex",
    flexDirection: "row"
    // marginLeft: "13%"
  },
  refreshClearButtons: {
    cursor: "pointer",
    color: "#005EFF",
    fontSize: "12px",
    fontFamily: "Source Sans Pro, sans-serif !important",
    fontWeight: 600,
    lineHeight: 1.3,
    fontStretch: "normal",
    textTransform: "capitalize",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      borderBottom: "1px solid #005EFF"
    }
  },
  pdfDiv: {
    display: "flex",
    alignItems: "center"
  },
  statusDiv: {
    width: "87px",
    display: "flex",
    alignItems: "center"
  }
}));
export default reportStyles;
