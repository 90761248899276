import { makeStyles, Theme } from "@material-ui/core/styles";
import indeterminateIcon from "../../assets/images/minus-white-16-16.svg";
import tickIcon from "../../assets/images/Tick-White.svg";

const dark_grey_blue = "#2f3e6c";
const useStyles = makeStyles<any, { [K: string]: any; }>((theme: Theme) => ({
  root: {
    display: "flex !important",
    flexWrap: "wrap",
    color: "rgba(0,0,0,0.8) !important",
    paddingBottom: "2px !important",
    paddingTop: "10px !important"
  },
  listItem: {
    width: "149px !important",
    display: "flex !important",
    // flexDirection: "row-reverse",
    justifyContent: "space-between !important",
    marginRight: "0px !important",
    marginLeft: "0px !important",
    fontSize: "14px !important",
    fontFamily: "Source Sans Pro, sans-serif !important"
  },
  icon: (props) => {
    return {
      backgroundColor: props.selectAll ? "#80808091" : "unset",
      borderRadius: "1px !important",
      border: "1px solid #005eff !important",
      width: 16,
      height: 16
    }
  },
  checkedIcon: (props) => {
    return {
      backgroundColor: props.selectAll ? "#80808091" : "#005eff",
      borderRadius: "1px !important",
      "&:before": {
        display: "block !important",
        width: 24,
        height: 24,
        backgroundImage:
          `url(${tickIcon})`,
        marginLeft: "-4.7px !important",
        marginTop: "-5px !important",
        content: '""',
      }
    }
  },
  indeterminateCheckedIcon: (props) => {
    return {
      backgroundColor: props.selectAll ? "#80808091" : "#005eff",
      borderRadius: "1px !important",
      "&:before": {
        display: "block !important",
        width: 16,
        height: 16,
        backgroundImage: `url(${indeterminateIcon})`,
        marginLeft: "-1px !important",
        marginTop: "-1px !important",
        content: '""',
      }
    }
  },
  checkboxUi: {
    height: "49px",
    display: "flex",
    borderBottom: "1px solid #005EFF",
    flexDirection: "column",
    justifyContent: "end"
  },
  toggleButton: {
    color: "rgba(0, 0, 0, 0) !important",
    border: "0px solid rgba(0, 0, 0, 0) !important",
    height: "auto !important",
    padding: "0px !important",
  },
  formControl: {
    marginLeft: theme.spacing(0),
    width: "100%  !important",
  },
  menuItems: ({ checked, selectAll }: { checked?: boolean; selectAll?: boolean }) => {

    return ({
      display: "flex !important",
      fontSize: "14px !important",
      color: dark_grey_blue,
      fontWeight: checked ? "bold" : "normal",
      width: "100%",
      minHeight: "0px  !important",
      "&:hover": {
        fontWeight: "bold",
        color: "#005eff",
      },
      fontFamily: "Source Sans Pro, sans-serif !important",
      "&:first-child": {
        marginTop: "8px"
      },
      "&:last-child": {
        marginBottom: "8px"
      }
    })
  },
  base: {
    width: "192px !important",
    height: "210px !important",
    boxShadow: "0 3px 8px 0 rgba(0, 0, 0, 0.14) !important",
    backgroundColor: "#ffffff !important"
  },

  selectStatus: {
    fontSize: "11px !important",
    color: "#585858 !important",
    minHeight: "0px !important",
    // paddingLeft:"24px !important",
    // padding:"0px 16px !important", 
    // position:"sticky",
    opacity: 0.5,
    // top:0,
    backgroundColor: "#fff"
  },
  byTower: {
    fontFamily: "Source Sans Pro, sans-serif !important",
    fontSize: "11px !important",
    fontWeight: "bold",
    fontStyle: "normal !important",
    fontStretch: "normal !important",
    lineHeight: 1.27,
    letterSpacing: "normal !important",
    textAlign: "left",
    color: "#585858 !important",
    display: "flex !important",
    flexDirection: "column"
  },
  selectByStatus: {
    // top: "16px",
    display: "inline-block !important",
    position: "absolute",
    left: 0,
    height: "32px !important",
    width: "32px !important",
    border: 0,
    cursor: "pointer !important",
    margin: 0,
    opacity: 0,
    zIndex: 5,
    background: "#fff !important",
    paddingRight: "0px !important"
  },
  filterButton: {
    // marginTop: "16px"
  },
  towerNotSelected: {
    fontFamily: "Source Sans Pro, sans-serif !important",
    fontSize: "14px !important",
    fontWeight: "normal",
    fontStyle: "normal !important",
    fontStretch: "normal !important",
    lineHeight: 1.27,
    letterSpacing: "normal !important",
    textAlign: "left",
    color: "#585858 !important",
  },
  towerRightdiv: {
    display: "flex !important",
  },
  towerText: {
    fontFamily: "Source Sans Pro, sans-serif !important",
    marginLeft: "16px !important",
    alignSelf: "center !important",
  },
  selectedTower: {
    fontFamily: "Source Sans Pro, sans-serif !important",
    fontSize: "11px !important",
    fontWeight: "normal",
    fontStyle: "normal !important",
    fontStretch: "normal !important",
    lineHeight: 1.27,
    letterSpacing: "normal !important",
    textAlign: "left",
    color: "#585858 !important",
  },
  applyDiv: {
    display: "flex",
    flexDirection: "column",
    bottom: 0,
    position: "sticky",
    marginTop: 8
  },
  applyTableAccordian: {
    fontSize: "12px",
    fontWeight: "normal",
    fontStyle: "normal",
    backgroundColor: "#005eff",
    color: "#faf9f9",
    borderRadius: "2px",
    width: "100%",
    height: "28px",
    textTransform: "capitalize",
    padding: "0px",
    "&:hover": {
      backgroundColor: "#1a6fff",
      color: "#faf9f9"
    },
    "&:disabled": {
      backgroundColor: "#d1d1d1",
      color: "#808080"
    },
  },
  applyAccordian: {
    fontSize: "12px",
    fontWeight: "normal",
    fontStyle: "normal",
    backgroundColor: "#005eff",
    color: "#faf9f9",
    borderRadius: "2px",
    width: "124px",
    height: "28px",
    textTransform: "capitalize",
    padding: "0px",
    "&:hover": {
      backgroundColor: "#1a6fff",
      color: "#faf9f9"
    },
    "&:disabled": {
      backgroundColor: "#d1d1d1",
      color: "#808080"
    },
  },
  clear: {
    fontSize: "12px",
    fontWeight: "normal",
    fontStyle: "normal",
    backgroundColor: "#fff",
    color: "#005eff",
    borderRadius: "2px",
    width: "81px",
    height: "28px",
    textTransform: "capitalize",
    padding: "0px",
    marginLeft: "12px",
    "&:hover": {
      backgroundColor: "#ededed",
    },
    "&:disabled": {
      backgroundColor: "#f0f0f0",
      color: "#808080"
    }
  },

  normText11px: {
    display: "flex",
    fontFamily: "Source Sans Pro, sans-serif !important",
    fontSize: "11px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.27,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#808080",
    alignItems: "center",
  },
  stagePadding: {
    paddingLeft: "3px !important"
  },
  customWidth: {
    width: 200
  },
  resetFilter: {
    backgroundImage: "none",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 16px",
    /* position: isLg ? "relative" : "sticky", */
    position: "sticky",
    top: 0,
    backgroundColor: "#fff",
    zIndex: 999999
  },
  stickyHeader: {
    padding: "0 12px 16px 12px",
    width: "100%",
    position: "sticky",
    top: 0,
    zIndex: 99999,
    backgroundColor: "#faf9f9"
  },
  selectedState: {
    fontWeight: "bold",
    color: dark_grey_blue
  },
  selectedCount: {
    height: "18px",
    marginRight: "4px",
    fontFamily: "Source Sans Pro, sans-serif !important",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.29,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#585858",
  },
  apply: {
    fontSize: "12px !important",
    fontWeight: "normal",
    fontStyle: "normal !important",
    backgroundColor: "#005eff !important",
    color: "rgb(255, 255, 255) !important",
    borderTopLeftRadius: "0px !important",
    borderTopRightRadius: "0px !important",
    height: "40px !important",
    textTransform: "capitalize"
  },
  applyDisabled: {
    fontSize: "12px !important",
    fontWeight: "normal",
    fontStyle: "normal !important",
    backgroundColor: "#ffffff !important",
    borderTopLeftRadius: "0px !important",
    borderTopRightRadius: "0px !important",
    height: "40px !important",
    borderTop: "1px solid #d1d1d1 !important",
    textTransform: "capitalize"
  },
  applyAccDiv: {
    /* bottom: 0,
     * position: "sticky",
     * marginTop: 8, */
    padding: "8px 12px",
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#f4f4f4",
    zIndex: 1
  },
  link: ({ isAccordian }: { isAccordian: boolean; }) => ({
    color: "#005eff",
    cursor: "pointer",
    backgroundImage: "none",
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "17px",
    marginTop: "5px",
    position: isAccordian ? "relative" : "fixed",
    backgroundColor: isAccordian ? "inherit" : "#FFF",
    boxShadow: isAccordian ? "none" : "0 3px 8px 0 rgba(0, 0, 0, 0.14) !important",
    borderRadius: isAccordian ? 0 : 5,
    padding: 2,
    zIndex: 999,
  }),
  CustomDatePicker: {
    marginLeft: "7px"
  },
  selectAllDummyDiv: ({ isLg }: { isLg: boolean; }) => ({
    display: "flex",
    flexGrow: 1,
    flexBasis: "auto",
    justifyContent: "flex-end",
    marginBottom: isLg ? 20 : 10
  }),
  optionsMenuContainer: {
    maxHeight: 372,
    /* minHeight: isLg ? "25%" : "25%", */
    minHeight: "25%",
    display: "flex",
    flexBasis: "auto",
    flexDirection: "column",
    justifyContent: "center",
  },
})
);

export default useStyles;
