const config = {
    SiteUrl: process.env.REACT_APP_SITE_URL,
    RestApiUrl: process.env.REACT_APP_REST_API_URL,
    ImageUrl: process.env.REACT_APP_API_URL,
    PortalV1: process.env.REACT_APP_PORTAL_V1_URL,
    LogRocketKey: process.env.REACT_APP_LOGROCKET_KEY,
    environment: process.env.REACT_APP_ENV,
    baseURL: process.env.REACT_APP_IMAGE_BASE_URL,
    TransactionServerUrl: process.env.REACT_APP_TRANSACTION_SERVER_URL,
    isWhatsappEnabled: process.env.REACT_APP_WHATSAPP_FLAG === "true",
    mixpanelToken: process.env.REACT_APP_MIXPANEL_TOKEN
};

module.exports = config;
