import { flow, getParent, getRoot, Instance, ReferenceIdentifier, resolveIdentifier, types } from 'mobx-state-tree';
import { FunctionComponent } from 'react';
import SummaryPopUp from '../components/popUp/summaryPopUp';
import { GET_POUR_SUMMARY, GET_UNIT_ACTIVITIES_BY_BLOCK, GET_UNIT_ACTIVITIES_BY_FLOOR, GET_UNIT_ACTIVITIES_BY_FLOOR_BY_UNIT_TYPE } from '../utils/queries';
// import { Brick, FinishingBrick, FinishingBrickRemote, PourBrick, PourBrickRemote, FinishingBrickLandingRemote } from './Brick';
import { Brick } from './Brick/Brick';
import { Pour } from './Brick/Pour';
import { Finishing } from './Brick/Finishing';
import { LateStoreModel } from './DataStore';
import { Tower } from './Tower';
import { groupBy, groupKeyBy, sortingWithPrecedence } from '../utils/utils';
import { GOT } from './enums';
// import { MomentBuiltinFormat } from 'moment';
// import { DateDiff } from './Common';

export interface IActivity {
    id: string;
    name: string;
    progress: Exclude<ICommonObj, "list"> & { snags: { open: number; closed: number; } };
};
export interface ICommonObj {
    done: number;
    total: number;
    list?: IActivity[];
};
export const progressDetailModel = types.model({
    units: types.maybeNull(types.frozen<ICommonObj>()),
    activities: types.maybeNull(types.frozen<ICommonObj>())
})
export interface IPerson {
    id: string;
    name: string;
    designation: string;
};
const Person = types.frozen<IPerson>();

export const MetaDataModel = types.model({
    people: types.array(Person)
})

export interface ISummaryComponent {
    open: boolean;
    pourType: string;
    unitId: string | number | undefined;
    phase: string | undefined;
}

const Popup = types.frozen<FunctionComponent<ISummaryComponent>>();


// * models fro unit Activity - finishing component

const snagModel = types.model({
    count_closed: types.maybeNull(types.number),
    count_for_review: types.maybeNull(types.number),
    count_open: types.maybeNull(types.number)
});

export const unitActivityDetailsModel = types.model({
    activity_type_id: types.maybeNull(types.string),
    block_id: types.maybeNull(types.safeReference(Tower)),
    floor_idx: types.maybeNull(types.number),
    floor_name: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    unit_id: types.maybeNull(types.string),
    current_step: types.maybeNull(types.string),
    current_user_designation: types.maybeNull(types.string),
    current_user_name: types.maybeNull(types.string),
    unit_activity_id: types.maybeNull(types.string),
    unit_name: types.maybeNull(types.string),
    activity_name: types.maybeNull(types.string),
    actualEnd: types.maybeNull(types.string),
    actualStart: types.maybeNull(types.string),
    planEnd: types.maybeNull(types.string),
    planStart: types.maybeNull(types.string),
    snag_counts: types.maybeNull(snagModel),
    lastUpdated: types.maybeNull(types.string),
    // plan: types.maybeNull(DateDiff),
    // actual: types.maybeNull(DateDiff),
}).views(self => ({
    get derivedStatus() {
        if (self.status === null) { return "plainWrong"; }
        else if (self.status === '8_done') { return "Completed"; }
        else if (self.status === '3_active') { return "On Going"; }
        else if (self.status === '1_planned') { return "Not Started"; }
        return "plainWrong"
    },
}));

export type IunitActivityDetails = typeof unitActivityDetailsModel

/*
 * Harsha : get Summary view creates a instance of SummaryPopup which is triggered on set of id from the summary component


 */

export const Summary = types.model({
    brick: types.safeReference(Brick),
    progressDetail: types.maybeNull(progressDetailModel),
    meta: types.maybeNull(MetaDataModel),
    loading: false,
    unitActivityDetails: types.maybeNull(
        types.array(unitActivityDetailsModel)
    )
})
    .volatile(_ => ({
        open: false
    }))
    .views(self => ({
        get startDelay() { return self.brick && self.brick.startDelay; },
        get endDelay() { return self.brick && self.brick.endDelay; },
        get totalDelay() {
            if (!self.brick) return false;
            const minimumCheck =
                self.brick &&
                self.brick.plan &&
                self.brick.actual &&
                !!self.brick.plan.diff;
            if (minimumCheck) {
                return self.brick.actual!.diff
                    ? self.brick.actual!.diff - self.brick.plan!.diff! > 0
                    : self.brick.actual!.diffTo &&
                    self.brick.actual!.diffTo - self.brick.plan!.diff!;
            }
            return false;
        },
        get statusCount() {
            if (!!self.unitActivityDetails) {
                const status_count: Array<string>[] = groupKeyBy(self.unitActivityDetails, 'status');
                const status = {
                    started: !!status_count && (status_count['8_done'] || 0) && (status_count['8_done'].length.toLocaleString('en-GB', { minimumIntegerDigits: 2 })),
                    onGoing: !!status_count && (status_count['3_active'] || 0) && (status_count['3_active'].length.toLocaleString('en-GB', { minimumIntegerDigits: 2 })),
                    notStarted: !!status_count && (status_count['1_planned'] || 0) && (status_count['1_planned'].length.toLocaleString('en-GB', { minimumIntegerDigits: 2 })),
                }
                return status
            }
            return null
        },
        get towerDetails() {
            if (!self.brick) return false
            const page: any = getRoot(self) as Instance<typeof LateStoreModel>;
            function CallbackFunctionToFindTowerById(towerName) {
                if (towerName.id === self?.brick?.tower) {
                    return towerName.name
                }
            }
            var towerName = page.towers.find(CallbackFunctionToFindTowerById);
            const tDetails = {
                towerName: towerName.name,
                floor: ((self.brick?.yVal?.id || 0) > 0) ? 'Floor ' + self.brick.yVal?.name : 'All Floors',
                activity: self.brick.xVal.name ? self.brick.xVal.name : self.brick.yVal?.id
            }
            return tDetails;
        }
    }))
    .views(self => ({
        get props(): ISummaryComponent {
            return {
                open: self.open || false,
                pourType: self.brick && self.brick["pourType"],
                unitId: (self.brick && self.brick.id) || undefined,
                phase: self.brick && self.brick.phase
            };
        },
        get Summary(): FunctionComponent<ISummaryComponent> {
            return Popup.create(SummaryPopUp);
        },
        get conventionalData(): any {
            if (self.brick && self.brick["pourType"] === "conventional") {
                return groupBy(self.progressDetail?.activities?.list, (list) => list["unit-member"].title);
            }
            else return []
        },
        get aluformData(): any {
            if (self.brick && self.brick["pourType"] === "aluminium") {
                const data = [...(self.progressDetail?.activities?.list || [])];
                return data?.sort(sortingWithPrecedence);
            }
            else return []
        },
        get people(): any {
            let tempPeopleArray: any = []
            groupBy(self.progressDetail?.activities?.list, (list) => list.people.map((p) => tempPeopleArray.push(p)))
            return tempPeopleArray
                .map(e => e.id)
                .map((e, i, final) => final.indexOf(e) === i && i)
                .filter(obj => tempPeopleArray[obj])
                .map(e => tempPeopleArray[e])
                .sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        },
        get towerData() {
            if (!self.brick) return false
            // const  id = (getRoot(self) as Instance<typeof LateStoreModel>).towers.find(t => t.id === self.brick?.tower)?.id;
            const id = self.brick?.tower;
            const tDetails = {
                towerId: id,
                floor: self.brick.yVal?.id
            }
            return tDetails;
        }
    }))
    .actions(self => ({
        getStructureSummary: flow(function* populate() {
            if (self.brick && !self.loading) {
                try {
                    const data: any = yield (getRoot(self) as Instance<typeof LateStoreModel>).fetch(GET_POUR_SUMMARY, {
                        variables: { unit_id: self.brick.id }
                    });
                    self.meta = data.pours[0].meta;
                    self.progressDetail = data.pours[0]!.progress_details;
                } catch (err) {
                    console.error(err.message);
                } finally {
                    self.loading = false;
                }
            }
        }),
        getFinishingSummaryLanding: flow(function* populate() {
            if (self.brick && !self.loading) {
                // conforming to new models
                // const blockInfo = getParent(self.brick, 4) as Instance<
                //   typeof LateStoreModel
                // >;
                const blockInfo = self.brick.yVal;

                self.unitActivityDetails = null;
                try {
                    const data: any = yield (getRoot(self) as Instance<typeof LateStoreModel>).fetch(GET_UNIT_ACTIVITIES_BY_BLOCK, {
                        variables: {
                            block_id: blockInfo.id,
                            activity_type_id: self.brick.xVal.id
                        }
                    });
                    // * Temp Fix
                    self.progressDetail = null;
                    self.meta = null;
                    self.unitActivityDetails = data.units;
                    self.loading = false;
                } catch (err) {
                    console.error(err.message);
                }
            }
        }),
        getFinishingSummaryFullScreen: flow(function* populate() {
            if (self.brick && !self.loading) {
                const blockInfo = getParent(self.brick, 4) as Instance<typeof LateStoreModel>;

                self.unitActivityDetails = null;
                try {
                    const data: any = yield (getRoot(self) as Instance<typeof LateStoreModel>).fetch(GET_UNIT_ACTIVITIES_BY_FLOOR, {
                        variables: {
                            block_id: self.brick.tower,
                            activity_type_id: self.brick.xVal.id,
                            floor_idx: { floor_idx: { _eq: self.brick.yVal?.id } },
                            phase: self.brick.phase,
                            spaceType: self.brick.spaceType
                        }
                    });
                    // * Temp Fix
                    self.progressDetail = null;
                    self.meta = null;
                    self.unitActivityDetails = data.units;
                    self.loading = false;
                } catch (err) {
                    console.error(err.message);
                }
            }
        }),
        getFinishingBarathonSummaryFullScreen: flow(function* populate() {
            if (self.brick && !self.loading) {
                self.unitActivityDetails = null;
                try {
                    // Please god don't let unit_type play any role in this!
                    const data: any = yield (getRoot(self) as Instance<typeof LateStoreModel>).fetch(GET_UNIT_ACTIVITIES_BY_FLOOR, {
                        variables: {
                            block_id: self.brick.tower,
                            activity_type_id: self.brick.xVal?.id,
                            phase: self.brick.phase,
                            spaceType: self.brick.spaceType,
                            floor_idx: {}
                        }
                    });
                    // * Temp Fix
                    self.progressDetail = null;
                    self.meta = null;
                    self.unitActivityDetails = data.units;
                    self.loading = false;
                } catch (err) {
                    console.error(err.message);
                }
            }
        }),
        getFinishingSummaryTcaLanding: flow(function* populateTcaLP(graphType?: GOT) {
            if (self.brick && !self.loading) {
                self.unitActivityDetails = null;
                try {
                    const data: any = yield (getRoot(self) as Instance<typeof LateStoreModel>).fetch(GET_UNIT_ACTIVITIES_BY_FLOOR_BY_UNIT_TYPE, {
                        variables: graphType === GOT.BARATHEON ?
                            {
                                block_id: self.brick.tower,
                                unit_type_id: self.brick.yVal.id,
                                floor_idx: {},
                                phase: self.brick.phase,
                                spaceType: self.brick.spaceType
                            }
                            :
                            {
                                block_id: self.brick.tower,
                                unit_type_id: self.brick.xVal.id,
                                floor_idx: { floor_idx: { _eq: self.brick.yVal.id } },
                                phase: self.brick.phase,
                                spaceType: self.brick.spaceType
                            }
                    });
                    // * Temp Fix
                    self.progressDetail = null;
                    self.meta = null;
                    self.unitActivityDetails = data.units;
                    self.loading = false;
                } catch (err) {
                    console.error(err.message);
                }
            }
        }),
    }))
    .actions(self => ({
        setUnit(id: ReferenceIdentifier, phase) {
            if (self.brick && self.brick.id === id) { return; }
            const page: any = getRoot(self) as Instance<typeof LateStoreModel>;
            if (phase === "structures" && page?.params.spaceType === "tower") {
                self.brick =
                    resolveIdentifier(Pour, getRoot(self), id);
                self.getStructureSummary();
            } else {
                self.brick = resolveIdentifier(Finishing, getRoot(self), id);
                if (page?.params.spaceType === "tca" || page?.params.spaceType === "eca" || page?.params.spaceType === "basement") {
                    if (page?.params.screen === "landing") {
                        self.getFinishingSummaryTcaLanding(page.currentDashboard?.tab?.graphType);
                    }
                    else if (page?.params.screen === "fullscreen") {
                        if (page?.currentDashboard?.tab?.graphType === GOT.BARATHEON) {
                            self.getFinishingBarathonSummaryFullScreen();
                        }
                        else { self.getFinishingSummaryFullScreen(); }
                    }
                }
                else if (phase === "finishing" && page?.params.spaceType === "tower") {
                    if (page?.params.screen === "landing") { self.getFinishingSummaryLanding(); }
                    else if (page?.params.screen === "fullscreen") { self.getFinishingSummaryFullScreen(); }
                }
            }
        },
        setOpen(val: boolean) {
            if (val !== self.open) {
                self.open = val;
            }
        }
    }))
    .actions(self => ({
        openSummary(id?: ReferenceIdentifier, phase?) {
            if ((id && !self.brick) || (id && self.brick && self.brick.id !== id)) {
                self.setUnit(id, phase);
            }
            self.setOpen(true);
        }
    }))
// * preprocress snapshot of status

export type ISummary = typeof Summary.Type;
