import { makeStyles, Theme } from "@material-ui/core";
import { amber, green } from "@material-ui/core/colors";

const useStyles = makeStyles((theme: Theme) => ({

    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: '#f44336',
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
}));

export default useStyles