import React from 'react'
import useStyles from "./Common";
import rotateScreenicon from "../../assets/images/rotatescreen.svg";
import whiteLogoIcon from "../../assets/images/projectSelection/fullLogoWhit.svg";

export default function ScreenRotation() {
    const classes = useStyles();
    return (
        <div className={classes.screenRoot}>
            <div>
                <img src={rotateScreenicon} alt="rotate" />
            </div>
            <div style={{ width: 175 }}>
                <img src={whiteLogoIcon} width={135} height={20} alt="logo" />

            </div>
            <div style={{ width: 175 }}>
                <p>
                    Reports are best viewed in
                    Landscape mode.
                </p>
                <p>
                    Please rotate your device.
                </p>
            </div>

        </div>
    )
}
