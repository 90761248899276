import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#ffffff",
    flexDirection: 'column',
    width: '80%',
    display: "flex",
    flexGrow: 1,
    flexBasis: "auto",
    flexShrink: 1,
  },
  landing: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    flex: '1 1 auto',
    // marginTop:"40px"
  },
  lPanel: {
    flex: "0 1 auto",
    marginBottom:"30px"
  },
  towerTitle: {
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'Source Sans Pro',
    fontSize: '20px',
    fontWeight: 700,
    color: '#005eff',
  },
  backBtn: {
    fontSize: 14,
    fontFamily: 'Source Sans Pro',
    color: '#005eff',
    fontWeight: "normal",
    alignSelf: "center",
    marginLeft:5
  },
  backDiv: {
    cursor: "pointer",
    display: "inline-block",
    textDecoration: " none",
    /* Set up the hover */
    /* If you aren't using autoprefix, remember to prefix the gradient for other browsers */
    backgroundImage: "linear-gradient(#005eff, #005eff)",
    backgroundSize: "0 3px, auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center bottom",
    transition: "all .2s ease-out",
    "&:hover": {
      borderBottom: "0px solid #005eff",
      backgroundSize: "100% 2px, auto",
    },
  }
}));

export default useStyles;
