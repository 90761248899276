import React, { memo, useMemo } from 'react'
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import { useLocation } from "react-router";
import useStyles from './FilterStyles';
export const SelectedState = (props) => {
    const location = useLocation();
  const classes = useStyles({});
    const { search } = useMemo(() => location, [
        location.search,
        location.pathname,
    ]);
    const searchParam = useMemo(() => new URLSearchParams(search), [search]);
    let filteredValue : string[]
    
    if (props.filterType?.toLowerCase() === 'delta') {
        filteredValue = searchParam.getAll('from');
        filteredValue = searchParam.getAll('to');
    } else {
        filteredValue = searchParam.getAll(props.filterType?.toLowerCase());
    }

    const checkedStatus = useMemo(() => {
        if (filteredValue.length > 0) {
            return <CheckOutlinedIcon style={{fill:"#005eff", marginRight:"12px", height:16, width:16 }}/>
            // <span className={classes.selectedCount}>{"+"+filteredValue.length}</span>
        }
    }, [props.state, location]);
    
    return(
        <div style={{display:"flex", alignItems:"center"}}>
           {checkedStatus} <div className={(filteredValue.length > 0) ? classes.selectedState : ""}>{props.title}</div>
        </div>
    )
}

export default memo(SelectedState);