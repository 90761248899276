import { makeStyles } from "@material-ui/core/styles";
import { ProgressBarProps } from "../progressBar/ProgressBar";

const useStyles = makeStyles((props: ProgressBarProps) => {
  return {
    handleContainer: (props: { isLg: boolean }) => ({
      width: props.isLg ? "425px" : "244px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      minHeight: props.isLg ? "266px" : "76vh",
      maxHeight: props.isLg ? "360px" : "77vh"
    }),
    handleSecondContainer: (props: { isLg: boolean }) => ({
      width: "100%",
      overflow: "auto",
      marginBottom: "10px",
      minHeight: props.isLg ? "216px" : "unset",
      maxHeight: props.isLg ? "300px" : "unset"
    }),
    titlediv: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      height: "59px",
      flexDirection: "column"
    },
    secondTitlediv: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "space-between"
    },
    horizantalDiv: {
      width: "100%",
      height: "1px",
      background: "black"
    },
    workStatusdiv: {
      fontSize: "26px",
      fontWeight: 500,
      color: "#242222"
    },
    fieldData: {
      display: "flex",
      flexDirection: "column",
      marginBottom: "12px"
    },
    UnitName: (props: { isLg: boolean }) => ({
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column"
    }),
    ActivityName: (props: { isLg: boolean }) => ({
      display: "flex",
      // justifyContent: "center",
      marginLeft: "5px",
      alignItems: "center",
      fontSize: props.isLg ? "16px" : "12px",
      color: "#0f0e0e",
      textTransform: "none"
    }),
    closeDiv: {
      display: "flex",
      flexDirection: "column",
      height: "35px",
      justifyContent: "space-between",
      width: "100%",
      alignItems: "center"
    },
    close: {
      display: "flex",
      // fontFamily: "SourceSansPro",
      fontSize: "20px",
      color: "#005eff",
      textAlign: "center",
      flexDirection: "column",
      justifyContent: "center",
      cursor: "pointer",
      width: "60px",
      border: "2px solid rgb(0 94 255 / 53%)",
      height: "60px"
    },
    image: {
      height: 64,
      width: 64,
      marginBottom: "5px",
      marginTop: "2px",
      cursor: "pointer",
      "&:hover": {
        height: 72,
        width: 72,
        marginLeft: "0px",
      }
    },
    brokenImage: {
      height: 64,
      width: 64,
      marginBottom: "5px",
      marginTop: "2px"
    },
    imgClass: {
      maxHeight: 100,
      maxWidth: 100,
      marginBottom: "10px",
      cursor: "pointer",
      "&:hover": {
        maxHeight: 110,
        maxWidth: 110,
      }
    },
    editFormLink: {
      color: "#005EFF",
      marginRight: "1rem",
      textDecoration: "none",
      "&:hover": {
        color: "#0040ff"
      }
    }

  }
});

export default useStyles;
