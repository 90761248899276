import React, { memo, useMemo, useCallback, useEffect, CSSProperties } from "react";
import { useHistory, useLocation } from 'react-router';
import useStyles from "./ProgressOverStyle";
import { putAsync } from "csp-with-ts";
import { useStore } from '../models/ProvideModel';
// import { Divider, Dialog, DialogContent, DialogActions, Button, InputLabel, Input } from "@material-ui/core";
// import Calendar from "./Calendar";
import { extendMoment, MomentRange } from "moment-range";
import * as originalMoment from 'moment';
import useAccordion from "./filter/UseAccordionHook";
import { DefaultList } from "./filter/DefaultAccordianRender";
// import Checkbox from "./filter/Checkbox";
import RadioButton from './filter/RadioButton';
import { SelectedState } from "./filter/SelectedState";
type IMoment = typeof originalMoment;
type Moment = originalMoment.Moment;
const moment: MomentRange & IMoment = extendMoment(originalMoment);

const MenuProps = {
  PaperProps: {
    style: {
      width: 208,
    },
  },
};
const progressOverArray = [
  { name: "This Week", id: "week", value: [moment().startOf('week').add(1, 'day'), moment().startOf('day')] },
  { name: "This Month", id: "month", value: [moment().startOf('month'), moment().startOf('day')] },
  { name: "This Quarter", id: "quarter", value: [moment().startOf('quarter'), moment().startOf('day')] },
  { name: "Last 7 days", id: "-7", value: [moment().startOf('day').subtract(7, 'day'), moment().startOf('day')] },
  { name: "Last 30 days", id: "-30", value: [moment().startOf('day').subtract(30, 'day'), moment().startOf('day')] },
  { name: "Last 90 days", id: "-90", value: [moment().startOf('day').subtract(90, 'day'), moment().startOf('day')] },
];

const getProgressOverVal = ({ key, value }) => progressOverArray.find(row => key === 'value' ? row.value.every((date: Moment | null, index: number) => ((!date && !value[index]) || (date && value[index] && date?.toISOString() === moment(value[index])?.toISOString()))) : value === row[key]);

function ProgressOver() {
  const { push } = useHistory();
  const location = useLocation();
  const { pathname, search } = useMemo(() => location, [location.search, location.pathname]);
  const classes = useStyles();
  const searchParams = useMemo(() => (new URLSearchParams(search)), [search]);
  const params = useStore().params;
  // const [calendarOpen, setCalendarOpen] = React.useState(false);

  const current: any = useMemo(
    () => {
      const val = getProgressOverVal({ key: 'value', value: [searchParams.get('from'), searchParams.get('to')] })?.id;
      return [val]
    },
    [search]
  );

  const initialState = useCallback(() => current, [current]);
  const [state, RenderFn, relay] = useAccordion<{ name: string; id: string; value: null[] | originalMoment.Moment[] }, 'id'>(DefaultList, { multiple: false, accordian: true }, initialState);
  useEffect(() => { putAsync(relay, { type: 'replace', payload: [...current] }); }, [current]);
  const disabled = useMemo(() => {
    const [fromD, toD] = [searchParams.get('from'), searchParams.get('to')];
    const value = [...current]
    if (!(!!fromD && !!toD && moment(fromD).startOf('day').isSame(value[0]) && moment(toD).startOf('day').isSame(value[1]))) {

    }
    if (state.length === current.length) {
      return state.length === 0
        ? true
        : state.every((el: string | number) =>
          (current as Array<typeof el>).includes(el)
        );
    } else {
      return false;
    }
  }, [state, current]);

  const viewDate = useMemo(() => {
    const val = getProgressOverVal({ key: 'id', value: state[0] })?.value;
    return (<> <span>{val && val[0] && val[0].format("DD.MM.YYYY") || "Start Date"}</span> <span>{"-"}</span> <span>{val && val[1] && val[1].format("DD.MM.YYYY") || "End Date"}</span> </>);
  }
    , [state]);
  const rerouter = useCallback(
    (value: [Moment | null, Moment | null]) => {
      if (!!value[0] && !!value[1]) {
        const [fromD, toD] = [searchParams.get('from'), searchParams.get('to')];
        if (!(!!fromD && !!toD && moment(fromD).startOf('day').isSame(value[0]) && moment(toD).startOf('day').isSame(value[1]))) {
          searchParams.delete("from");
          searchParams.delete("to");
          const [start, end] = value.map(d => d!.toDate());
          searchParams.append("from", start.toISOString());
          searchParams.append("to", end.toISOString());
          push({ ...location, search: searchParams.toString() });
        }
      }
      else if (!value[0] && !value[1]) {
        searchParams.delete("from");
        searchParams.delete("to");
        push({ ...location, search: searchParams.toString() });
      }
    }, [search, pathname]
  );

  const applyHandler = useCallback(() => {
    const dates = getProgressOverVal({ key: 'id', value: state[0] }) !== undefined ? getProgressOverVal({ key: 'id', value: state[0] })!.value : [null, null];
    rerouter([...dates] as [Moment | null, Moment | null]);
  }, [search, pathname, state]);

  const applyReset = () => {
    searchParams.delete("from");
    searchParams.delete("to");
    push({ ...location, search: searchParams.toString() });
  }

  // useEffect(() => {
  //   setValue([searchParams.get('from') ? moment(searchParams.get('from')) : null, searchParams.get('to') ? moment(searchParams.get('to')) : null]);
  // }, [search, pathname]);

  useEffect(() => { putAsync(relay, { type: 'replace', payload: current }); }, [pathname, current]);

  if (params.graphType === 'barChart') return <></>;
  return (
    // <div className={classes.root}>
    <RenderFn
      options={progressOverArray}
      Render={RadioButton}
      callback={applyHandler}
      reset={applyReset}
      disabled={disabled}
      title={<SelectedState title={"Completion Over"} state={state} filterType={"delta"} viewDate={viewDate} />}
      filterLenght={current.length}
    />
    // </div>
  );
}
export default memo(ProgressOver)
