import React from 'react'

const BlockCell = (props) => {
    const {id, title} = props.row.original[props.column.value]
    return (
        <div key={id}>
            {title}
        </div>
    )
}

export default BlockCell
