import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        // background: "#FAFAFA",
        padding: "1rem",
        fontFamily: "Source Sans Pro, sans-serif !important",
    },
    tableRoot: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    filtersPadding: {
        // paddingBottom: "24px",
    },
    filterIcondiv: {
        // width:"68px",
        display: "flex",
        // textAlignLast: "end"
    },
    ClosefilterIcondiv: {
        display: "flex",
        justifyContent: "center"
    },
    ongoingTilediv: {
        display: "flex",
        textAlignLast: "end",
        justifyContent: "space-around"
    },
    closeButton: {
        display: "flex",
        justifyContent: "right",
        color: "#005eff",
        cursor: "pointer",
        fontSize: "12px",
        marginBottom: "9px",
        alignItems: "center"
    },
    resetFilter: {
        display: "flex",
        justifyContent: "space-between"
    },
    resetAll: {
        color: "#005eff",
        cursor: "pointer",
        fontSize: "12px",
        marginBottom: "0.5rem"
    },
    filterBy: {
        height: "14px",
        width: "90px",
        fontFamily: "Source Sans Pro, sans-serif !important",
        fontSize: "11px !important",
        fontWeight: "bold",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: 1.27,
        letterSpacing: "normal",
        textAlign: "left",
        color: "#2f3e6c",
        textTransform: "uppercase",
        alignSelf: "center"
    },
    divider: {
        width: "94%",
        height: "1px",
        borderRadius: "10px",
        backgroundColor: "#ddd",
        marginLeft: "16px"
    },
    ongoing: {
        height: "14px",
        fontFamily: "Source Sans Pro, sans-serif !important",
        fontSize: "11px !important",
        fontWeight: "bold",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: 1.27,
        letterSpacing: "normal",
        textAlign: "left",
        color: "#2f3e6c",
        textTransform: "uppercase",
        alignSelf: "center"
    },
    rectangle: {
        width: "8px",
        height: "10px",
        borderRadius: "1px",
        border: "solid 1px #005eff",
        margin: "2px 10px 0px 2px"
    }
}));

export default useStyles;
