import React, { forwardRef, RefObject, FunctionComponent, PropsWithoutRef, memo, useState } from 'react';
import { getSnapshot, Instance, SnapshotOut } from 'mobx-state-tree';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import { ClassNameMap } from '@material-ui/styles';
import useStyles from './ScrollingLayoutStyles';
import Skeleton from '@material-ui/lab/Skeleton';
import { eScreen, ePhase } from '../../models/Common';
import { CellAddress } from '../../models/Brick/CellAddress';
import { useHistory } from 'react-router';
import { useStore } from '../../models/ProvideModel';
import { Link } from '@material-ui/core';

interface IScrollableXRenderData {
  data: Instance<typeof CellAddress>[];
  columns: number;
  x: number;
  screen: SnapshotOut<typeof eScreen>;
  phase: SnapshotOut<typeof ePhase>;
  loading: boolean;
  classes: ClassNameMap;
  handleCol: (v: any) => void;
};

const MakeScrollableXRender = memo(({ data: dataObj, index, style, isScrolling, ...props }: ListChildComponentProps & IScrollableXRenderData) => {
  const { data, columns, x, screen, phase, spaceType, loading, classes, handleCol } = dataObj;
  const realIndex = index - 1;
  const store = useStore()
  const [hover, setHover] = useState<boolean>(false);
  const [linkId, setId] = useState<string>("");
  const urlPath = (col) => {
    if (store.params.section === 'dashboard') {
      if (store.params.phase === 'finishing' && store.params.spaceType === 'tower') {
        return store.params.toPath({ ...getSnapshot(store.params), section: "unit-act-info" }) + `?activity_type_id=${col.id}`;
      }
      else if (store.params.spaceType === 'tca' || store.params.spaceType === 'eca' || store.params.spaceType === 'basement') {
        if (store.params.screen === 'landing' /* && store.params.graphType === 'baratheon' */) {
          return store.params.toPath({ ...getSnapshot(store.params), section: "unit-act-info", tower: col.tower, screen: 'fullscreen' }) + `?unit_type_id=${col.id}`;
        }
        else if (store.params.screen === 'fullscreen') {
          return store.params.toPath({ ...getSnapshot(store.params), section: "unit-act-info" }) + `?activity_type_id=${col.id}&unit_type_id=${store.params.unitType}`;
        }
      }
    }
  };
  const hoverState = (id) => {
    setId(id)
    setHover(true)
  }

  return (realIndex < 0 || realIndex === columns - 1 || !data[realIndex]) ?
    (
      <div
        style={{ ...style, width: style.width! as number - x, left: style.left! as number + x }}
        {...props}
      >
      </div>
    )
    :
    (
      <div
        {...props}
        style={{ ...style, width: style.width! as number - x, left: style.left! as number + x, top: '16px' }}
      // className={screen === "fullscreen" ? classes.towerDisplayFull : ''}
      >
        {loading ? <Skeleton width={40} height={20} variant="text" style={{ alignSelf: "center" }}>
        </Skeleton> :
          phase === "structures" && spaceType === "tower" ?
            <span className={screen === "fullscreen" ? classes.towerSubTitleFull : classes.towerSubTitle}>
              {data[realIndex] ? data[realIndex].name : 'oof'}
            </span> :
            <div style={{ cursor: "pointer", color: "#005eff !important" }}>
              <Link id={`${data[realIndex].id}_${data[realIndex].name}`}
                onMouseEnter={() => hoverState(`${data[realIndex].id}_${data[realIndex].name}`)}
                onMouseLeave={() => setHover(false)}
                href={hover && linkId === `${data[realIndex].id}_${data[realIndex].name}` ? `${window.location.href.split('/', 3).join('/')}${urlPath(data[realIndex] || { id: "" })}` : ``}
                className={classes.underlineOnHover}
                target="popup" style={{ textDecoration: "none" }}
                onClick={(e) => phase === "structures" && spaceType === "tower" ? null : handleCol(data[realIndex] || { id: "" })}
              >
                <span className={screen === "fullscreen" ? classes.towerSubTitleFull : classes.towerSubTitle}>
                  {data[realIndex] ? data[realIndex].name : 'oof'}
                </span></Link></div>
        }
        {/* phase === 'structures' ?
                 (<span className={screen === "fullscreen" ? classes.towerSubTitle2Full : classes.towerSubTitle2}>
                 {
                 store.currentDashboard!.progressDetails && store.currentDashboard!.progressDetails.structures &&
                 store.currentDashboard!.progressDetails.structures.perPour &&
                 store.currentDashboard!.progressDetails.structures.perPour[index - start[tIndex] - 1] ? `${store.currentDashboard!.progressDetails.structures.perPour[index - start[tIndex] - 1] * 100}%` : null
                 }
                 </span>) : null */}
      </div >
    );
});

export interface IXHeadingScrollProps extends PropsWithoutRef<{}> {
  children: FunctionComponent;
  width: number;
  data: Instance<typeof CellAddress>[];
  columns: number;
  totalColumnSortedFiltered: SnapshotOut<typeof CellAddress>[];
  itemSize: (index: number) => number;
  gutter: number;
  screen: SnapshotOut<typeof eScreen>;
  phase: SnapshotOut<typeof ePhase>;
  loading: boolean;
};
export interface IXHeadingScrollRef {
  myRef: RefObject<VariableSizeList>
};

export type IXHeadingScrollComponent = IXHeadingScrollProps & IXHeadingScrollRef;

// TODO: Get rid of useStore call in here and pass everything through props instead.
const XHeadingScroll = ({ width, data, columns, phase, myRef, itemSize, gutter, children, screen, loading }: IXHeadingScrollComponent) => {
  const classes = useStyles({ screen, phase });
  const history = useHistory();
  const store = useStore();
  const handleBrickSection = (col) => {
    document?.getElementById(`${col.id}_${col.name}`)?.removeAttribute("href");
    if (store.params.section === 'dashboard') {
      if (store.params.phase === 'finishing' && store.params.spaceType === 'tower') {
        history.push(`/unit-act-info/${store.params.phase}/${store.params.spaceType}/fullscreen?activity_type_id=${col.id}`);
        // history.push(store.params.toPath({ ...getSnapshot(store.params), section: "unit-act-info" }) + `?activity_type_id=${col.id}`);
      }
      else if (store.params.spaceType === 'tca' || store.params.spaceType === 'eca' || store.params.spaceType === "basement") {
        if (store.params.screen === 'landing' /* && store.params.graphType === 'baratheon' */) {
          history.push(store.params.toPath({ ...getSnapshot(store.params), section: "unit-act-info", tower: col.tower, screen: 'fullscreen' }) + `?unit_type_id=${col.id}`);
        }
        else if (store.params.screen === 'fullscreen') {
          // TODO: Not happy that I have to hardcode unit type here.
          history.push(store.params.toPath({ ...getSnapshot(store.params), section: "unit-act-info" }) + `?activity_type_id=${col.id}&unit_type_id=${store.params.unitType}`);
        }
      }
    }
  };
  return (
    <VariableSizeList
      ref={myRef}
      innerElementType={children}
      style={{ overflow: 'hidden', lineHeight: 1.29, top: screen === "landing" && phase === "structures" && store.params.spaceType === "tower" ? "10px" : "0px" }}
      layout="horizontal"
      height={55}
      width={0.945 * width}
      itemCount={columns}
      itemData={{ data, columns, x: gutter, screen, phase, spaceType: store.params.spaceType, loading, handleCol: handleBrickSection, classes }}
      itemSize={itemSize}
    >
      {MakeScrollableXRender}
    </VariableSizeList>
  )
};

export default forwardRef<VariableSizeList, IXHeadingScrollProps>(
  (props: IXHeadingScrollProps, ref: RefObject<VariableSizeList>) => (<XHeadingScroll {...props} myRef={ref} />));
