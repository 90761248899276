import React from 'react';
import { useMediaQuery } from 'react-responsive';

export const DEVICE_ORIENTATIONS = {
  POTRAIT: 'portrait',
  LANDSCAPE: 'landscape'
}

export const LG_DEVICE_QUERY = { minWidth: 1025 };
export const XS_LANDSCAPE_QUERY = { maxWidth: 767, orientation: DEVICE_ORIENTATIONS.LANDSCAPE };
export const TAB_LANDSCAPE_QUERY = { maxWidth: 1180, orientation: DEVICE_ORIENTATIONS.LANDSCAPE };
export const SM_LANDSCAPE_QUERY = { minWidth: 768, maxWidth: 1024, orientation: DEVICE_ORIENTATIONS.LANDSCAPE };
export const PORTRAIT_QUERY = { orientation: DEVICE_ORIENTATIONS.POTRAIT };

export const Desktop: React.FC = ({ children }): JSX.Element | null => {
  const isDesktop = useMediaQuery(LG_DEVICE_QUERY);
  return isDesktop ? (<>{children}</>) : null;
}

export const MobileLandscape: React.FC = ({ children }): JSX.Element | null => {
  const isMobileLandscape = useMediaQuery(XS_LANDSCAPE_QUERY);
  return (isMobileLandscape) ? (<>{children}</>) : null;
}

export const TabletLandscape: React.FC = ({ children }): JSX.Element | null => {
  const isTabletLandscape = useMediaQuery(SM_LANDSCAPE_QUERY);
  return isTabletLandscape ? (<>{children}</>) : null;
}

export const TabletOrMobileBoth: React.FC = ({ children }): JSX.Element | null => {
  const isTabletLandscape = useMediaQuery(SM_LANDSCAPE_QUERY);
  const isMobileLandscape = useMediaQuery(XS_LANDSCAPE_QUERY);
  return isTabletLandscape || isMobileLandscape ? (<>{children}</>) : null;
}
