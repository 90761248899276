import React, { useCallback, useMemo } from 'react';
import { useStore } from '../../models/ProvideModel';
import { observer } from 'mobx-react-lite';
import { ChecklistSelectActions } from '../../models/MultiSelect';
import { getFileNameFromRow } from '../../utils/utils';

/* {props.row.original[props.column.value] || props.cell.value || "—"} */
const SelectCheckbox = ({ row: { original }, column: { value }, rows }) => {
  const store = useStore();
  const items = useMemo(() => rows.map(({ original }) => ({ id: original[value], fileName: getFileNameFromRow(original) })), [rows, value]);
  const handleClick = useCallback(() => {
    store.checklistMultiSelect.addItems({ action: ChecklistSelectActions.TOGGLE, payload: { items: [{ id: original[value], fileName: getFileNameFromRow(original) }], totalNoItems: items } })
  }, []);

  return (
    <div>
      <input
        style={{ margin: "50%" }}
        type="checkbox"
        name="downloadReport"
        value={original[value]}
        checked={store.checklistMultiSelect.checklistItems.filter(({ id }) => id === original[value]).length}
        onChange={handleClick} />
    </div>
  );
};

export default observer(SelectCheckbox);
