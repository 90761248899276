import React, { memo, Dispatch, ReducerAction } from 'react';
import { TextField, TextareaAutosize } from '@material-ui/core';

export interface IGenericInputProps {
  field: string;
  dispatch: Dispatch<ReducerAction<any>>;
  state: any;
  type: string;
  label: string;
  disabled?: boolean;
};

const GenericInput = ({ field, dispatch, state, type, label, disabled }: IGenericInputProps) => type === "text" ? (
  <TextareaAutosize
    placeholder={label}
    value={state}
    disabled={!!disabled}
    onChange={e => { const input = e.target.value; dispatch({ type: 'REPLACE_GENERIC', payload: { input, type, field } }); }}
  />
)
  : (
    <TextField
    //   label={label}
      type={(type === 'percent' || type === 'numeric') ? 'number' : type}
      value={state.replace(/[^0-9]+/g, "")}
      InputLabelProps={{
        shrink: true,
      }}
      disabled={!!disabled}
      onChange={e => { const input = e.target.value; dispatch({ type: 'REPLACE_GENERIC', payload: { input: (type === 'percent' &&  Number.parseInt(input) > 100) ? state : input, type, field } }); }}
    />
  );

export default memo(GenericInput);