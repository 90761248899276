import { types, flow, getRoot } from 'mobx-state-tree';
import { Instance } from 'mobx-state-tree/dist/internal';
import { Tower } from './Tower';
import { LateStoreModel } from './DataStore';
import { GET_ACTIVITIES_SUMMARY_BY_BLOCK, GET_ACTIVITIES_BY_BLOCK_BY_UNIT, GET_UNITS_BY_BLOCK_BY_ACTIVITY, GET_UNITS_SUMMARY_BY_BLOCK, GET_STEP_BY_UNIT_ACTIVITY_ID } from '../utils/queries';


// type defs
const snagModel = types.model({
  count_closed: types.refinement(types.number, val => Number.isInteger(val)),
  count_for_review: types.refinement(types.number, val => Number.isInteger(val)),
  count_open: types.refinement(types.number, val => Number.isInteger(val)),
});

const unitActivityComboDetailsModel = types.model({
  activity_type_id: types.maybeNull(types.string),
  block_id: types.maybeNull(types.safeReference(Tower)),
  floor_idx: types.maybeNull(types.number),
  floor_name: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  unit_id: types.maybeNull(types.string),
  current_step: types.maybeNull(types.string),
  current_user_designation: types.maybeNull(types.string),
  current_user_name: types.maybeNull(types.string),
  unit_activity_id: types.maybeNull(types.string),
  unit_name: types.maybeNull(types.string),
  activity_name: types.maybeNull(types.string),
  actualEnd: types.maybeNull(types.string),
  actualStart: types.maybeNull(types.string),
  planEnd: types.maybeNull(types.string),
  planStart: types.maybeNull(types.string),
  snag_counts: types.maybeNull(snagModel),
  lastUpdated: types.maybeNull(types.string),
}).views(self => ({
  get derivedStatus() {
    if (self.status === null) { return "plainWrong"; }
    else if (self.status === '8_done') { return "Completed"; }
    else if (self.status === '3_active') { return "On Going"; }
    else if (self.status === '1_planned') { return "Not Started"; }
    return "plainWrong"
  },
  get activityName() {
    return self.activity_name;
  }
}));

const activitySummaryModel = types.model({
  activity_name: types.maybeNull(types.string),
  activity_type_id: types.maybeNull(types.string),
  block_id: types.maybeNull(types.safeReference(Tower)),
  total: types.refinement(types.number, val => Number.isInteger(val)),
  completed: types.refinement(types.number, val => Number.isInteger(val)),
  in_progress: types.refinement(types.number, val => Number.isInteger(val)),
  activity_precedence: types.maybeNull(types.number),
  actual_end: types.maybeNull(types.string),
  actualEnd: types.maybeNull(types.string),
  actualStart: types.maybeNull(types.string),
  planEnd: types.maybeNull(types.string),
  planStart: types.maybeNull(types.string),
  lastUpdated: types.maybeNull(types.string),
  unit_type: types.maybeNull(types.string),
  snag_counts: types.maybeNull(snagModel),
}).views(self => ({
  get derivedStatus() {
    if (self.total < self.completed + self.in_progress) { return "plainWrong"; }
    else if (self.total === self.completed) { return "Completed"; }
    else if (self.in_progress || self.completed) { return "On Going"; }
    else if (self.in_progress === 0 && self.completed === 0) { return "Not Started"; }
    return "plainWrong"
  },
  get progress(): number { return self.completed / self.total; },
  get activityName() {
    return self.activity_name;
  }
}));


const unitSummaryModel = types.model({
  unit_id: types.maybeNull(types.string),
  unit_name: types.maybeNull(types.string),
  block_id: types.maybeNull(types.safeReference(Tower)),
  block_name: types.maybeNull(types.string),
  total: types.refinement(types.number, val => Number.isInteger(val)),
  completed: types.refinement(types.number, val => Number.isInteger(val)),
  in_progress: types.refinement(types.number, val => Number.isInteger(val)),
  not_started: types.refinement(types.number, val => Number.isInteger(val)),
  // actual_end: types.maybeNull(types.string),
  // actualEnd: types.maybeNull(types.string),
  // actualStart: types.maybeNull(types.string),
  // planEnd: types.maybeNull(types.string),
  // planStart: types.maybeNull(types.string),
  lastUpdated: types.maybeNull(types.string),
  snag_counts: types.maybeNull(snagModel),
}).views(self => ({
  get derivedStatus() {
    if (self.total < self.completed + self.in_progress) { return "plainWrong"; }
    else if (self.total === self.completed) { return "Completed"; }
    else if (self.in_progress || self.completed) { return "On Going"; }
    else if (self.in_progress === 0 && self.completed === 0) { return "Not Started"; }
    return "plainWrong"
  },
  get progress(): number { return self.completed / self.total; }
}));


const stepModel = types.model({
  unit_activity_id: types.maybeNull(types.string),
  block: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  step_name: types.maybeNull(types.string),
  step_number: types.maybeNull(types.string),
  current_user_name: types.maybeNull(types.string),
  actual_start: types.maybeNull(types.string),
  unit_name: types.maybeNull(types.string),
  activity_name: types.maybeNull(types.string),
  floor_name: types.maybeNull(types.string),
}).views(self => ({
  get derivedStatus() {
    if (self.status === null) { return "plainWrong"; }
    else if (self.status === '8_done') { return "Completed"; }
    else if (self.status === '3_active') { return "On Going"; }
    else if (self.status === '1_planned') { return "Not Started"; }
    return "plainWrong"
  },
}));

const UnitActivityDetails = types.model({
  activitySummary: types.maybeNull(types.array(activitySummaryModel)),
  unitSummary: types.maybeNull(types.array(unitSummaryModel)),
  unitActivityComboDetails: types.maybeNull(types.array(unitActivityComboDetailsModel)),
  steps: types.maybeNull(types.array(stepModel)),
  loading: true,
  error: types.maybeNull(types.string),
}).views(self => ({
  // get userName(): string { return self.isAuthenticated ? jwt_decode(self.jwtToken).username : ''; },
  // get customerName(): string { return self.isAuthenticated ? jwt_decode(self.jwtToken).customer_name : ''; },
  // get customerId(): string { return self.isAuthenticated ? jwt_decode(self.jwtToken).this.customer_id : ''; }
})).actions(self => ({
  getActivitySummaryByBlock: flow(function* populate(block_id) {
    self.activitySummary = null
    try {
      const data: any = yield (getRoot(self) as Instance<typeof LateStoreModel>).fetch(GET_ACTIVITIES_SUMMARY_BY_BLOCK, {
        variables: { block_id }
      });
      self.activitySummary = data.activities
      self.loading = false;
    } catch (err) {
      console.error(err.message);
    }
  }),
  getActivitySummaryByUnit: flow(function* populate(block_id, unit_id) {
    self.unitActivityComboDetails = null
    try {
      const data: any = yield (getRoot(self) as Instance<typeof LateStoreModel>).fetch(GET_ACTIVITIES_BY_BLOCK_BY_UNIT, {
        variables: { block_id, unit_id }
      });
      self.unitActivityComboDetails = data.activities
      self.loading = false;
    } catch (err) {
      console.error(err.message);
    }
  }),
  getUnitSummaryByBlock: flow(function* populate(block_id) {
    self.unitSummary = null
    try {
      const data: any = yield (getRoot(self) as Instance<typeof LateStoreModel>).fetch(GET_UNITS_SUMMARY_BY_BLOCK, {
        variables: { block_id }
      });
      self.unitSummary = data.units
      self.loading = false;
    } catch (err) {
      console.error(err.message);
    }
  }),
  getUnitSummaryByActivity: flow(function* populate(block_id, activity_type_id) {
    self.unitActivityComboDetails = null
    try {
      const data: any = yield (getRoot(self) as Instance<typeof LateStoreModel>).fetch(GET_UNITS_BY_BLOCK_BY_ACTIVITY, {
        variables: { block_id, activity_type_id }
      });
      self.unitActivityComboDetails = data.units
      self.loading = false;
    } catch (err) {
      console.error(err.message);
    }
  }),
  getStepByunitActivityId: flow(function* populate(unit_activity_id) {
    self.steps = null
    try {
      const data: any = yield (getRoot(self) as Instance<typeof LateStoreModel>).fetch(GET_STEP_BY_UNIT_ACTIVITY_ID, {
        variables: { unit_activity_id }
      });
      self.steps = data.steps
      self.loading = false;
    } catch (err) {
      console.error(err.message);
    }
  }),
}))


// *exports
export default UnitActivityDetails;
export type IUnitActivityDetailsModel = typeof UnitActivityDetails.Type;
export type IActivitySummaryModel = typeof activitySummaryModel.Type;
