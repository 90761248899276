import React, { memo, Dispatch, ReducerAction, ChangeEvent, useState, useRef, CSSProperties } from 'react';
import FsLightbox from "fslightbox-react";
import CloseDefault from '../../assets/images/menuNavigation/Close Default.svg';
import refreshButton from '../../assets/images/refresh.svg';
import { uploadImage } from '../../api/transactionServer';
import config from '../../configs/clientConfig';
import LoadingSpinner from '../loadingSkelaton/LoadingSpinner';

export interface IImagePickerProps {
  field: string;
  dispatch: Dispatch<ReducerAction<any>>;
  state: any[];
  type?: string;
  label?: string;
  disabled?: boolean;
};
// Remember that you'll probably have to do some state shenanigans with upload
const baseUrl = config.baseURL;

const ImagePicker = ({ field, dispatch, state, disabled }: IImagePickerProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [bigImg, setImg] = React.useState('');
  const [toggle, setToggle] = React.useState(false);
  const [retry, setRetry] = useState(false);
  const imgRefs = useRef<HTMLImageElement[]>([]);
  const onChangeCallback = (field: string) => (ev: ChangeEvent<HTMLInputElement>) => {
    const input: File | null = (ev?.target?.files && ev?.target.files[0]) || null;
    if (input !== null) {
      setLoading(true);
      uploadImage(input).then(res => {
        setLoading(false);
        dispatch({ type: 'ADD_IMAGE', payload: { field, input: res.fileName } });
      }).catch(err => {
        setLoading(false);
        setError(err.response?.errorData || err.message);
      })
    }
  };
  const retryCallback = () => {
    setRetry(false);
    imgRefs.current.forEach(img => {
      const src = img.getAttribute('src');
      img.setAttribute('src', '');
      img.setAttribute('src', src!);
      imgRefs.current.shift();
    });
  };

  const largePhoto = (id) => {
    setImg(id)
    setToggle(t => !t)
  }

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      {loading && (<LoadingSpinner />)}
      <div style={{ display: "flex", flexWrap: "wrap", flexShrink: 1 }}> {
        state.map(key => (
          <div style={{ display: "flex" }} key={key}>
            <img
              src={`${baseUrl}small/${key}`}
              alt={key}
              style={{ maxHeight: 120, maxWidth: 120, margin: "14px 3px 10px 0px", cursor: "pointer" }}
              onError={(ev) => { setRetry(_ => true); imgRefs.current.push(ev.currentTarget); }}
              onClick={() => largePhoto(key)}
            />
            {!disabled && (<div>
              <img src={CloseDefault} style={{ cursor: "pointer" }} onClick={() => { dispatch({ type: 'REMOVE_IMAGE', payload: { field, input: key } }); }}></img>
            </div>)
            }
          </div>
        )
        )}
        {(bigImg) && <FsLightbox
          key={bigImg}
          toggler={toggle}
          sources={[`${baseUrl}large/${bigImg}`]}
          type="image"
          onClose={() => { setImg(""); }}
        />
        }      </div>
      {!disabled && (<div style={{ alignSelf: "center" }}>
        <input
          type="file"
          disabled={loading}
          id="avatar" name="avatar"
          accept="image/png, image/jpeg"
          onChange={onChangeCallback(field)}
        />
      </div>)}
      {retry && (
        <div style={{ display: "flex", fontSize: ".8em", maxWidth: "26em" }}>
          <p>Sometimes, the image is not immediately available after uploading, and takes a few seconds. You can retry loading the images by clicking here.
            <span onClick={retryCallback} style={refreshClearButtons}>
              <img src={refreshButton} alt="refresh" height={15} style={{ marginLeft: "4px", marginRight: "4px" }} />
              <span>Retry</span>
            </span>
          </p>
        </div>
      )}
      {error && (<span style={{ color: "red" }}>{error}</span>)}
    </div>
  );
};

const refreshClearButtons: CSSProperties = {
  cursor: "pointer",
  color: "#005EFF",
  fontSize: "1.2em",
  fontFamily: "Source Sans Pro, sans-serif !important",
  fontWeight: 600,
  lineHeight: 1.3,
  fontStretch: "normal",
  textTransform: "capitalize",
  alignItems: "center",
};

export default memo(ImagePicker);
