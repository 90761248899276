import React from "react";
import { Grid } from "@material-ui/core";
import useStyles from "./LegacyUnitInfoSummaryStyles";


const TableSnagInformation = ({ block, units, activities }: { block: string; units: number; activities: number; }) => {
  const classes = useStyles();
  return (
    <div className={classes.rootDiv}>
      <Grid container className={classes.GridBox}>
        <Grid key="1" item xs={2} style={{ flexBasis: "auto", display: "flex", marginRight: "18px" }}>BLOCK: <span className={classes.PropertyValue}>{block}</span></Grid>
        <Grid key="2" item xs={2} style={{ flexBasis: "auto", display: "flex", marginRight: "18px" }}>UNITS: <span className={classes.PropertyValue}>{units.toLocaleString('en-GB', { minimumIntegerDigits: 2 })}</span></Grid>
        <Grid key="4" item xs={3} style={{ flexBasis: "auto", display: "flex", marginRight: "18px" }}>ACTIVITY TYPES: <span className={classes.PropertyValue}>{activities? activities?.toLocaleString('en-GB', { minimumIntegerDigits: 2 }) : "00"}</span></Grid>
      </Grid>
    </div>
  );
}
export default React.memo(TableSnagInformation);
