import { makeStyles, Theme } from "@material-ui/core/styles";
import activeArrowIcon from "../../assets/images/Arrow-Circle-active.svg";
import disabledIcon from "../../assets/images/Arrow-Circle-Disabled.svg";
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // backgroundColor: "#ffffff",
    flexDirection: 'column',
    width: '80%',
    display: "flex",
    flexGrow: 1,
    flexBasis: "auto",
    flexShrink: 1,
  },
  mainDiv: {
    display: "flex",
    flexGrow: 1,
    flexBasis: "auto",
    flexShrink: 1,
    flexDirection: "row"
  },
  Structureslanding: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    marginTop: "16px",
  },
  landing: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexBasis: 'auto',
    flexGrow: 1,
    flexShrink: 1
    // marginTop:"40px"
  },
  lPanel: {
    flex: "0 0 auto",
    paddingBottom: "30px"
  },
  towerTitle: {
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'Source Sans Pro, sans-serif',
    fontSize: '20px',
    fontWeight: 700,
    color: '#005eff',
  },
  backBtn: {
    fontSize: 14,
    fontFamily: 'Source Sans Pro, sans-serif',
    fontWeight: "normal",
    alignSelf: "center",
    marginLeft: 6,
  },
  backDiv: {
    // color: '#005eff',
    border: "none",
    textAlign: "center",
    marginLeft: "6px",
    width: "60px",
    transition: "margin 0.5s",
    cursor: "pointer",
    "& span": {
      cursor: "pointer",
      display: "inline-block",
      position: "relative",
      transition: "0.5s",
      "&:after": {
        position: "absolute",
        opacity: 0,
        top: 0,
        right: "-15px",
        transition: "0.5s",
      },
    },
    "&:hover": {
      color: "#585858",
      "& span": {
        paddingLeft: "8px",
        "&:hover": {
          opacity: 1,
          right: 0,
          color: "#585858"

        }
      }
    }
  },
  changeImage: {
    color: '#005eff',
    background:
      `url(${activeArrowIcon}) no-repeat`,
    display: "inline-block",
    marginLeft: "16px",
    "&:hover": {
      "&:before": {
        background:
          `url(${disabledIcon}) no-repeat`,
      },
      background:
        `url(${disabledIcon}) no-repeat`,
    }
  },
  listOfFloorsandActivities: {
    width: "133px",
    height: "18px",
    display: "flex",
    alignSelf: "center",
    justifyContent: "space-between",
    margin: "1rem",
    color: "#2f3e6c",
    lineHeight: 1.29,
    letterSpacing: "normal",
    fontFamily: 'Source Sans Pro, sans-serif',

  },
  dropdownDiv: {
    display: "flex",
    justifyContent: "space-between",
    height: "10%",
    alignItems: "center",
    marginTop: "10px"
  },
  verticalDivider: {
    width: "2px",
    height: "12px",
    backgroundColor: "#cecece",
    alignSelf: "center",
    marginTop: "1px",
  },
  fix: {
    display: 'flex',
    height: 2,
    position: 'sticky',
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: "2px",
    bottom: 10
  }
}));

export default useStyles;
