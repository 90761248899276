import React, { memo } from "react";
import { useObserver } from "mobx-react-lite";
import { useParams } from 'react-router';
import useStyles from './ActivityInfoPageStyles';
/* import ActPerBlock from "../../containers/activityInfo/ActPerBlock";
 * import ActPerUnit from "../../containers/activityInfo/ActPerUnit"; */
import StepPerUnitPerActivity from "../../containers/stepInfo/StepPerUnitPerActivity";
/* import ActPerUnitFat from "../../containers/activityInfo/ActPerUnitFat"; */

const ActivityInfoPage = () => {
  const classes = useStyles();
  const { unitActivity } = useParams();
  return useObserver(() =>
    <div className={classes.root}>
      <StepPerUnitPerActivity classes={classes} unitActivity={unitActivity} />
    </div>
  );
};

export default memo(ActivityInfoPage);
