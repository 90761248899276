import React, { useState } from 'react';
import { DetailBasementBlock } from './detailBasementBlock'
import { ITabsList } from '../../containers/tabTiles/tabTiles';
import { Link } from '@material-ui/core';

export interface ISidebar {
  data: ITabsList[];
  handleClick(id: string): void;
  selected: string;
  screen: string;
  href(id?: string): any
  screenProps?: any
}

const SideBar = ({ data, handleClick, selected, screen, href, screenProps }: ISidebar) => {
  const [hover, setHover] = useState<boolean>(false);
  const [linkId, setId] = useState<string>("");

  const removehref = (id, name) => {
    return document?.getElementById(`${id}_${name}`)?.removeAttribute("href")
  }
  const hoverState = (id) => {
    setId(id)
    setHover(true)
  }
  return (
    <>
      {data.map(({ id, name, graphType, stats }) => (
        <Link
          key={`${id}_${name}`}
          id={`${id}_${name}`}
          onMouseEnter={() => hoverState(id)}
          onMouseLeave={() => setHover(false)}
          href={hover && linkId === id ? href(id) : href(undefined)}
          target="popup"
          style={{
            cursor: selected === id ? "auto" : "pointer", backgroundImage: "none", width: "100%"
          }}
          onClick={selected === id ? () => removehref(id, name) : () => { removehref(id, name); handleClick(id) }}
        >
          <DetailBasementBlock id={id} name={name} graphType={graphType} stats={stats} selected={selected === id} screen={screen} screenProps={screenProps} />
        </Link>
      ))
      }
    </>
  )
};

export default React.memo(SideBar);
